import { z } from "zod";

export enum EvaluationStatus {
  Draft = "draft",
  Evaluated = "evaluated",
}

export const EVALUATION_STATUS = {
  [EvaluationStatus.Draft]: "Draft",
  [EvaluationStatus.Evaluated]: "Evaluated",
};

export interface QueryOptions {
  enabled?: boolean;
}

export const BasicUserSchema = z.object({
  id: z.number(),
  avatar: z.string(),
  email: z.string(),
  fullName: z.string(),
});

export const BasicUserListResponseSchema = BasicUserSchema.array();

export type BasicUser = z.infer<typeof BasicUserSchema>;

export type BasicUserListResponse = z.infer<typeof BasicUserListResponseSchema>;

export enum RatingChoice {
  Null = "",
  Poor = "poor",
  Unsatisfactory = "unsatisfactory",
  Satisfactory = "satisfactory",
  VerySatisfactory = "very_satisfactory",
  Outstanding = "outstanding",
}

export const EVALUATION_RATINGS = {
  [RatingChoice.Null]: 0,
  [RatingChoice.Poor]: 1,
  [RatingChoice.Unsatisfactory]: 2,
  [RatingChoice.Satisfactory]: 3,
  [RatingChoice.VerySatisfactory]: 4,
  [RatingChoice.Outstanding]: 5,
};

export const BasicEvaluationRatingSchema = z.object({
  id: z.number(),
  evaluatee: BasicUserSchema,
  evaluationId: z.number(),
  isSelfEvaluation: z.boolean(),
  projectId: z.number().optional(),
  projectName: z.string().optional(),
  rating: z.nativeEnum(RatingChoice).or(z.literal("")),
  ratingNotes: z.string().optional(),
});

export type BasicEvaluationRating = z.infer<typeof BasicEvaluationRatingSchema>;

export enum EvaluationStep {
  EvaluateSelf = 1,
  SelectMembers = 2,
  EvaluateMembers = 3,
  RankMembers = 4,
}
