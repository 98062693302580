import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";
import { Toaster as ToasterBase } from "sonner";

export function Toaster() {
  return (
    <ToasterBase
      className="flex w-full items-center justify-center"
      icons={{
        error: (
          <div className="rounded-full bg-error-main p-1">
            <XMarkIcon className="size-3 text-primary-contrast-text" />
          </div>
        ),
        info: (
          <div className="flex size-5 items-center justify-center rounded-full bg-info-main">
            <CheckIcon className="size-4 text-primary-contrast-text" />
          </div>
        ),
        warning: (
          <div className="rounded-full bg-warning-main p-1">
            <ExclamationTriangleIcon className="size-3 text-primary-contrast-text" />
          </div>
        ),
        success: (
          <div className="rounded-full bg-success-main p-1">
            <CheckIcon className="size-3 text-primary-contrast-text" />
          </div>
        ),
      }}
      position="top-center"
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            "flex items-center gap-3 min-w-[31.25rem] px-[1.125rem] py-[0.9375rem] rounded-lg",
          error: "bg-error-light-bg text-error-dark",
          info: "bg-info-light text-primary-main",
          warning: "bg-warning-light-bg text-warning-dark",
          success: "bg-success-light-bg text-success-dark",
        },
      }}
    />
  );
}
