import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";

import { cn } from "@/utils/ui";

const BasePopover = PopoverPrimitive.Root;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 10, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      align={align}
      className={cn(
        "z-50 max-h-[26.6875rem] w-[20.5rem] rounded-lg overflow-auto bg-white p-4 shadow-elevation10 outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      ref={ref}
      sideOffset={sideOffset}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

interface PopoverProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>,
    "content"
  > {
  anchor?: React.ReactNode;
  anchorAsChild?: boolean;
  className?: string;
  trigger?: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
  open?: boolean;
  triggerAsChild?: boolean;
}

const Popover = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverProps
>(
  (
    {
      anchor,
      anchorAsChild = true,
      className,
      content,
      disabled,
      open,
      trigger,
      triggerAsChild = true,
      ...props
    },
    ref,
  ) => (
    <BasePopover open={open}>
      {Boolean(anchor) && (
        <PopoverAnchor asChild={anchorAsChild}>{anchor}</PopoverAnchor>
      )}
      {Boolean(trigger) && (
        <PopoverTrigger asChild={triggerAsChild} disabled={disabled}>
          {trigger}
        </PopoverTrigger>
      )}
      <PopoverContent
        className={cn(
          "max-h-[calc(var(--radix-popover-content-available-height)-0.5rem)]",
          className,
        )}
        ref={ref}
        {...props}
      >
        {content}
      </PopoverContent>
    </BasePopover>
  ),
);
Popover.displayName = "Popover";

export { Popover };
