import { createContext, useContext } from "react";

import type { useNavigationBlocker as useNavigationBlockerBase } from "@/utils/hooks";

export const NavigationBlockerContext = createContext<
  ReturnType<typeof useNavigationBlockerBase>
>({
  cancelNavigate: () => null,
  confirmNavigate: () => null,
  disableNextBlocker: (_fn?: () => void | Promise<void>) => null,
  isBlocked: false,
  status: "idle",
});

export const useNavigationBlocker = () => useContext(NavigationBlockerContext);
