import { StarIcon } from "@heroicons/react/24/solid";
import { SelectItem } from "@radix-ui/react-select";
import { type ComponentProps } from "react";
import { type FieldPath, type FieldValues } from "react-hook-form";

import { Checkbox } from "@/components/ui/Checkbox";
import { Form } from "@/components/ui/Form";
import { Rating } from "@/components/ui/Rating";
import {
  type MultipleSelectProps,
  Select,
  type SelectOption,
} from "@/components/ui/Select";
import { cn } from "@/utils/ui";

export interface RatingSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ComponentProps<typeof Form.Field<TFieldValues, TName>>,
    "render"
  > {
  className?: string;
  label: string;
  onChange?: MultipleSelectProps["onChange"];
  value?: SelectOption[] | null;
}

export function RatingSelectField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  className,
  label,
  onChange,
  value: controlledValue,
  ...props
}: RatingSelectFieldProps<TFieldValues, TName>) {
  const ratingOptions = [...Array<number>(6)]
    .map((_, i) => {
      return {
        label: `${i}`,
        value: i,
      };
    })
    .reverse();

  return (
    <Form.Field
      render={({ field: { value, onChange: handleChange, ...field } }) => (
        <Form.Item className={cn("space-y-[0.625rem]", className)}>
          <Form.Label className="text-subtitle2 text-text-secondary">
            {label}
          </Form.Label>
          <Select
            closeOnSelect={false}
            onChange={(selected, clicked) => {
              handleChange(selected?.map((option) => option.value));
              onChange?.(selected, clicked);
            }}
            options={ratingOptions}
            renderItem={({ optionProps, checkboxProps, ...itemProps }) => {
              return (
                <SelectItem {...itemProps} value={`${optionProps.value}`}>
                  <Checkbox {...checkboxProps} />
                  <Rating
                    className="flex items-center"
                    icon={<StarIcon className="size-5" />}
                    inactiveRatingClassName={
                      checkboxProps.checked ? "text-other-backdrop" : undefined
                    }
                    readOnly
                    size="medium"
                    value={Number(optionProps.value)}
                  />
                </SelectItem>
              );
            }}
            selectClassName="ring-actions-selected"
            type="multiple"
            value={
              controlledValue ??
              ratingOptions.filter((option) =>
                (value as number[] | null)?.includes(option.value),
              )
            }
            {...field}
          />
        </Form.Item>
      )}
      {...props}
    />
  );
}
