import { useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";

import { api, handleApiError, validateSchema } from "../utils";
import { EvaluationCycleSchema, evaluationCyclesKeys } from "./constants";

const getCurrentEvaluationCycle = async () => {
  const response = await api.get("evaluation-cycles/current/");

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleSchema, data);
};

export const useGetCurrentEvaluationCycle = () => {
  const { data, isFetched, isFetching, isLoading } = useQuery({
    queryFn: getCurrentEvaluationCycle,
    queryKey: evaluationCyclesKeys.current,
  });

  const isCycleLoading = isLoading;
  return { currentCycle: data || null, isFetched, isFetching, isCycleLoading };
};
