export const getPaginationVisiblePages = ({
  boundaryCount = 1,
  page,
  pageCount,
  separator = "...",
  siblingCount = 1,
}: {
  boundaryCount?: number;
  page: number;
  pageCount: number;
  separator?: string;
  siblingCount?: number;
}) => {
  const maxSlots = 2 * (boundaryCount + siblingCount + 1) + 1;

  if (pageCount <= maxSlots) {
    return [Array.from({ length: pageCount }, (_, i) => i + 1)];
  }

  const firstPage = 1;
  const midCount = 2 * siblingCount + 1;
  const closerToStart = (page - firstPage) / (pageCount - page) < 1;
  const startEllipsisIndex = maxSlots - boundaryCount;
  const endEllipsisIndex = pageCount - (maxSlots - 1) + boundaryCount;
  const withinEllipses =
    page >= startEllipsisIndex - 1 && page <= endEllipsisIndex + 1;

  if (withinEllipses)
    return [
      [firstPage],
      { key: `l-${separator}`, separator },
      Array.from({ length: midCount }, (_, i) => page - siblingCount + i),
      { key: `r-${separator}`, separator },
      [pageCount],
    ];

  if (closerToStart)
    return [
      Array.from({ length: maxSlots - boundaryCount - 1 }, (_, i) => i + 1),
      { key: separator, separator },
      [pageCount],
    ];

  return [
    [1],
    { key: separator, separator },
    Array.from(
      { length: maxSlots - boundaryCount - 1 },
      (_, i) => pageCount - (maxSlots - boundaryCount - 1) + i + 1,
    ),
  ];
};
