import { createFileRoute } from "@tanstack/react-router";
import type { z } from "zod";

import { formSchema } from "@/components/pages/MemberDetailPage/Tabs/SelfEvaluationsTab/FilterForm/utils";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

const routeParamsSchema = TableStateBaseParamsSchema.extend({
  filters: formSchema.optional(),
}).transform(({ per = 0, grouping = [], ordering = [], ...schema }) => ({
  ...schema,
  per,
  grouping: [...new Set(["isPreviousCycle", ...grouping])],
  ordering: [...new Set(["isPreviousCycle", ...ordering])],
}));

export type RouteParams = z.infer<typeof routeParamsSchema>;

export const Route = createFileRoute(
  "/admin/cycles/$cycleId/members/$memberId/self_evaluations",
)({
  validateSearch: (search) => routeParamsSchema.parse(search),
  beforeLoad: () => {
    return { routeId: null };
  },
});
