import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useLoaderData } from "@tanstack/react-router";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { EvaluationCycleStatus } from "@/api/evaluationCycles/constants";
import { useGetEvaluationCycleById } from "@/api/evaluationCycles/useGetEvaluationCycleById";
import { EvaluationCycleStatusIndicator } from "@/components/app/EvaluationCycleStatusIndicator";
import { Breadcrumb } from "@/components/ui/Breadcrumb";
import { Button } from "@/components/ui/Button";
import { Label } from "@/components/ui/Label";
import { Progress } from "@/components/ui/Progress";
import { Spinner } from "@/components/ui/Spinner";
import { useRouteBreadcrumbs } from "@/utils/hooks/useRouteBreadcrumbs";

export function EvaluationCycleDetailPage() {
  const { t } = useTranslation();
  const routes = useRouteBreadcrumbs();
  const cycleId = Number.parseInt(
    useLoaderData({ from: "/admin/cycles/$cycleId/" }),
  );

  const { evaluationCycle, isFetching } = useGetEvaluationCycleById(cycleId);

  return (
    <div className="flex flex-auto flex-col items-stretch gap-6 px-10 pb-16 pt-12">
      <div className="flex items-center justify-between gap-2">
        <div className="flex flex-col gap-2">
          <h3 className="text-h3 text-text-primary">Cycle Details</h3>
          <Breadcrumb routes={routes} />
        </div>
        <div className="flex items-center gap-6">
          <Button disabled={isFetching} variant="outlined">
            <ArrowDownTrayIcon className="size-[1.25rem]" />
            {t("evaluationCycles.detail.buttonDownloadCSV")}
          </Button>
          <Button disabled={isFetching}>
            <PencilSquareIcon className="size-[1.375rem]" />
            <span>{t("evaluationCycles.detail.buttonEdit")}</span>
          </Button>
        </div>
      </div>
      <div className="flex min-h-[8.1875rem] flex-col justify-center gap-2 rounded-2xl px-8 py-6 shadow-elevation1">
        {isFetching ? (
          <Spinner className="!h-full" />
        ) : (
          <>
            <Label className="h-[1.8rem] text-h6 text-text-primary">
              {evaluationCycle?.name}
            </Label>
            <div className="flex items-center justify-start gap-[6.5rem]">
              <Progress
                className="w-full max-w-[47.4275rem] grow"
                label={t("evaluationCycles.detail.progress")}
              />
              <div className="flex shrink-0 flex-col gap-1">
                <Label className="text-subtitle2 text-text-secondary">
                  {t("evaluationCycles.detail.status")}
                </Label>
                <div className="h-[1.25125rem]">
                  <EvaluationCycleStatusIndicator
                    status={
                      evaluationCycle?.status || EvaluationCycleStatus.Inactive
                    }
                  />
                </div>
              </div>
              <div className="flex shrink-0 flex-col gap-1">
                <Label className="text-subtitle2 text-text-secondary">
                  {t("evaluationCycles.detail.dueDate")}
                </Label>
                <p className="h-[1.25125rem] text-body2 text-text-primary">
                  {format(
                    evaluationCycle?.deadline || new Date(),
                    "MMM dd, yyyy",
                  )}
                </p>
              </div>
              <div className="flex shrink-0 flex-col gap-1">
                <Label className="text-subtitle2 text-text-secondary">
                  {t("evaluationCycles.detail.created")}
                </Label>
                <p className="h-[1.25125rem] text-body2 text-text-primary">
                  {format(
                    evaluationCycle?.createdAt || new Date(),
                    "MMM dd, yyyy",
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
