import { useCallback } from "react";

import type { User } from "@/api/users";
import { UserThumbnail } from "@/components/app/UserThumbnail";
import {
  SearchSelect,
  type SearchSelectProps,
} from "@/components/ui/SearchSelect";

type BaseProps = SearchSelectProps["Item"];

export interface MemberSelectItemProps extends BaseProps {
  user: User;
}

export function MemberSelectItem({
  option,
  user,
  ...props
}: MemberSelectItemProps) {
  const renderLabel = useCallback(
    () => <UserThumbnail showEmail={false} user={user} />,
    [user],
  );

  return (
    <SearchSelect.Item
      key={option.value}
      option={option}
      renderLabel={renderLabel}
      {...props}
    />
  );
}
