import { TrashIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Link } from "@tanstack/react-router";

import { EvaluationCycleStatus } from "@/api/evaluationCycles";
import type { EvaluationRating } from "@/api/evaluationRatings";
import { useEvaluationsDashboardModal } from "@/components/pages/EvaluationsDashboardPage/hooks/useEvaluationsDashboardModal";
import { IconButton } from "@/components/ui/IconButton";

export interface TableRowActionProps {
  evaluationRating: EvaluationRating;
}

export function TableRowAction({ evaluationRating }: TableRowActionProps) {
  const modalStore = useEvaluationsDashboardModal();

  const handleDeleteClick = () => {
    modalStore.openDeleteDialog({ evaluationRating });
  };

  return (
    <div className="flex w-[8.25rem] items-center justify-end gap-6 max-md:absolute max-md:inset-y-0 max-md:right-2 max-md:w-[3.125rem] max-md:flex-col-reverse max-md:justify-center max-md:border-l max-md:border-other-divider max-md:pl-2 md:gap-2">
      {evaluationRating.evaluation.cycle.status ===
        EvaluationCycleStatus.Active && (
        <div className="max-md:p-[0.5625rem]">
          <IconButton
            icon={<TrashIcon className="size-4 text-error-main md:size-5" />}
            onClick={handleDeleteClick}
          />
        </div>
      )}
      <div className="max-md:p-[0.5625rem]">
        <Link
          params={{ evaluationId: `${evaluationRating.evaluation.id}` }}
          search={{
            evaluateeId: evaluationRating.evaluatee.id,
            projectId: evaluationRating.projectId,
          }}
          to="/evaluations/$evaluationId"
        >
          <IconButton
            icon={
              <ArrowRightIcon className="size-4 text-text-secondary md:size-5" />
            }
          />
        </Link>
      </div>
    </div>
  );
}
