import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import {
  type CaptionProps,
  useDayPicker,
  useNavigation,
} from "react-day-picker";

import { IconButton } from "@/components/ui/IconButton";
import { Select } from "@/components/ui/Select";
import { cn } from "@/utils/ui";

const months = Array.from({ length: 12 }, (_, i) => {
  return {
    label: format(new Date(2024, i), "MMMM"),
    value: i,
  };
});

export function CustomCaption(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const dayPicker = useDayPicker();

  const fromYear = dayPicker.fromYear ?? 1900;
  const toYear = dayPicker.toYear ?? 2099;

  const years = Array.from({ length: toYear - fromYear + 1 }, (_, i) => {
    return { label: `${fromYear + i}`, value: fromYear + i };
  });

  return (
    <div className={dayPicker.classNames.caption}>
      <div className="flex size-10 items-center justify-center">
        <IconButton
          className={cn(
            dayPicker.classNames.nav_button,
            dayPicker.classNames.nav_button_previous,
          )}
          disabled={!previousMonth}
          icon={
            <ChevronLeftIcon className="size-4 stroke-[2.5px] text-text-secondary" />
          }
          onClick={() => {
            previousMonth && goToMonth(previousMonth);
          }}
        />
      </div>

      <Select
        className={cn(
          dayPicker.classNames.dropdown,
          dayPicker.classNames.dropdown_month,
        )}
        onChange={(e) => {
          goToMonth(
            new Date(props.displayMonth.getFullYear(), Number(e?.value)),
          );
        }}
        options={Object.values(months)}
        value={months[props.displayMonth.getMonth()]}
        valueClassName="text-subtitle2 flex-1 text-left"
      />

      <Select
        className={cn(
          dayPicker.classNames.dropdown,
          dayPicker.classNames.dropdown_year,
        )}
        onChange={(e) => {
          goToMonth(new Date(Number(e?.value), props.displayMonth.getMonth()));
        }}
        options={years}
        value={{
          label: `${props.displayMonth.getFullYear()}`,
          value: props.displayMonth.getFullYear(),
        }}
        valueClassName="text-subtitle2 flex-1 text-left"
      />

      <div className="flex size-10 items-center justify-center">
        <IconButton
          className={cn(
            dayPicker.classNames.nav_button,
            dayPicker.classNames.nav_button_next,
          )}
          disabled={!nextMonth}
          icon={
            <ChevronRightIcon className="size-4 stroke-[2.5px] text-text-secondary" />
          }
          onClick={() => {
            nextMonth && goToMonth(nextMonth);
          }}
        />
      </div>
    </div>
  );
}
