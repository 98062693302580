import { z } from "zod";

import {
  type BasicUser,
  type BasicUserListResponse,
  BasicUserListResponseSchema,
  BasicUserSchema,
} from "../constants";

export enum UserRole {
  Employee = "Employee",
  Manager = "Manager",
  Administrator = "Administrator",
}

export {
  type BasicUser,
  type BasicUserListResponse,
  BasicUserListResponseSchema,
  BasicUserSchema,
};

export const UserSchema = BasicUserSchema.extend({
  bldEmail: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().optional(),
  nickname: z.string().optional(),
  position: z.string(),
  role: z.nativeEnum(UserRole),
  username: z.string(),
});

export const UserListResponseSchema = UserSchema.array();

export type User = z.infer<typeof UserSchema>;

export type UserListResponse = z.infer<typeof UserListResponseSchema>;

export const UserQueryParamsSchema = z.object({
  id: z.number().array().optional(),
  ordering: z.string().array().optional(),
  project: z.number().array().optional(),
  search: z.string().optional(),
});

export const UserQueryParamsSchemaTransformer = UserQueryParamsSchema.transform(
  ({ id, ordering, ...schema }) => ({
    ...(id && { id: id.join(",") }),
    ...(ordering && { ordering: ordering.join(",") }),
    ...schema,
  }),
);

export type UserQueryParams = z.infer<typeof UserQueryParamsSchema>;

export type UserQueryParamsTransformer = z.infer<
  typeof UserQueryParamsSchemaTransformer
>;

export const usersKeys = {
  current: ["api", "users", "current"],
  detail: (id: number) => ["api", "users", id],
  list: (params?: UserQueryParams) =>
    params ? ["api", "users", params] : ["api", "users"],
};
