import { z } from "zod";

import { generatePaginatedResponseSchema } from "@/api/utils";

import {
  type BasicEvaluationRating,
  BasicEvaluationRatingSchema,
  RatingChoice,
} from "../constants";
import { EvaluationCycleStatus } from "../evaluationCycles";
import { EvaluationSchema } from "../evaluations/constants";

export { EVALUATION_RATINGS } from "../constants";

export { type BasicEvaluationRating, BasicEvaluationRatingSchema };

export const EvaluationRatingSchema = BasicEvaluationRatingSchema.omit({
  evaluationId: true,
}).extend({
  evaluation: z.lazy(() =>
    EvaluationSchema.omit({
      ratings: true,
      rankingNotes: true,
      updatedAt: true,
    }),
  ),
});

export const EvaluationRatingListResponseSchema =
  generatePaginatedResponseSchema(EvaluationRatingSchema);

export const EvaluationRatingQueryParamsSchema = z.object({
  cycle: z.number().array().optional(),
  evaluatee: z.number().array().optional(),
  evaluatedAt: z.coerce.date().optional(),
  evaluation: z.number().optional(),
  evaluator: z.number().array().optional(),
  isSelfEvaluation: z.boolean().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  ordering: z.string().array().optional(),
  project: z.number().array().optional(),
  search: z.string().optional(),
  rating: z.number().array().optional(),
  cycleStatus: z.nativeEnum(EvaluationCycleStatus).optional(),
});

export const EvaluationRatingQueryParamsSchemaTransformer =
  EvaluationRatingQueryParamsSchema.transform(
    ({ ordering, project, ...schema }) => ({
      ...(ordering && { ordering: ordering.join(",") }),
      ...(project && { project: project.join(",") }),
      ...schema,
    }),
  );

export const EvaluationRatingSubmitPayloadSchema = z.object({
  id: z.number().optional(),
  evaluateeId: z.number().optional(),
  projectId: z.number().optional(),
  rating: z.nativeEnum(RatingChoice),
  ratingNotes: z.string(),
});

export type EvaluationRating = z.infer<typeof EvaluationRatingSchema>;

export type EvaluationRatingListResponse = z.infer<
  typeof EvaluationRatingListResponseSchema
>;

export type EvaluationRatingQueryParams = z.infer<
  typeof EvaluationRatingQueryParamsSchema
>;

export type EvaluationRatingQueryParamsTransformer = z.infer<
  typeof EvaluationRatingQueryParamsSchemaTransformer
>;

export type EvaluationRatingSubmitPayload = z.infer<
  typeof EvaluationRatingSubmitPayloadSchema
>;

export interface UserEvaluationRatingParams {
  projectId?: number;
  evaluateeId?: number;
}

export const evaluationRatingsKeys = {
  detail: (id: number) => ["api", "evaluationRatings", id],
  list: (params?: EvaluationRatingQueryParams) =>
    params
      ? ["api", "evaluationRatings", params]
      : ["api", "evaluationRatings"],
};
