import { useNavigate } from "@tanstack/react-router";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Fragment, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EvaluationStep } from "@/api/constants";
import { type BasicProject } from "@/api/projects";
import { type BasicUser, useGetCurrentUser, useListUsers } from "@/api/users";
import { UserListItem } from "@/components/app/ListItem";
import { Progress } from "@/components/ui/Progress";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { useEvaluationPageStore } from "../../utils/hooks/useEvaluationPageStore";
import { useMemberSelectionState } from "../../utils/hooks/useMemberSelectionState";
import { useNavigationBlocker } from "../../utils/hooks/useNavigationBlocker";

export interface AddedMembersViewProps {
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
}

export function AddedMembersView({
  children,
  className,
  isLoading: _,
}: AddedMembersViewProps) {
  const { t } = useTranslation();
  const { md } = useScreenSize();

  const { disableNextBlocker } = useNavigationBlocker();
  const navigate = useNavigate();

  const { currentUser } = useGetCurrentUser();

  const { users } = useListUsers({ ordering: ["first_name", "last_name"] });

  const usersList = useMemo(() => users ?? [], [users]);

  const { clickedProjectId, clickedUserId } = useMemberSelectionState();

  const { getIsMemberRatingPending, projectMemberRatings, selectedProjects } =
    useEvaluationPageStore((state) => ({
      getIsMemberRatingPending: (userId: number, projectId: number) =>
        state.getIsMemberRatingPending(userId, currentUser, projectId),
      projectMemberRatings: state.selectedProjectMemberRatings,
      selectedProjects: state.selectedProjects,
    }));

  const selectedProjectMembers = useMemo(
    () =>
      selectedProjects
        .filter(({ id }) => id in projectMemberRatings)
        .map((project) => ({
          project,
          members: usersList.filter(
            (user) => `${user.id}` in projectMemberRatings[project.id],
          ),
        })),
    [usersList, projectMemberRatings, selectedProjects],
  );

  const resolvedClickedProjectId =
    clickedProjectId ?? selectedProjectMembers.at(0)?.project.id;
  const resolvedClickedUserId =
    clickedUserId ?? selectedProjectMembers.at(0)?.members.at(0)?.id;

  const selectedMemberRatings = useMemo(
    () =>
      selectedProjects
        .filter(({ id }) => id in projectMemberRatings)
        .map((project) => Object.values(projectMemberRatings[project.id]))
        .flat()
        .filter((rating) => rating?.evaluateeId !== undefined),
    [projectMemberRatings, selectedProjects],
  );

  const pendingRatingsCount = useMemo(
    () =>
      selectedMemberRatings.filter((rating) => rating?.isPending ?? true)
        .length,
    [selectedMemberRatings],
  );
  const evaluatedRatingsCount =
    selectedMemberRatings.length - pendingRatingsCount;

  const handleMemberClick = (user: BasicUser, project: BasicProject) => {
    if (
      resolvedClickedUserId === user.id &&
      resolvedClickedProjectId === project.id
    )
      return;

    disableNextBlocker(async () => {
      await navigate({
        replace: true,
        search: {
          step: EvaluationStep.EvaluateMembers,
          evaluateeId: user.id,
          projectId: project.id,
        },
      });
    });
  };

  return (
    <div
      className={cn("m-0.5 flex flex-col items-stretch rounded-2xl", className)}
    >
      {children}

      <div className="overflow-hidden rounded-t-2xl">
        <div className="mx-0.5 mt-0.5 rounded-t-2xl px-4 pb-3 pt-4 shadow-elevation1">
          <div className="sticky top-0 z-[1] flex items-center gap-6 px-4 py-1">
            <Progress
              className="flex-auto"
              showLabel={false}
              showValue={false}
              value={
                (evaluatedRatingsCount / selectedMemberRatings.length) * 100
              }
            />
            <p className="text-caption text-text-secondary">
              {evaluatedRatingsCount}/{selectedMemberRatings.length}
            </p>
          </div>
        </div>
      </div>

      <OverlayScrollbarsComponent
        className="-mr-4 flex flex-[40%] flex-col items-stretch pr-4"
        defer
        element="div"
        options={{ scrollbars: { autoHide: "move" } }}
      >
        <div className="mx-0.5 mb-0.5 space-y-3 rounded-b-2xl px-4 pb-6 shadow-elevation1 md:min-h-[99.5%]">
          <p className="text-subtitle2 text-text-secondary">
            {t("evaluations.create.addedMembers")}
          </p>
          {selectedProjectMembers.map(({ project, members }) =>
            members.length > 0 ? (
              <Fragment key={project.id}>
                <p className="text-overline uppercase text-text-secondary">
                  {project.name}
                </p>
                {members.map((user) => (
                  <UserListItem
                    className="border border-other-divider data-[state=selected]:border-transparent"
                    data-state={
                      user.id === resolvedClickedUserId &&
                      project.id === resolvedClickedProjectId
                        ? "selected"
                        : undefined
                    }
                    key={`${project.id}-${user.id}`}
                    onClick={() => {
                      handleMemberClick(user, project);
                    }}
                    selected={
                      user.id === resolvedClickedUserId &&
                      project.id === resolvedClickedProjectId
                    }
                    showEmail={md}
                    status={
                      getIsMemberRatingPending(user.id, project.id)
                        ? "pending"
                        : "evaluated"
                    }
                    user={user}
                  />
                ))}
              </Fragment>
            ) : null,
          )}
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
