import { Navigate, useLocation } from "@tanstack/react-router";
import { type HTMLAttributes } from "react";

import { useGetCurrentUser } from "@/api/users";
import { LoadingComponent } from "@/components/app/LoadingComponent";
import { cn } from "@/utils/ui";

import { Navigation } from "./Navigation";

export interface MainLayoutProps extends HTMLAttributes<HTMLDivElement> {
  containerClassName?: string;
}

export function MainLayout({
  className,
  children,
  containerClassName,
  ...props
}: MainLayoutProps) {
  const location = useLocation();
  const { isAuthenticated, isFetched, isFetching } = useGetCurrentUser();

  if (!isFetched && isFetching) {
    return <LoadingComponent />;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        mask={{ search: { redirect: location.href } }}
        search={{ ignoreAuth: true, redirect: location.href }}
        to="/login"
      />
    );
  }

  return (
    <div
      className={cn(
        "flex min-w-full flex-auto flex-col items-stretch",
        containerClassName,
      )}
      {...props}
    >
      <Navigation className="sticky top-0 z-[1] min-h-14" />
      <div className={cn("flex-auto overflow-y-auto", className)}>
        {children}
      </div>
    </div>
  );
}
