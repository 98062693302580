import { type ReactNode, useCallback, useMemo, useState } from "react";
import { toast } from "sonner";
import { useDebouncedCallback } from "use-debounce";

import { useSubmitEvaluation } from "@/api/evaluations";
import { ApiError } from "@/api/utils";
import { useNavigationBlocker } from "@/utils/hooks";

import { DraftSaveSucessToast } from "../DraftSaveSuccessToast";
import { DraftAutosaveContext } from "./hooks/useDraftAutosave";
import { useEvaluationPageStore } from "./hooks/useEvaluationPageStore";
import { NavigationBlockerContext } from "./hooks/useNavigationBlocker";

const SERVER_AUTOSAVE_DELAY = 5000;

export function DraftAutosaveProvider({ children }: { children: ReactNode }) {
  const [isAutosaved, setIsAutosaved] = useState(false);

  const { submit, isPending: isAutosavePending } = useSubmitEvaluation();

  const getDraftPayload = useEvaluationPageStore(
    (state) => state.getDraftPayload,
  );
  const resetStore = useEvaluationPageStore((state) => state.reset);

  const onAutosaveDraft = useCallback(async () => {
    setIsAutosaved(true);

    try {
      const draftData = getDraftPayload();
      await submit({ draftData, isDraft: true });
    } catch (e) {
      if (e instanceof ApiError) {
        toast.error(e.errors?.[0].detail);
      }
    }
  }, [getDraftPayload, submit]);

  const onAutosaveDraftDebounced = useDebouncedCallback(
    onAutosaveDraft,
    SERVER_AUTOSAVE_DELAY,
  );

  const draftAutosave = useMemo(
    () => ({
      isAutosaved,
      isAutosavePending,
      onAutosaveDraft,
      onAutosaveDraftDebounced,
    }),
    [isAutosaved, isAutosavePending, onAutosaveDraft, onAutosaveDraftDebounced],
  );

  const navigationBlocker = useNavigationBlocker(async () => {
    try {
      await onAutosaveDraft();
      resetStore();

      toast.success(<DraftSaveSucessToast />);

      return true;
    } catch (e) {
      return true;
    }
  });

  return (
    <NavigationBlockerContext.Provider value={navigationBlocker}>
      <DraftAutosaveContext.Provider value={draftAutosave}>
        {children}
      </DraftAutosaveContext.Provider>
    </NavigationBlockerContext.Provider>
  );
}
