import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import { cn } from "@/utils/ui";

export function Placeholder({ className }: { className?: string }) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "flex flex-auto flex-col items-center justify-center gap-3 text-center text-text-secondary",
        className,
      )}
    >
      <InformationCircleIcon className="size-6" />
      <div>
        <p className="text-subtitle2">
          {t("components.evaluationForm.placeholderSubtitle")}
        </p>
        <p className="text-body2">
          {t("components.evaluationForm.placeholderBody")}
        </p>
      </div>
    </div>
  );
}
