import { useMutation, useQueryClient } from "@tanstack/react-query";

import { api } from "../utils";
import { usersKeys } from "./constants";

export function useLogoutUser() {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, ...mutation } = useMutation({
    mutationFn: async () => {
      const response = await api.post("auth/logout/");

      return response.json();
    },
    meta: {
      errorMessage: "Failed to verify authentication.",
    },
    onSuccess: async () => {
      queryClient.removeQueries({
        predicate: (query) =>
          usersKeys.current.length !== query.queryKey.length ||
          query.queryKey.some(
            (component, idx) => usersKeys.current.at(idx) !== component,
          ),
      });
      await queryClient.resetQueries({
        queryKey: usersKeys.current,
        exact: true,
      });
    },
  });

  return {
    ...mutation,
    logoutUser: mutateAsync,
    logoutUserSync: mutate,
  };
}
