import { useTranslation } from "react-i18next";

import { type BasicUser } from "@/api/constants";

import { Tag, type TagProps } from "./Tag";

export interface UserTagProps extends Omit<TagProps, "onRemoveClick"> {
  user: BasicUser;
  onRemoveClick?: (user: BasicUser) => void;
}

export function UserTag({ onRemoveClick, user, ...props }: UserTagProps) {
  const { t } = useTranslation();

  return (
    <Tag
      label={t("components.filterTag.user")}
      onRemoveClick={() => onRemoveClick?.(user)}
      value={user.fullName}
      {...props}
    />
  );
}
