import { Outlet, useNavigate, useSearch } from "@tanstack/react-router";
import { useCallback } from "react";

import { useListEvaluations } from "@/api/evaluations";
import { Dialog } from "@/components/ui/Dialog";

import { tableStateToQueryParamsTransformer } from "../utils";

function MemberDetailDialog() {
  const search = useSearch({ from: "/admin" });
  const navigate = useNavigate({ from: "/admin" });

  const evaluationsQueryParams =
    tableStateToQueryParamsTransformer.parse(search);

  const { isFetched } = useListEvaluations({
    ...evaluationsQueryParams.dashboardFilters,
    limit: 0,
  });

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      navigate({
        to: "/admin",
        search: (prev) => ({
          dashboardFilters: prev.dashboardFilters || {},
          isModalShown: isOpen || undefined,
        }),
      }).catch(() => null);
    },
    [navigate],
  );

  return (
    <Dialog
      onOpenChange={handleOpenChange}
      open={Boolean(search.isModalShown) && isFetched}
    >
      <Dialog.Content className="h-screen w-screen max-w-[79.625rem] p-0">
        <Outlet />
      </Dialog.Content>
    </Dialog>
  );
}
export { MemberDetailDialog };
