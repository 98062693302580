import { ChevronDownIcon } from "@heroicons/react/20/solid";
import type { Table } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown } from "../Dropdown";
import { countLeafRows, TABLE_PAGE_SIZE_OPTIONS } from "./utils";

export interface PageSizeDropdownProps<TData> {
  enableShowAll?: boolean;
  filteredResultsCount?: number;
  onChange?: (value: number) => boolean | undefined;
  table: Table<TData>;
  value?: number;
}

export function PageSizeDropdown<TData>({
  enableShowAll = true,
  filteredResultsCount,
  onChange,
  table,
  value: pageSize,
}: PageSizeDropdownProps<TData>) {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const finalFilteredResultsCount =
    filteredResultsCount ?? countLeafRows(table.getFilteredRowModel());
  const pageSizeAll = t("components.dataTable.pageSizeAll");
  let finalPageSize = showAll
    ? pageSizeAll
    : (pageSize ?? table.getState().pagination.pageSize);
  finalPageSize =
    (pageSize !== undefined && pageSize <= 0) ||
    finalFilteredResultsCount === pageSize
      ? pageSizeAll
      : finalPageSize;

  return (
    <Dropdown>
      <Dropdown.Trigger className="group flex h-[1.625rem] items-center">
        <span className="text-subtitle2 !leading-none text-primary-main">
          {finalPageSize}
        </span>
        <ChevronDownIcon className="size-6 self-start text-primary-main transition-[transform] duration-300 group-data-[state=open]:-rotate-180" />
      </Dropdown.Trigger>
      <Dropdown.Content
        align="end"
        className="rounded-lg bg-white shadow-elevation5"
      >
        {TABLE_PAGE_SIZE_OPTIONS.map(({ label, value }) => (
          <Dropdown.Item
            key={value}
            onSelect={() => {
              setShowAll(false);
              if (onChange?.(value) === false) return;
              table.setPageSize(value);
            }}
          >
            {label}
          </Dropdown.Item>
        ))}
        {Boolean(enableShowAll) && (
          <Dropdown.Item
            onSelect={() => {
              setShowAll(true);
              if (onChange?.(0) === false) return;
              table.setPageSize(finalFilteredResultsCount);
            }}
          >
            {pageSizeAll}
          </Dropdown.Item>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
}
