import * as React from "react";

import { cn } from "@/utils/ui";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  endIcon?: React.ReactNode;
  helper?: string;
  helperClassName?: string;
  inputClassName?: string;
  startIcon?: React.ReactNode;
}

const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      endIcon,
      helper,
      helperClassName,
      inputClassName,
      startIcon,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cn("space-y-[0.3125rem]", className)}>
        <label
          className={cn(
            "h-[3.125rem] inline-flex items-center gap-1 ring-1 ring-other-border hover:ring-primary-main has-[:focus]:ring-primary-border has-[:focus]:ring-[0.1875rem] has-[:enabled]:has-[:active]:ring-primary-border has-[:enabled]:has-[:active]:ring-[0.1875rem] rounded-lg has-[:disabled]:text-text-disabled has-[:disabled]:bg-actions-disabled-background has-[:disabled]:ring-1 has-[:disabled]:ring-actions-disabled-text text-text-primary",
            startIcon && "pl-5",
            endIcon && "pr-4",
            inputClassName,
          )}
        >
          {Boolean(startIcon) && (
            <span className="size-6 text-other-border">{startIcon}</span>
          )}

          <input
            className="w-full min-w-0 grow rounded-lg bg-transparent py-3 pl-5 pr-4 text-body1 outline-none placeholder:text-text-hint"
            ref={ref}
            {...props}
          />

          {Boolean(endIcon) && (
            <span className="size-6 text-other-border">{endIcon}</span>
          )}
        </label>

        {Boolean(helper) && (
          <div
            className={cn("text-caption text-text-secondary", helperClassName)}
          >
            {helper}
          </div>
        )}
      </div>
    );
  },
);
TextInput.displayName = "TextInput";

export { TextInput };
