import { decamelize } from "humps";

import type { EvaluationCycleMemberReceivedEvaluationQueryParams } from "@/api/evaluationCycles";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

const columnToOrderingFieldMap: Record<string, string | undefined> = {
  evaluator: "evaluator__first_name,evaluator__last_name",
  "-evaluator": "-evaluator__first_name,-evaluator__last_name",
};

export const tableStateToQueryParamsTransformer =
  TableStateBaseParamsSchema.transform(
    ({
      ordering,
      ...schema
    }): EvaluationCycleMemberReceivedEvaluationQueryParams => ({
      ...schema,
      ordering:
        ordering?.map((field) =>
          decamelize(columnToOrderingFieldMap[field] ?? field),
        ) ?? [],
    }),
  );
