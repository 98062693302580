import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as React from "react";
import type { LiteralUnion } from "type-fest";

import { cn } from "@/utils/ui";

import type { ButtonProps } from "./Button";
import { Button } from "./Button";

const AlertDialog = AlertDialogPrimitive.Root;

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogPortal = AlertDialogPrimitive.Portal;

const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
    ref={ref}
  />
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
>(({ className, ...props }, ref) => (
  <AlertDialogPortal>
    <AlertDialogOverlay className="bg-actions-disabled-background" />
    <AlertDialogPrimitive.Content
      className={cn(
        "fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] bg-white shadow-elevation1 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-2xl",
        className,
      )}
      ref={ref}
      {...props}
    />
  </AlertDialogPortal>
));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

function AlertDialogHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("flex p-6 max-sm:pb-4 gap-4 text-left", className)}
      {...props}
    />
  );
}

function AlertDialogFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("flex flex-row py-3 px-6 justify-end gap-x-2.5", className)}
      {...props}
    />
  );
}

const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Title
    className={cn("text-h6 font-semibold text-text-primary", className)}
    ref={ref}
    {...props}
  />
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Description
    className={cn("text-body2 text-text-secondary", className)}
    ref={ref}
    {...props}
  />
));
AlertDialogDescription.displayName =
  AlertDialogPrimitive.Description.displayName;

const AlertDialogAction = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Action className={cn(className)} ref={ref} {...props} />
));
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;

const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Cancel className={className} ref={ref} {...props} />
));
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

interface ConfirmDialogButton extends Omit<ButtonProps, "asChild"> {
  action: LiteralUnion<"submit" | "cancel", string>;
  label: string;
}

interface ConfirmDialogProps extends AlertDialogPrimitive.AlertDialogProps {
  className?: string;
  contentClassName?: string;
  description: React.ReactNode;
  footerButtons: ConfirmDialogButton[];
  icon?: React.ReactNode;
  onSubmit?: (action: ConfirmDialogButton["action"]) => void;
  title: React.ReactNode;
  trigger?: React.ReactNode;
  variant?: "info" | "danger";
}

function ConfirmDialog({
  contentClassName,
  description,
  footerButtons,
  icon,
  onSubmit = () => undefined,
  title,
  trigger,
  variant = "info",
  ...props
}: ConfirmDialogProps) {
  const defaultIcon =
    variant === "info" ? (
      <InformationCircleIcon />
    ) : (
      <ExclamationTriangleIcon />
    );

  const renderFooterButtons = () => {
    return footerButtons.map(({ color, ...buttonProps }) => {
      if (buttonProps.action === "submit") {
        let defaultColor: ButtonProps["color"] | undefined;
        if (variant === "danger") {
          defaultColor = "danger";
        }

        return (
          <AlertDialogAction asChild key={buttonProps.label}>
            <Button
              color={color ?? defaultColor}
              onClick={() => {
                onSubmit(buttonProps.action);
              }}
              {...buttonProps}
            >
              {buttonProps.label}
            </Button>
          </AlertDialogAction>
        );
      }

      if (buttonProps.action === "cancel") {
        return (
          <AlertDialogCancel asChild key={buttonProps.label}>
            <Button color={color} {...buttonProps}>
              {buttonProps.label}
            </Button>
          </AlertDialogCancel>
        );
      }

      return (
        <Button color={color} key={buttonProps.label} {...buttonProps}>
          {buttonProps.label}
        </Button>
      );
    });
  };

  return (
    <AlertDialog {...props}>
      {Boolean(trigger) && <AlertDialogTrigger>{trigger}</AlertDialogTrigger>}
      <AlertDialogContent
        className={cn(
          "max-w-[34.5rem] max-sm:max-w-full max-sm:w-[21.875rem]",
          contentClassName,
        )}
      >
        <AlertDialogHeader>
          <div
            className={cn(
              "size-10 p-2 rounded-full shrink-0",
              variant === "info"
                ? "bg-info-light-bg text-info-main"
                : "bg-error-light-bg text-error-main",
            )}
          >
            {icon ?? defaultIcon}
          </div>
          <div className="space-y-2">
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>{renderFooterButtons()}</AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export { ConfirmDialog, type ConfirmDialogProps };
