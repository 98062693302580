import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { EVALUATION_RATINGS } from "@/api/constants";
import { Accordion, type AccordionProps } from "@/components/ui/Accordion";

import { InfoText } from "../InfoText";

const evaluationRatings = Object.entries(EVALUATION_RATINGS).filter(
  ([_, value]) => value !== 0,
);
evaluationRatings.reverse();

export interface RatingGuideProps
  extends Omit<
    AccordionProps,
    "defaultValue" | "onValueChange" | "type" | "value"
  > {
  onOpenChange?: (open: boolean) => void;
}

export function RatingGuide({ onOpenChange, ...props }: RatingGuideProps) {
  const { t } = useTranslation();

  const handleValueChange = useCallback(
    (value: string[]) => {
      onOpenChange?.(value.length > 0);
    },
    [onOpenChange],
  );

  return (
    <Accordion onValueChange={handleValueChange} type="multiple" {...props}>
      <Accordion.Item
        className="rounded-lg border-other-divider"
        value="item-1"
      >
        <Accordion.Trigger>
          <InfoText
            className="border-0 p-0"
            title={t("components.ratingGuide.title")}
          />
        </Accordion.Trigger>
        <Accordion.Content className="flex flex-col items-stretch gap-3">
          {evaluationRatings.map(([key, value]) => (
            <div
              className="flex max-w-full flex-col items-stretch gap-1"
              key={key}
            >
              <p className="text-overline uppercase text-text-secondary">
                {value} {t(`constants.ratings.${value}.label`)}
              </p>
              <p className="text-caption text-text-secondary">
                {t(`constants.ratings.${value}.description`)}
              </p>
            </div>
          ))}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
}
