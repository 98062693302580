/* eslint-disable @typescript-eslint/no-misused-promises */
import { useParams } from "@tanstack/react-router";
import {
  type DetailedHTMLProps,
  type FormHTMLAttributes,
  useMemo,
  useState,
} from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useGetEvaluationCycleMemberEvaluators } from "@/api/evaluationCycles/useGetEvaluationCycleMemberEvaluators";
import { useGetProjectsByIds, useListProjects } from "@/api/projects";
import { useGetUsersByIds, type User } from "@/api/users";
import {
  MultiSelectSearchField,
  RatingSelectField,
} from "@/components/app/FormFields";
import { Button } from "@/components/ui/Button";
import { Form } from "@/components/ui/Form";
import { Spinner } from "@/components/ui/Spinner";
import { cn } from "@/utils/ui";

import { DateSubmittedField } from "./DateSubmittedField";
import { type FormData as MemberDetailFilterFormData } from "./utils";

export interface FilterFormProps
  extends Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    "children" | "onSubmit"
  > {
  resetDisabled?: boolean;
  form: UseFormReturn<MemberDetailFilterFormData>;
  onClear?: () => boolean | undefined;
  onSubmit: (formData: MemberDetailFilterFormData) => void;
  submitDisabled?: boolean;
  users?: User[] | null;
}

export function FilterForm({
  className,
  resetDisabled,
  form,
  onClear,
  onSubmit,
  submitDisabled,
  ...props
}: FilterFormProps) {
  const { t } = useTranslation();
  const { cycleId, memberId } = useParams({
    from: "/admin/cycles/$cycleId/members/$memberId/evaluators",
  });

  const [evaluatorsSearch, setEvaluatorsSearch] = useState<string>();
  const [projectsSearch, setProjectsSearch] = useState<string>();

  const { evaluators } = useGetEvaluationCycleMemberEvaluators(
    Number(cycleId),
    Number(memberId),
    { search: evaluatorsSearch },
  );

  const {
    results: projects,
    isFetching,
    cursor: projectsCursor,
  } = useListProjects({
    ordering: ["name"],
    user: [Number(memberId)],
    ...(projectsSearch && { search: projectsSearch }),
  });

  const selectedEvaluatorIds = form.getValues("evaluator") ?? [];
  const { users: selectedEvaluators } = useGetUsersByIds(selectedEvaluatorIds);

  const selectedProjectIds = form.getValues("project") ?? [];
  const { projects: selectedProjects } =
    useGetProjectsByIds(selectedProjectIds);

  const evaluateesList = useMemo(() => {
    const evaluatorsResults = evaluators?.results ?? [];
    const fetchedEvaluatorIds = evaluatorsResults.map(({ id }) => id);
    const selectedEvaluatorsExtra = selectedEvaluators.filter(
      ({ id }) => !fetchedEvaluatorIds.includes(id),
    );
    return [...evaluatorsResults, ...selectedEvaluatorsExtra];
  }, [evaluators, selectedEvaluators]);

  const projectsList = useMemo(() => {
    const fetchedProjectIds = projects.map(({ id }) => id);
    const selectedProjectsExtra = selectedProjects.filter(
      ({ id }) => !fetchedProjectIds.includes(id),
    );
    return [...projects, ...selectedProjectsExtra];
  }, [projects, selectedProjects]);

  const evaluatorOptions = useMemo(
    () =>
      evaluateesList.map((user) => ({
        label: user.fullName,
        value: user.id,
      })),
    [evaluateesList],
  );

  const projectOptions = useMemo(
    () =>
      projectsList.map((project) => ({
        label: project.name,
        value: project.id,
      })),
    [projectsList],
  );

  const [projectsScrollSentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: projectsCursor.hasNextPage,
    onLoadMore: projectsCursor.loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Form {...form}>
      <form
        className={cn("space-y-[0.625rem]", className)}
        onSubmit={(e) => {
          void form.handleSubmit(onSubmit)(e);
        }}
        {...props}
      >
        <div className="text-h6 text-text-primary">
          {t("admin.filterForm.filters")}
        </div>
        <div className="h-max space-y-6 pb-6">
          <MultiSelectSearchField
            control={form.control}
            label={t("admin.filterForm.evaluator")}
            name="evaluator"
            onSearchChange={setEvaluatorsSearch}
            options={evaluatorOptions}
          />
          <RatingSelectField
            control={form.control}
            label={t("admin.filterForm.rating")}
            name="rating"
          />
          <MultiSelectSearchField
            control={form.control}
            label={t("admin.filterForm.projects")}
            name="project"
            onSearchChange={setProjectsSearch}
            options={projectOptions}
            scrollSentry={
              <div
                className="flex min-h-[1px] w-full justify-center"
                ref={projectsScrollSentryRef}
              >
                {Boolean(projectsCursor.isFetching) && (
                  <div className="p-2">
                    <Spinner className="size-4" />
                  </div>
                )}
              </div>
            }
          />
          <DateSubmittedField
            control={form.control}
            label={t("admin.filterForm.dateSubmitted")}
            name="evaluatedAt"
          />
        </div>

        <div className="flex gap-[0.625rem]">
          <Button
            className="flex-auto"
            disabled={resetDisabled}
            onClick={onClear}
            type="reset"
            variant="outlined"
          >
            {t("admin.filterForm.buttonResetFilters")}
          </Button>
          <Button className="flex-auto" disabled={submitDisabled} type="submit">
            {t("admin.filterForm.buttonApplyFilters")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
