import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import Error404Background from "@/assets/images/error404_background.png";
import { Button } from "@/components/ui/Button";
import { cn } from "@/utils/ui";

export interface NotFoundError {
  className?: string;
}

export function NotFoundError({ className }: NotFoundError) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "relative w-full min-h-full bg-no-repeat bg-cover bg-center",
        className,
      )}
      style={{
        backgroundImage: `url('${Error404Background}')`,
      }}
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 space-y-8 text-center">
        <h1 className="text-h2 text-primary-main md:text-h1">
          {t("components.notFoundError.title")}
        </h1>
        <p className="text-h6 text-text-primary md:text-h5">
          {t("components.notFoundError.subtitle")}
        </p>
        <Button asChild isLink size="large">
          <Link to="/">{t("components.notFoundError.buttonBack")}</Link>
        </Button>
      </div>
    </div>
  );
}
