import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { EvaluationsDashboardContainer } from "@/components/pages/EvaluationsDashboardPage";

const evaluationsFilterFormSchema = z.object({
  filters: z
    .object({
      cycle: z.array(z.number()).optional(),
      projects: z.array(z.number()).optional(),
    })
    .partial()
    .passthrough()
    .optional(),
});

export const Route = createFileRoute("/evaluations/_dashboard")({
  component: EvaluationsDashboardContainer,
  validateSearch: (search) => evaluationsFilterFormSchema.parse(search),
});
