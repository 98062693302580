import { type HTMLAttributes } from "react";

import type { BasicUser } from "@/api/users";
import { Avatar, type AvatarProps } from "@/components/ui/Avatar";
import { cn } from "@/utils/ui";

export interface UserThumbnailProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  contrast?: boolean;
  size?: AvatarProps["size"];
  showEmail?: boolean;
  user: BasicUser;
}

export function UserThumbnail({
  className,
  contrast = false,
  size,
  showEmail = true,
  user,
  ...props
}: UserThumbnailProps) {
  const nameColor = contrast
    ? "text-primary-contrast-text"
    : "text-text-primary";
  const emailColor = contrast ? "text-secondary-light" : "text-text-secondary";

  return (
    <div className={cn("flex items-center gap-2", className)} {...props}>
      <Avatar displayName={user.fullName} size={size} src={user.avatar} />
      <div className="text-left">
        <p className={cn("text-subtitle2", nameColor)}>{user.fullName}</p>
        {Boolean(showEmail) && (
          <p className={cn("text-caption", emailColor)}>{user.email}</p>
        )}
      </div>
    </div>
  );
}
