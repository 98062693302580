import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { ReceivedEvaluationListResponseSchema } from "../evaluations";
import { api, handleApiError, validateSchema } from "../utils";
import {
  type EvaluationCycleMemberReceivedEvaluationQueryParams,
  EvaluationCycleMemberReceivedEvaluationsQueryParamsSchemaTransformer,
  evaluationCyclesKeys,
} from "./constants";

export const listEvaluationCycleMemberReceivedEvaluations = async (
  cycleId: number,
  memberId: number,
  params: EvaluationCycleMemberReceivedEvaluationQueryParams = {},
) => {
  const response = await api.get(
    `evaluation-cycles/${cycleId}/members/${memberId}/received-evaluations/`,
    {
      searchParams: qs.stringify(
        decamelizeKeys(
          EvaluationCycleMemberReceivedEvaluationsQueryParamsSchemaTransformer.parse(
            params,
          ),
        ),
      ),
    },
  );

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(ReceivedEvaluationListResponseSchema, data);
};

export const useListEvaluationCycleMemberReceivedEvaluations = (
  cycleId: number,
  memberId: number,
  params: EvaluationCycleMemberReceivedEvaluationQueryParams = {},
) => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: () =>
      listEvaluationCycleMemberReceivedEvaluations(cycleId, memberId, params),
    queryKey: evaluationCyclesKeys.memberReceivedEvaluationList(
      cycleId,
      memberId,
      params,
    ),
    placeholderData: keepPreviousData,
  });

  return { evaluations: data || null, isFetched, isFetching };
};
