import { CheckIcon } from "@heroicons/react/16/solid";

import { cn } from "@/utils/ui";

import { StepStatus } from "./constants";

export interface StepIndicatorProps {
  className?: string;
  onClick?: () => void;
  status: StepStatus;
  value: number;
}

export function StepIndicator({
  className,
  onClick,
  status,
  value,
  ...props
}: StepIndicatorProps) {
  const statusClassName = {
    [StepStatus.Completed]: "border-primary-main bg-primary-main",
    [StepStatus.Current]: "border-primary-main bg-primary-background",
    [StepStatus.Future]: "border-text-disabled bg-actions-disabled-background",
  }[status];

  return (
    <div
      className={cn(
        "flex size-8 items-center justify-center rounded-full border",
        statusClassName,
        className,
      )}
      {...(status !== StepStatus.Future && {
        onClick,
        onKeyDown: onClick,
        role: "button",
        tabIndex: 0,
      })}
      {...props}
    >
      {
        {
          [StepStatus.Completed]: (
            <CheckIcon className="size-5 text-primary-contrast-text" />
          ),
          [StepStatus.Current]: (
            <p className="text-overline leading-none tracking-normal text-primary-main">
              {value}
            </p>
          ),
          [StepStatus.Future]: (
            <p className="text-overline leading-none tracking-normal text-text-disabled">
              {value}
            </p>
          ),
        }[status]
      }
    </div>
  );
}
