import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "@tanstack/react-router";
import { StrictMode, Suspense, useMemo } from "react";
import { I18nextProvider } from "react-i18next";

import { useGetCurrentUser } from "@/api/users";
import { ErrorFallback } from "@/components/app/ErrorFallback";
import type { RootRouterContext } from "@/utils/constants";
import { config, isLocal } from "@/utils/constants";

import { i18n } from "../../i18n";
import { router } from "../../router";
import { LoadingComponent } from "./LoadingComponent";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

Sentry.init({
  dsn: "https://409e900b536f9f70f6ee90002ffe62c0@o4506710175776768.ingest.sentry.io/4506710183772160",
  enabled: !isLocal,
  environment: config.MODE,
});

function InnerApp() {
  const { currentUser, isAuthenticated, isFetched, isFetching } =
    useGetCurrentUser();

  const routerContext = useMemo<RootRouterContext>(
    () => ({
      auth: {
        isAuthenticated,
        userRole: currentUser?.role,
      },
    }),
    [currentUser?.role, isAuthenticated],
  );

  if (isFetching && !isFetched) {
    return <LoadingComponent />;
  }

  return (
    <Suspense>
      <RouterProvider context={routerContext} router={router} />
    </Suspense>
  );
}

function App() {
  return (
    <StrictMode>
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <I18nextProvider defaultNS={config.I18N_DEFAULT_NS} i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <InnerApp />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </I18nextProvider>
      </Sentry.ErrorBoundary>
    </StrictMode>
  );
}

export { App };
