import { useMemo } from "react";
import type { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";

import type { RatingChoice } from "@/api/constants";
import { EVALUATION_RATINGS } from "@/api/constants";
import type { EvaluationCycle } from "@/api/evaluationCycles";
import { useGetProjectsByIds } from "@/api/projects";
import { useListUsers } from "@/api/users";
import {
  DateRangeTag,
  ProjectTag,
  RatingRangeTag,
  RatingTag,
  UserTag,
} from "@/components/app/FilterTag";
import { CycleTag } from "@/components/app/FilterTag/CycleTag";
import { formatDateRange } from "@/components/ui/DatePicker/utils";
import type { FilterSummaryProps as FilterSummaryPropsBase } from "@/components/ui/NavigationTable/TableHeaderControls";
import { cn } from "@/utils/ui";

import type { FormData } from "./AdminFilterForm/utils";

export interface FilterSummaryProps<TData>
  extends FilterSummaryPropsBase<TData, FormData> {
  className?: string;
  evaluationCycles: EvaluationCycle[];
}

export function FilterSummary<TData>({
  className,
  evaluationCycles,
  filters,
  form,
  table,
  ...props
}: FilterSummaryProps<TData>) {
  const { t } = useTranslation();

  const { users } = useListUsers({ ordering: ["first_name", "last_name"] });

  const userList = useMemo(
    () =>
      users?.filter(
        (user) =>
          filters.evaluatee?.includes(user.id) ||
          filters.evaluators?.includes(user.id),
      ) ?? [],
    [filters, users],
  );

  const cyclesList = useMemo(
    () =>
      evaluationCycles.filter(
        (cycle) => filters.cycle?.includes(cycle.id) ?? [],
      ),
    [filters, evaluationCycles],
  );

  const { projects: projectList } = useGetProjectsByIds(filters.projects ?? []);

  const handleRemoveDateRangeClick = (column: keyof typeof filters) => {
    table.getColumn(column)?.setFilterValue({});
    form.setValue(column, {});
  };

  const handleRemoveRatingClick = (
    column: keyof typeof filters,
    rating: RatingChoice,
  ) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedRatings = curr.filter((v) => v !== EVALUATION_RATINGS[rating]);
    table.getColumn(column)?.setFilterValue(updatedRatings);
    form.setValue(column, updatedRatings as number[]);
  };

  const handleRemoveRatingRangeClick = (
    column: keyof typeof filters,
    rating: number,
  ) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedRatings = curr.filter((v) => v !== rating);
    table.getColumn(column)?.setFilterValue(updatedRatings);
    form.setValue(column, updatedRatings as number[]);
  };

  const handleRemoveIdClick = (column: keyof typeof filters, id: number) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedIds = curr.filter((v) => v !== id);
    table.getColumn(column)?.setFilterValue(updatedIds);
    form.setValue(column, updatedIds as number[]);
  };

  return (
    <div
      className={cn(
        "flex flex-wrap gap-2 border-t border-other-border px-4 py-3.5",
        className,
      )}
      {...props}
    >
      {Object.keys(filters).map(
        (label) =>
          ({
            avgRating: (filters.avgRating ?? []).map((rating) => (
              <RatingRangeTag
                key={`${label}-${rating}`}
                label={t("admin.filterForm.filterTagAvgRating")}
                onRemoveClick={() => {
                  handleRemoveRatingRangeClick("avgRating", rating);
                }}
                rating={rating}
              />
            )),

            cycle: filters.cycle
              ? filters.cycle.map((cycleId) => {
                  const cycle = cyclesList.find(({ id }) => id === cycleId);
                  return cycle ? (
                    <CycleTag
                      cycle={cycle}
                      key={cycle.id}
                      onRemoveClick={() => {
                        handleRemoveIdClick("cycle", cycleId);
                      }}
                    />
                  ) : null;
                })
              : null,

            evaluatedAt: filters.evaluatedAt ? (
              <DateRangeTag
                dateRange={filters.evaluatedAt as DateRange}
                key={`${label}-${formatDateRange(filters.evaluatedAt as DateRange, "PP")}`}
                onRemoveClick={() => {
                  handleRemoveDateRangeClick("evaluatedAt");
                }}
              />
            ) : null,

            evaluatee: (filters.evaluatee ?? []).map((userId) => {
              const user = userList.find(({ id }) => id === userId);
              return user ? (
                <UserTag
                  key={`${label}-${userId}`}
                  label={t("admin.filterForm.filterTagEvaluatee")}
                  onRemoveClick={({ id }) => {
                    handleRemoveIdClick("evaluatee", id);
                  }}
                  user={user}
                />
              ) : null;
            }),

            evaluators: (filters.evaluators ?? []).map((userId) => {
              const user = userList.find(({ id }) => id === userId);
              return user ? (
                <UserTag
                  key={`${label}-${userId}`}
                  label={t("admin.filterForm.filterTagEvaluator")}
                  onRemoveClick={({ id }) => {
                    handleRemoveIdClick("evaluators", id);
                  }}
                  user={user}
                />
              ) : null;
            }),

            projects: (filters.projects ?? []).map((projectId) => {
              const project = projectList.find(({ id }) => id === projectId);
              return project ? (
                <ProjectTag
                  key={`${label}-${projectId}`}
                  onRemoveClick={({ id }) => {
                    handleRemoveIdClick("projects", id);
                  }}
                  project={project}
                />
              ) : null;
            }),

            selfRating: (filters.selfRating ?? []).map((filterValue) => {
              const rating = Object.entries(EVALUATION_RATINGS).find(
                ([_, v]) => v === filterValue,
              )?.[0] as RatingChoice | undefined;
              return rating !== undefined ? (
                <RatingTag
                  key={`${label}-${rating}`}
                  label={t("admin.filterForm.filterTagSelfRating")}
                  onRemoveClick={() => {
                    handleRemoveRatingClick("selfRating", rating);
                  }}
                  rating={rating}
                />
              ) : null;
            }),
          })[label],
      )}
    </div>
  );
}
