import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { api, handleApiError, validateSchema } from "../utils";
import {
  type EvaluationCycleMemberEvaluatorQueryParams,
  EvaluationCycleMemberEvaluatorQueryParamsSchemaTransformer,
  EvaluationCycleMemberRatingListResponseSchema,
  evaluationCyclesKeys,
} from "./constants";

export const getEvaluationCycleMemberEvaluators = async (
  cycleId: number,
  memberId: number,
  params: EvaluationCycleMemberEvaluatorQueryParams = {},
) => {
  const response = await api.get(
    `evaluation-cycles/${cycleId}/members/${memberId}/evaluators/`,
    {
      searchParams: qs.stringify(
        decamelizeKeys(
          EvaluationCycleMemberEvaluatorQueryParamsSchemaTransformer.parse(
            params,
          ),
        ),
      ),
    },
  );

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleMemberRatingListResponseSchema, data);
};

export const useGetEvaluationCycleMemberEvaluators = (
  cycleId: number,
  memberId: number,
  params?: EvaluationCycleMemberEvaluatorQueryParams,
) => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: () =>
      getEvaluationCycleMemberEvaluators(cycleId, memberId, params),
    queryKey: evaluationCyclesKeys.memberEvaluatorRatingList(
      cycleId,
      memberId,
      params,
    ),
    placeholderData: keepPreviousData,
  });

  return { evaluators: data || null, isFetched, isFetching };
};
