import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Translation } from "react-i18next";

import {
  EVALUATION_RATINGS,
  type EvaluationRating,
} from "@/api/evaluationRatings";
import { Avatar } from "@/components/ui/Avatar";
import { Rating } from "@/components/ui/Rating";
import { cn } from "@/utils/ui";

export type TableColumns = Omit<EvaluationRating, "id" | "evaluation">;

const columnHelper = createColumnHelper<TableColumns>();

export const columns = [
  columnHelper.accessor((row) => row.evaluatee.fullName, {
    id: "evaluatee",
    enableGlobalFilter: true,
    enableMultiSort: true,
    size: 320,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.memberName")}</Translation>
    ),
    cell: (info) => (
      <button
        className="flex gap-2 text-body2 font-semibold text-text-primary max-md:mb-2 max-md:flex-col md:h-fit"
        onClick={info.row.getToggleSelectedHandler()}
        type="button"
      >
        <Avatar
          displayName={info.row.original.evaluatee.fullName}
          src={info.row.original.evaluatee.avatar}
        />
        <div className="items-start text-left">
          <div className="text-subtitle2 text-text-primary">
            {info.row.original.evaluatee.fullName}
          </div>
          <div className="text-body2 text-text-secondary">
            {info.row.original.evaluatee.email}
          </div>
        </div>
      </button>
    ),
  }),

  columnHelper.accessor((row) => row.projectName, {
    id: "project",
    enableGlobalFilter: true,
    enableMultiSort: true,
    size: 260,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.project")}</Translation>
    ),
    cell: (info) => (
      <div
        className={cn(
          "flex items-center gap-1",
          info.row.original.isSelfEvaluation && "max-md:hidden",
        )}
      >
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.project")}:`}
          </Translation>
        </span>
        <p className="text-text-primary max-md:text-caption">
          {info.getValue()}
        </p>
      </div>
    ),
  }),

  columnHelper.accessor(
    (row) => (!row.rating ? 0 : EVALUATION_RATINGS[row.rating]),
    {
      id: "rating",
      enableGlobalFilter: false,
      enableMultiSort: true,
      size: 143,
      header: () => (
        <Translation>{(t) => t("evaluations.dashboard.rating")}</Translation>
      ),
      cell: (info) => (
        <Rating
          buttonClassName="p-0 md:size-6"
          className={cn(
            "flex size-auto items-center p-0",
            !info.row.original.isSelfEvaluation && "max-md:mb-2",
          )}
          readOnly
          size="auto"
          value={info.getValue()}
        />
      ),
    },
  ),
] as ColumnDef<TableColumns>[];
