import { useMediaQuery } from "react-responsive";
import { screens } from "tailwindcss/defaultTheme";

export const useScreenSize = () => {
  const sm = useMediaQuery({ minWidth: screens.sm });
  const md = useMediaQuery({ minWidth: screens.md });
  const lg = useMediaQuery({ minWidth: screens.lg });
  const xl = useMediaQuery({ minWidth: screens.xl });

  const maxSm = useMediaQuery({ maxWidth: screens.sm });
  const maxMd = useMediaQuery({ maxWidth: screens.md });
  const maxLg = useMediaQuery({ maxWidth: screens.lg });
  const maxXl = useMediaQuery({ maxWidth: screens.xl });

  const isSm = maxSm;
  const isMd = maxMd && sm;
  const isLg = maxLg && md;
  const isXl = maxXl && lg;

  return { sm, md, lg, xl, maxSm, maxMd, maxLg, maxXl, isSm, isMd, isLg, isXl };
};
