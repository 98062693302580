import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/solid";
import { useRouterState } from "@tanstack/react-router";
import { type ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import { type User, UserRole } from "@/api/users";
import { UserThumbnail } from "@/components/app/UserThumbnail";
import { Dropdown } from "@/components/ui/Dropdown";
import { cn } from "@/utils/ui";

import { AdminNavigation } from "./AdminNavigation";

export interface UserDropdownMenuProps
  extends Omit<ComponentProps<typeof Dropdown.Content>, "align" | "children"> {
  onSignoutClick: () => void;
  user: User;
}

export function UserDropdownMenu({
  className,
  onSignoutClick,
  user,
  ...props
}: UserDropdownMenuProps) {
  const { t } = useTranslation();
  const router = useRouterState();

  return (
    <Dropdown.Content
      align="end"
      className={cn("bg-white py-0", className)}
      sideOffset={11}
      {...props}
    >
      <Dropdown.Label className="pb-3">
        <UserThumbnail user={user} />
      </Dropdown.Label>

      {user.role === UserRole.Administrator && (
        <>
          <Dropdown.Separator />
          <AdminNavigation routePath={router.location.pathname} />
        </>
      )}

      <Dropdown.Separator />

      <Dropdown.Item className="gap-2" onClick={onSignoutClick}>
        <ArrowRightStartOnRectangleIcon className="size-6 text-text-secondary" />
        <span className="text-body1 text-text-primary">
          {t("components.userDropdownMenu.signOut")}
        </span>
      </Dropdown.Item>
    </Dropdown.Content>
  );
}
