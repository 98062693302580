import { useMutation, useQueryClient } from "@tanstack/react-query";

import { api, handleApiError } from "../utils";
import { evaluationRatingsKeys } from "./constants";

const deleteEvaluationRatingById = async (id: number) => {
  const response = await api.delete(`evaluation-ratings/${id}/`);

  await handleApiError(response);
};

export const useDeleteEvaluationRatingById = ({
  onSuccess,
}: { onSuccess?: (variables: number) => void } = {}) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, ...mutation } = useMutation({
    mutationFn: (id: number) => deleteEvaluationRatingById(id),
    mutationKey: evaluationRatingsKeys.list(),
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: evaluationRatingsKeys.list(),
      });
      onSuccess?.(variables);
    },
  });

  return { ...mutation, delete: mutateAsync, deleteSync: mutate };
};
