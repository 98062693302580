import { createFileRoute } from "@tanstack/react-router";

import { EvaluationsDashboardSubmissionsTab as EvaluationsIndexPage } from "@/components/pages/EvaluationsDashboardPage/SubmissionsTab";
import { formSchema as filterFormSchema } from "@/components/pages/EvaluationsDashboardPage/SubmissionsTab/EvaluationsFilterForm/utils";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

const routeParamsSchema = TableStateBaseParamsSchema.extend({
  filters: filterFormSchema.partial().optional(),
}).transform(({ ordering, filters, ...schema }) => {
  const { ordering: _, ...baseFilters } = filters ?? {};

  return {
    ...schema,
    ordering: filters?.ordering ?? ordering,
    ...(Boolean(filters) && { filters: baseFilters }),
  };
});

export const Route = createFileRoute("/evaluations/_dashboard/")({
  component: EvaluationsIndexPage,
  beforeLoad: () => ({ routeId: null }),
  validateSearch: (search) => routeParamsSchema.parse(search),
});
