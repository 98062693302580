import * as TabsPrimitive from "@radix-ui/react-tabs";
import type { LinkProps } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import React from "react";

import { cn } from "@/utils/ui";

const BaseTab = TabsPrimitive.Root;

const TabList = TabsPrimitive.List;

const TabTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, children, value, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={cn(
      "box-border px-4 py-3 text-subtitle2 text-text-disabled",
      className,
    )}
    ref={ref}
    value={value}
    {...props}
  >
    {children}
  </TabsPrimitive.Trigger>
));
TabTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, children, value, ...props }, ref) => (
  <TabsPrimitive.Content
    className={cn("pt-6", className)}
    ref={ref}
    value={value}
    {...props}
  >
    {children}
  </TabsPrimitive.Content>
));
TabContent.displayName = TabsPrimitive.Content.displayName;

export interface TabListProps {
  label: string;
  params?: Record<string, string>;
  routeTo?: LinkProps["to"];
  value: string;
  linkProps?: Partial<LinkProps>;
}

export interface TabContentProps {
  content: React.ReactElement;
  value: string;
}

export interface TabsProps extends TabsPrimitive.TabsProps {
  className?: string;
  maskedParams?: string[];
  tabContent?: TabContentProps;
  tabContentClassName?: string;
  tabList: TabListProps[];
  tabsListClassName?: string;
  tabsTriggerClassName?: string;
  tabsTriggerGeneralClassName?: string;
  paramsRetainedOnSwitch?: string[];
}

function Tab({
  className,
  maskedParams = [],
  paramsRetainedOnSwitch = [],
  tabContent,
  tabContentClassName,
  tabList,
  tabsListClassName,
  tabsTriggerClassName,
  tabsTriggerGeneralClassName,
  ...props
}: TabsProps) {
  return (
    <BaseTab className={cn("min-w-fit", className)} {...props}>
      <TabList
        className={cn("flex border-b border-other-divider", tabsListClassName)}
      >
        {tabList.map(({ label, params, routeTo, value, linkProps = {} }) => {
          if (params && routeTo) {
            return (
              <Link
                key={value}
                mask={{
                  to: routeTo,
                  search: (prev) => {
                    const newParams: Record<string, unknown> = {};
                    let key: string | number | symbol;
                    for (key of paramsRetainedOnSwitch) {
                      if (key in prev && !maskedParams.includes(key)) {
                        newParams[key] = prev[key as keyof typeof prev];
                      }
                    }
                    return newParams;
                  },
                }}
                params={params}
                preload={false}
                search={(prev) => {
                  const newParams: Record<string, unknown> = {};
                  let key: string | number | symbol;
                  for (key of paramsRetainedOnSwitch) {
                    if (key in prev) {
                      newParams[key] = prev[key as keyof typeof prev];
                    }
                  }
                  return newParams;
                }}
                to={routeTo}
                {...linkProps}
              >
                {({ isActive }) => {
                  return (
                    <TabTrigger
                      className={cn(
                        isActive
                          ? "border-b-2 border-primary-main text-primary-main"
                          : tabsTriggerClassName,
                        tabsTriggerGeneralClassName,
                      )}
                      value={value}
                    >
                      {label}
                    </TabTrigger>
                  );
                }}
              </Link>
            );
          }
          return (
            <TabTrigger
              className={cn(
                "data-[state=active]:border-b-2 data-[state=active]:border-primary-main data-[state=active]:text-primary-main",
                tabsTriggerClassName,
                tabsTriggerGeneralClassName,
              )}
              key={value}
              value={value}
            >
              {label}
            </TabTrigger>
          );
        })}
      </TabList>
      {tabContent !== undefined && (
        <TabContent
          className={tabContentClassName}
          key={tabContent.value}
          value={tabContent.value}
        >
          {tabContent.content}
        </TabContent>
      )}
    </BaseTab>
  );
}

export { Tab };
