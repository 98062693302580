import type { ReactNode } from "react";

import { cn } from "@/utils/ui";

export interface StatusIndicatorProps {
  className?: string;
  icon?: ReactNode;
  text: string;
}

export function StatusIndicator({
  className,
  icon,
  text,
}: StatusIndicatorProps) {
  return (
    <div
      className={cn(
        "flex items-center gap-2 px-1 xl:px-3 py-2 text-body2 text-text-primary",
        className,
      )}
    >
      {icon}
      <p className="max-xl:hidden">{text}</p>
    </div>
  );
}
