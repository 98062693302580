/* eslint-disable no-nested-ternary */
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/20/solid";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link, Outlet, useNavigate, useSearch } from "@tanstack/react-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EvaluationStatus } from "@/api/constants";
import {
  EvaluationCycleStatus,
  useListEvaluationCycles,
} from "@/api/evaluationCycles";
import { useGetCurrentEvaluationCycle } from "@/api/evaluationCycles/useGetCurrentEvaluationCycle";
import { useGetCurrentEvaluation } from "@/api/evaluations";
import { Button } from "@/components/ui/Button";
import { IconButton } from "@/components/ui/IconButton";
import { Spinner } from "@/components/ui/Spinner";
import type { TabListProps } from "@/components/ui/Tab";
import { Tab } from "@/components/ui/Tab";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { cn } from "@/utils/ui";

export function EvaluationsDashboardContainer() {
  const { t } = useTranslation();
  const { md } = useScreenSize();

  const navigate = useNavigate();
  const { filters } = useSearch({ from: "/evaluations/_dashboard" });

  const { currentCycle } = useGetCurrentEvaluationCycle();
  const { evaluation, isFetched: isCurrentEvaluationFetched } =
    useGetCurrentEvaluation();

  const { evaluationCycles, isFetched: isEvaluationCyclesFetched } =
    useListEvaluationCycles();

  const cycleIndex = useMemo(() => {
    if (filters?.cycle && filters.cycle.length === 1) {
      return (
        evaluationCycles?.results.findIndex(
          ({ id }) => filters.cycle?.[0] === id,
        ) ?? -1
      );
    }

    if (!filters?.cycle && currentCycle) {
      return (
        evaluationCycles?.results.findIndex(
          ({ id }) => currentCycle.id === id,
        ) ?? -1
      );
    }

    return -1;
  }, [currentCycle, evaluationCycles?.results, filters?.cycle]);
  const selectedCycle = evaluationCycles?.results[cycleIndex];

  const hasNextCycle = cycleIndex > 0;
  const hasPrevCycle = cycleIndex < (evaluationCycles?.results.length ?? 0) - 1;

  const handleNextCycleClick = () => {
    if (!hasNextCycle) return;

    const nextCycle = evaluationCycles?.results[cycleIndex - 1];

    void navigate({
      replace: true,
      search: (prev) => ({
        ...prev,
        ...(nextCycle && { filters: { cycle: [nextCycle.id] } }),
      }),
    });
  };

  const handlePrevCycleClick = () => {
    if (!hasPrevCycle) return;

    const prevCycle = evaluationCycles?.results[cycleIndex + 1];

    void navigate({
      replace: true,
      search: (prev) => ({
        ...prev,
        ...(prevCycle && { filters: { cycle: [prevCycle.id] } }),
      }),
    });
  };

  const tabList = useMemo<TabListProps[]>(() => {
    return [
      {
        label: t("evaluations.dashboard.submission"),
        routeTo: "/evaluations",
        params: {},
        value: "evaluations",
        linkProps: {
          activeOptions: { exact: true, includeSearch: false },
          search: (prev) => ({
            filters:
              "filters" in prev &&
              prev.filters &&
              "cycle" in prev.filters &&
              prev.filters.cycle
                ? { cycle: prev.filters.cycle }
                : undefined,
          }),
        },
      },
      {
        label: t("evaluations.dashboard.draft"),
        routeTo: "/evaluations/drafts",
        params: {},
        value: "drafts",
        linkProps: {
          activeOptions: { exact: true, includeSearch: false },
          search: (prev) => ({
            filters:
              "filters" in prev &&
              prev.filters &&
              "cycle" in prev.filters &&
              prev.filters.cycle
                ? { cycle: prev.filters.cycle }
                : undefined,
          }),
        },
      },
    ];
  }, [t]);

  const canEdit =
    evaluation && (evaluation.draftData || evaluation.ratings.length > 0);

  let createButtonLabelKey: string;
  if (
    !evaluation ||
    (!evaluation.draftData && evaluation.ratings.length === 0)
  ) {
    createButtonLabelKey = "evaluations.dashboard.buttonCreateEvaluation";
  } else if (
    evaluation.status === EvaluationStatus.Draft ||
    evaluation.draftData
  ) {
    createButtonLabelKey = "evaluations.dashboard.buttonEditDraft";
  } else {
    createButtonLabelKey = "evaluations.dashboard.buttonEditEvaluation";
  }

  return (
    <div className="flex h-full flex-col items-stretch md:gap-6 md:px-10 md:pb-16 md:pt-12">
      <div className="space-y-6 max-md:p-6">
        <div className="flex flex-wrap items-center justify-between gap-x-3">
          <div className="space-y-2">
            <h1 className="text-h6 text-text-primary md:text-h3">
              {t("evaluations.dashboard.evaluation")}
            </h1>
            {cycleIndex !== -1 && (
              <div className="flex items-center gap-2 max-md:hidden">
                <span className="whitespace-nowrap text-h6 text-text-secondary">
                  {t("evaluations.dashboard.cycle")}
                </span>
                {selectedCycle ? (
                  <div className="flex flex-row items-center justify-center gap-6">
                    <IconButton
                      className={cn(
                        "group relative h-[2rem] rounded-lg p-[0.375rem]",
                        "enabled:bg-other-basic enabled:text-primary-main disabled:bg-actions-disabled-background",
                      )}
                      disabled={!hasPrevCycle}
                      icon={<ChevronLeftIcon className="size-[1.25rem]" />}
                      onClick={handlePrevCycleClick}
                      size="custom"
                    />
                    <span className="whitespace-nowrap text-h6 text-primary-main">
                      {selectedCycle.name}
                    </span>
                    <IconButton
                      className={cn(
                        "group relative h-[2rem] rounded-lg p-[0.375rem]",
                        "enabled:bg-other-basic enabled:text-primary-main disabled:bg-actions-disabled-background",
                      )}
                      disabled={!hasNextCycle}
                      icon={<ChevronRightIcon className="size-[1.25rem]" />}
                      onClick={handleNextCycleClick}
                      size="custom"
                    />
                  </div>
                ) : !isEvaluationCyclesFetched ? (
                  <Spinner className="inline-flex size-4" />
                ) : (
                  <span className="whitespace-nowrap text-h6 text-text-primary">
                    {t("evaluations.dashboard.noActiveCycle")}
                  </span>
                )}
              </div>
            )}
          </div>

          {isCurrentEvaluationFetched ? (
            <Button
              asChild
              className={cn(
                "gap-2 invisible",
                currentCycle?.status === EvaluationCycleStatus.Active &&
                  "visible",
              )}
              disabled={!selectedCycle}
              isLink
              size={md ? "medium" : "small"}
            >
              <Link to="/evaluations/create">
                {canEdit ? (
                  <PencilSquareIcon className="size-5" />
                ) : (
                  <PlusIcon className="size-5" />
                )}
                <span>{t(createButtonLabelKey)}</span>
              </Link>
            </Button>
          ) : (
            <Spinner className="inline-flex size-4" />
          )}
        </div>

        {cycleIndex !== -1 && (
          <div className="flex flex-wrap items-center justify-between gap-x-2 md:hidden">
            <span className="whitespace-nowrap text-subtitle2 text-text-secondary">
              {t("evaluations.dashboard.cycle")}
            </span>
            {selectedCycle ? (
              <div className="flex max-w-[23rem] flex-auto flex-row items-center justify-between gap-3">
                <IconButton
                  className={cn(
                    "group relative h-[2rem] rounded-lg p-[0.375rem]",
                    "enabled:bg-other-basic enabled:text-primary-main disabled:bg-actions-disabled-background",
                  )}
                  disabled={!hasPrevCycle}
                  icon={<ChevronLeftIcon className="size-[1.25rem]" />}
                  onClick={handlePrevCycleClick}
                  size="custom"
                />
                <span className="whitespace-nowrap text-subtitle2 leading-none text-primary-main">
                  {selectedCycle.name}
                </span>
                <IconButton
                  className={cn(
                    "group relative h-[2rem] rounded-lg p-[0.375rem]",
                    "enabled:bg-other-basic enabled:text-primary-main disabled:bg-actions-disabled-background",
                  )}
                  disabled={!hasNextCycle}
                  icon={<ChevronRightIcon className="size-[1.25rem]" />}
                  onClick={handleNextCycleClick}
                  size="custom"
                />
              </div>
            ) : !isEvaluationCyclesFetched ? (
              <Spinner className="inline-flex size-4" />
            ) : (
              <span className="whitespace-nowrap text-h6 text-text-primary">
                {t("evaluations.dashboard.noActiveCycle")}
              </span>
            )}
          </div>
        )}
      </div>

      <Tab
        className="max-md:px-6"
        tabList={tabList}
        tabsTriggerGeneralClassName="md:w-[7.625rem]"
      />

      <Outlet />
    </div>
  );
}
