import { useMutation, useQueryClient } from "@tanstack/react-query";

import { api, handleApiError } from "../utils";
import type { Evaluation } from "./constants";
import { evaluationDraftsKeys, evaluationsKeys } from "./constants";

const discardDraftById = async (id: number) => {
  const response = await api.delete(`evaluation-drafts/${id}/`);

  await handleApiError(response);
};

export const useDiscardDraftById = ({
  onSuccess,
}: { onSuccess?: (variables: number) => void } = {}) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, ...mutation } = useMutation({
    mutationFn: (id: number) => discardDraftById(id),
    onSuccess: async (_data, id) => {
      await queryClient.invalidateQueries({
        queryKey: evaluationDraftsKeys.list(),
      });

      const currentEvaluation = queryClient.getQueryData<Evaluation>(
        evaluationsKeys.current,
      );

      if (currentEvaluation && currentEvaluation.id === id) {
        await queryClient.invalidateQueries({
          queryKey: evaluationsKeys.current,
        });
      }

      onSuccess?.(id);
    },
  });

  return { ...mutation, delete: mutateAsync, deleteSync: mutate };
};
