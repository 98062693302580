import * as Sentry from "@sentry/react";
import type { KyResponse } from "ky";
import ky from "ky";
import { toast } from "sonner";
import type { TypeOf, ZodTypeAny } from "zod";
import { z, ZodError } from "zod";

import { config, isLocal, isStaging } from "@/utils/constants";

let csrfToken = "";

export const api = ky.create({
  hooks: {
    beforeRequest: [
      async (request) => {
        try {
          if (!csrfToken) {
            const response = await ky.get(
              `${config.EVALS_API_BASE}/auth/csrf/`,
              {
                credentials: "include",
              },
            );
            const jsonResponse = await response.json<{ csrfToken: string }>();
            csrfToken = jsonResponse.csrfToken;
          }

          request.headers.set("X-CSRFToken", csrfToken);
        } catch (e) {
          // NO-OP
        }
      },
    ],
  },
  credentials: "include",
  prefixUrl: config.EVALS_API_BASE,
  throwHttpErrors: false,
});

interface ErrorResponse {
  errors?: {
    attr: string | null;
    code: string;
    detail: string;
  }[];
  type: string;
}

export class ApiError extends Error {
  name = "ApiError";
  errors?: ErrorResponse["errors"];
}

export class AuthError extends ApiError {
  name = "AuthError";
}

export class ClientError extends ApiError {
  name = "ClientError";
}

export class ServerError extends ApiError {
  name = "ServerError";
}

const apiErrorMessages: Record<number, string> = {
  400: "Bad request. Please check your input.",
  401: "Unauthorized. Please log in.",
  403: "Forbidden. You do not have permission to access this resource.",
  500: "Internal server error. Please try again later.",
  502: "Bad gateway. The server received an invalid response.",
  503: "Service unavailable. Please try again later.",
  504: "Gateway timeout. The server did not respond in time.",
};

interface HandleApiErrorOptions {
  ignoredErrorCodes?: number[];
  showErrorToast?: boolean;
}

export async function handleApiError(
  response: KyResponse,
  { ignoredErrorCodes = [], showErrorToast = true }: HandleApiErrorOptions = {},
) {
  if (response.ok) return;
  if (ignoredErrorCodes.includes(response.status)) return;

  if (response.status === 400) {
    const errorMessage = apiErrorMessages[response.status];
    const errorResponse = await response.json<ErrorResponse>();

    if (showErrorToast) {
      toast.error(errorMessage, { id: `server-${response.status}` });
    }

    const error = new ClientError(errorMessage);
    error.errors = errorResponse.errors;

    throw error;
  }

  if ([401, 403].includes(response.status)) {
    const errorMessage = apiErrorMessages[response.status];

    if (showErrorToast) {
      toast.error(errorMessage, { id: `server-${response.status}` });
    }

    throw new AuthError(errorMessage);
  }

  if (response.status >= 500 && response.status <= 599) {
    const errorMessage =
      apiErrorMessages[response.status] || apiErrorMessages["500"];

    if (showErrorToast) {
      toast.error(errorMessage, { id: `server-${response.status}` });
    }

    throw new ServerError(errorMessage);
  }
}

export function validateSchema<T extends ZodTypeAny>(schema: T, data: unknown) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return schema.parse(data) as TypeOf<T>;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    if (isLocal || isStaging) {
      toast.error(
        "Invalid schema detected. See console logs for more details.",
        { id: "schema" },
      );
    }

    Sentry.captureException(e);

    if (e instanceof ZodError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data as TypeOf<T>;
    }
  }
}

export const generatePaginatedResponseSchema = <T extends z.ZodTypeAny>(
  schema: T,
) =>
  z.object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: schema.array(),
  });

export function clearCsrfToken() {
  csrfToken = "";
}
