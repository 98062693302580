/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  type DetailedHTMLProps,
  type FormHTMLAttributes,
  useMemo,
  useState,
} from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";

import type { EvaluationCycle } from "@/api/evaluationCycles";
import { useGetProjectsByIds, useListProjects } from "@/api/projects";
import { useGetUsersByIds, useListUsers } from "@/api/users";
import {
  MultiSelectField,
  MultiSelectSearchField,
  RatingRangeSelectField,
  RatingSelectField,
} from "@/components/app/FormFields";
import { Button } from "@/components/ui/Button";
import { Form } from "@/components/ui/Form";
import { Spinner } from "@/components/ui/Spinner";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { DateSubmittedField } from "./DateSubmittedField";
import { type FormData as AdminFilterFormData } from "./utils";

export interface AdminFilterFormProps
  extends Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    "children" | "onSubmit"
  > {
  form: UseFormReturn<AdminFilterFormData>;
  onClear?: () => boolean | undefined;
  onSubmit: (formData: AdminFilterFormData) => void;
  resetDisabled?: boolean;
  submitDisabled?: boolean;
  evaluationCycles: EvaluationCycle[];
}

export function AdminFilterForm({
  className,
  form,
  onClear,
  onSubmit,
  resetDisabled,
  submitDisabled,
  evaluationCycles,
  ...props
}: AdminFilterFormProps) {
  const { t } = useTranslation();
  const { maxMd } = useScreenSize();
  const screenType = maxMd ? "mobile" : "default";

  const cycleOptions = useMemo(
    () =>
      evaluationCycles.map((cycle) => ({
        label: cycle.name,
        value: cycle.id,
      })),
    [evaluationCycles],
  );

  const [evaluateesSearch, setEvaluateesSearch] = useState<string>();
  const [evaluatorsSearch, setEvaluatorsSearch] = useState<string>();
  const [projectsSearch, setProjectsSearch] = useState<string>();

  const { users: evaluatees, isFetched: isFetchedEvaluatees } = useListUsers({
    ordering: ["first_name", "last_name"],
    ...(evaluateesSearch && { search: evaluateesSearch }),
  });

  const { users: evaluators, isFetched: isFetchedEvaluators } = useListUsers({
    ordering: ["first_name", "last_name"],
    ...(evaluatorsSearch && { search: evaluatorsSearch }),
  });

  const {
    results: projects,
    isFetched: isFetchedProjects,
    cursor: projectsCursor,
  } = useListProjects({
    ordering: ["name"],
    ...(projectsSearch && { search: projectsSearch }),
  });

  const selectedEvaluateeIds = form.getValues("evaluatee") ?? [];
  const { users: selectedEvaluatees, isFetched: isFetchedSelectedEvaluatees } =
    useGetUsersByIds(selectedEvaluateeIds);

  const selectedEvaluatorIds = form.getValues("evaluators") ?? [];
  const { users: selectedEvaluators, isFetched: isFetchedSelectedEvaluators } =
    useGetUsersByIds(selectedEvaluatorIds);

  const selectedProjectIds = form.getValues("projects") ?? [];
  const { projects: selectedProjects, isFetched: isFetchedSelectedProjects } =
    useGetProjectsByIds(selectedProjectIds);

  const isFetchingEvaluatees =
    !isFetchedSelectedEvaluatees || !isFetchedEvaluatees;
  const isFetchingEvaluators =
    !isFetchedSelectedEvaluators || !isFetchedEvaluators;
  const isFetchingProjects = !isFetchedSelectedProjects || !isFetchedProjects;

  const evaluateesList = useMemo(() => {
    const evaluateesResults = evaluatees ?? [];
    const fetchedEvaluateeIds = evaluateesResults.map(({ id }) => id);
    const selectedEvaluateesExtra = selectedEvaluatees.filter(
      ({ id }) => !fetchedEvaluateeIds.includes(id),
    );
    return [...evaluateesResults, ...selectedEvaluateesExtra];
  }, [evaluatees, selectedEvaluatees]);

  const evaluatorsList = useMemo(() => {
    const evaluatorsResults = evaluators ?? [];
    const fetchedEvaluatorIds = evaluatorsResults.map(({ id }) => id);
    const selectedEvaluatorsExtra = selectedEvaluators.filter(
      ({ id }) => !fetchedEvaluatorIds.includes(id),
    );
    return [...evaluatorsResults, ...selectedEvaluatorsExtra];
  }, [evaluators, selectedEvaluators]);

  const projectsList = useMemo(() => {
    const fetchedProjectIds = projects.map(({ id }) => id);
    const selectedProjectsExtra = selectedProjects.filter(
      ({ id }) => !fetchedProjectIds.includes(id),
    );
    return [...projects, ...selectedProjectsExtra];
  }, [projects, selectedProjects]);

  const evaluateeOptions = useMemo(
    () =>
      evaluateesList.map((user) => ({
        label: user.fullName,
        value: user.id,
      })),
    [evaluateesList],
  );

  const evaluatorOptions = useMemo(
    () =>
      evaluatorsList.map((user) => ({
        label: user.fullName,
        value: user.id,
      })),
    [evaluatorsList],
  );

  const projectOptions = useMemo(
    () =>
      projectsList.map((project) => ({
        label: project.name,
        value: project.id,
      })),
    [projectsList],
  );

  const [projectsScrollSentryRef] = useInfiniteScroll({
    loading: isFetchingProjects,
    hasNextPage: projectsCursor.hasNextPage,
    onLoadMore: projectsCursor.loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Form {...form}>
      <form
        className={cn("space-y-[0.625rem]", className)}
        onSubmit={(e) => {
          void form.handleSubmit(onSubmit)(e);
        }}
        {...props}
      >
        <p className="text-h6 text-text-primary">
          {t("admin.filterForm.filters")}
        </p>
        <div className="h-max space-y-6 pb-6">
          <MultiSelectField
            control={form.control}
            label={t("components.evaluationFilterForm.evaluationCycle")}
            name="cycle"
            options={cycleOptions}
            placeholder={t(
              `components.evaluationFilterForm.placeholderSelectCycle.${screenType}`,
            )}
          />
          <MultiSelectSearchField
            control={form.control}
            isFetching={isFetchingEvaluatees}
            label={t("admin.filterForm.evaluatee")}
            name="evaluatee"
            onSearchChange={setEvaluateesSearch}
            options={evaluateeOptions}
          />
          <MultiSelectSearchField
            control={form.control}
            isFetching={isFetchingEvaluators}
            label={t("admin.filterForm.evaluator")}
            name="evaluators"
            onSearchChange={setEvaluatorsSearch}
            options={evaluatorOptions}
          />
          <RatingSelectField
            control={form.control}
            label={t("admin.filterForm.selfRating")}
            name="selfRating"
          />
          <RatingRangeSelectField
            control={form.control}
            label={t("admin.filterForm.rating")}
            name="avgRating"
          />
          <MultiSelectSearchField
            control={form.control}
            isFetching={isFetchingProjects}
            label={t("admin.filterForm.project")}
            name="projects"
            onSearchChange={setProjectsSearch}
            options={projectOptions}
            scrollSentry={
              <div
                className="flex min-h-[1px] w-full justify-center"
                ref={projectsScrollSentryRef}
              >
                {Boolean(projectsCursor.isFetching) && (
                  <div className="p-2">
                    <Spinner className="size-4" />
                  </div>
                )}
              </div>
            }
          />
          <DateSubmittedField
            control={form.control}
            label={t("admin.filterForm.dateSubmitted")}
            name="evaluatedAt"
          />
        </div>

        <div className="flex gap-[0.625rem]">
          <Button
            className="flex-auto"
            disabled={resetDisabled}
            onClick={onClear}
            type="reset"
            variant="outlined"
          >
            {t("admin.filterForm.buttonResetFilters")}
          </Button>
          <Button className="flex-auto" disabled={submitDisabled} type="submit">
            {t("admin.filterForm.buttonApplyFilters")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
