import { useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { type QueryOptions } from "../constants";
import { api, handleApiError, validateSchema } from "../utils";
import {
  EvaluationCycleListResponseSchema,
  type EvaluationCycleQueryParams,
  evaluationCyclesKeys,
} from "./constants";

const listEvaluationCycles = async (params: EvaluationCycleQueryParams) => {
  const response = await api.get("evaluation-cycles/", {
    searchParams: decamelizeKeys(qs.stringify(params)),
  });

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleListResponseSchema, data);
};

export const useListEvaluationCycles = (
  params: EvaluationCycleQueryParams = {},
  options: QueryOptions = { enabled: true },
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled: options.enabled,
    queryFn: () => listEvaluationCycles(params),
    queryKey: evaluationCyclesKeys.list(params),
  });

  return { evaluationCycles: data || null, isFetched, isFetching };
};
