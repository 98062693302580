import { useParams, useSearch } from "@tanstack/react-router";
import { type HTMLAttributes, useMemo } from "react";

import { useGetEvaluationCycleMemberEvaluatees } from "@/api/evaluationCycles/useGetEvaluationCycleMemberEvaluatees";
import { NavigationTable } from "@/components/ui/NavigationTable";
import { cn } from "@/utils/ui";

import { EmptyDashboard } from "../../EmptyDashboard";
import { columns } from "./columns";
import { FilterForm } from "./FilterForm";
import { defaultValues, formSchema } from "./FilterForm/utils";
import { FilterSummary } from "./FilterSummary";
import { tableStateToQueryParamsTransformer } from "./utils";

export type EvaluateesTabProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>;

export function EvaluateesTab({ className, ...props }: EvaluateesTabProps) {
  const from = "/admin/cycles/$cycleId/members/$memberId/evaluatees";
  const { cycleId, memberId } = useParams({ from });
  const search = useSearch({ from });
  const evaluateesQueryParams =
    tableStateToQueryParamsTransformer.parse(search);

  const { evaluatees, isFetched } = useGetEvaluationCycleMemberEvaluatees(
    Number(cycleId),
    Number(memberId),
    evaluateesQueryParams,
  );

  const data = useMemo(() => evaluatees?.results ?? [], [evaluatees]);

  return (
    <NavigationTable
      className={cn("flex-auto", className)}
      columns={columns}
      data={data}
      defaultFilterFormValues={defaultValues}
      formSchema={formSchema}
      isFetching={!isFetched}
      placeholder={Boolean(isFetched) && <EmptyDashboard />}
      renderFilterForm={FilterForm}
      renderFilterSummary={FilterSummary}
      {...props}
    />
  );
}
