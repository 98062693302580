import { type ComponentProps, useMemo } from "react";
import { type FieldPath, type FieldValues } from "react-hook-form";

import { Form } from "@/components/ui/Form";
import {
  type MultipleSelectProps,
  Select,
  type SelectOption,
} from "@/components/ui/Select";
import { cn } from "@/utils/ui";

export interface RatingRangeSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ComponentProps<typeof Form.Field<TFieldValues, TName>>,
    "render"
  > {
  className?: string;
  label: string;
  onChange?: MultipleSelectProps["onChange"];
  value?: SelectOption[] | null;
}

export function RatingRangeSelectField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  className,
  label,
  onChange,
  value: controlledValue,
  ...props
}: RatingRangeSelectFieldProps<TFieldValues, TName>) {
  const ratingOptions = useMemo(
    () => [
      {
        label: "0",
        value: 0,
      },
      ...[1, 2, 3, 4].map((v) => ({
        label: `${v} - ${v}.99`,
        value: v,
      })),
      {
        label: "5",
        value: 5,
      },
    ],
    [],
  ).reverse();

  return (
    <Form.Field
      render={({ field: { value, onChange: handleChange, ...field } }) => (
        <Form.Item className={cn("space-y-[0.625rem]", className)}>
          <Form.Label className="text-subtitle2 text-text-secondary">
            {label}
          </Form.Label>
          <Select
            onChange={(selected, clicked) => {
              handleChange(selected?.map((option) => option.value));
              onChange?.(selected, clicked);
            }}
            options={ratingOptions}
            selectClassName="ring-actions-selected"
            type="multiple"
            value={
              controlledValue ??
              ratingOptions.filter((option) =>
                (value as number[] | null)?.includes(option.value),
              )
            }
            {...field}
          />
        </Form.Item>
      )}
      {...props}
    />
  );
}
