import {
  createFileRoute,
  ErrorComponent,
  Outlet,
} from "@tanstack/react-router";

import { LoadingComponent } from "@/components/app/LoadingComponent";
import { MainLayout } from "@/components/app/MainLayout";
import { checkAuthentication } from "@/utils/auth";

function Evaluations() {
  return (
    <MainLayout containerClassName="lg:max-h-[100dvh]">
      <Outlet />
    </MainLayout>
  );
}

export const Route = createFileRoute("/evaluations")({
  component: Evaluations,
  errorComponent: ErrorComponent,
  pendingComponent: LoadingComponent,
  beforeLoad: ({ context, location }) => {
    checkAuthentication(location.href, context.auth);
    return { routeId: "evaluations" };
  },
});
