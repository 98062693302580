import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { type QueryOptions } from "../constants";
import { api, handleApiError, validateSchema } from "../utils";
import {
  type AdminEvaluationQueryParams,
  AdminEvaluationQueryParamsSchemaTransformer,
  AdminEvaluationsListResponseSchema,
  evaluationsKeys,
} from "./constants";

export const listEvaluations = async (params: AdminEvaluationQueryParams) => {
  const response = await api.get("evaluations", {
    searchParams: qs.stringify(
      decamelizeKeys(AdminEvaluationQueryParamsSchemaTransformer.parse(params)),
    ),
  });

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(AdminEvaluationsListResponseSchema, data);
};

export const useListEvaluations = (
  params: AdminEvaluationQueryParams,
  options: QueryOptions = { enabled: true },
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled: options.enabled,
    queryFn: () => listEvaluations(params),
    queryKey: evaluationsKeys.list(params),
    placeholderData: keepPreviousData,
  });

  return { evaluations: data || null, isFetched, isFetching };
};
