import {
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import {
  type ChangeEventHandler,
  type ComponentProps,
  forwardRef,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type WheelEventHandler,
} from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { Popover } from "@/components/ui/Popover";
import type { SelectOption } from "@/components/ui/Select";
import { Spinner } from "@/components/ui/Spinner";
import { TextInput } from "@/components/ui/TextInput";
import { cn } from "@/utils/ui";

import { DropdownContent } from "./DropdownContent";
import type { SelectItemProps } from "./SelectItem";
import { isOptionsGrouped } from "./utils";

export interface SearchSelectProps
  extends Omit<ComponentProps<typeof Popover>, "anchor" | "content" | "open"> {
  anchorClassName?: string;
  containerClassName?: string;
  disableFiltering?: boolean;
  emptyResultsPlaceholder?: ReactNode;
  filterValue?: string;
  inputContainerClassName?: string;
  isFetching?: boolean;
  isOpen?: boolean;
  onCancelClick?: (selected: SelectOption[]) => void;
  onClickOutside?: () => void;
  onClose?: () => void;
  onConfirmClick?: (selected: SelectOption[]) => void;
  onOpen?: () => void;
  onRemoveSelected?: (item: SelectOption) => void;
  onSearchChange?: (search: string) => void;
  onSelectItem?: (option: SelectOption, isSelected: boolean) => void;
  options: SelectOption[] | { header: string; options: SelectOption[] }[];
  placeholder?: string;
  renderDropdownFooter?: (props: {
    options: SelectOption[];
    selected: SelectOption[];
    original: ReactNode;
  }) => ReactNode;
  renderItem?: (props: SelectItemProps) => ReactNode;
  scrollSentry?: ReactNode;
  showSelectionTags?: boolean;
  value?: SelectOption[];
}

function DefaultEmptyPlaceholder() {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2.5 px-4">
      <InformationCircleIcon className="size-6 text-text-disabled" />
      <div>
        <p className="text-text-secondary">
          {t("components.searchSelect.defaultEmptyPlaceholderTitle")}
        </p>
        <p className="text-caption text-text-hint">
          {t("components.searchSelect.defaultEmptyPlaceholderDescription")}
        </p>
      </div>
    </div>
  );
}

export const SearchSelect = forwardRef(function SearchSelect(
  {
    anchorClassName,
    className,
    disabled,
    disableFiltering = false,
    containerClassName,
    emptyResultsPlaceholder = <DefaultEmptyPlaceholder />,
    filterValue,
    inputContainerClassName,
    isFetching,
    isOpen,
    onCancelClick,
    onClickOutside,
    onClose,
    onConfirmClick,
    onOpen,
    onRemoveSelected,
    onSearchChange,
    onSelectItem,
    options,
    placeholder,
    renderDropdownFooter,
    renderItem,
    scrollSentry,
    showSelectionTags,
    value,
    ...props
  }: SearchSelectProps,
  ref,
) {
  const { t } = useTranslation();

  const [internalFilterValue, setInternalFilterValue] = useState("");
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const [selected, setSelected] = useState<SelectOption[]>([]);
  const [dropdownWidth, setDropdownWidth] = useState<number>();

  const internalRef = useRef<HTMLInputElement>(
    null,
  ) as MutableRefObject<HTMLInputElement | null>;
  const dropdownRef = useRef<HTMLDivElement>(
    null,
  ) as MutableRefObject<HTMLDivElement | null>;

  const finalIsOpen = isOpen ?? internalIsOpen;
  const finalFilterValue = filterValue ?? internalFilterValue;
  const finalValue = value ?? selected;

  const filteredOptions = useMemo(() => {
    if (finalFilterValue === "" || disableFiltering) return options;

    const filterText = finalFilterValue
      .toLocaleLowerCase()
      .replace(/\s+/g, " ");

    if (isOptionsGrouped(options)) {
      return options.map((optionGroup) => ({
        ...optionGroup,
        options: optionGroup.options.filter((option) =>
          option.label
            .toLocaleLowerCase()
            .replace(/\s+/g, " ")
            .includes(filterText),
        ),
      }));
    }

    return options.filter((option) =>
      option.label
        .toLocaleLowerCase()
        .replace(/\s+/g, " ")
        .includes(filterText),
    );
  }, [disableFiltering, finalFilterValue, options]);

  const dropdownStyles = useMemo(
    () => ({
      minWidth: dropdownWidth,
      width: dropdownWidth,
    }),
    [dropdownWidth],
  );

  const handleClose = useCallback(() => {
    setInternalIsOpen(false);

    onClose?.();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    setInternalIsOpen(true);

    onOpen?.();

    internalRef.current?.focus();
  }, [onOpen]);

  const handleWheel: WheelEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const query = e.target.value;

      onSearchChange?.(query);

      setInternalFilterValue(query);
    },
    [onSearchChange],
  );

  const handleCancelClick = useCallback(
    (selectedOptions: SelectOption[]) => {
      setInternalIsOpen(false);

      onCancelClick?.(selectedOptions);
      onClose?.();
    },
    [onCancelClick, onClose],
  );

  const handleConfirmClick = useCallback(
    (selectedOptions: SelectOption[]) => {
      setSelected([]);
      setInternalIsOpen(false);

      onConfirmClick?.(selectedOptions);
      onClose?.();
    },
    [onClose, onConfirmClick],
  );

  const handleSelectItem = useCallback(
    (option: SelectOption) => {
      const isSelected = Boolean(
        finalValue.find(
          (selectedOption) => selectedOption.value === option.value,
        ),
      );

      onSelectItem?.(option, !isSelected);

      setSelected((internal) => {
        const internalIsSelected = Boolean(
          internal.find(
            (selectedOption) => selectedOption.value === option.value,
          ),
        );

        if (internalIsSelected) {
          return internal.filter(
            (selectedOption) => selectedOption.value !== option.value,
          );
        }

        return [...internal, option];
      });
    },
    [onSelectItem, finalValue],
  );

  const handleClickOutside = useCallback(
    (e: PointerEvent) => {
      const element = e.target as Element;
      if (
        !internalRef.current?.contains(element) &&
        !dropdownRef.current?.contains(element)
      ) {
        handleClose();

        onClickOutside?.();
      }
    },
    [handleClose, onClickOutside],
  );

  const handleRemoveSelected = useCallback(
    (item: SelectOption) => {
      setSelected((prevSelected) =>
        prevSelected.filter((option) => option.value !== item.value),
      );

      onRemoveSelected?.(item);
    },
    [onRemoveSelected],
  );

  const handleAnchorMount = useCallback(
    (anchor: HTMLElement | null) => {
      if (anchor === null) {
        document.removeEventListener("pointerdown", handleClickOutside);
        return;
      }

      setDropdownWidth(anchor.offsetWidth);

      document.addEventListener("pointerdown", handleClickOutside);
    },
    [handleClickOutside],
  );

  useImperativeHandle(ref, () => internalRef.current!, []);

  return (
    <div className={cn("flex flex-col gap-2", containerClassName)}>
      <Popover
        align="start"
        anchor={
          <div
            className={cn("group/anchor", inputContainerClassName)}
            data-state={finalIsOpen ? "open" : undefined}
            ref={handleAnchorMount}
          >
            <TextInput
              className={cn(
                "flex flex-1 h-12 w-full items-center justify-between gap-1 min-w-0 rounded-lg",
                anchorClassName,
              )}
              data-state={finalIsOpen ? "open" : undefined}
              disabled={disabled}
              endIcon={
                <div className="flex size-6 items-center justify-end">
                  {isFetching ? (
                    <Spinner className="size-5" />
                  ) : (
                    <ChevronDownIcon
                      className={cn(
                        "size-4 text-text-hint transition-[transform] duration-300",
                        finalIsOpen && "-rotate-180",
                      )}
                    />
                  )}
                </div>
              }
              inputClassName="flex-1 items-center !pr-5 min-w-0 overflow-hidden group-data-[state=open]/anchor:ring-primary-border group-data-[state=open]/anchor:ring-[0.1875rem]"
              onChange={handleSearchChange}
              onClick={handleOpen}
              onFocus={handleOpen}
              placeholder={
                placeholder ?? t("components.searchSelect.placeholder")
              }
              ref={internalRef}
              value={finalFilterValue}
            />
          </div>
        }
        className={cn("max-h-[15.0625] px-0 py-[0.625rem]", className)}
        content={
          <DropdownContent
            emptyPlaceholder={emptyResultsPlaceholder}
            onCancelClick={handleCancelClick}
            onConfirmClick={handleConfirmClick}
            onSelectItem={handleSelectItem}
            options={filteredOptions}
            renderFooter={renderDropdownFooter}
            renderItem={renderItem}
            scrollSentry={scrollSentry}
            selected={finalValue}
          />
        }
        onEscapeKeyDown={handleClose}
        onWheel={handleWheel}
        open={finalIsOpen}
        ref={dropdownRef}
        style={dropdownStyles}
        {...props}
      />

      {Boolean(showSelectionTags) && finalValue.length > 0 && (
        <div className="flex flex-wrap gap-1">
          {finalValue.map((item) => (
            <Button
              className={cn(
                "h-6 cursor-default border-other-border pl-2 pr-1 text-caption text-text-primary has-[:hover]:bg-actions-hover",
                disabled && "px-2",
              )}
              color="custom"
              disabled={disabled}
              endIcon={
                !disabled && (
                  <XCircleIcon
                    className="w-3.5 cursor-pointer text-other-border"
                    onClick={() => {
                      handleRemoveSelected(item);
                    }}
                  />
                )
              }
              key={`${item.label}-${item.value}`}
              variant="outlined"
            >
              {item.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
});
