import type { AvatarProps } from "@/components/ui/Avatar";
import { Avatar } from "@/components/ui/Avatar";
import { cn } from "@/utils/ui";

import { Tooltip } from "../Tooltip";

export interface AvatarStackProps {
  // Enforce consistent sizing
  size?: number;
  maxAvatarsShown?: number;
  spaceInset?: number;
  avatars: Omit<AvatarProps, "size">[];
}

export function AvatarStack({
  avatars,
  size = 32,
  spaceInset = 12,
  maxAvatarsShown = 2,
}: AvatarStackProps) {
  const overflowedAvatars = avatars.slice(maxAvatarsShown);

  return (
    <div className="flex">
      {avatars.slice(0, maxAvatarsShown).map(({ className, ...props }, idx) => (
        <Tooltip
          content={<p className="text-body2">{props.displayName}</p>}
          key={props.displayName}
        >
          <Avatar
            {...props}
            className={cn("border-[2px] border-white block", className)}
            size={size}
            style={{
              position: "relative",
              left: -spaceInset * idx,
            }}
          />
        </Tooltip>
      ))}
      {overflowedAvatars.length > 0 && (
        <Tooltip
          content={
            <div className="flex flex-col">
              {overflowedAvatars.map((avatar) => (
                <span className="text-body2" key={avatar.displayName}>
                  {avatar.displayName}
                </span>
              ))}
            </div>
          }
        >
          <div
            className="grid aspect-square place-items-center rounded-full bg-secondary-light text-caption text-primary-main hover:bg-secondary-light"
            style={{
              width: size,
              height: size,
              position: "relative",
              left: -spaceInset * maxAvatarsShown,
            }}
          >
            +{overflowedAvatars.length}
          </div>
        </Tooltip>
      )}
    </div>
  );
}
