import { Fragment, type HTMLAttributes, type ReactNode } from "react";

import { cn } from "@/utils/ui";

import { StepStatus } from "./constants";
import { StepIndicator } from "./StepIndicator";

export interface StepperProps extends HTMLAttributes<HTMLDivElement> {
  classNames?: {
    stepContainer?: string;
    stepIndicator?: {
      [StepStatus.Completed]?: string;
      [StepStatus.Current]?: string;
      [StepStatus.Future]?: string;
      default: string;
    };
    stepLabel?: {
      [StepStatus.Completed]?: string;
      [StepStatus.Current]?: string;
      [StepStatus.Future]?: string;
      default: string;
    };
    stepSeparator?: string;
    stepSeparatorContainer?: string;
  };
  currentStep: string;
  isCurrentStepCompleted?: boolean;
  unlockedStep?: string;
  onStepClick?: (stepOption: {
    name: string;
    label?: string;
    status: StepStatus;
  }) => void;
  renderStepIndicator?: (props: {
    className?: string;
    onClick?: () => void;
    status: StepStatus;
    value: number;
  }) => ReactNode;
  stepOffset?: number;
  steps: { name: string; label?: string }[];
}

export function Stepper({
  className,
  classNames,
  currentStep,
  isCurrentStepCompleted,
  unlockedStep,
  onStepClick,
  renderStepIndicator,
  stepOffset = 0,
  steps,
  ...props
}: StepperProps) {
  const RenderStepIndicator = renderStepIndicator ?? StepIndicator;

  const currentStepValue =
    steps.findIndex(({ name }) => name === currentStep) + 1;
  const unlockedStepValue =
    steps.findIndex(({ name }) => name === (unlockedStep ?? currentStep)) + 1;

  return (
    <div className={cn("flex items-start", className)} {...props}>
      {steps.map((step, i) => {
        let status: StepStatus;
        if (currentStepValue < i + 1) {
          status =
            unlockedStepValue < i + 1 ? StepStatus.Future : StepStatus.Current;
        } else if (currentStepValue > i + 1) {
          status = StepStatus.Completed;
        } else {
          status = isCurrentStepCompleted
            ? StepStatus.Completed
            : StepStatus.Current;
        }

        return (
          <Fragment key={step.name}>
            <div
              className={cn(
                "flex min-w-[5.2rem] max-w-[5.2rem] flex-col items-center gap-[0.125rem]",
                classNames?.stepContainer,
              )}
            >
              <RenderStepIndicator
                className={cn(
                  classNames?.stepIndicator?.default,
                  classNames?.stepIndicator?.[status],
                )}
                onClick={() => {
                  onStepClick?.({ ...step, status });
                }}
                status={status}
                value={stepOffset + i + 1}
              />
              <p
                className={cn(
                  "text-center text-caption text-text-secondary",
                  status === StepStatus.Future && "text-text-hint",
                  classNames?.stepLabel?.default,
                  classNames?.stepLabel?.[status],
                )}
              >
                {step.label ?? step.name}
              </p>
            </div>
            {i < steps.length - 1 && (
              <div
                className={cn(
                  "relative h-8 min-w-10 flex-auto",
                  classNames?.stepSeparatorContainer,
                )}
              >
                <div
                  className={cn(
                    "absolute top-1/2 h-[0.0625rem] w-full -translate-y-1/2 bg-other-divider",
                    classNames?.stepSeparator,
                  )}
                />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
