import {
  type ComponentProps,
  type KeyboardEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { type Project } from "@/api/projects";
import { useGetCurrentUser, type User } from "@/api/users";
import { UserThumbnail } from "@/components/app/UserThumbnail";
import { Button } from "@/components/ui/Button";
import { Dialog } from "@/components/ui/Dialog";
import { type SelectOption } from "@/components/ui/Select";

import { MemberSearchSelect } from "./MemberSearchSelect";

export interface AddMembersDialogProps
  extends Omit<
    ComponentProps<typeof Dialog>,
    "description" | "footerButtons" | "onOpenChange" | "title" | "variant"
  > {
  excludedMembers: Record<string, User[]>;
  isLoading?: boolean;
  onCancelClick: () => void;
  onConfirmClick: (users: User[]) => void;
  onOpenChange: (isOpen: boolean) => void;
  project: Project | undefined;
}

export function AddMembersDialog({
  defaultOpen,
  excludedMembers: excludedUsers,
  isLoading,
  onCancelClick,
  onConfirmClick,
  onOpenChange,
  open,
  project,
  ...props
}: AddMembersDialogProps) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const finalIsOpen = open ?? isOpen;

  const { currentUser } = useGetCurrentUser();

  const excludedMembers = useMemo(() => {
    const members =
      project && project.id in excludedUsers ? excludedUsers[project.id] : [];
    return currentUser ? [...members, currentUser] : members;
  }, [currentUser, excludedUsers, project]);

  const handleSelectItem = useCallback(
    (_option: SelectOption, _isSelected: boolean, selected: User[]) => {
      setSelectedUsers(selected);
    },
    [],
  );

  const handleConfirmClick = () => {
    setSelectedUsers([]);
    setIsOpen(false);
    onConfirmClick(selectedUsers);
  };

  const handleOpenAutoFocus = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key !== "Escape") return;
      setSelectedUsers([]);
      setIsOpen(false);
      onOpenChange(false);
    },
    [onOpenChange],
  );

  const handleOpenChange = useCallback(
    (flag: boolean) => {
      if (!flag) {
        setSelectedUsers([]);
      }
      setIsOpen(flag);
      onOpenChange(flag);
    },
    [onOpenChange],
  );

  const handleClose = useCallback(() => {
    setSelectedUsers([]);
    setIsOpen(false);
    onOpenChange(false);
  }, [onOpenChange]);

  return (
    <Dialog onOpenChange={handleOpenChange} open={finalIsOpen} {...props}>
      <Dialog.Content
        onKeyDown={handleKeyDown}
        onOpenAutoFocus={handleOpenAutoFocus}
        onPointerDownOutside={handleClose}
      >
        <Dialog.Header>
          <Dialog.Title>
            {t("evaluations.create.titleAddMemberDialog")}
            <span className="text-primary-main">{project?.name}</span>
          </Dialog.Title>
        </Dialog.Header>

        <Dialog.Body className="space-y-[1.125rem] lg:space-y-[0.625rem]">
          <div className="space-y-3 md:space-y-6">
            <MemberSearchSelect
              excludedMembers={excludedMembers}
              onSelectItem={handleSelectItem}
              placeholder={t("evaluations.create.searchMembersPlaceholder")}
            />
            {selectedUsers.length > 0 && (
              <div className="space-y-3">
                {selectedUsers.map((user) => (
                  <UserThumbnail key={user.id} showEmail={false} user={user} />
                ))}
              </div>
            )}
          </div>
        </Dialog.Body>

        <Dialog.Footer>
          <Dialog.Close asChild disabled={isLoading} onClick={onCancelClick}>
            <Button variant="outlined">
              {t(`evaluations.create.buttonAddMembersCancel`)}
            </Button>
          </Dialog.Close>
          <Button disabled={isLoading} onClick={handleConfirmClick}>
            {t(`evaluations.create.buttonAddMembersConfirm`)}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
