import * as ProgressPrimitive from "@radix-ui/react-progress";
import { cva, type VariantProps } from "class-variance-authority";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/utils/ui";

import { Label } from "../Label";

const progressVariants = cva("", {
  variants: {
    size: {
      small: "h-2",
      medium: "h-2",
      large: "h-3",
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

const labelVariants = cva("", {
  variants: {
    size: {
      small: "text-button-small",
      medium: "text-subtitle2",
      large: "text-subtitle2",
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

interface ProgressProps
  extends VariantProps<typeof progressVariants>,
    React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  color?: string;
  label?: string;
  labelClassName?: string;
  labelPosition?: "top" | "right";
  showLabel?: boolean;
  showValue?: boolean;
  valueClassName?: string;
}

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(
  (
    {
      className,
      color,
      label,
      labelClassName,
      labelPosition = "top",
      showLabel = true,
      showValue = true,
      size = "medium",
      value,
      valueClassName,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const showTopLabel = (showLabel || showValue) && labelPosition === "top";
    const showRightLabel = showValue && labelPosition === "right";

    const progressColor = useMemo(() => {
      if (!value) {
        return undefined;
      }

      if (value < 50) {
        return "bg-error-main";
      }

      if (value === 100) {
        return "bg-success-main";
      }

      return "bg-warning-main";
    }, [value]);

    const textColor = useMemo(() => {
      if (!value) {
        return "text-text-secondary";
      }

      if (value < 50) {
        return "text-error-main";
      }

      if (value === 100) {
        return "text-success-main";
      }

      return "text-warning-main";
    }, [value]);

    const renderProgressValue = () => (
      <div
        className={cn(
          labelVariants({ size }),
          size === "small" && "text-caption",
          "leading-none",
          textColor,
          valueClassName,
        )}
      >{`${value ?? 0}%`}</div>
    );

    return (
      <div
        className={cn(
          showTopLabel && "space-y-1",
          showRightLabel && "flex items-center gap-6",
          className,
        )}
      >
        {showTopLabel ? (
          <div
            className={cn(
              "flex items-center justify-between ",
              !showLabel && "justify-end",
            )}
          >
            {showLabel ? (
              <Label className={cn(labelVariants({ size }), labelClassName)}>
                {label ?? t("components.progress.progressLabel")}
              </Label>
            ) : null}

            {showValue ? renderProgressValue() : null}
          </div>
        ) : null}

        <ProgressPrimitive.Root
          className={cn(
            "relative flex w-full overflow-hidden rounded-full bg-actions-main",
            progressVariants({ size }),
            className,
          )}
          ref={ref}
          {...props}
        >
          <ProgressPrimitive.Indicator
            className={cn(
              "size-full flex-1 transition-all rounded-full",
              progressColor,
            )}
            style={{
              transform: `translateX(-${100 - (value || 0)}%)`,
              backgroundColor: color,
            }}
          />
        </ProgressPrimitive.Root>

        {showRightLabel ? renderProgressValue() : null}
      </div>
    );
  },
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
