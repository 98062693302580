/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  ArrowLeftIcon as ArrowLeftIconMini,
  PencilSquareIcon as PencilSquareIconMini,
} from "@heroicons/react/16/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  Link,
  useLoaderData,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  EvaluationCycleStatus,
  useGetCurrentEvaluationCycle,
} from "@/api/evaluationCycles";
import { type BasicEvaluationRating } from "@/api/evaluationRatings";
import { useGetCurrentEvaluation } from "@/api/evaluations";
import { useGetEvaluationById } from "@/api/evaluations/useGetEvaluationById";
import { useGetCurrentUser } from "@/api/users";
import { Breadcrumb } from "@/components/ui/Breadcrumb";
import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { useRouteBreadcrumbs } from "@/utils/hooks/useRouteBreadcrumbs";

import { EvaluationRatingDetail } from "./EvaluationRatingDetail";
import { EvaluationSubjects } from "./EvaluationSubjects";

export function EvaluationDetailPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routes = useRouteBreadcrumbs();
  const evaluationId = Number.parseInt(
    useLoaderData({ from: "/evaluations/$evaluationId" }),
  );
  const {
    evaluateeId,
    projectId,
  }: { evaluateeId?: number; projectId?: number } = useSearch({
    from: "/evaluations/$evaluationId",
  });

  const { currentCycle, isFetched: isFetchedCycle } =
    useGetCurrentEvaluationCycle();
  const { evaluation, isFetched } = useGetEvaluationById(evaluationId, {
    enabled: Number.isFinite(evaluationId),
  });
  const { evaluation: currentEvaluation } = useGetCurrentEvaluation();
  const { currentUser } = useGetCurrentUser();

  const canEdit =
    evaluation &&
    isFetchedCycle &&
    currentEvaluation &&
    evaluation.id === currentEvaluation.id;

  const ratings = useMemo(() => evaluation?.ratings ?? [], [evaluation]);
  const selectedMemberId = evaluateeId || null;
  const selectedProjectId = projectId || null;

  const projectMemberRatings = useMemo(() => {
    const map: Record<
      string,
      { projectName: string; ratings: BasicEvaluationRating[] }
    > = {};
    ratings.forEach((rating) => {
      if (rating.isSelfEvaluation) return;

      if (!(`${rating.projectId}` in map)) {
        map[`${rating.projectId}`] = {
          projectName: rating.projectName ?? "",
          ratings: [],
        };
      }

      map[`${rating.projectId}`].ratings.push(rating);
    });

    return map;
  }, [ratings]);

  const memberRatingList = useMemo(
    () =>
      selectedProjectId === null ||
      !(`${selectedProjectId}` in projectMemberRatings)
        ? []
        : projectMemberRatings[`${selectedProjectId}`].ratings,
    [projectMemberRatings, selectedProjectId],
  );

  const selectedRatingIndex = useMemo(
    () =>
      memberRatingList.findIndex(
        (rating) =>
          rating.projectId === selectedProjectId &&
          rating.evaluatee.id === selectedMemberId,
      ),
    [memberRatingList, selectedMemberId, selectedProjectId],
  );

  const hasNextRating = useMemo(() => {
    if (selectedRatingIndex === -1) return false;
    return selectedRatingIndex < memberRatingList.length - 1;
  }, [memberRatingList, selectedRatingIndex]);

  const hasPrevRating = useMemo(() => {
    if (selectedRatingIndex === -1) return false;
    return selectedRatingIndex > 0;
  }, [selectedRatingIndex]);

  const selectedRating = useMemo(() => {
    const selected = ratings.find(
      (rating) =>
        (!("projectId" in rating) || selectedProjectId === rating.projectId) &&
        selectedMemberId === rating.evaluatee.id,
    );

    if (selected) return selected;
    if (
      !currentEvaluation ||
      !currentUser ||
      selectedMemberId !== currentUser.id
    )
      return null;

    return {
      evaluatee: currentUser,
      evaluationId: currentEvaluation.id,
      isSelfEvaluation: true,
      projectName: t("evaluations.detail.selfEvaluation"),
      rating: "" as const,
    };
  }, [
    currentEvaluation,
    currentUser,
    ratings,
    selectedProjectId,
    selectedMemberId,
    t,
  ]);

  const selectedProjectName = useMemo(() => {
    if (selectedRating?.isSelfEvaluation) {
      return t("evaluations.detail.selfEvaluation");
    }

    const selected = ratings.find(
      (rating) => selectedProjectId === rating.projectId,
    );

    return selected?.projectName ?? "";
  }, [ratings, selectedProjectId, selectedRating?.isSelfEvaluation, t]);

  const handleProjectOrRatingClick = async (
    newEvaluateeId: number,
    newProjectId?: number,
  ) =>
    navigate({
      to: undefined,
      params: {},
      replace: true,
      search: {
        evaluateeId: newEvaluateeId,
        projectId: newProjectId,
      },
    });

  const incSelectedRatingIndex = useCallback(
    async (deltaIndex: number) => {
      const rating = memberRatingList[selectedRatingIndex + deltaIndex];

      await navigate({
        to: undefined,
        params: {},
        replace: true,
        search: {
          evaluateeId: rating.evaluatee.id,
          projectId: rating.projectId,
        },
      });
    },
    [memberRatingList, selectedRatingIndex, navigate],
  );

  const handleNextRatingClick = useCallback(async () => {
    await incSelectedRatingIndex(1);
  }, [incSelectedRatingIndex]);

  const handlePrevRatingClick = useCallback(async () => {
    await incSelectedRatingIndex(-1);
  }, [incSelectedRatingIndex]);

  if (!Number.isFinite(evaluationId)) return null;

  return (
    <div className="flex flex-auto flex-col items-stretch max-md:min-h-full md:max-h-full md:gap-6 md:px-10 md:pb-10 md:pt-12">
      <div className="flex items-center justify-between gap-2">
        <div className="flex flex-col p-6 md:gap-2 md:p-0">
          <h1 className="text-h6 text-text-primary md:text-h3">
            {t("evaluations.detail.title")}
          </h1>
          <Breadcrumb routes={routes} />
        </div>

        <div className="flex items-center gap-3 max-md:hidden">
          <Button asChild variant="text">
            <Link to="/evaluations">{t("evaluations.detail.buttonBack")}</Link>
          </Button>
          {canEdit && currentCycle?.status === EvaluationCycleStatus.Active ? (
            <Button asChild>
              <Link
                search={{ evaluateeId, projectId }}
                to="/evaluations/create"
              >
                <PencilSquareIcon className="size-[1.375rem]" />
                <span>{t("evaluations.detail.buttonEdit")}</span>
              </Link>
            </Button>
          ) : null}
        </div>
      </div>

      <div className="text-h6 text-text-secondary max-md:hidden">
        <span className="whitespace-nowrap text-h6 text-text-secondary">
          {`${t("evaluations.create.evaluationCycle")} : `}
        </span>
        {!isFetched ? (
          <Spinner className="inline-flex size-4" />
        ) : (
          <span className="whitespace-nowrap text-text-primary">
            {evaluation
              ? evaluation.cycle.name
              : t("evaluations.dashboard.noActiveCycle")}
          </span>
        )}
      </div>

      <div className="flex flex-auto items-stretch gap-3 overflow-y-hidden max-lg:flex-col-reverse max-md:flex-col max-md:px-6 max-md:pb-6 max-md:pt-3 md:gap-10 lg:gap-6">
        <div className="text-subtitle2 text-text-secondary md:hidden">
          <span className="whitespace-nowrap text-text-secondary">
            {`${t("evaluations.create.evaluationCycle")} : `}
          </span>
          {!isFetched ? (
            <Spinner className="inline-flex size-4" />
          ) : (
            <span className="whitespace-nowrap text-text-primary">
              {evaluation
                ? evaluation.cycle.name
                : t("evaluations.dashboard.noActiveCycle")}
            </span>
          )}
        </div>

        <EvaluationSubjects
          className="md:mr-[1.125rem]"
          containerClassName="md:grow-[40%] md:basis-[40%]"
          isLoading={!isFetched}
          memberRatingList={memberRatingList}
          onProjectOrRatingClick={handleProjectOrRatingClick}
          projectMemberRatings={projectMemberRatings}
          ratings={ratings}
          selectedMemberId={selectedMemberId}
          selectedProjectId={selectedProjectId}
        />
        <EvaluationRatingDetail
          className="max-md:h-full md:m-0.5 md:mr-3"
          containerClassName="md:grow-[60%] md:basis-[60%] max-md:flex-auto"
          hasNextRating={hasNextRating}
          hasPrevRating={hasPrevRating}
          isLoading={!isFetched}
          onNextRatingClick={handleNextRatingClick}
          onPrevRatingClick={handlePrevRatingClick}
          projectName={selectedProjectName}
          rating={selectedRating}
        />
      </div>

      <div className="flex items-center gap-3 border-t border-other-divider p-4 md:hidden">
        <Button
          asChild
          className="grow-[50%] basis-[50%] rounded-full"
          size="small"
          variant="text"
        >
          <Link to="/evaluations">
            <ArrowLeftIconMini className="size-[1.125rem]" />
            <span className="leading-none">
              {t("evaluations.detail.buttonBack")}
            </span>
          </Link>
        </Button>
        {currentCycle?.status === EvaluationCycleStatus.Active && (
          <Button
            asChild
            className="grow-[50%] basis-[50%]"
            disabled={!canEdit}
            size="small"
          >
            <Link search={{ evaluateeId, projectId }} to="/evaluations/create">
              <PencilSquareIconMini className="size-[1.125rem]" />
              <span className="leading-none">
                {t("evaluations.detail.buttonEdit")}
              </span>
            </Link>
          </Button>
        )}
      </div>
    </div>
  );
}
