import { DropdownContent, type DropdownContentProps } from "./DropdownContent";
import {
  SearchSelect as SearchSelectRoot,
  type SearchSelectProps as SearchSelectRootProps,
} from "./SearchSelect";
import {
  ItemCheckbox,
  ItemLabel,
  SelectItem,
  type SelectItemProps,
} from "./SelectItem";

export { type SelectOption } from "@/components/ui/Select";

export interface SearchSelectProps {
  Root: SearchSelectRootProps;
  Dropdown: DropdownContentProps;
  Item: SelectItemProps;
}

export const SearchSelect = Object.assign(SearchSelectRoot, {
  Dropdown: DropdownContent,
  Item: Object.assign(SelectItem, {
    Checkbox: ItemCheckbox,
    Label: ItemLabel,
  }),
});
