import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export const FormSchema = z.object({
  ratingNotes: z.string().min(50, { message: "Rating notes is required" }),
  evaluatee: z.number(),
  project: z.number().optional(),
  rating: z
    .number()
    .min(1, { message: "Rating must not be less than 1" })
    .max(5, { message: "Rating must not be greater than 5" }),
});

export type FormData = z.infer<typeof FormSchema>;

export const resolver = zodResolver(FormSchema);
