import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";

import type { EvaluationDraft } from "@/api/evaluations";
import { DataTable } from "@/components/ui/DataTable";

import { columns } from "./columns";

const initialState = {
  pagination: { pageSize: 99999, pageIndex: 0 },
};

export interface RatingsSubTableProps {
  row: Row<EvaluationDraft>;
}

export function RatingsSubTable({ row }: RatingsSubTableProps) {
  const data = useMemo(
    () =>
      row.original.draftData?.ratingsWithEvaluatee.sort((a) =>
        a.isSelfEvaluation ? -1 : 1,
      ) ?? [],
    [row.original.draftData],
  );

  return (
    <DataTable
      bodyClassName="[&>tr:last-child>td]:border-0 [&>tr]:bg-blue-grey-100"
      className="rounded-none"
      columnHeaderClassName="bg-blue-grey-300 h-[3.375rem]"
      columns={columns}
      data={data}
      defaultPagination={initialState.pagination}
      renderTableFooter={() => null}
      renderTableHeader={() => null}
    />
  );
}
