import { useSearch } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  useGetCurrentEvaluationCycle,
  useListEvaluationCycles,
} from "@/api/evaluationCycles";
import type { EvaluationDraft } from "@/api/evaluations";
import { useListEvaluationDrafts } from "@/api/evaluations/useListEvaluationsDrafts";
import { NavigationTable } from "@/components/ui/NavigationTable";
import type { FilterFormProps } from "@/components/ui/NavigationTable/TableHeaderControls";
import { useScreenSize } from "@/utils/hooks";

import { EmptyDashboard } from "../EmptyDashboard";
import { columns } from "./columns";
import { DraftsFilterForm } from "./DraftsFilterForm";
import type { FormData as FilterFormData } from "./DraftsFilterForm/utils";
import { defaultValues, formSchema } from "./DraftsFilterForm/utils";
import { RatingsSubTable } from "./RatingsSubTable";
import { tableStateToQueryParamsTransformer } from "./utils";

export function EvaluationsDashboardDraftsTab() {
  const { t } = useTranslation();
  const { maxMd } = useScreenSize();
  const search = useSearch({ from: "/evaluations/_dashboard/drafts" });
  const { filters } = search;

  const draftsQueryParams = tableStateToQueryParamsTransformer.parse(search);

  const { evaluations, isFetched: isFetchedOwnEvaluationData } =
    useListEvaluationDrafts({
      ...draftsQueryParams,
      isDraft: true,
    });

  const { evaluationCycles, isFetched: isEvaluationCyclesFetched } =
    useListEvaluationCycles();

  const { currentCycle, isFetched: isCurrentCycleFetched } =
    useGetCurrentEvaluationCycle();

  const data = useMemo(() => evaluations?.results ?? [], [evaluations]);

  const cycleIndex = useMemo(() => {
    if (filters?.cycle && filters.cycle.length === 1) {
      return (
        evaluationCycles?.results.findIndex(
          ({ id }) => filters.cycle?.[0] === id,
        ) ?? -1
      );
    }

    if (!filters?.cycle && currentCycle) {
      return (
        evaluationCycles?.results.findIndex(
          ({ id }) => currentCycle.id === id,
        ) ?? -1
      );
    }

    return -1;
  }, [currentCycle, evaluationCycles?.results, filters?.cycle]);
  const selectedCycle = evaluationCycles?.results[cycleIndex];

  const renderExpandedSubcomponent = useCallback(
    (props: { row: Row<EvaluationDraft> }) => <RatingsSubTable {...props} />,
    [],
  );

  const renderFilterForm = useCallback(
    (filterProps: FilterFormProps<FilterFormData>) => (
      <DraftsFilterForm
        {...filterProps}
        // Ensure form re-renders when cycle filter changes
        // without causing entire table header to re-render
        evaluationCycles={evaluationCycles?.results ?? []}
        key={search.filters?.cycle?.join(",")}
      />
    ),
    [evaluationCycles?.results, search.filters?.cycle],
  );

  const defaultFilterFormValues = useMemo(
    () => ({
      ...defaultValues,
      cycle: currentCycle ? [currentCycle.id] : defaultValues.cycle,
    }),
    [currentCycle],
  );

  const searchPlaceholder = t(
    maxMd
      ? "evaluations.dashboard.searchPlaceholder.mobile"
      : "evaluations.dashboard.searchPlaceholder.default",
  );

  const isFetchingTableData =
    !isFetchedOwnEvaluationData ||
    !isCurrentCycleFetched ||
    !isEvaluationCyclesFetched;

  const canReset =
    !isFetchingTableData &&
    (selectedCycle?.id !== currentCycle?.id ||
      (draftsQueryParams?.projects && draftsQueryParams.projects.length > 0));

  return (
    <NavigationTable
      bodyClassName="max-md:[&>tr[data-row-type=single]>td]:pr-[4.125rem]"
      className="flex-auto max-md:m-6"
      columns={columns}
      data={data}
      defaultFilterFormValues={defaultFilterFormValues}
      filteredResultsCount={evaluations?.count}
      formSchema={formSchema}
      isFetching={isFetchingTableData}
      isResetDisabled={!canReset}
      placeholder={!isFetchingTableData && <EmptyDashboard />}
      renderExpandedSubcomponent={renderExpandedSubcomponent}
      renderFilterForm={renderFilterForm}
      searchPlaceholder={searchPlaceholder}
    />
  );
}
