import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import { Link, Outlet, useParams, useSearch } from "@tanstack/react-router";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { useGetEvaluationCycleMember } from "@/api/evaluationCycles/useGetEvaluationCycleMember";
import type { AdminEvaluationsListResponse } from "@/api/evaluations";
import { evaluationsKeys, useListEvaluations } from "@/api/evaluations";
import { Dialog } from "@/components/ui/Dialog";
import { IconButton } from "@/components/ui/IconButton";
import { Spinner } from "@/components/ui/Spinner";
import type { TabListProps } from "@/components/ui/Tab";
import { Tab } from "@/components/ui/Tab";
import { cn } from "@/utils/ui";

import { tableStateToQueryParamsTransformer } from "../AdminDashboardPage/utils";
import { MemberDetailsHeader } from "./MemberDetailHeaders";

export type MemberDetailPageProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>;

export function MemberDetailPage({
  className,
  ...props
}: MemberDetailPageProps) {
  const { t } = useTranslation();

  const from = "/admin/cycles/$cycleId/members/$memberId";
  const { cycleId, memberId } = useParams({ from });
  const search = useSearch({ from });
  const evaluationsQueryParams =
    tableStateToQueryParamsTransformer.parse(search);

  const queryClient = useQueryClient();
  const cachedEvaluations =
    queryClient.getQueryData<AdminEvaluationsListResponse>(
      evaluationsKeys.list({
        ...evaluationsQueryParams.dashboardFilters,
        limit: 0,
      }),
    );

  // If this is viewed as a standalone page, then the list of evaluations
  // must be fetched from within this component. Otherwise, it must be fetched
  // outside of the containing Dialog (i.e. before this component even renders)
  // to ensure that there is no table loading state when opening the modal.
  const { evaluations: fetchedEvaluations } = useListEvaluations(
    {
      ...evaluationsQueryParams.dashboardFilters,
      limit: 0,
    },
    { enabled: !search.isModalShown },
  );

  const finalEvaluationsPage = cachedEvaluations ?? fetchedEvaluations;
  const evaluationsList = finalEvaluationsPage?.results ?? [];

  const { cycleMember, isFetched } = useGetEvaluationCycleMember(
    Number(cycleId),
    Number(memberId),
  );

  const currentIndex =
    evaluationsList.findIndex(
      (elem) =>
        elem.cycle.id === Number(cycleId) &&
        elem.evaluatee.id === Number(memberId),
    ) || 0;

  const nextEntry =
    evaluationsList.length === 0 || currentIndex === evaluationsList.length - 1
      ? null
      : evaluationsList[currentIndex + 1];

  const prevEntry =
    evaluationsList.length === 0 || currentIndex === 0
      ? null
      : evaluationsList[currentIndex - 1];

  const url = "/admin/cycles/$cycleId/members/$memberId/evaluators";

  const tabList: TabListProps[] = [
    {
      label: t("admin.memberDetails.tabs.evaluators"),
      params: { cycleId, memberId },
      routeTo: "/admin/cycles/$cycleId/members/$memberId/evaluators",
      value: "evaluators",
    },
    {
      label: t("admin.memberDetails.tabs.evaluatees"),
      params: { cycleId, memberId },
      routeTo: "/admin/cycles/$cycleId/members/$memberId/evaluatees",
      value: "evaluatees",
    },
    {
      label: t("admin.memberDetails.tabs.selfEvaluations"),
      params: { cycleId, memberId },
      routeTo: "/admin/cycles/$cycleId/members/$memberId/self_evaluations",
      value: "self_evaluations",
    },
    {
      label: t("admin.memberDetails.tabs.rankingNotes"),
      params: { cycleId, memberId },
      routeTo: "/admin/cycles/$cycleId/members/$memberId/ranking_notes",
      value: "ranking_notes",
    },
  ];

  if (!isFetched) {
    return <Spinner className="!h-full" />;
  }

  return (
    <div
      className={cn(
        "h-full flex flex-col items-stretch gap-6",
        "pl-6 pr-3 pt-6 pb-3",
        className,
      )}
      {...props}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="flex flex-col gap-2">
          <h2 className="text-h5 text-text-primary">
            {cycleMember?.fullName ?? ""}
          </h2>
        </div>
        <div className="flex flex-row gap-2 pr-6 md:pr-[2.875rem]">
          <Link
            disabled={!prevEntry}
            mask={{
              to: url,
              params: {
                cycleId: `${prevEntry?.cycle.id}`,
                memberId: `${prevEntry?.evaluatee.id}`,
              },
              search: (prev) => ({
                per: 0,
                ordering: [],
                dashboardFilters:
                  "dashboardFilters" in prev ? prev.dashboardFilters : {},
              }),
            }}
            params={{
              cycleId: `${prevEntry?.cycle.id}`,
              memberId: `${prevEntry?.evaluatee.id}`,
            }}
            preload={false}
            search={(prev) => ({
              per: 0,
              ordering: [],
              isModalShown:
                "isModalShown" in prev ? prev.isModalShown : undefined,
              dashboardFilters:
                "dashboardFilters" in prev ? prev.dashboardFilters : {},
            })}
            to={url}
          >
            <IconButton
              className="size-12 rounded p-3 enabled:bg-primary-background enabled:text-primary-main disabled:bg-actions-disabled-background"
              disabled={!prevEntry}
              icon={<ChevronLeftIcon className="size-6" />}
              size="medium"
            />
          </Link>
          <Link
            disabled={!nextEntry}
            mask={{
              to: url,
              params: {
                cycleId: `${nextEntry?.cycle.id}`,
                memberId: `${nextEntry?.evaluatee.id}`,
              },
              search: (prev) => ({
                per: 0,
                ordering: [],
                dashboardFilters:
                  "dashboardFilters" in prev ? prev.dashboardFilters : {},
              }),
            }}
            params={{
              cycleId: `${nextEntry?.cycle.id}`,
              memberId: `${nextEntry?.evaluatee.id}`,
            }}
            preload={false}
            search={(prev) => ({
              per: 0,
              ordering: [],
              isModalShown:
                "isModalShown" in prev ? prev.isModalShown : undefined,
              dashboardFilters:
                "dashboardFilters" in prev ? prev.dashboardFilters : {},
            })}
            to={url}
          >
            <IconButton
              className="size-12 rounded p-3 enabled:bg-primary-background enabled:text-primary-main disabled:bg-actions-disabled-background"
              disabled={!nextEntry}
              icon={<ChevronRightIcon className="size-6" />}
              size="medium"
            />
          </Link>
          {search.isModalShown ? (
            <Dialog.Close asChild>
              <IconButton
                className="ml-1 size-12 p-3"
                icon={<XMarkIcon className="size-6" />}
                size="medium"
              />
            </Dialog.Close>
          ) : null}
        </div>
      </div>

      <OverlayScrollbarsComponent
        className={cn(
          "p-0.5 max-h-full flex-1 flex flex-col items-stretch md:pr-[2.875rem] pr-6",
        )}
        defer
        element="div"
        options={{
          scrollbars: { visibility: "visible", autoHide: "move" },
        }}
      >
        <div className="flex h-full flex-1 flex-col items-stretch p-0.5">
          <div className="flex h-fit min-h-full flex-col gap-6">
            <MemberDetailsHeader cycleMember={cycleMember} />
            <OverlayScrollbarsComponent
              className="flex shrink-0"
              defer
              element="div"
              options={{
                scrollbars: { visibility: "hidden" },
              }}
            >
              <Tab
                className="flex-auto"
                maskedParams={["isModalShown"]}
                paramsRetainedOnSwitch={["isModalShown", "dashboardFilters"]}
                tabList={tabList}
                tabsListClassName="whitespace-nowrap"
              />
            </OverlayScrollbarsComponent>
            <div className="flex flex-1 items-stretch pb-4">
              <Outlet />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
