import { useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";

import { api, handleApiError, validateSchema } from "../utils";
import { EvaluationCycleSchema, evaluationCyclesKeys } from "./constants";

export const getEvaluationCycleById = async (id: number) => {
  const response = await api.get(`evaluation-cycles/${id}/`);

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleSchema, data);
};

export const useGetEvaluationCycleById = (id: number) => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: () => getEvaluationCycleById(id),
    queryKey: evaluationCyclesKeys.detail(id),
  });

  return { evaluationCycle: data || null, isFetched, isFetching };
};
