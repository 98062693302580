import { useMemo } from "react";
import type { DateRange } from "react-day-picker";

import type { RatingChoice } from "@/api/constants";
import { EVALUATION_RATINGS } from "@/api/constants";
import type { EvaluationCycle } from "@/api/evaluationCycles";
import { DateRangeTag, RatingTag } from "@/components/app/FilterTag";
import { CycleTag } from "@/components/app/FilterTag/CycleTag";
import { formatDateRange } from "@/components/ui/DatePicker/utils";
import type { FilterSummaryProps as FilterSummaryPropsBase } from "@/components/ui/NavigationTable/TableHeaderControls";
import { cn } from "@/utils/ui";

import type { FormData } from "./FilterForm/utils";

export interface FilterSummaryProps<TData>
  extends FilterSummaryPropsBase<TData, FormData> {
  className?: string;
  evaluationCycles?: EvaluationCycle[];
}

export function FilterSummary<TData>({
  className,
  filters,
  form,
  table,
  evaluationCycles = [],
  ...props
}: FilterSummaryProps<TData>) {
  const handleRemoveDateRangeClick = (column: keyof typeof filters) => {
    table.getColumn(column)?.setFilterValue({});
    form.setValue(column, {});
  };

  const handleRemoveRatingClick = (
    column: keyof typeof filters,
    rating: RatingChoice,
  ) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedRatings = curr.filter((v) => v !== EVALUATION_RATINGS[rating]);
    table.getColumn(column)?.setFilterValue(updatedRatings);
    form.setValue(column, updatedRatings as number[]);
  };

  const handleRemoveIdClick = (column: keyof typeof filters, id: number) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedIds = curr.filter((v) => v !== id);
    table.getColumn(column)?.setFilterValue(updatedIds);
    form.setValue(column, updatedIds as number[]);
  };

  const cyclesList = useMemo(
    () =>
      evaluationCycles.filter(
        (cycle) => filters.cycle?.includes(cycle.id) ?? [],
      ),
    [evaluationCycles, filters],
  );

  return (
    <div
      className={cn(
        "flex flex-wrap gap-2 border-t border-other-border px-4 py-3.5",
        className,
      )}
      {...props}
    >
      {Object.keys(filters).map(
        (label) =>
          ({
            cycle: filters.cycle
              ? filters.cycle.map((cycleId) => {
                  const cycle = cyclesList.find(({ id }) => id === cycleId);
                  return cycle ? (
                    <CycleTag
                      cycle={cycle}
                      key={cycle.id}
                      onRemoveClick={() => {
                        handleRemoveIdClick("cycle", cycleId);
                      }}
                    />
                  ) : null;
                })
              : null,

            rating: (filters.rating ?? []).map((filterValue) => {
              const rating = Object.entries(EVALUATION_RATINGS).find(
                ([_, v]) => v === filterValue,
              )?.[0] as RatingChoice | undefined;

              return rating ? (
                <RatingTag
                  key={`${label}-${rating}`}
                  onRemoveClick={() => {
                    handleRemoveRatingClick("rating", rating);
                  }}
                  rating={rating}
                />
              ) : null;
            }),
            evaluatedAt: filters.evaluatedAt ? (
              <DateRangeTag
                dateRange={filters.evaluatedAt as DateRange}
                key={`${label}-${formatDateRange(filters.evaluatedAt as DateRange, "PP")}`}
                onRemoveClick={() => {
                  handleRemoveDateRangeClick("evaluatedAt");
                }}
              />
            ) : null,
          })[label],
      )}
    </div>
  );
}
