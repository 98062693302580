import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export const formSchema = z.object({
  cycle: z.number().array().optional(),
  cycleStatus: z.string().optional(),
  ordering: z.string().array().optional(),
  project: z.number().array().optional(),
});

export type FormData = z.infer<typeof formSchema>;

export const defaultValues: FormData = {
  cycle: [],
  ordering: [],
  project: [],
};

export const resolver = zodResolver(formSchema);
