import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type PaginationState,
  type SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { type ReactNode, useMemo, useState } from "react";

import { DataTable, type DataTableProps } from "@/components/ui/DataTable";
import { EmptyDashboard } from "@/components/ui/NavigationTable/EmptyDashboard";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { cn } from "@/utils/ui";

import { type TableColumns as MembersTableColumns } from "./columns";

type RequiredSubField = Required<DataTableProps<MembersTableColumns, unknown>>;

export interface MembersTableProps {
  className?: string;
  columns: RequiredSubField["columns"];
  data: RequiredSubField["data"];
  isFetching?: boolean;
  renderTableFooter: (props: {
    className?: string;
    table: RequiredSubField["table"];
  }) => ReactNode;
  renderTableHeader: (props: {
    className?: string;
    table: RequiredSubField["table"];
  }) => ReactNode;
}

export function MembersTable({
  className,
  columns,
  data,
  isFetching,
  renderTableFooter,
  renderTableHeader,
}: MembersTableProps) {
  const { maxMd } = useScreenSize();

  const [globalFilter, setGlobalFilter] = useState<string>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [mobilePagination, setMobilePagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 0,
  });
  const desktopPagination: PaginationState = { pageIndex: 0, pageSize: 0 };

  const pagination = maxMd ? mobilePagination : desktopPagination;
  const setPagination = maxMd ? setMobilePagination : () => null;
  const paginationState = useMemo(
    () => ({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize === 0 ? data.length : pagination.pageSize,
    }),
    [data, pagination.pageIndex, pagination.pageSize],
  );
  const columnsOrder = useMemo(
    () => (maxMd ? ["evaluatee", "rating", "project"] : []),
    [maxMd],
  );

  const orderedColumns = columns.slice().sort((a, b) => {
    const aIndex = columnsOrder.indexOf(a.id ?? "");
    const bIndex = columnsOrder.indexOf(b.id ?? "");
    const delta = aIndex - bIndex;
    return delta === 0 ? 0 : delta / Math.abs(delta);
  });

  const table = useReactTable({
    data,
    columns: orderedColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      globalFilter,
      pagination: paginationState,
      sorting,
    },
  });

  return (
    <DataTable
      className={cn("flex-auto", className)}
      data={data}
      isFetching={isFetching}
      placeholder={!isFetching && <EmptyDashboard />}
      renderTableFooter={renderTableFooter}
      renderTableHeader={renderTableHeader}
      table={table}
    />
  );
}
