import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { api, handleApiError, validateSchema } from "../utils";
import {
  type EvaluationCycleMemberEvaluateeQueryParams,
  EvaluationCycleMemberEvaluateeQueryParamsSchemaTransformer,
  EvaluationCycleMemberRatingListResponseSchema,
  evaluationCyclesKeys,
} from "./constants";

export const getEvaluationCycleMemberEvaluatees = async (
  cycleId: number,
  memberId: number,
  params: EvaluationCycleMemberEvaluateeQueryParams = {},
) => {
  const response = await api.get(
    `evaluation-cycles/${cycleId}/members/${memberId}/evaluatees/`,
    {
      searchParams: qs.stringify(
        decamelizeKeys(
          EvaluationCycleMemberEvaluateeQueryParamsSchemaTransformer.parse(
            params,
          ),
        ),
      ),
    },
  );

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleMemberRatingListResponseSchema, data);
};

export const useGetEvaluationCycleMemberEvaluatees = (
  cycleId: number,
  memberId: number,
  params?: EvaluationCycleMemberEvaluateeQueryParams,
) => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: () =>
      getEvaluationCycleMemberEvaluatees(cycleId, memberId, params),
    queryKey: evaluationCyclesKeys.memberEvaluateeRatingList(
      cycleId,
      memberId,
      params,
    ),
    placeholderData: keepPreviousData,
  });

  return { evaluatees: data || null, isFetched, isFetching };
};
