import { FunnelIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import type {
  AnyRoute,
  RegisteredRouter,
  RootSearchSchema,
  RouteById,
  RouteIds,
} from "@tanstack/react-router";
import { deepEqual, useSearch } from "@tanstack/react-router";
import { type Table } from "@tanstack/react-table";
import {
  type HTMLAttributes,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  type DefaultValues,
  useForm,
  type UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { KeysOfUnion } from "type-fest";
import { type z } from "zod";

import { SearchBar } from "@/components/app/SearchBar";
import { Button } from "@/components/ui/Button";
import { Dialog } from "@/components/ui/Dialog";
import { Popover } from "@/components/ui/Popover";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import {
  filteringToColumnFilterState,
  SEARCH_DEBOUNCE_TIME,
  type TableStateBaseParams,
} from "./utils";

export interface FilterFormProps<
  TFormData extends z.ZodRawShape | Record<string, unknown>,
> {
  resetDisabled: boolean;
  form: UseFormReturn<TFormData>;
  onReset: () => void;
  onSubmit: (formData: TFormData) => void;
}

export interface FilterSummaryProps<
  TData,
  TFormData extends z.ZodRawShape | Record<string, unknown>,
> {
  filters: TFormData;
  form: UseFormReturn<TFormData>;
  table: Table<TData>;
}

export interface TableHeaderContolsProps<
  TData,
  TFormSchema extends z.AnyZodObject = z.AnyZodObject,
  TRouteTree extends AnyRoute = RegisteredRouter["routeTree"],
  TFrom extends RouteIds<TRouteTree> = RouteIds<TRouteTree>,
  TSearch = Exclude<
    RouteById<TRouteTree, TFrom>["types"]["fullSearchSchema"],
    RootSearchSchema
  >,
> extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  defaultFilters?: z.infer<TFormSchema>;
  formSchema: TFormSchema;
  isResetDisabled?: boolean;
  customFrom?: TFrom;
  filterSource?: KeysOfUnion<TSearch>;
  onFilterOpenChange?: (flag: boolean) => void;
  onResetFiltersClick?: () => void;
  onSubmitFiltersClick?: (formData: z.infer<TFormSchema>) => void;
  renderFilterForm?: (
    props: FilterFormProps<z.infer<TFormSchema>>,
  ) => ReactNode;
  renderFilterSummary?: (
    props: FilterSummaryProps<TData, z.infer<TFormSchema>>,
  ) => ReactNode;
  searchDebounceTime?: number;
  searchPlaceholder?: string;
  table: Table<TData>;
}

export function TableHeaderControls<
  TData,
  TFormSchema extends z.AnyZodObject = z.AnyZodObject,
  TRouteTree extends AnyRoute = RegisteredRouter["routeTree"],
  TFrom extends RouteIds<TRouteTree> = RouteIds<TRouteTree>,
  TSearch = Exclude<
    RouteById<TRouteTree, TFrom>["types"]["fullSearchSchema"],
    RootSearchSchema
  >,
>({
  className,
  defaultFilters,
  formSchema,
  isResetDisabled,
  customFrom,
  filterSource,
  onResetFiltersClick,
  onSubmitFiltersClick,
  renderFilterForm,
  renderFilterSummary,
  searchDebounceTime = SEARCH_DEBOUNCE_TIME,
  searchPlaceholder,
  table,
  ...props
}: TableHeaderContolsProps<TData, TFormSchema, TRouteTree, TFrom, TSearch>) {
  const { t } = useTranslation();
  const { maxMd } = useScreenSize();

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const stateParams = useSearch({
    from: customFrom,
    strict: false,
    select: filterSource
      ? (search) =>
          search[
            filterSource as keyof typeof search
          ] as TableStateBaseParams & { filters?: z.infer<typeof formSchema> }
      : undefined,
  });

  const filters = useMemo<z.infer<TFormSchema>>(
    () => stateParams.filters ?? defaultFilters ?? {},
    [defaultFilters, stateParams],
  );

  const areFiltersUnmodified = useMemo(
    () =>
      Object.entries(defaultFilters ?? {}).every(([k, v]) =>
        deepEqual(filters[k], v),
      ),
    [defaultFilters, filters],
  );

  const internalIsResetDisabled = useMemo(
    () => areFiltersUnmodified && !stateParams.search,
    [areFiltersUnmodified, stateParams.search],
  );

  const finalIsResetDisabled = isResetDisabled ?? internalIsResetDisabled;

  const form = useForm({
    defaultValues: filters as DefaultValues<z.infer<TFormSchema>>,
    resolver: zodResolver(formSchema),
  });

  const handleFilterClick = () => {
    setIsOpenFilter((flag) => !flag);
  };

  const handleFilterClose = useCallback(() => {
    setIsOpenFilter(false);
  }, []);

  const handleOpenAutoFocus = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const handleSearchChange = useCallback(
    (text: string) => {
      table.setGlobalFilter(text);
    },
    [table],
  );

  const handleSubmit = useCallback(
    (formData: z.infer<TFormSchema>) => {
      handleFilterClose();

      table.setColumnFilters(filteringToColumnFilterState(formData));

      onSubmitFiltersClick?.(formData);
    },
    [handleFilterClose, onSubmitFiltersClick, table],
  );

  const [searchInputValue, setSearchInputValue] = useState(
    stateParams.search ?? "",
  );

  const handleReset = useCallback(() => {
    table.setColumnFilters(filteringToColumnFilterState(defaultFilters ?? {}));
    table.setGlobalFilter("");
    setSearchInputValue("");
    form.reset(defaultFilters);

    onResetFiltersClick?.();
  }, [defaultFilters, form, onResetFiltersClick, table]);

  const FilterForm = renderFilterForm;
  const FilterSummary = renderFilterSummary;

  return (
    <div className={cn("flex flex-col items-stretch", className)} {...props}>
      <div className="flex items-center justify-end gap-6 border-other-border px-4 py-2 md:px-5 md:py-4">
        <SearchBar
          className="!min-w-0 flex-auto"
          defaultValue={stateParams.search}
          inputClassName="w-full !min-w-0 max-md:ring-transparent"
          name="search"
          onChange={setSearchInputValue}
          onSearchChange={handleSearchChange}
          placeholder={
            searchPlaceholder ??
            t("components.navigationTable.searchPlaceholder.default")
          }
          searchDebounceTime={searchDebounceTime}
          value={searchInputValue}
        />

        {FilterForm !== undefined && (
          <div className="flex items-center gap-3">
            <Button
              className="rounded-full max-md:hidden"
              disabled={finalIsResetDisabled}
              onClick={handleReset}
              size="large"
              variant="text"
            >
              {t("components.navigationTable.buttonResetFilters")}
            </Button>
            <Dialog
              onOpenChange={setIsOpenFilter}
              open={Boolean(maxMd && isOpenFilter)}
            >
              <Dialog.Content className="w-[21.25rem] max-w-full p-3 pb-6">
                <FilterForm
                  form={form}
                  onReset={handleReset}
                  onSubmit={handleSubmit}
                  resetDisabled={finalIsResetDisabled}
                />
              </Dialog.Content>
            </Dialog>
            <Popover
              align="end"
              anchor={
                <Button
                  className="px-[1.375rem] py-[0.625rem] max-md:h-[1.625rem] max-md:px-3 max-md:py-1"
                  onClick={handleFilterClick}
                  size="large"
                  startIcon={
                    <FunnelIcon className="size-[1.125rem] md:size-6" />
                  }
                  variant="outlined"
                >
                  <span className="max-md:hidden">
                    {t("components.navigationTable.buttonFilter")}
                  </span>
                </Button>
              }
              className="w-[26.125rem] p-3 pb-6"
              content={
                <FilterForm
                  form={form}
                  onReset={handleReset}
                  onSubmit={handleSubmit}
                  resetDisabled={finalIsResetDisabled}
                />
              }
              onEscapeKeyDown={handleFilterClose}
              onOpenAutoFocus={handleOpenAutoFocus}
              onPointerDownOutside={handleFilterClose}
              open={!maxMd && isOpenFilter}
            />
          </div>
        )}
      </div>

      {FilterSummary !== undefined &&
      stateParams.filters &&
      !areFiltersUnmodified &&
      !finalIsResetDisabled ? (
        <FilterSummary
          filters={stateParams.filters}
          form={form}
          table={table}
        />
      ) : null}
    </div>
  );
}
