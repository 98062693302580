import type { ColumnDef, Row } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { Translation } from "react-i18next";

import type { EvaluationDraft } from "@/api/evaluations";
import { AvatarStack } from "@/components/ui/AvatarStack";
import { Tooltip } from "@/components/ui/Tooltip";

import { MAX_MEMBERS_SHOWN, MAX_PROJECTS_SHOWN } from "./constants";
import { TableRowAction } from "./TableRowAction";

const columnHelper = createColumnHelper<EvaluationDraft>();

type FilterFn<Data> = (
  row: Row<EvaluationDraft>,
  _: unknown,
  filterValue?: Data,
) => boolean;

const filterFn: FilterFn<string> = (_row, _, _filterValue) => true;

export const columns = [
  columnHelper.accessor((row) => row.cycle, {
    id: "cycle",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.cycle")}</Translation>
    ),
    cell: (info) => (
      <div className="flex items-center gap-x-1 max-md:mb-[0.125rem] max-md:flex-wrap">
        <span className="whitespace-nowrap text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.cycle")}:`}
          </Translation>
        </span>
        <p className="text-body2 max-md:text-caption">
          {info.row.original.cycleName}
        </p>
      </div>
    ),
  }),

  columnHelper.accessor(
    (row) =>
      row.draftData?.ratings.map((rating) => rating.projectName).join(", "),
    {
      id: "projects",
      enableGlobalFilter: false,
      enableMultiSort: true,
      filterFn,
      header: () => (
        <Translation>
          {(t) => t("evaluations.dashboard.projectsAdded")}
        </Translation>
      ),
      cell: (info) => {
        const uniqueProjectNames = Array.from(
          new Set(
            info.row.original.draftData?.ratings
              .map(({ projectName }) => projectName)
              .filter((name) => Boolean(name)) ?? [],
          ).values(),
        ) as string[];

        const overflowedProjectNames =
          uniqueProjectNames.slice(MAX_PROJECTS_SHOWN);

        return (
          <div className="flex items-center gap-x-1 max-md:mb-[0.125rem] max-md:flex-wrap">
            <span className="whitespace-nowrap text-caption text-text-secondary md:hidden">
              <Translation>
                {(t) => `${t("evaluations.dashboard.projectsAdded")}:`}
              </Translation>
            </span>
            <div className="flex items-center gap-x-1 max-md:flex-wrap">
              {uniqueProjectNames.length === 0 && (
                <span className="text-body2 max-md:text-caption">-</span>
              )}
              <div className="flex flex-wrap items-center">
                {uniqueProjectNames
                  .slice(0, MAX_PROJECTS_SHOWN)
                  .map((project, index) => (
                    <span
                      className="whitespace-nowrap text-body2 text-text-primary max-md:text-caption"
                      key={project}
                    >
                      {project}
                      {(uniqueProjectNames.length > MAX_PROJECTS_SHOWN ||
                        (uniqueProjectNames.length > 1 && index === 0)) && (
                        <span className="whitespace-pre-wrap">, </span>
                      )}
                    </span>
                  ))}
              </div>
              {overflowedProjectNames.length > 0 && (
                <Tooltip
                  content={
                    <div className="flex flex-col">
                      {overflowedProjectNames.map((project) => (
                        <span
                          className="text-body2 max-md:text-caption"
                          key={project}
                        >
                          {project}
                        </span>
                      ))}
                    </div>
                  }
                >
                  <div className="grid min-h-8 min-w-8 place-items-center rounded-full bg-secondary-light text-caption text-primary-main hover:bg-secondary-light">
                    +{overflowedProjectNames.length}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
  ),

  columnHelper.accessor(
    (row) =>
      row.draftData?.ratings
        .filter((rating) => Boolean(rating.evaluatee))
        .map((rating) => rating.evaluatee?.fullName)
        .join(", "),
    {
      id: "membersEvaluated",
      enableGlobalFilter: false,
      enableMultiSort: true,
      filterFn,
      header: () => (
        <Translation>
          {(t) => t("evaluations.dashboard.membersEvaluated")}
        </Translation>
      ),
      cell: ({ row }) => {
        const uniqueMembersEvaluated = Array.from(
          new Map(
            row.original.draftData?.ratings.map((rating) => [
              rating.evaluateeId,
              rating.evaluatee,
            ]),
          ).values(),
        );
        return (
          <div className="flex items-center gap-x-1 max-md:mb-[0.125rem] max-md:flex-wrap">
            <span className="whitespace-nowrap text-caption text-text-secondary md:hidden">
              <Translation>
                {(t) => `${t("evaluations.dashboard.membersEvaluated")}:`}
              </Translation>
            </span>
            <AvatarStack
              avatars={uniqueMembersEvaluated
                .filter((member) => Boolean(member))
                .map((member) => ({
                  displayName: member?.fullName,
                  src: member?.avatar,
                }))}
              maxAvatarsShown={MAX_MEMBERS_SHOWN}
            />
          </div>
        );
      },
    },
  ),

  columnHelper.accessor((row) => row.updatedAt, {
    id: "updatedAt",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.lastSaved")}</Translation>
    ),
    cell: (info) => (
      <div className="flex items-center gap-x-1 max-md:flex-wrap">
        <span className="whitespace-nowrap text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.lastSaved")}:`}
          </Translation>
        </span>
        <p className="text-body2 max-md:text-caption">
          {format(parseISO(info.row.original.updatedAt), "MMMM d, yyyy ; p")}
        </p>
      </div>
    ),
  }),

  columnHelper.display({
    id: "actions",
    header: () => (
      <Translation>
        {(t) => (
          <span className="w-full text-right">
            {t("evaluations.dashboard.actions")}
          </span>
        )}
      </Translation>
    ),
    size: 0,
    cell: ({ row }) => <TableRowAction row={row} />,
  }),
] as ColumnDef<EvaluationDraft>[];
