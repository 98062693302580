import { CheckIcon, MinusIcon } from "@heroicons/react/24/outline";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cva, type VariantProps } from "class-variance-authority";
import React, { useState } from "react";

import { cn } from "@/utils/ui";

const checkboxVariants = cva(
  "flex size-[1.125rem] rounded-sm border-2 disabled:border-actions-disabled-background disabled:data-[state=checked]:bg-actions-disabled-background disabled:data-[state=checked]:text-white",
  {
    variants: {
      color: {
        primary:
          "border-text-hint focus:border-primary-main focus:outline-none enabled:hover:border-primary-main data-[state=checked]:border-none enabled:data-[state=indeterminate]:text-text-hint",
        secondary:
          "border-text-hint focus:border-secondary-main focus:outline-none enabled:hover:border-secondary-main data-[state=checked]:border-none enabled:data-[state=indeterminate]:text-text-hint",
      },
      variant: {
        default: "",
        inverted: "",
      },
    },
    compoundVariants: [
      {
        color: "primary",
        variant: "default",
        className:
          "text-primary-contrast-text data-[state=checked]:focus:ring-primary-contrast-text enabled:data-[state=checked]:bg-primary-main",
      },
      {
        color: "primary",
        variant: "inverted",
        className:
          "data-[state=checked]:focus:ring-primary-main enabled:data-[state=checked]:bg-primary-contrast-text enabled:data-[state=checked]:text-primary-main",
      },
      {
        color: "secondary",
        variant: "default",
        className:
          "text-primary-contrast-text data-[state=checked]:focus:ring-secondary-contrast-text enabled:data-[state=checked]:bg-secondary-main",
      },
      {
        color: "secondary",
        variant: "inverted",
        className:
          "data-[state=checked]:focus:ring-secondary-main enabled:data-[state=checked]:bg-secondary-contrast-text enabled:data-[state=checked]:text-secondary-main",
      },
    ],
    defaultVariants: {
      color: "primary",
      variant: "default",
    },
  },
);

const BaseCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
  const [internalChecked, setInternalChecked] =
    useState<CheckboxPrimitive.CheckedState>(props.checked ?? false);
  const finalChecked = props.checked ?? internalChecked;

  return (
    <CheckboxPrimitive.Root
      className={className}
      onCheckedChange={setInternalChecked}
      ref={ref}
      {...props}
    >
      <CheckboxPrimitive.Indicator className="flex-1">
        {finalChecked === "indeterminate" && (
          <MinusIcon className="flex-1 stroke-[0.1875rem]" />
        )}
        {finalChecked === true && (
          <CheckIcon className="flex-1 stroke-[0.1875rem]" />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
BaseCheckbox.displayName = CheckboxPrimitive.Root.displayName;

export interface ButtonProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
      "color"
    >,
    VariantProps<typeof checkboxVariants> {}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  ButtonProps
>(({ className, color, variant, ...props }, ref) => (
  <BaseCheckbox
    className={cn(checkboxVariants({ color, variant, className }))}
    ref={ref}
    {...props}
  />
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
