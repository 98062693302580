import { useParams, useSearch } from "@tanstack/react-router";
import { type InitialTableState } from "@tanstack/react-table";
import { type HTMLAttributes } from "react";
import { z } from "zod";

import { useListEvaluationCycleMemberReceivedEvaluations } from "@/api/evaluationCycles/useListEvaluationCycleMemberReceivedEvaluations";
import { NavigationTable } from "@/components/ui/NavigationTable";
import { cn } from "@/utils/ui";

import { EmptyDashboard } from "../../EmptyDashboard";
import { columns } from "./columns";
import { tableStateToQueryParamsTransformer } from "./utils";

const formSchema = z.object({});

const initialState: InitialTableState = {
  columnVisibility: {
    isPreviousCycle: false,
  },
};

export type RankingNotesTabProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>;

export function RankingNotesTab({ className, ...props }: RankingNotesTabProps) {
  const from = "/admin/cycles/$cycleId/members/$memberId/ranking_notes";
  const { cycleId, memberId } = useParams({ from });
  const search = useSearch({ from });
  const queryParams = tableStateToQueryParamsTransformer.parse(search);

  const { evaluations, isFetched } =
    useListEvaluationCycleMemberReceivedEvaluations(
      Number(cycleId),
      Number(memberId),
      queryParams,
    );

  const data = evaluations?.results ?? [];

  return (
    <NavigationTable
      className={cn("flex-auto", className)}
      columns={columns}
      data={data}
      formSchema={formSchema}
      initialState={initialState}
      isFetching={!isFetched}
      placeholder={Boolean(isFetched) && <EmptyDashboard />}
      renderTableHeader={() => null}
      {...props}
    />
  );
}
