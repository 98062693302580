import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { api, handleApiError, validateSchema } from "../utils";
import type { EvaluationDraftQueryParams } from "./constants";
import {
  EvaluationDraftListResponseSchema,
  evaluationDraftsKeys,
} from "./constants";

const listEvaluationDrafts = async (params: EvaluationDraftQueryParams) => {
  const response = await api.get("evaluation-drafts", {
    searchParams: qs.stringify(decamelizeKeys(params), {
      arrayFormat: "comma",
    }),
  });
  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationDraftListResponseSchema, data);
};

export const useListEvaluationDrafts = (params: EvaluationDraftQueryParams) => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: () => listEvaluationDrafts(params),
    queryKey: evaluationDraftsKeys.list(params),
    placeholderData: keepPreviousData,
  });

  return { evaluations: data || null, isFetched, isFetching };
};
