import { useRouter } from "@tanstack/react-router";
import { memo, useEffect, useRef } from "react";
import type { LoadingBarRef } from "react-top-loading-bar";
import TopLoadingBar from "react-top-loading-bar";

function LoadingBarInner() {
  const loadingBarRef = useRef<LoadingBarRef>(null);
  const router = useRouter();

  useEffect(() => {
    router.subscribe("onBeforeLoad", ({ fromLocation, toLocation }) => {
      if (fromLocation.pathname === toLocation.pathname) return;

      loadingBarRef.current?.continuousStart();
    });
    router.subscribe("onLoad", ({ fromLocation, toLocation }) => {
      if (fromLocation.pathname === toLocation.pathname) return;

      loadingBarRef.current?.complete();
    });

    return () => {
      router.subscribers.clear();
    };
  }, [router]);

  return <TopLoadingBar color="#0C59AC" ref={loadingBarRef} />;
}

export const LoadingBar = memo(function LoadingBar() {
  const {
    latestLocation: { pathname },
  } = useRouter();

  return <LoadingBarInner key={pathname} />;
});
