import {
  type ColumnDef,
  createColumnHelper,
  type Row,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { Translation } from "react-i18next";

import { EVALUATION_RATINGS } from "@/api/constants";
import type { EvaluationCycleMemberRating } from "@/api/evaluationCycles";
import { Rating } from "@/components/ui/Rating";

export type FilterFn<Data, TableColumnData> = (
  row: Row<TableColumnData>,
  _: unknown,
  filterValue?: Data,
) => boolean;

export const filterFn: FilterFn<string, EvaluationCycleMemberRating> = (
  _row,
  _,
  _filterValue,
) => true;

export type TableColumnData = EvaluationCycleMemberRating;

export const columnHelper = createColumnHelper<TableColumnData>();

export const columns = [
  columnHelper.accessor((row) => row.projectName, {
    id: "project",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.project")}</Translation>
    ),
    cell: (info) => (
      <span className="text-body2 text-text-primary">{info.getValue()}</span>
    ),
  }),

  columnHelper.accessor(
    (row) => (!row.rating ? 0 : EVALUATION_RATINGS[row.rating]),
    {
      id: "rating",
      enableGlobalFilter: false,
      enableMultiSort: true,
      filterFn,
      header: () => (
        <Translation>{(t) => t("admin.filterForm.rating")}</Translation>
      ),
      cell: (info) => (
        <Rating
          buttonClassName="p-0 size-6"
          className="flex size-auto items-center p-0"
          readOnly
          size="medium"
          value={info.getValue()}
        />
      ),
    },
  ),

  columnHelper.accessor((row) => row.ratingNotes, {
    id: "ratingNotes",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.review")}</Translation>
    ),
    cell: (info) => (
      <span className="whitespace-pre-line text-body2 text-text-primary">
        {info.getValue()}
      </span>
    ),
  }),

  columnHelper.accessor((row) => row.evaluatedAt, {
    id: "evaluatedAt",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.dateSubmitted")}</Translation>
    ),
    cell: (info) => (
      <span className="text-body2 text-text-primary">
        {info.getValue()
          ? format(info.getValue() || new Date(), "MMM dd, yyyy")
          : "N/A"}
      </span>
    ),
  }),
] as ColumnDef<TableColumnData>[];
