import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/utils/ui";

const BaseAlertDialog = AlertDialogPrimitive.Root;

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogPortal = AlertDialogPrimitive.Portal;

const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-actions-disabled-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
    ref={ref}
  />
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
>(({ className, ...props }, ref) => (
  <AlertDialogPortal>
    <AlertDialogOverlay />
    <AlertDialogPrimitive.Content
      className={cn(
        "fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] bg-white shadow-elevation1 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-2xl max-w-screen max-h-screen flex flex-col",
        className,
      )}
      ref={ref}
      {...props}
    />
  </AlertDialogPortal>
));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

function AlertDialogHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "flex flex-col overflow-auto items-center gap-4 px-6 pt-6 pb-4 text-center sm:text-left",
        className,
      )}
      {...props}
    />
  );
}
AlertDialogHeader.displayName = "AlertDialogHeader";

function AlertDialogFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("flex pt-3 pb-6 px-6 justify-center", className)}
      {...props}
    />
  );
}
AlertDialogFooter.displayName = "AlertDialogFooter";

const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Title
    className={cn("text-text-primary text-h6", className)}
    ref={ref}
    {...props}
  />
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Description
    className={cn("text-body2 text-text-secondary overflow-auto", className)}
    ref={ref}
    {...props}
  />
));
AlertDialogDescription.displayName =
  AlertDialogPrimitive.Description.displayName;

const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Cancel
    className={cn("max-w-full", className)}
    ref={ref}
    {...props}
  />
));
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

const alertVariants = cva("size-10 shrink-0 rounded-full p-2", {
  variants: {
    variant: {
      error: "bg-error-light-bg text-error-main",
      info: "bg-info-light-bg text-info-main",
      success: "bg-success-light-bg text-success-main",
      warning: "bg-warning-light-bg text-warning-main",
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

interface AlertDialogProps
  extends AlertDialogPrimitive.AlertDialogProps,
    VariantProps<typeof alertVariants> {
  className?: string;
  contentProps?: AlertDialogPrimitive.AlertDialogContentProps;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
  title: React.ReactNode;
  trigger?: React.ReactNode;
  triggerProps?: AlertDialogPrimitive.AlertDialogTriggerProps;
}

function AlertDialog({
  className,
  contentProps,
  description,
  footer,
  icon,
  title,
  trigger,
  triggerProps,
  variant = "info",
  ...props
}: AlertDialogProps) {
  const renderDefaultIcon = () => {
    if (variant === "error") {
      return <XCircleIcon />;
    }

    if (variant === "success") {
      return <CheckCircleIcon />;
    }

    if (variant === "warning") {
      return <ExclamationTriangleIcon />;
    }

    return <InformationCircleIcon />;
  };

  return (
    <BaseAlertDialog {...props}>
      {Boolean(trigger) && (
        <AlertDialogTrigger {...triggerProps}>{trigger}</AlertDialogTrigger>
      )}
      <AlertDialogContent
        className={cn("max-w-[34.5rem]", className)}
        {...contentProps}
      >
        <AlertDialogHeader>
          <div className={cn(alertVariants({ variant }))}>
            {icon ?? renderDefaultIcon()}
          </div>
          <div className="flex flex-col items-center justify-center space-y-2 overflow-y-auto text-center">
            {Boolean(title) && <AlertDialogTitle>{title}</AlertDialogTitle>}
            {Boolean(description) && (
              <AlertDialogDescription>{description}</AlertDialogDescription>
            )}
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>{footer}</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </BaseAlertDialog>
  );
}

export { AlertDialog, type AlertDialogProps };
