import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { type DialogProps } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { type BasicUser } from "@/api/users";
import { Avatar } from "@/components/ui/Avatar";
import { Button } from "@/components/ui/Button";
import { Dialog } from "@/components/ui/Dialog";
import { IconButton } from "@/components/ui/IconButton";
import { TextInput } from "@/components/ui/TextInput";

interface ProjectsDialogProps extends DialogProps {
  label: string;
  projects: string[];
  user?: BasicUser;
}

function ProjectsDialog({
  label,
  projects,
  user,
  ...props
}: ProjectsDialogProps) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const finalList = filter
    ? projects.filter((value) => value.includes(filter))
    : projects;

  return (
    <Dialog
      onOpenChange={() => {
        setFilter("");
      }}
      {...props}
    >
      <Dialog.Trigger asChild>
        <Button
          className="h-6 cursor-default border-other-border p-2 text-caption text-text-primary hover:cursor-pointer hover:bg-actions-hover"
          color="custom"
          variant="outlined"
        >
          {label}
        </Button>
      </Dialog.Trigger>

      <Dialog.Content className="space-y-6 p-6">
        <Dialog.Header className="mb-0 space-y-3">
          <div className="flex w-[26.25rem]">
            <h6 className="flex-1 text-h6 text-text-primary">
              {t("admin.dashboard.projects")}
            </h6>
            <div>
              <Dialog.Close asChild>
                <IconButton icon={<XMarkIcon className="size-5" />} />
              </Dialog.Close>
            </div>
          </div>

          {user ? (
            <div className="flex gap-2">
              <Avatar src={user.avatar} />
              <div>
                <div className="text-subtitle2 text-text-primary">
                  {user.fullName}
                </div>
                <div className="text-body2 text-text-secondary">
                  {user.email}
                </div>
              </div>
            </div>
          ) : null}
        </Dialog.Header>

        <TextInput
          inputClassName="w-full"
          onChange={(event) => {
            setFilter(event.target.value);
          }}
          placeholder={t("admin.dashboard.search")}
          startIcon={
            <MagnifyingGlassIcon className="size-5 text-text-secondary" />
          }
          value={filter}
        />

        <Dialog.Body>
          {finalList.length > 0 &&
            finalList.map((project) => (
              <div className="px-0 py-2" key={project}>
                {project}
              </div>
            ))}
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  );
}

export { ProjectsDialog };
