import { decamelize } from "humps";

import type { EvaluationCycleStatus } from "@/api/evaluationCycles";
import type { EvaluationRatingQueryParams } from "@/api/evaluationRatings";
import { TABLE_PAGE_SIZE_OPTIONS } from "@/components/ui/DataTable/utils";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

import { formSchema } from "./EvaluationsFilterForm/utils";

const columnToOrderingFieldMap: Record<string, string | undefined> = {
  evaluatee: "evaluatee_full_name",
  deadline: "evaluation__cycle__active_end_date",
  evaluatedAt: "evaluation__evaluated_at",
  rating: "number_rating",
  project: "project__name",
  cycle: "cycle",
};

const getColumnToOrderingField = (column: string) => {
  const desc = column.startsWith("-");
  const col = desc ? column.slice(1) : column;
  const orderingField = decamelize(columnToOrderingFieldMap[col] ?? col);
  return desc ? `-${orderingField}` : orderingField;
};

export const tableStateToQueryParamsTransformer =
  TableStateBaseParamsSchema.extend({
    filters: formSchema.optional(),
  })
    .transform(
      ({
        filters: { cycleStatus, ...filters } = {},
        ordering,
        page,
        per,
        ...schema
      }): EvaluationRatingQueryParams => {
        const limit = per ?? TABLE_PAGE_SIZE_OPTIONS[0].value;
        return {
          limit,
          offset: !page ? 0 : limit * (page - 1),
          ...schema,
          ...filters,
          ...(cycleStatus && {
            cycleStatus: cycleStatus as EvaluationCycleStatus,
          }),
          ...((filters.ordering ?? ordering) && {
            ordering: (filters.ordering ?? ordering)?.map(
              getColumnToOrderingField,
            ),
          }),
        };
      },
    )
    .optional();
