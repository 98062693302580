import { createFileRoute, ErrorComponent } from "@tanstack/react-router";

import { LoadingComponent } from "@/components/app/LoadingComponent";
import { MemberDetailPage } from "@/components/pages/MemberDetailPage";

export const Route = createFileRoute(
  "/admin/cycles/$cycleId/members/$memberId",
)({
  component: MemberDetailPage,
  errorComponent: ErrorComponent,
  pendingComponent: LoadingComponent,
  beforeLoad: () => ({ routeId: "memberDetails" }),
});
