import { createContext, useContext } from "react";
import type { DebouncedState } from "use-debounce";

export const DraftAutosaveContext = createContext({
  isAutosaved: false,
  isAutosavePending: false,
  onAutosaveDraft: (() => {}) as () => Promise<void>,
  onAutosaveDraftDebounced: (() => {}) as DebouncedState<() => Promise<void>>,
});

export const useDraftAutosave = () => useContext(DraftAutosaveContext);
