import type { SVGProps } from "react";
import { memo } from "react";

import { cn } from "@/utils/ui";

export type SpinnerProps = SVGProps<SVGSVGElement> & {
  className?: string;
};

export const Spinner = memo(function Spinner({
  className,
  ...props
}: SpinnerProps) {
  return (
    <div
      className={cn("flex items-center justify-center h-svh w-full", className)}
    >
      <div className="text-primary-main">
        <svg
          {...props}
          className={cn("animate-spin", className)}
          fill="none"
          height={28}
          viewBox="0 0 24 24"
          width={28}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
});
