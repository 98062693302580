export const downloadBlob = (blob: Blob, filename?: string) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.style.display = "none";

  if (filename) {
    link.setAttribute("download", filename);
  }

  document.body.appendChild(link);

  link.click();
  link.remove();
};
