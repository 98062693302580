import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";

import type { Project } from "@/api/projects";
import { SearchSelect, type SelectOption } from "@/components/ui/SearchSelect";
import { Spinner } from "@/components/ui/Spinner";

import { useEvaluationPageStore } from "../../utils/hooks/useEvaluationPageStore";

export interface ProjectSearchSelectProps {
  cursor: {
    isFetching: boolean;
    hasNextPage: boolean;
    loadMore: () => void;
  };
  isFetching: boolean;
  onConfirmClick: (
    selectedProjects: Project[],
    selectedOptions: SelectOption[],
  ) => void;
  onProjectSearchChange: (search: string) => void;
  otherProjects: Project[];
  placeholder: string;
  projects: Project[];
}

export function ProjectSearchSelect({
  cursor: { isFetching, hasNextPage, loadMore },
  onConfirmClick,
  onProjectSearchChange,
  otherProjects,
  projects,
  ...props
}: ProjectSearchSelectProps) {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const [scrollSentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  const selectedProjectList = useEvaluationPageStore(
    (state) => state.selectedProjects,
  );

  const currentFetchedProjects = useMemo(
    () => [...projects, ...otherProjects],
    [projects, otherProjects],
  );

  const [cachedProjects, setCachedProjects] = useState(currentFetchedProjects);

  if (
    currentFetchedProjects.some(
      (currProject) =>
        !cachedProjects.find(
          (cachedProject) => cachedProject.id === currProject.id,
        ),
    )
  ) {
    setCachedProjects((prev) => {
      const newList = [...prev];
      for (const project of currentFetchedProjects) {
        if (!prev.find((cachedProject) => cachedProject.id === project.id)) {
          newList.push(project);
        }
      }
      return newList;
    });
  }

  const projectsMap = useMemo(
    () =>
      Object.fromEntries(
        cachedProjects.map((project) => [`${project.id}`, project]),
      ),
    [cachedProjects],
  );

  const options = useMemo(
    () => [
      {
        header: t("evaluations.create.myProjects"),
        options: projects.map((project) => ({
          label: project.name,
          value: project.id,
        })),
      },
      {
        header: t("evaluations.create.otherProjects"),
        options: otherProjects.map((project) => ({
          label: project.name,
          value: project.id,
        })),
      },
    ],
    [otherProjects, projects, t],
  );

  const selectedValues = useMemo(
    () => selectedProjectList.map(({ id }) => id),
    [selectedProjectList],
  );

  const remainingOptions = useMemo(
    () =>
      options.map((optionGroup) => ({
        ...optionGroup,
        options: optionGroup.options.filter(
          ({ value }) => !selectedValues.includes(value),
        ),
      })),
    [options, selectedValues],
  );

  const handleConfirmClick = useCallback(
    (selectedOptions: SelectOption[]) => {
      const selectedProjects = selectedOptions.map(
        ({ value }) => projectsMap[`${value}`],
      );
      onConfirmClick(selectedProjects, selectedOptions);
      setFilterValue("");
    },
    [onConfirmClick, projectsMap],
  );

  const handleProjectSearchChange = useCallback(
    (text: string) => {
      onProjectSearchChange(text);
      setFilterValue(text);
    },
    [onProjectSearchChange],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <SearchSelect
      disableFiltering
      filterValue={filterValue}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirmClick={handleConfirmClick}
      onOpen={handleOpen}
      onSearchChange={handleProjectSearchChange}
      options={remainingOptions}
      renderDropdownFooter={({ options: o, original }) =>
        o.length === 0 ? null : original
      }
      scrollSentry={
        <div
          className="flex min-h-[1px] w-full justify-center"
          ref={scrollSentryRef}
        >
          {Boolean(isFetching) && (
            <div className="p-2">
              <Spinner className="size-4" />
            </div>
          )}
        </div>
      }
      {...props}
    />
  );
}
