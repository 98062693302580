import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { type QueryOptions } from "../constants";
import { api, handleApiError, validateSchema } from "../utils";
import {
  UserListResponseSchema,
  type UserQueryParams,
  UserQueryParamsSchemaTransformer,
  usersKeys,
} from "./constants";

export const listUsers = async (params: UserQueryParams) => {
  const response = await api.get("users/", {
    searchParams: qs.stringify(
      decamelizeKeys(UserQueryParamsSchemaTransformer.parse(params)),
    ),
  });

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(UserListResponseSchema, data);
};

export const useListUsers = (
  params: UserQueryParams = {},
  options: QueryOptions = { enabled: true },
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled: options.enabled,
    queryFn: () => listUsers(params),
    queryKey: usersKeys.list(params),
    placeholderData: keepPreviousData,
  });

  return { users: data || null, isFetched, isFetching };
};
