/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  type DetailedHTMLProps,
  type FormHTMLAttributes,
  useMemo,
  useState,
} from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";

import type { EvaluationCycle } from "@/api/evaluationCycles";
import { useGetProjectsByIds, useListProjects } from "@/api/projects";
import {
  MultiSelectField,
  MultiSelectSearchField,
} from "@/components/app/FormFields";
import { Button } from "@/components/ui/Button";
import { Form } from "@/components/ui/Form";
import { Spinner } from "@/components/ui/Spinner";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { type FormData as DraftFilterFormData } from "./utils";

export interface DraftsFilterFormProps
  extends Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    "children" | "onSubmit"
  > {
  form: UseFormReturn<DraftFilterFormData>;
  onClear?: () => boolean | undefined;
  onSubmit: (formData: DraftFilterFormData) => void;
  resetDisabled?: boolean;
  submitDisabled?: boolean;
  evaluationCycles: EvaluationCycle[];
}

export function DraftsFilterForm({
  className,
  form,
  onClear,
  onSubmit,
  resetDisabled,
  submitDisabled,
  evaluationCycles,
  ...props
}: DraftsFilterFormProps) {
  const { t } = useTranslation();
  const { maxMd } = useScreenSize();
  const screenType = maxMd ? "mobile" : "default";

  const cycleOptions = useMemo(
    () =>
      evaluationCycles.map((cycle) => ({
        label: cycle.name,
        value: cycle.id,
      })),
    [evaluationCycles],
  );

  const [projectsSearch, setProjectsSearch] = useState<string>();

  const {
    results: projects,
    isFetched: isFetchedProjects,
    cursor: projectsCursor,
  } = useListProjects({
    ordering: ["name"],
    ...(projectsSearch && { search: projectsSearch }),
  });

  const selectedProjectIds = form.getValues("projects") ?? [];
  const { projects: selectedProjects, isFetched: isFetchedSelectedProjects } =
    useGetProjectsByIds(selectedProjectIds);

  const isFetchingProjects = !isFetchedSelectedProjects || !isFetchedProjects;

  const projectsList = useMemo(() => {
    const fetchedProjectIds = projects.map(({ id }) => id);
    const selectedProjectsExtra = selectedProjects.filter(
      ({ id }) => !fetchedProjectIds.includes(id),
    );
    return [...projects, ...selectedProjectsExtra];
  }, [projects, selectedProjects]);

  const projectOptions = useMemo(
    () =>
      projectsList.map((project) => ({
        label: project.name,
        value: project.id,
      })),
    [projectsList],
  );

  const [projectsScrollSentryRef] = useInfiniteScroll({
    loading: isFetchingProjects,
    hasNextPage: projectsCursor.hasNextPage,
    onLoadMore: projectsCursor.loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Form {...form}>
      <form
        className={cn("space-y-[0.625rem]", className)}
        onSubmit={(e) => {
          void form.handleSubmit(onSubmit)(e);
        }}
        {...props}
      >
        <p className="text-h6 text-text-primary">
          {t("components.evaluationFilterForm.filters")}
        </p>
        <div className="h-max space-y-6 pb-6">
          <MultiSelectField
            control={form.control}
            label={t("components.evaluationFilterForm.evaluationCycle")}
            name="cycle"
            options={cycleOptions}
            placeholder={t(
              `components.evaluationFilterForm.placeholderSelectCycle.${screenType}`,
            )}
          />
          <MultiSelectSearchField
            control={form.control}
            isFetching={isFetchingProjects}
            label={t("components.evaluationFilterForm.project")}
            name="projects"
            onSearchChange={setProjectsSearch}
            options={projectOptions}
            scrollSentry={
              <div
                className="flex min-h-[1px] w-full justify-center"
                ref={projectsScrollSentryRef}
              >
                {Boolean(projectsCursor.isFetching) && (
                  <div className="p-2">
                    <Spinner className="size-4" />
                  </div>
                )}
              </div>
            }
          />
        </div>

        <div className="flex gap-[0.625rem]">
          <Button
            className="flex-auto"
            disabled={resetDisabled}
            onClick={onClear}
            type="reset"
            variant="outlined"
          >
            {t("components.evaluationFilterForm.buttonClearFilters")}
          </Button>
          <Button className="flex-auto" disabled={submitDisabled} type="submit">
            {t("components.evaluationFilterForm.buttonApplyFilters")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
