import { type BlockerFn, useBlocker } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";

export const useNavigationBlocker = (blockerFn?: BlockerFn) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const [callbackFn, setCallbackFn] = useState<() => void | Promise<void>>();

  const { proceed, reset, status } = useBlocker({
    blockerFn,
    condition: isEnabled,
  });

  const disableNextBlocker = useCallback((fn?: () => void | Promise<void>) => {
    setIsEnabled(false);
    setCallbackFn(() => fn);
  }, []);

  useEffect(() => {
    if (isEnabled || callbackFn === undefined) return;

    void (async () => {
      await callbackFn();

      setIsEnabled(true);
      setCallbackFn(undefined);
    })();
  }, [isEnabled, callbackFn]);

  return {
    cancelNavigate: reset,
    confirmNavigate: proceed,
    disableNextBlocker,
    isBlocked: status === "blocked",
    status,
  };
};
