/* eslint-disable @typescript-eslint/no-misused-promises */
import { useSearch } from "@tanstack/react-router";
import type { InitialTableState } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useListEvaluationCycles } from "@/api/evaluationCycles";
import { useGetCurrentEvaluationCycle } from "@/api/evaluationCycles/useGetCurrentEvaluationCycle";
import { useListEvaluationRatings } from "@/api/evaluationRatings";
import { useDeleteEvaluationRatingById } from "@/api/evaluationRatings/useDeleteEvaluationRatingById";
import { useGetCurrentUser, UserRole } from "@/api/users";
import { DeleteEvaluationConfirmDialog } from "@/components/app/DeleteEvaluationConfirmDialog";
import { useEvaluationsDashboardModal } from "@/components/pages/EvaluationsDashboardPage/hooks/useEvaluationsDashboardModal";
import { NavigationTable } from "@/components/ui/NavigationTable";
import type { FilterFormProps } from "@/components/ui/NavigationTable/TableHeaderControls";
import { useScreenSize } from "@/utils/hooks";

import { useEvaluationPageStore } from "../../CreateEvaluationPage/utils/hooks/useEvaluationPageStore";
import { EmptyDashboard } from "../EmptyDashboard";
import { columns } from "./columns";
import { EvaluationsFilterForm } from "./EvaluationsFilterForm";
import {
  defaultValues,
  type FormData as FilterFormData,
  formSchema,
} from "./EvaluationsFilterForm/utils";
import { tableStateToQueryParamsTransformer } from "./utils";

const initialState: InitialTableState = {
  columnVisibility: {
    cycle: false,
    evaluators: false,
  },
};

export function EvaluationsDashboardSubmissionsTab() {
  const { md } = useScreenSize();
  const { t } = useTranslation();

  const search = useSearch({ from: "/evaluations/_dashboard/" });
  const { filters } = search;

  const modalStore = useEvaluationsDashboardModal();

  const { currentCycle, isFetched: isFetchedCurrentCycle } =
    useGetCurrentEvaluationCycle();
  const { currentUser, isFetched: isFetchedCurrentUser } = useGetCurrentUser();

  const { evaluationCycles, isFetched: isEvaluationCyclesFetched } =
    useListEvaluationCycles();

  const defaultFilterFormValues = useMemo(
    () =>
      currentCycle
        ? { ...defaultValues, cycle: [currentCycle.id] }
        : defaultValues,
    [currentCycle],
  );

  const cycleIndex = useMemo(() => {
    if (filters?.cycle && filters.cycle.length === 1) {
      return (
        evaluationCycles?.results.findIndex(
          ({ id }) => filters.cycle?.[0] === id,
        ) ?? -1
      );
    }

    if (!filters?.cycle && currentCycle) {
      return (
        evaluationCycles?.results.findIndex(
          ({ id }) => currentCycle.id === id,
        ) ?? -1
      );
    }

    return -1;
  }, [currentCycle, evaluationCycles?.results, filters?.cycle]);
  const selectedCycle = evaluationCycles?.results[cycleIndex];

  const ratingsQueryParams = tableStateToQueryParamsTransformer.parse(search);

  const queryParams = {
    params: {
      ...ratingsQueryParams,
      ...(ratingsQueryParams?.cycle === undefined &&
        currentCycle && { cycle: [currentCycle.id] }),
      ...(currentUser && { evaluator: [currentUser.id] }),
    },
    options: {
      enabled:
        isFetchedCurrentUser &&
        isFetchedCurrentCycle &&
        isEvaluationCyclesFetched,
    },
  };

  const { evaluationRatings, isFetched } = useListEvaluationRatings(
    queryParams.params,
    queryParams.options,
  );

  const removeRating = useEvaluationPageStore((state) => state.removeRating);

  const mutation = useDeleteEvaluationRatingById({
    onSuccess: () => {
      const rating = modalStore.evaluationRating;

      if (!rating || rating.evaluation.draftData) return;

      removeRating({
        project: rating.projectId || null,
        evaluatee: rating.evaluatee.id,
      });
    },
  });

  const isFetchingTableData =
    !isFetched || !isFetchedCurrentCycle || !isEvaluationCyclesFetched;

  const { params } = queryParams;
  const canReset =
    (!isFetchingTableData &&
      (selectedCycle?.id !== currentCycle?.id ||
        (params.project && params.project.length > 0) ||
        (Boolean(params.cycleStatus) &&
          params.cycleStatus !== defaultFilterFormValues.cycleStatus))) ||
    (!md && params.ordering && params.ordering.length > 0);

  const columnsOrder = useMemo(
    () =>
      md
        ? []
        : [
            "evaluatee",
            "rating",
            "project",
            "deadline",
            "evaluatedAt",
            "cycleStatus",
          ],
    [md],
  );

  const orderedColumns = useMemo(
    () =>
      columns.slice().sort((a, b) => {
        const aIndex = columnsOrder.indexOf(a.id ?? "");
        const bIndex = columnsOrder.indexOf(b.id ?? "");
        const delta = aIndex - bIndex;

        return delta === 0 ? 0 : delta / Math.abs(delta);
      }),
    [columnsOrder],
  );

  const data = useMemo(
    () => evaluationRatings?.results ?? [],
    [evaluationRatings],
  );

  const handleDeleteCancelClick = () => {
    modalStore.setEvaluationDeleteConfirmModalOpen(false);
  };

  const handleDeleteConfirmClick = async () => {
    if (modalStore.evaluationRating === null) return;

    await mutation.delete(modalStore.evaluationRating.id);

    modalStore.setEvaluationDeleteConfirmModalOpen(false);
  };

  const renderFilterForm = useCallback(
    (filterProps: FilterFormProps<FilterFormData>) => (
      <EvaluationsFilterForm
        {...filterProps}
        evaluationCycles={evaluationCycles?.results ?? []}
        key={filters?.cycle?.join(",")}
      />
    ),
    [evaluationCycles, filters?.cycle],
  );

  return (
    <>
      <DeleteEvaluationConfirmDialog
        isLoading={mutation.isPending}
        onCancelClick={handleDeleteCancelClick}
        onConfirmClick={handleDeleteConfirmClick}
        open={modalStore.evaluationDeleteConfirmModalOpen}
      />

      <NavigationTable
        bodyClassName="max-md:[&>tr:last-child>td]:border-b max-md:[&>tr:last-child>td]:border-other-divider max-md:[&>tr[data-row-type=single]>td]:pr-[4.125rem]"
        className="flex-auto max-md:m-6"
        columns={orderedColumns}
        data={data}
        defaultFilterFormValues={defaultFilterFormValues}
        enableShowAll={currentUser?.role === UserRole.Administrator || false}
        filteredResultsCount={evaluationRatings?.count}
        formSchema={formSchema}
        initialState={initialState}
        isFetching={isFetchingTableData}
        isResetDisabled={!canReset}
        placeholder={!isFetchingTableData && <EmptyDashboard />}
        renderFilterForm={renderFilterForm}
        searchPlaceholder={
          md
            ? t("evaluations.dashboard.searchPlaceholder.default")
            : t("evaluations.dashboard.searchPlaceholder.mobile")
        }
      />
    </>
  );
}
