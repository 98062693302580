import type { ReactNode } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Form } from "@/components/ui/Form";
import { TextArea } from "@/components/ui/TextArea";
import { withLoadingState } from "@/utils/decorators";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { useEvaluationPageStore } from "../../../utils/hooks/useEvaluationPageStore";
import { type FormData as RankingFormData } from "./utils";

export interface RankingFormProps {
  children?: ReactNode;
  className?: string;
  form: UseFormReturn<RankingFormData>;
  isLoading?: boolean;
  onFormInputValueChange?: () => void;
}

export const RankingForm = withLoadingState(function RankingForm({
  children,
  className,
  form,
  isLoading: _,
  onFormInputValueChange,
}: RankingFormProps) {
  const { t } = useTranslation();
  const { lg } = useScreenSize();

  const setRankingNotes = useEvaluationPageStore(
    (state) => state.setRankingNotes,
  );

  return (
    <Form {...form}>
      <form
        className={cn("flex flex-col items-stretch gap-[0.625rem]", className)}
      >
        {children}

        <Form.Field
          control={form.control}
          name="rankingNotes"
          render={({ field: { onChange: handleChange, ...field } }) => (
            <Form.Item className="flex flex-auto flex-col gap-[0.625rem]">
              <div className="flex items-center justify-between gap-2">
                <Form.Label className="text-subtitle2 text-text-secondary">
                  {t("components.rankingForm.rankMembers")}
                </Form.Label>
                <p className="text-caption text-text-secondary">
                  {t("components.rankingForm.required")}
                </p>
              </div>
              <Form.Control>
                <TextArea
                  className="flex flex-auto flex-col gap-[0.625rem]"
                  helper={
                    lg
                      ? t("components.rankingForm.hintText.default")
                      : t("components.rankingForm.hintText.mobile")
                  }
                  helperClassName="flex items-center justify-between w-full"
                  hideCounter
                  inputClassName="w-full flex-auto"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange(value);
                    setRankingNotes(value);
                    onFormInputValueChange?.();
                  }}
                  placeholder={t("components.rankingForm.placeholder")}
                  {...field}
                />
              </Form.Control>
            </Form.Item>
          )}
        />
      </form>
    </Form>
  );
});
