import type { HTMLAttributes } from "react";

import type { BasicUser } from "@/api/users";
import { Avatar } from "@/components/ui/Avatar";
import { cn } from "@/utils/ui";

export interface UserRatingInfoCellProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  user: BasicUser;
}

export function UserRatingInfoCell({
  className,
  user,
}: UserRatingInfoCellProps) {
  return (
    <div
      className={cn(
        "flex gap-2 text-body2 font-semibold text-text-primary max-md:mb-1 max-md:flex-col max-md:gap-1 md:h-fit md:items-center",
        className,
      )}
    >
      <Avatar displayName={user.fullName} src={user.avatar} />
      <div className="max-w-full items-start overflow-hidden text-left">
        <p className="text-ellipsis text-subtitle2 text-text-primary">
          {user.fullName}
        </p>
        <p className="text-body2 text-text-secondary">{user.email}</p>
      </div>
    </div>
  );
}
