/* eslint-disable @typescript-eslint/no-misused-promises */
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { EvaluationStep } from "@/api/constants";
import { useListUsers } from "@/api/users";
import { withLoadingState } from "@/utils/decorators";

import { CreateEvaluationForm } from "../../CreateEvaluationForm";
import {
  type FormData as EvaluationRatingFormData,
  resolver,
} from "../../CreateEvaluationForm/utils";
import { useDraftAutosave } from "../../utils/hooks/useDraftAutosave";
import { useEvaluationPageFormDefaultValues } from "../../utils/hooks/useEvaluationPageFormDefaultValues";
import { useEvaluationPageStore } from "../../utils/hooks/useEvaluationPageStore";
import { useMemberSelectionState } from "../../utils/hooks/useMemberSelectionState";
import { useNavigationBlocker } from "../../utils/hooks/useNavigationBlocker";
import { AddedMembersView as AddedMembersViewBase } from "./AddedMembersView";

const AddedMembersView = withLoadingState(AddedMembersViewBase);

export function EvaluateMembersStep() {
  const { disableNextBlocker } = useNavigationBlocker();
  const navigate = useNavigate();

  const { onAutosaveDraftDebounced } = useDraftAutosave();

  const { users, isFetched: isFetchedUsers } = useListUsers({
    ordering: ["first_name", "last_name"],
  });

  const usersList = useMemo(() => users ?? [], [users]);

  const { defaultValues, isFetched: isFetchedValues } =
    useEvaluationPageFormDefaultValues({ users: usersList });

  const selectedProjects = useEvaluationPageStore(
    (state) => state.selectedProjects,
  );
  const projectMemberRatings = useEvaluationPageStore(
    (state) => state.selectedProjectMemberRatings,
  );

  const { clickedProjectId, clickedUserId } = useMemberSelectionState();

  const selectedMembersList = useMemo(
    () =>
      selectedProjects
        .filter(({ id }) => id in projectMemberRatings)
        .map((project) =>
          usersList
            .filter((user) => `${user.id}` in projectMemberRatings[project.id])
            .map((user) => ({ project, user })),
        )
        .flat(),
    [usersList, projectMemberRatings, selectedProjects],
  );

  const resolvedClickedProjectId =
    clickedProjectId ?? selectedMembersList.at(0)?.project.id;
  const resolvedClickedUserId =
    clickedUserId ?? selectedMembersList.at(0)?.user.id;

  const { project: clickedProject, user: clickedUser } = useMemo(
    () =>
      selectedMembersList.find(
        ({ project, user }) =>
          project.id === resolvedClickedProjectId &&
          user.id === resolvedClickedUserId,
      ) ?? { project: null, user: null },
    [resolvedClickedProjectId, resolvedClickedUserId, selectedMembersList],
  );

  const clickedMemberIndex = useMemo(
    () =>
      selectedMembersList.findIndex(
        ({ project, user }) =>
          resolvedClickedProjectId === project.id &&
          resolvedClickedUserId === user.id,
      ),
    [resolvedClickedProjectId, resolvedClickedUserId, selectedMembersList],
  );

  const hasNextMember =
    clickedMemberIndex !== -1 &&
    clickedMemberIndex < selectedMembersList.length - 1;

  const hasPrevMember = clickedMemberIndex !== -1 && clickedMemberIndex > 0;

  const form = useForm<EvaluationRatingFormData>({
    defaultValues,
    resolver,
  });

  const handleNextMemberClick = useCallback(() => {
    const newIndex =
      clickedMemberIndex === -1 ? clickedMemberIndex : clickedMemberIndex + 1;
    const member = newIndex !== -1 ? selectedMembersList[newIndex] : undefined;

    disableNextBlocker(async () => {
      await navigate({
        replace: true,
        search: {
          step: EvaluationStep.EvaluateMembers,
          evaluateeId: member?.user.id,
          projectId: member?.project.id,
        },
      });
    });
  }, [clickedMemberIndex, disableNextBlocker, navigate, selectedMembersList]);

  const handlePrevMemberClick = useCallback(() => {
    const newIndex =
      clickedMemberIndex === -1 ? clickedMemberIndex : clickedMemberIndex - 1;
    const member = newIndex !== -1 ? selectedMembersList[newIndex] : undefined;

    disableNextBlocker(async () => {
      await navigate({
        replace: true,
        search: {
          step: EvaluationStep.EvaluateMembers,
          evaluateeId: member?.user.id,
          projectId: member?.project.id,
        },
      });
    });
  }, [clickedMemberIndex, disableNextBlocker, navigate, selectedMembersList]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  return (
    <div className="flex flex-auto items-stretch gap-6 overflow-hidden max-lg:flex-col-reverse max-md:flex-col max-md:gap-3 max-md:px-6 max-md:pb-6 max-md:pt-3 md:gap-10 lg:gap-10">
      <AddedMembersView
        className="max-md:flex-auto md:grow-[40%] md:basis-[40%]"
        isLoading={!isFetchedUsers || !isFetchedValues}
      />

      <CreateEvaluationForm
        className="lg:mr-[1.375rem]"
        containerClassName="max-md:flex-auto md:grow-[60%] md:basis-[60%]"
        form={form}
        hasNextMember={hasNextMember}
        hasPrevMember={hasPrevMember}
        isCurrentUserClicked={false}
        isLoading={!isFetchedUsers || !isFetchedValues}
        onFormInputValueChange={onAutosaveDraftDebounced}
        onNextMemberClick={handleNextMemberClick}
        onPrevMemberClick={handlePrevMemberClick}
        project={clickedProject}
        user={clickedUser}
      />
    </div>
  );
}
