/* eslint-disable @typescript-eslint/no-misused-promises */
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { type InitialTableState } from "@tanstack/react-table";
import { type HTMLAttributes, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  EvaluationCycleStatus,
  useGetCurrentEvaluationCycle,
  useListEvaluationCycles,
} from "@/api/evaluationCycles";
import type { AdminEvaluation } from "@/api/evaluations";
import { useListEvaluations } from "@/api/evaluations";
import { downloadEvaluations } from "@/api/evaluations/useDownloadEvaluations";
import { CreateCycleDialog } from "@/components/app/CreateCycleDialog";
import { Button } from "@/components/ui/Button";
import { NavigationTable } from "@/components/ui/NavigationTable";
import type {
  FilterFormProps,
  FilterSummaryProps,
} from "@/components/ui/NavigationTable/TableHeaderControls";
import type { TableStateBaseParams } from "@/components/ui/NavigationTable/utils";
import { Spinner } from "@/components/ui/Spinner";
import { downloadBlob } from "@/utils/downloads";
import { cn } from "@/utils/ui";

import { AdminFilterForm } from "./AdminFilterForm";
import type { FormData as FilterFormData } from "./AdminFilterForm/utils";
import { defaultValues, formSchema } from "./AdminFilterForm/utils";
import { columns } from "./columns";
import { CycleGroupRow } from "./CycleGroupRow";
import { FilterSummary } from "./FilterSummary";
import { MemberDetailDialog } from "./MemberDetailDialog";
import { tableStateToQueryParamsTransformer } from "./utils";

const DOWNLOADED_CSV_FILENAME = "evaluations.csv";

export type AdminDashboardProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>;

const initialState: InitialTableState = {
  columnVisibility: {
    cycle: false,
    evaluators: false,
  },
};

export function AdminDashboardPage({
  className,
  ...props
}: AdminDashboardProps) {
  const { t } = useTranslation();
  const search = useSearch({ from: "/admin" });
  const navigate = useNavigate({ from: "/admin" });

  const evaluationsQueryParams =
    tableStateToQueryParamsTransformer.parse(search);

  const { currentCycle, isFetched: isFetchedCycle } =
    useGetCurrentEvaluationCycle();

  const { evaluations, isFetching: isFetchingTableData } = useListEvaluations(
    evaluationsQueryParams.dashboardFilters || {},
  );

  const { evaluationCycles } = useListEvaluationCycles();

  const isCycleInactive =
    currentCycle?.status === EvaluationCycleStatus.Inactive;

  const data = useMemo(() => evaluations?.results ?? [], [evaluations]);

  const handleDownloadCsvClick = async () => {
    const blob = await downloadEvaluations(
      evaluationsQueryParams.dashboardFilters || {},
    );
    downloadBlob(blob, DOWNLOADED_CSV_FILENAME);
  };

  const renderFilterForm = useCallback(
    (filterProps: FilterFormProps<FilterFormData>) => (
      <AdminFilterForm
        {...filterProps}
        evaluationCycles={evaluationCycles?.results ?? []}
      />
    ),
    [evaluationCycles?.results],
  );

  const renderFilterSummary = useCallback(
    (filterProps: FilterSummaryProps<AdminEvaluation, FilterFormData>) => (
      <FilterSummary
        {...filterProps}
        evaluationCycles={evaluationCycles?.results ?? []}
      />
    ),
    [evaluationCycles?.results],
  );

  const onUpdateStateParams = useCallback(
    (params: TableStateBaseParams) => {
      navigate({
        search: (prev) => ({
          dashboardFilters: {
            ...prev.dashboardFilters,
            ...params,
          },
        }),
      }).catch(() => null);
    },
    [navigate],
  );

  return (
    <div
      className={cn(
        "h-full flex flex-col items-stretch gap-6 px-10 pt-12 pb-16",
        className,
      )}
      {...props}
    >
      <div className="flex flex-wrap items-center justify-between gap-x-3 gap-y-4">
        <div className="flex flex-col gap-2">
          <h1 className="text-h3 text-text-primary">
            {t("evaluations.dashboard.evaluation")}
          </h1>
          <div className="flex items-center gap-2">
            {isFetchedCycle ? (
              <>
                <span
                  className={cn(
                    "whitespace-nowrap text-h6 text-text-secondary",
                    isCycleInactive && "invisible",
                  )}
                >
                  {t("evaluations.dashboard.cycle")}
                </span>
                <span
                  className={cn(
                    "whitespace-nowrap text-h6 text-text-primary",
                    isCycleInactive && "invisible",
                  )}
                >
                  {currentCycle
                    ? currentCycle.name
                    : t("evaluations.dashboard.noActiveCycle")}
                </span>
              </>
            ) : (
              <Spinner className="inline-flex size-4" />
            )}
          </div>
        </div>

        <div className="space-x-3 space-y-3 sm:space-y-0">
          <Button
            onClick={handleDownloadCsvClick}
            startIcon={<ArrowDownTrayIcon className="size-4" />}
            variant="outlined"
          >
            {t("admin.dashboard.downloadCsv")}
          </Button>
          <CreateCycleDialog />
        </div>
      </div>

      <NavigationTable
        className="flex-auto"
        columns={columns}
        customFrom="/admin"
        data={data}
        defaultFilterFormValues={defaultValues}
        filterSource="dashboardFilters"
        filteredResultsCount={evaluations?.count}
        formSchema={formSchema}
        initialState={initialState}
        isFetching={isFetchingTableData}
        onUpdateStateParams={onUpdateStateParams}
        renderFilterForm={renderFilterForm}
        renderFilterSummary={renderFilterSummary}
        renderGroupRow={CycleGroupRow}
        searchPlaceholder={t("admin.dashboard.searchPlaceholder")}
      />

      <MemberDetailDialog />
    </div>
  );
}
