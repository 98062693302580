import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Translation } from "react-i18next";

import { EVALUATION_RATINGS } from "@/api/constants";
import type { EvaluationDraftRatingValidEvaluatee } from "@/api/evaluations";
import { DraftRatingStatusIndicator } from "@/components/app/DraftRatingStatusIndicator";
import { Rating } from "@/components/ui/Rating";
import { cn } from "@/utils/ui";

import { DraftUserRatingInfoCell } from "./DraftUserRatingInfoCell";

const columnHelper = createColumnHelper<EvaluationDraftRatingValidEvaluatee>();

export const columns = [
  columnHelper.accessor((row) => row.evaluatee.id, {
    id: "evaluator",
    meta: {
      cellClassName: "px-3",
    },
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.memberName")}</Translation>
    ),
    cell: (info) => (
      <DraftUserRatingInfoCell
        className="max-md:mb-2"
        evaluationRating={info.row.original}
      />
    ),
    enableSorting: false,
  }),

  columnHelper.accessor(
    (row) => (!row.rating ? 0 : EVALUATION_RATINGS[row.rating]),
    {
      id: "rating",
      header: () => (
        <Translation>{(t) => t("evaluations.dashboard.rating")}</Translation>
      ),
      cell: (info) => (
        <Rating
          buttonClassName="p-0"
          className="flex items-center p-0 max-md:mb-2"
          readOnly
          size="auto"
          value={info.getValue()}
        />
      ),
      enableSorting: false,
    },
  ),

  columnHelper.accessor((row) => row.projectName, {
    id: "project",
    enableSorting: false,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.project")}</Translation>
    ),
    cell: (info) => (
      <div
        className={cn(
          "flex items-center gap-x-1 max-md:flex-wrap max-md:mb-[0.125rem]",
          info.row.original.isSelfEvaluation && "max-md:hidden",
        )}
      >
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.project")}:`}
          </Translation>
        </span>
        <span className="text-caption text-text-primary md:text-body2">
          {info.getValue()}
        </span>
      </div>
    ),
  }),

  columnHelper.accessor((row) => row.isPending, {
    id: "status",
    enableSorting: false,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.status")}</Translation>
    ),
    cell: (info) => (
      <div className="flex items-center gap-x-1 max-md:flex-wrap">
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.status")}:`}
          </Translation>
        </span>
        <DraftRatingStatusIndicator
          className="max-md:*:text-caption"
          isPending={info.row.original.isPending}
        />
      </div>
    ),
  }),
] as ColumnDef<EvaluationDraftRatingValidEvaluatee>[];
