import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "@/utils/ui";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipPortal = TooltipPrimitive.Portal;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    className={cn(
      "z-50 rounded-[0.3125rem] bg-white px-[0.875rem] py-[0.3125rem] text-caption text-text-primary animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 drop-shadow-elevation2",
      className,
    )}
    ref={ref}
    sideOffset={sideOffset}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface TooltipProps
  extends Omit<TooltipPrimitive.TooltipProviderProps, "children"> {
  arrowProps?: TooltipPrimitive.TooltipArrowProps &
    React.RefAttributes<SVGSVGElement>;
  children?: React.ReactNode;
  content?: React.ReactNode;
  contentProps?: React.ComponentPropsWithoutRef<
    typeof TooltipPrimitive.Content
  >;
  portalProps?: TooltipPrimitive.TooltipPortalProps;
  rootProps?: TooltipPrimitive.TooltipProps;
  showArrow?: boolean;
}

function DefaultTooltip({
  arrowProps,
  children,
  content,
  contentProps,
  portalProps,
  rootProps,
  showArrow = true,
  ...props
}: TooltipProps) {
  return (
    <TooltipProvider {...props}>
      <TooltipRoot {...rootProps}>
        {children ? <TooltipTrigger asChild>{children}</TooltipTrigger> : null}

        <TooltipPortal {...portalProps}>
          <TooltipContent sideOffset={5} {...contentProps}>
            {content}
            {showArrow ? (
              <TooltipArrow
                {...arrowProps}
                className={cn("fill-white", arrowProps?.className)}
              />
            ) : null}
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  );
}

export const Tooltip = Object.assign(DefaultTooltip, {
  Arrow: TooltipArrow,
  Content: TooltipContent,
  Portal: TooltipPortal,
  Provider: TooltipProvider,
  Root: TooltipRoot,
  Trigger: TooltipTrigger,
});
