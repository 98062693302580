import { CalendarIcon } from "@heroicons/react/24/solid";
import { parse } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import {
  type DateRange,
  type DayClickEventHandler,
  DayPicker,
  type DayPickerRangeProps,
} from "react-day-picker";
import { useTranslation } from "react-i18next";

import type { TextInput } from "@/components/ui/TextInput";
import { cn } from "@/utils/ui";

import { CustomCaption } from "./CustomCaption";
import { PopoverInput } from "./PopoverInput";
import { dateListToDateRange, formatDateRange } from "./utils";

export interface DateRangePickerProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof TextInput>,
    "defaultValue" | "onChange" | "value"
  > {
  dayPickerProps?: Omit<DayPickerRangeProps, "mode">;
  defaultTextInputValue?: string;
  defaultValue?: DateRange;
  format?: string;
  onChange?: (dateRange: DateRange) => void;
  onTextInputChange?: (text: string) => void;
  onTextInputSubmit?: (text: string) => void;
  open?: boolean;
  popoverClassName?: string;
  textInputValue?: string;
  value?: DateRange;
}

export const DateRangePicker = React.forwardRef<
  HTMLInputElement,
  DateRangePickerProps
>(function DateRangePicker(
  {
    className,
    dayPickerProps,
    defaultTextInputValue,
    defaultValue,
    disabled,
    endIcon = (
      <CalendarIcon className="size-6 cursor-pointer text-text-secondary" />
    ),
    format: dateFormat = "PP",
    onChange,
    onTextInputChange,
    onTextInputSubmit,
    open,
    placeholder,
    textInputValue,
    value,
    ...props
  },
  ref,
) {
  const { t } = useTranslation();

  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const finalIsOpen = open ?? internalIsOpen;

  const [internalValue, setInternalValue] = useState<DateRange>(
    defaultValue ?? { from: undefined },
  );
  const finalValue = value ?? internalValue;

  const [internalTextInputValue, setInternalTextInputValue] = useState<string>(
    defaultTextInputValue ?? formatDateRange(finalValue, dateFormat),
  );
  const finalTextInputValue = textInputValue ?? internalTextInputValue;

  const {
    classNames,
    components,
    fromYear = 1900,
    today,
    toYear = 2099,
    ...customDayPickerProps
  } = dayPickerProps ?? {};
  const todayDate = useMemo(() => today ?? new Date(), [today]);

  const [month, setMonth] = useState(defaultValue?.to ?? new Date());

  const handleClose = useCallback(() => {
    setInternalIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setInternalIsOpen(true);
  }, []);

  const handleDayUpdate = () => {
    const inputDates = finalTextInputValue
      .split("-")
      .map((text) => parse(text.trim(), dateFormat, todayDate));
    const inputDateRange = dateListToDateRange(inputDates);

    const date = inputDateRange.to ?? inputDateRange.from;
    if (date !== undefined) {
      setMonth(date);
    }
    setInternalValue(inputDateRange);

    onChange?.(inputDateRange);
  };

  const handleDayClick: DayClickEventHandler = useCallback(
    (day, { selected }) => {
      if (selected) {
        const updatedRange = {
          from: finalValue.to ? finalValue.from : undefined,
        };
        const textInput = formatDateRange(updatedRange, dateFormat);

        setInternalTextInputValue(textInput);
        setInternalValue(updatedRange);
        setMonth(finalValue.from ?? todayDate);

        onTextInputChange?.(textInput);
        onChange?.(updatedRange);

        return;
      }

      const updatedRange = (() => {
        if (!finalValue.from) return { from: day };
        if (!finalValue.to) return { ...finalValue, to: day };
        return { from: day };
      })();
      const textInput = formatDateRange(updatedRange, dateFormat);

      setInternalTextInputValue(textInput);
      setInternalValue(updatedRange);
      setMonth(day);

      onTextInputChange?.(textInput);
      onChange?.(updatedRange);
    },
    [dateFormat, finalValue, onChange, onTextInputChange, todayDate],
  );

  const handleTextInputChange = useCallback(
    (text: string) => {
      setInternalTextInputValue(text);

      onTextInputChange?.(text);
    },
    [onTextInputChange],
  );

  const handleTextInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.code !== "Enter") return;

        const inputDates = finalTextInputValue
          .split("-")
          .map((text) => parse(text.trim(), dateFormat, todayDate));
        const inputDateRange = dateListToDateRange(inputDates);
        const textInput = formatDateRange(finalValue, dateFormat);

        setInternalTextInputValue(textInput);
        setInternalValue(inputDateRange);
        setInternalIsOpen(false);

        onTextInputSubmit?.(textInput);
        onChange?.(inputDateRange);
      },
      [
        dateFormat,
        finalValue,
        finalTextInputValue,
        onChange,
        onTextInputSubmit,
        todayDate,
      ],
    );

  return (
    <PopoverInput
      anchorAsChild={false}
      className={cn("w-max px-3 py-0", className)}
      disabled={disabled}
      endIcon={endIcon}
      isOpen={finalIsOpen}
      onClose={handleClose}
      onEscapeKeyDown={() => {
        handleClose();
      }}
      onInputBlur={handleDayUpdate}
      onInputChange={handleTextInputChange}
      onKeyDown={handleTextInputKeyDown}
      onOpen={handleOpen}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
      placeholder={placeholder ?? t("components.dateRangePicker.placeholder")}
      ref={ref}
      value={finalTextInputValue}
      {...props}
    >
      <DayPicker
        classNames={{
          caption:
            "flex select-none justify-around relative items-center gap-2 py-3 px-4",
          cell: "select-none text-center",
          day: "rounded-full size-6 hover:bg-primary-background aria-selected:hover:bg-primary-dark disabled:hover:bg-transparent text-subtitle1 leading-none",
          day_hidden: "invisible",
          day_outside: "opacity-50",
          day_selected: "bg-primary-main text-white hover:bg-primary-dark",
          dropdown:
            "ring-0 gap-3.5 px-2.5 py-3 h-10 [&:not(:focus)]:enabled:hover:ring-0 data-[state=open]:ring-0",
          dropdown_month: "w-[8.75rem]",
          dropdown_year: "w-[6.25rem]",
          head: "h-10",
          head_cell: "select-none text-subtitle1 text-text-secondary",
          nav_button: "shrink-0 flex",
          row: "h-10",
          table: "w-full mb-2",
          ...classNames,
        }}
        components={{
          Caption: CustomCaption,
          ...components,
        }}
        fromYear={fromYear}
        mode="range"
        month={finalTextInputValue ? month : undefined}
        onDayClick={handleDayClick}
        onMonthChange={setMonth}
        selected={finalValue}
        toYear={toYear}
        today={todayDate}
        {...customDayPickerProps}
      />
    </PopoverInput>
  );
});
