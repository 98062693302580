import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { Label } from "@radix-ui/react-dropdown-menu";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { type HTMLAttributes, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { BasicEvaluationRating } from "@/api/evaluationRatings";
import type { BasicProjectWithUserIds, Project } from "@/api/projects";
import { useGetCurrentUser, type User } from "@/api/users";
import { InfoText } from "@/components/app/InfoText";
import { ProjectListItem, UserListItem } from "@/components/app/ListItem";
import { IconButton } from "@/components/ui/IconButton";
import { cn } from "@/utils/ui";

export interface EvaluationSubjectsProps
  extends HTMLAttributes<HTMLDivElement> {
  containerClassName?: string;
  isLoading: boolean;
  memberRatingList: BasicEvaluationRating[];
  onProjectOrRatingClick: (evaluateeId: number, projectId?: number) => void;
  projectMemberRatings: Record<
    string,
    { projectName: string; ratings: BasicEvaluationRating[] }
  >;
  ratings: BasicEvaluationRating[];
  selectedMemberId: number | null;
  selectedProjectId: number | null;
}

export function EvaluationSubjects({
  children,
  className,
  containerClassName,
  isLoading,
  memberRatingList,
  onProjectOrRatingClick,
  projectMemberRatings,
  ratings,
  selectedMemberId,
  selectedProjectId,
  ...props
}: EvaluationSubjectsProps) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { currentUser } = useGetCurrentUser();

  const selfRating = useMemo(
    () => ratings.find((rating) => rating.isSelfEvaluation) || null,
    [ratings],
  );
  const isSelfRatingSelected = selectedMemberId === currentUser?.id;

  const projectList = useMemo<BasicProjectWithUserIds[]>(
    () =>
      Object.entries(projectMemberRatings).map(([k, v]) => ({
        id: Number.parseInt(k),
        name: v.projectName,
        userIds: v.ratings.map((rating) => rating.evaluatee.id),
      })),
    [projectMemberRatings],
  );

  const selectedProject = useMemo(
    () =>
      isSelfRatingSelected
        ? { id: 0, name: "Self-Evaluation" }
        : projectList.find(({ id }) => id === selectedProjectId) || null,
    [isSelfRatingSelected, projectList, selectedProjectId],
  );

  const selectedMember = useMemo(
    () =>
      isSelfRatingSelected
        ? currentUser
        : memberRatingList.find(
            (rating) => rating.evaluatee.id === selectedMemberId,
          )?.evaluatee || null,
    [currentUser, isSelfRatingSelected, memberRatingList, selectedMemberId],
  );

  const handleCollapseToggle = useCallback(() => {
    setIsOpen((flag) => !flag);
  }, []);

  return (
    <OverlayScrollbarsComponent
      className={cn("flex flex-col items-stretch", containerClassName)}
      defer
      element="div"
      options={{ scrollbars: { autoHide: "move" } }}
    >
      {children}

      <div
        className={cn(
          "group flex flex-col items-stretch gap-3 md:p-0.5 border-other-divider max-md:p-3 max-md:rounded-2xl max-md:border",
          className,
        )}
        data-state={isOpen ? "open" : "closed"}
        {...props}
      >
        <div className="flex items-center justify-between gap-3 px-5 md:hidden">
          <p className="text-overline uppercase text-text-secondary">
            {t("evaluations.detail.select")}
          </p>
          <IconButton
            className="flex"
            icon={
              <ChevronDownIcon className="size-6 transition-transform group-data-[state=open]:-rotate-180" />
            }
            onClick={handleCollapseToggle}
          />
        </div>

        <div className="flex flex-col items-stretch gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 group-data-[state=open]:hidden md:hidden">
          <Label className="text-subtitle2 text-text-secondary">
            {t("evaluations.detail.project")}
          </Label>
          {Boolean(selectedProject) && (
            <ProjectListItem data-state="selected" project={selectedProject} />
          )}
        </div>

        {selectedMember !== null && (
          <div className="flex flex-col items-stretch gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 group-data-[state=open]:hidden md:hidden">
            <Label className="text-subtitle2 text-text-secondary">
              {t("evaluations.detail.member")}
            </Label>
            <UserListItem
              data-state="selected"
              selected
              user={selectedMember}
            />
          </div>
        )}

        <div className="flex flex-col gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 max-md:group-data-[state=closed]:hidden">
          <Label className="text-subtitle2 text-text-secondary">
            {t("evaluations.create.selfEvaluation")}
          </Label>
          <UserListItem
            data-state={
              selectedMemberId === currentUser?.id ? "selected" : undefined
            }
            onClick={() => {
              if (!selfRating) {
                return;
              }

              onProjectOrRatingClick(selfRating.evaluatee.id);
            }}
            selected={selectedMemberId === currentUser?.id}
            user={
              (selfRating?.evaluatee ?? currentUser) as unknown as User | null
            }
          />
        </div>

        <div className="flex flex-col items-stretch gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 max-md:group-data-[state=closed]:hidden">
          <Label className="text-subtitle2 text-text-secondary">
            {t("evaluations.detail.projects")}
          </Label>
          {projectList.length > 0 ? (
            projectList.map((project) => (
              <ProjectListItem
                data-state={project.id === selectedProjectId && "selected"}
                key={project.id}
                onClick={() => {
                  const newEvaluateeId =
                    project.id === selectedProjectId && selectedMemberId
                      ? selectedMemberId
                      : project.userIds[0];
                  onProjectOrRatingClick(newEvaluateeId, project.id);
                }}
                project={project as unknown as Project}
              />
            ))
          ) : (
            <InfoText
              isLoading={isLoading}
              subtitle={t("evaluations.detail.noAddedProjects")}
              title={t("evaluations.detail.nothingToShow")}
            />
          )}
        </div>

        {memberRatingList.length > 0 && (
          <div className="flex flex-col items-stretch gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 max-md:group-data-[state=closed]:hidden">
            <Label className="text-subtitle2 text-text-secondary">
              {t("evaluations.detail.members")}
            </Label>

            {memberRatingList.map((memberRating) => (
              <UserListItem
                data-state={
                  memberRating.evaluatee.id === selectedMemberId && "selected"
                }
                key={memberRating.id}
                onClick={() => {
                  onProjectOrRatingClick(
                    memberRating.evaluatee.id,
                    memberRating.projectId,
                  );
                }}
                selected={memberRating.evaluatee.id === selectedMemberId}
                user={memberRating.evaluatee as unknown as User}
              />
            ))}
          </div>
        )}
      </div>
    </OverlayScrollbarsComponent>
  );
}
