import { create, type StateCreator } from "zustand";

import type { RatingChoice } from "@/api/constants";
import { EVALUATION_RATINGS } from "@/api/evaluationRatings";
import {
  type EvaluationDraftData,
  EvaluationSubmitPayloadSchema,
} from "@/api/evaluations";

import type { FormData as EvaluationRatingFormData } from "../../CreateEvaluationForm/utils";
import type { EvaluationPageStore } from "../types";

const ratingFormDataToPayload = (formData: EvaluationRatingFormData) => {
  const {
    evaluatee: evaluateeId,
    project: projectId,
    ...formDataProps
  } = formData;
  const rating = Object.entries(EVALUATION_RATINGS).find(
    ([_, value]) => value === formDataProps.rating,
  )?.[0];

  return {
    ...formDataProps,
    evaluateeId,
    projectId,
    rating: (rating || null) as RatingChoice | null,
  };
};

const createEvaluationPageStore: StateCreator<EvaluationPageStore> = (
  set,
  get,
) => ({
  isRatingsEmpty: true,
  rankingNotes: "",
  selectedProjects: [],
  selectedProjectMemberRatings: { self: { eval: { isPending: true } } },

  getDraftPayload: () => {
    const { self, ...selectedProjectMemberRatings } =
      get().selectedProjectMemberRatings;
    const selfRating = self.eval;

    const projects = Object.entries(selectedProjectMemberRatings);
    const memberRatings = projects
      .map(([projectId, memberRatingsMap]) => {
        const { project, ...ratingsMap } = memberRatingsMap;
        const ratings = Object.entries(ratingsMap);

        if (ratings.length === 0) {
          return [
            {
              isPending: true,
              projectId: Number.parseInt(projectId),
              projectName: project?.projectName,
            },
          ];
        }

        return ratings.map(([id, rating]) => ({
          ...rating,
          evaluateeId: Number.parseInt(id),
          projectId: Number.parseInt(projectId),
          projectName: rating?.projectName,
          rating: rating?.rating || undefined,
        }));
      })
      .flat(1);

    const ratings = selfRating?.evaluateeId
      ? [
          {
            ...selfRating,
            evaluateeId: selfRating.evaluateeId,
            rating: selfRating.rating || undefined,
            isSelfEvaluation: true,
          },
          ...memberRatings,
        ]
      : memberRatings;

    return {
      ratings,
      rankingNotes: get().rankingNotes || undefined,
      version: 1,
    } as EvaluationDraftData;
  },

  getSubmitPayload: (isDraft = false) => {
    const { self, ...selectedProjectMemberRatings } =
      get().selectedProjectMemberRatings;
    const selfRating = self.eval;

    const projects = Object.entries(selectedProjectMemberRatings);
    const memberRatings = projects
      .map(([projectId, memberRatingsMap]) =>
        Object.entries(memberRatingsMap)
          .filter(([id]) => id !== "project")
          .map(([id, rating]) => ({
            ...rating,
            evaluateeId: Number.parseInt(id),
            projectId: Number.parseInt(projectId),
          })),
      )
      .flat(1);
    const ratings = selfRating?.evaluateeId
      ? [
          { ...selfRating, evaluateeId: selfRating.evaluateeId },
          ...memberRatings,
        ]
      : memberRatings;

    const result = EvaluationSubmitPayloadSchema.safeParse({
      isDraft,
      ratings,
      rankingNotes: get().rankingNotes,
    });

    return result.success ? result.data : null;
  },

  getIsSelfRatingPending: () => {
    return get().selectedProjectMemberRatings.self.eval?.isPending ?? true;
  },

  getIsMemberRatingPending: (userId, currentUser, clickedProjectId) => {
    if (currentUser?.id === userId) {
      return get().selectedProjectMemberRatings.self.eval?.isPending ?? true;
    }

    if (
      clickedProjectId === null ||
      !(clickedProjectId in get().selectedProjectMemberRatings)
    ) {
      return false;
    }

    return (
      get().selectedProjectMemberRatings[`${clickedProjectId}`][`${userId}`]
        ?.isPending ?? true
    );
  },

  getCanSubmitRatings: () =>
    Object.values(get().selectedProjectMemberRatings)
      .map(({ project: _, ...ratings }) => {
        const ratingList = Object.values(ratings);
        return (
          ratingList.length > 0 &&
          ratingList.every((rating) => !(rating?.isPending ?? true))
        );
      })
      .every(Boolean),

  getSelectedProjectMembers: (users, clickedProjectId) => {
    if (
      clickedProjectId === null ||
      !(clickedProjectId in get().selectedProjectMemberRatings)
    )
      return [];

    const projectMemberRatings =
      get().selectedProjectMemberRatings[clickedProjectId];

    return users.filter((user) => `${user.id}` in projectMemberRatings);
  },

  getSelectedProjects: () => get().selectedProjects,

  setSelectedUsers: (value, users, clickedProjectId) => {
    set((state) => {
      const addedUsers = value;
      const addedUserIds = addedUsers.map((option) => option.value);
      const addedUserList = users.filter(({ id }) => addedUserIds.includes(id));
      const projectUserRatings =
        state.selectedProjectMemberRatings[`${clickedProjectId}`];

      addedUserList.forEach((user) => {
        if (!(`${user.id}` in projectUserRatings)) {
          projectUserRatings[`${user.id}`] = {
            evaluateeId: user.id,
            projectId: clickedProjectId || undefined,
            projectName: projectUserRatings.project?.projectName,
            rating: undefined,
            ratingNotes: undefined,
            isPending: true,
          };
        }
      });

      return {
        selectedProjectMemberRatings: { ...state.selectedProjectMemberRatings },
      };
    });
  },

  addSelectedProject: (project) => {
    set((state) => {
      const isFirstProject =
        Object.keys(state.selectedProjectMemberRatings).length === 1;

      state.selectedProjectMemberRatings[`${project.id}`] = {
        project: {
          isPending: true,
          projectId: project.id,
          projectName: project.name,
          rating: "",
        },
      };

      return {
        ...(isFirstProject && {
          isRatingsEmpty: false,
        }),
        selectedProjectMemberRatings: { ...state.selectedProjectMemberRatings },
        selectedProjects: [project, ...state.selectedProjects].sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    });
  },

  removeSelectedProject: (project) => {
    set((state) => {
      const { [`${project.id}`]: _, ...selectedProjectMemberRatings } =
        state.selectedProjectMemberRatings;

      const isLastProject =
        Object.keys(selectedProjectMemberRatings).length === 1;

      return {
        ...(isLastProject && {
          isRatingsEmpty:
            state.selectedProjectMemberRatings.self.eval?.isPending ?? true,
        }),
        selectedProjectMemberRatings: { ...selectedProjectMemberRatings },
        selectedProjects: state.selectedProjects.filter(
          (selectedProject) => selectedProject.id !== project.id,
        ),
      };
    });
  },

  removeSelectedUser: (user, clickedProjectId) => {
    set((state) => {
      const { [`${user.id}`]: _, ...selectedProjectRatings } =
        state.selectedProjectMemberRatings[`${clickedProjectId}`];

      state.selectedProjectMemberRatings[`${clickedProjectId}`] =
        selectedProjectRatings;

      return {
        selectedProjectMemberRatings: { ...state.selectedProjectMemberRatings },
      };
    });
  },

  getRating: ({ evaluatee, project }) => {
    if (evaluatee === null) return null;

    const map = get().selectedProjectMemberRatings;

    if (project === null) return map.self.eval || null;

    if (project in map && evaluatee in map[`${project}`])
      return map[`${project}`][`${evaluatee}`] || null;

    return null;
  },

  setRankingNotes: (value) => {
    set(() => ({ rankingNotes: value }));
  },

  saveRating: (formData, isPending) => {
    const payload = { ...ratingFormDataToPayload(formData), isPending };
    set((state) => {
      const { evaluateeId, projectId } = payload;

      if (projectId === undefined) {
        const memberRating = state.selectedProjectMemberRatings.self.eval;

        if (!memberRating) {
          state.selectedProjectMemberRatings.self.eval = payload;
        } else {
          Object.assign(memberRating, payload);
        }

        return {
          isRatingsEmpty: false,
          selectedProjectMemberRatings: {
            ...state.selectedProjectMemberRatings,
          },
        };
      }

      const selectedProjectRatings =
        state.selectedProjectMemberRatings[`${projectId}`];

      if (`${evaluateeId}` in selectedProjectRatings) {
        const selectedMemberRating = selectedProjectRatings[`${evaluateeId}`];
        if (!selectedMemberRating) {
          selectedProjectRatings[`${evaluateeId}`] = payload;
        } else {
          Object.assign(selectedMemberRating, payload);
        }
      } else {
        selectedProjectRatings[`${evaluateeId}`] = payload;
      }

      return {
        isRatingsEmpty: false,
        selectedProjectMemberRatings: { ...state.selectedProjectMemberRatings },
      };
    });
  },

  removeRating: ({ evaluatee, project }) => {
    set((state) => {
      if (project === null) {
        state.selectedProjectMemberRatings.self.eval = { isPending: true };
        return {
          selectedProjectMemberRatings: state.selectedProjectMemberRatings,
        };
      }

      if (
        evaluatee === null &&
        `${project}` in state.selectedProjectMemberRatings
      ) {
        const { [`${project}`]: _, ...selectedRatingSubjects } =
          state.selectedProjectMemberRatings;
        return { selectedProjectMemberRatings: selectedRatingSubjects };
      }

      const selectedRatingSubjects = { ...state.selectedProjectMemberRatings };

      if (!(project in selectedRatingSubjects)) {
        return { selectedProjectMemberRatings: selectedRatingSubjects };
      }

      const { [`${evaluatee}`]: _, ...memberRatings } =
        selectedRatingSubjects[`${project}`];
      selectedRatingSubjects[`${project}`] = memberRatings;

      return { selectedProjectMemberRatings: selectedRatingSubjects };
    });
  },

  clearRatings: (reInitData, reInitProjects, initEmptyOnly = true) => {
    set((state) => {
      if (!initEmptyOnly) {
        return {
          isRatingsEmpty: Object.keys(reInitData ?? {}).length === 0,
          selectedProjectMemberRatings: {
            self: { eval: { isPending: true } },
            ...reInitData,
          },
          selectedProjects: reInitProjects?.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
        };
      }

      if (state.isRatingsEmpty) {
        return {
          isRatingsEmpty: Object.keys(reInitData ?? {}).length === 0,
          selectedProjectMemberRatings: {
            self: { eval: { isPending: true } },
            ...reInitData,
          },
          selectedProjects: reInitProjects?.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
        };
      }

      if (!reInitData && !reInitProjects) {
        return {
          isRatingsEmpty: true,
          selectedProjectMemberRatings: {
            self: { eval: { isPending: true } },
          },
          selectedProjects: [],
        };
      }

      return {};
    });
  },

  reset: () => {
    set(() => ({
      isRatingsEmpty: true,
      selectedProjectMemberRatings: { self: { eval: { isPending: true } } },
      rankingNotes: "",
      _isInitialized: false,
    }));
  },

  _isInitialized: false,

  _initialize: () => {
    set(() => ({ _isInitialized: true }));
  },
});

export const useEvaluationPageStore = create<EvaluationPageStore>()(
  createEvaluationPageStore,
);
