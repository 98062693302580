import { useMemo } from "react";
import type { DateRange } from "react-day-picker";

import type { RatingChoice } from "@/api/constants";
import { EVALUATION_RATINGS } from "@/api/constants";
import { useGetProjectsByIds } from "@/api/projects";
import { useListUsers } from "@/api/users";
import {
  DateRangeTag,
  ProjectTag,
  RatingTag,
  UserTag,
} from "@/components/app/FilterTag";
import { formatDateRange } from "@/components/ui/DatePicker/utils";
import type { FilterSummaryProps as FilterSummaryPropsBase } from "@/components/ui/NavigationTable/TableHeaderControls";
import { cn } from "@/utils/ui";

import type { FormData } from "./FilterForm/utils";

export interface FilterSummaryProps<TData>
  extends FilterSummaryPropsBase<TData, FormData> {
  className?: string;
}

export function FilterSummary<TData>({
  className,
  filters,
  form,
  table,
  ...props
}: FilterSummaryProps<TData>) {
  const { users } = useListUsers({ ordering: ["first_name", "last_name"] });

  const userList = useMemo(
    () => users?.filter((user) => filters.evaluatee?.includes(user.id)) ?? [],
    [filters, users],
  );

  const { projects: projectList } = useGetProjectsByIds(filters.project ?? []);

  const handleRemoveDateRangeClick = (column: keyof typeof filters) => {
    table.getColumn(column)?.setFilterValue({});
    form.setValue(column, {});
  };

  const handleRemoveRatingClick = (
    column: keyof typeof filters,
    rating: RatingChoice,
  ) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedRatings = curr.filter((v) => v !== EVALUATION_RATINGS[rating]);
    table.getColumn(column)?.setFilterValue(updatedRatings);
    form.setValue(column, updatedRatings as number[]);
  };

  const handleRemoveIdClick = (column: keyof typeof filters, id: number) => {
    const curr = [table.getColumn(column)?.getFilterValue()].flat();
    const updatedIds = curr.filter((v) => v !== id);
    table.getColumn(column)?.setFilterValue(updatedIds);
    form.setValue(column, updatedIds as number[]);
  };

  return (
    <div
      className={cn(
        "flex flex-wrap gap-2 border-t border-other-border px-4 py-3.5",
        className,
      )}
      {...props}
    >
      {Object.keys(filters).map(
        (label) =>
          ({
            rating: (filters.rating ?? []).map((filterValue) => {
              const rating = Object.entries(EVALUATION_RATINGS).find(
                ([_, v]) => v === filterValue,
              )?.[0] as RatingChoice | undefined;

              return rating ? (
                <RatingTag
                  key={`${label}-${rating}`}
                  onRemoveClick={() => {
                    handleRemoveRatingClick("rating", rating);
                  }}
                  rating={rating}
                />
              ) : null;
            }),

            evaluatedAt: filters.evaluatedAt ? (
              <DateRangeTag
                dateRange={filters.evaluatedAt as DateRange}
                key={`${label}-${formatDateRange(filters.evaluatedAt as DateRange, "PP")}`}
                onRemoveClick={() => {
                  handleRemoveDateRangeClick("evaluatedAt");
                }}
              />
            ) : null,

            evaluatee: (filters.evaluatee ?? []).map((userId) => {
              const user = userList.find(({ id }) => id === userId);

              return user ? (
                <UserTag
                  key={`${label}-${userId}`}
                  onRemoveClick={({ id }) => {
                    handleRemoveIdClick("evaluatee", id);
                  }}
                  user={user}
                />
              ) : null;
            }),

            project: (filters.project ?? []).map((projectId) => {
              const project = projectList.find(({ id }) => id === projectId);

              return project ? (
                <ProjectTag
                  key={`${label}-${projectId}`}
                  onRemoveClick={({ id }) => {
                    handleRemoveIdClick("project", id);
                  }}
                  project={project}
                />
              ) : null;
            }),
          })[label],
      )}
    </div>
  );
}
