import type { ColumnDef } from "@tanstack/react-table";
import { Translation } from "react-i18next";

import { UserRatingInfoCell } from "../../UserRatingInfoCell";
import {
  columnHelper,
  columns as columnsBase,
  filterFn,
  type TableColumnData,
} from "../columns";

export const columns = [
  columnHelper.accessor((row) => row.fullName, {
    id: "evaluatee",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.evaluatee")}</Translation>
    ),
    cell: (info) => <UserRatingInfoCell user={info.row.original} />,
  }),
  ...columnsBase,
] as ColumnDef<TableColumnData>[];
