import { z } from "zod";

import { generatePaginatedResponseSchema } from "@/api/utils";

export const ProjectSchema = z.object({
  id: z.number(),
  isTraining: z.boolean(),
  userIds: z.number().array(),
  name: z.string(),
});

export const ProjectListResponseSchema =
  generatePaginatedResponseSchema(ProjectSchema);

export const ProjectQueryParamsSchema = z.object({
  id: z.number().array().optional(),
  limit: z.number().optional(),
  ordering: z.string().array().optional(),
  user: z.number().array().optional(),
  search: z.string().optional(),
});

export const ProjectQueryParamsSchemaTransformer =
  ProjectQueryParamsSchema.transform(({ id, ordering, ...schema }) => ({
    ...(id && { id: id.join(",") }),
    ...(ordering && { ordering: ordering.join(",") }),
    ...schema,
  }));

export type Project = z.infer<typeof ProjectSchema>;

export type BasicProject = Pick<Project, "id" | "name">;

export type BasicProjectWithUserIds = BasicProject & Pick<Project, "userIds">;

export type ProjectListResponse = z.infer<typeof ProjectListResponseSchema>;

export type ProjectQueryParams = z.infer<typeof ProjectQueryParamsSchema>;

export type ProjectQueryParamsTransformer = z.infer<
  typeof ProjectQueryParamsSchemaTransformer
>;

export const projectsKeys = {
  detail: (id: number | null) => ["api", "projects", id],
  list: (params?: ProjectQueryParams) =>
    params ? ["api", "projects", params] : ["api", "projects"],
};
