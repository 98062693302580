import { Link, useRouterState } from "@tanstack/react-router";
import { type HTMLAttributes } from "react";

import { useGetCurrentUser, useLogoutUser } from "@/api/users";
import { UserDropdownMenu } from "@/components/app/UserDropdownMenu";
import { Avatar } from "@/components/ui/Avatar";
import { Dropdown } from "@/components/ui/Dropdown";
import { cn } from "@/utils/ui";

import { logoutEvent } from "./events";

export type NavigationProps = Omit<HTMLAttributes<HTMLDivElement>, "children">;

export function Navigation({ className, ...props }: NavigationProps) {
  const { currentUser } = useGetCurrentUser();
  const { logoutUserSync } = useLogoutUser();
  const router = useRouterState();

  const handleSignoutClick = () => {
    logoutUserSync();

    logoutEvent.dispatch();
  };

  return (
    <div
      className={cn(
        "flex h-14 w-full items-center justify-between px-6 bg-white gap-20",
        className,
      )}
      {...props}
    >
      <Link
        to={
          router.location.pathname.startsWith("/admin")
            ? "/admin"
            : "/evaluations"
        }
      >
        <svg
          fill="none"
          height="2.125rem"
          viewBox="0 0 50 25"
          width="2.125rem"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M49.6 12.5H37.6V24.5H49.6V12.5Z" fill="#0C59AC" />
          <path d="M49.6 0.5L37.6 12.5V0.5H49.6Z" fill="#0C59AC" />
          <path
            d="M36.4 0.5V12.5L24.4 24.5V12.5L12.4 24.5H0.400024L24.4 0.5H36.4Z"
            fill="#0C59AC"
          />
        </svg>
      </Link>

      <div className="flex items-center gap-5">
        <Dropdown>
          <Dropdown.Trigger>
            <Avatar
              displayName={currentUser?.fullName}
              src={currentUser?.avatar ?? ""}
            />
          </Dropdown.Trigger>
          {currentUser !== null && (
            <UserDropdownMenu
              onSignoutClick={handleSignoutClick}
              user={currentUser}
            />
          )}
        </Dropdown>
      </div>
    </div>
  );
}
