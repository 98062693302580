/* eslint-disable react/no-array-index-key */
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-router";
import React, { type HTMLAttributes, type ReactNode } from "react";

import { cn } from "@/utils/ui";

interface BreadcrumbProps
  extends Omit<HTMLAttributes<HTMLOListElement>, "onClick"> {
  onClick?: (title: ReactNode, url?: string) => void;
  routes: {
    title: React.ReactNode;
    url?: string;
  }[];
}

function Breadcrumb({ className, onClick, routes, ...props }: BreadcrumbProps) {
  return (
    <ol
      className={cn(
        "flex flex-wrap items-center gap-3 text-nowrap text-body1 text-text-primary",
        className,
      )}
      {...props}
    >
      {routes.length > 0 && (
        <>
          {routes.slice(0, routes.length - 1).map((route, index) => (
            <React.Fragment key={index}>
              <li>
                <Link
                  className="break-all text-text-disabled hover:underline"
                  onClick={() => {
                    onClick?.(route.title, route.url);
                  }}
                  params={{}}
                  to={route.url}
                >
                  {route.title}
                </Link>
              </li>
              <ChevronRightIcon className="size-3 stroke-[3px]" />
            </React.Fragment>
          ))}
          <li>{routes.at(routes.length - 1)?.title}</li>
        </>
      )}
    </ol>
  );
}

export { Breadcrumb };
