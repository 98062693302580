import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { lazy, Suspense } from "react";

import { LoadingBar } from "@/components/app/LoadingBar";
import { Toaster } from "@/components/app/Toaster";
import type { RootRouterContext } from "@/utils/constants";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

function RootRoute() {
  return (
    <>
      <LoadingBar />
      <Suspense>
        <Toaster />
        <Outlet />
      </Suspense>
      <Suspense fallback={null}>
        <TanStackRouterDevtools position="bottom-left" />
      </Suspense>
    </>
  );
}

export const Route = createRootRouteWithContext<RootRouterContext>()({
  component: RootRoute,
});
