import { useTranslation } from "react-i18next";

import {
  AlertDialog,
  type AlertDialogProps,
} from "@/components/ui/AlertDialog";
import { Button } from "@/components/ui/Button";

export interface SubmitDeadlineErrorAlertDialogProps
  extends Omit<AlertDialogProps, "footer" | "title" | "variant"> {
  onConfirmClick?: () => void;
}

export function SubmitDeadlineErrorAlertDialog({
  onConfirmClick,
  ...props
}: SubmitDeadlineErrorAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      description={t("components.submitDeadlineErrorAlertDialog.description")}
      footer={
        <Button onClick={onConfirmClick}>
          {t("components.submitDeadlineErrorAlertDialog.buttonConfirm")}
        </Button>
      }
      title={t("components.submitDeadlineErrorAlertDialog.title")}
      variant="error"
      {...props}
    />
  );
}
