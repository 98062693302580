import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-router";
import type { ColumnDef, Row } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { Translation } from "react-i18next";

import type { AdminEvaluation } from "@/api/evaluations";
import { Avatar } from "@/components/ui/Avatar";
import { IconButton } from "@/components/ui/IconButton";
import { Rating } from "@/components/ui/Rating";
import { Tooltip } from "@/components/ui/Tooltip";
import { cn } from "@/utils/ui";

import { ProjectsDialog } from "./ProjectsDialog";

type FilterFn<Data> = (
  row: Row<AdminTableColumns>,
  _: unknown,
  filterValue?: Data,
) => boolean;

const filterFn: FilterFn<string> = (_row, _, _filterValue) => true;

export type AdminTableColumns = Omit<AdminEvaluation, "status">;

const columnHelper = createColumnHelper<AdminTableColumns>();

export const columns = [
  columnHelper.accessor((row) => row.evaluatee.fullName, {
    id: "evaluatee",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.dashboard.memberName")}</Translation>
    ),
    cell: (info) => {
      const evaluation = info.row.original;
      const url = "/admin/cycles/$cycleId/members/$memberId/evaluators";

      return (
        <div className="flex gap-2 text-body2 font-semibold text-text-primary max-md:mb-1 max-md:flex-col max-md:gap-1 md:h-fit md:items-center">
          <Avatar
            displayName={info.row.original.evaluatee.fullName}
            src={info.row.original.evaluatee.avatar}
          />
          <Link
            params={{
              cycleId: `${evaluation.cycle.id}`,
              memberId: `${evaluation.evaluatee.id}`,
            }}
            preload={false}
            search={(prev) => ({
              per: 0,
              ordering: [],
              isModalShown: true,
              dashboardFilters:
                "dashboardFilters" in prev ? prev.dashboardFilters : {},
            })}
            to={url}
          >
            <div className="max-w-full items-start overflow-hidden text-left">
              <div className="text-ellipsis text-subtitle2 text-text-primary">
                {info.row.original.evaluatee.fullName}
              </div>
              <div className="text-body2 text-text-secondary">
                {info.row.original.evaluatee.email}
              </div>
            </div>
          </Link>
        </div>
      );
    },
  }),

  columnHelper.accessor((row) => row.selfRating, {
    id: "selfRating",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.dashboard.selfRating")}</Translation>
    ),
    cell: (info) => {
      const ratingValue = info.row.original.selfRating ?? 0;
      return (
        <Tooltip
          content={
            ratingValue ? (
              Number(ratingValue).toFixed(2)
            ) : (
              <Translation>
                {(t) => t("admin.dashboard.emptyRating")}
              </Translation>
            )
          }
        >
          <div className="flex w-max items-center gap-1 max-md:mb-1">
            <span className="min-w-[4.5rem] text-caption text-text-secondary md:hidden">
              <Translation>
                {(t) => `${t("admin.dashboard.selfRating")}:`}
              </Translation>
            </span>
            <Rating
              buttonClassName="p-0 size-[1.125rem] md:size-5"
              className="size-auto"
              readOnly
              size="auto"
              type="continuous"
              value={ratingValue}
            />
          </div>
        </Tooltip>
      );
    },
  }),

  columnHelper.accessor((row) => row.avgRating, {
    id: "avgRating",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.dashboard.rating")}</Translation>
    ),
    cell: (info) => {
      const ratingValue = info.row.original.avgRating ?? 0;
      return (
        <Tooltip
          content={
            ratingValue ? (
              Number(ratingValue).toFixed(2)
            ) : (
              <Translation>
                {(t) => t("admin.dashboard.emptyRating")}
              </Translation>
            )
          }
        >
          <div className="flex w-max items-center gap-1 max-md:mb-1">
            <span className="min-w-[4.5rem] text-caption text-text-secondary md:hidden">
              <Translation>
                {(t) => `${t("admin.dashboard.rating")}:`}
              </Translation>
            </span>
            <Rating
              buttonClassName="p-0 size-[1.125rem] md:size-5"
              className="size-auto"
              readOnly
              size="auto"
              type="continuous"
              value={ratingValue}
            />
          </div>
        </Tooltip>
      );
    },
  }),

  columnHelper.accessor(
    (row) => row.projects.map(({ name }) => name).join(", "),
    {
      id: "projects",
      enableGlobalFilter: false,
      enableMultiSort: true,
      filterFn,
      header: () => (
        <Translation>{(t) => t("admin.dashboard.projects")}</Translation>
      ),
      cell: (info) => {
        const projects = info.row.original.projects.map(({ name }) => name);

        return (
          <div
            className={cn(
              "flex items-center gap-1 max-md:mb-1",
              projects.length === 0 && "hidden",
            )}
          >
            <span className="text-caption text-text-secondary md:hidden">
              <Translation>
                {(t) => `${t("admin.dashboard.projects")}:`}
              </Translation>
            </span>

            <div className="flex flex-wrap">
              {projects.slice(0, 2).map((project, index) => (
                <span
                  className="whitespace-nowrap text-body2 text-text-primary max-md:text-caption"
                  key={project}
                >
                  {project}
                  {(projects.length > 2 ||
                    (projects.length > 1 && index === 0)) && (
                    <span className="whitespace-pre-wrap">, </span>
                  )}
                </span>
              ))}
            </div>

            {projects.length > 2 && (
              <ProjectsDialog
                label={`+${projects.length - 2} more`}
                projects={projects}
                user={info.row.original.evaluatee}
              />
            )}
          </div>
        );
      },
    },
  ),

  columnHelper.accessor((row) => row.evaluatedAt, {
    id: "evaluatedAt",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.dashboard.dateSubmitted")}</Translation>
    ),
    cell: (info) => (
      <div className="flex w-max items-center gap-1">
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("admin.dashboard.dateSubmitted")}:`}
          </Translation>
        </span>
        <span className="text-body2 text-text-primary max-md:text-caption">
          {format(info.getValue(), "PP")}
        </span>
      </div>
    ),
  }),

  columnHelper.display({
    id: "actions",
    header: () => (
      <Translation>
        {(t) => (
          <span className="min-w-full text-right">
            {t("admin.dashboard.actions")}
          </span>
        )}
      </Translation>
    ),
    cell: (info) => {
      const evaluation = info.row.original;
      const url = "/admin/cycles/$cycleId/members/$memberId/evaluators";

      return (
        <div className="flex items-center justify-end gap-2 max-md:absolute max-md:right-3 max-md:top-1/2 max-md:-translate-y-1/2">
          <Link
            mask={{
              to: url,
              params: {
                cycleId: `${evaluation.cycle.id}`,
                memberId: `${evaluation.evaluatee.id}`,
              },
              search: (prev) => ({
                per: 0,
                ordering: [],
                dashboardFilters:
                  "dashboardFilters" in prev ? prev.dashboardFilters : {},
              }),
            }}
            params={{
              cycleId: `${evaluation.cycle.id}`,
              memberId: `${evaluation.evaluatee.id}`,
            }}
            preload={false}
            search={(prev) => ({
              per: 0,
              ordering: [],
              isModalShown: true,
              dashboardFilters:
                "dashboardFilters" in prev ? prev.dashboardFilters : {},
            })}
            to="/admin/cycles/$cycleId/members/$memberId/evaluators"
          >
            <IconButton
              icon={<ArrowRightIcon className="size-5 text-text-secondary" />}
            />
          </Link>
        </div>
      );
    },
  }),

  columnHelper.accessor((row) => row.cycle.deadline, {
    // hidden
    id: "cycle",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.dashboard.cycle")}</Translation>
    ),
    cell: (info) => (
      <span className="text-body2 text-text-primary">
        {info.row.original.cycle.name}
      </span>
    ),
  }),

  columnHelper.accessor((row) => row.evaluators.map(({ id }) => id), {
    // hidden
    id: "evaluators",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
  }),
] as ColumnDef<AdminEvaluation>[];
