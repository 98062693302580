import { format } from "date-fns";
import { decamelize } from "humps";
import { z } from "zod";

import { type AdminEvaluationQueryParams } from "@/api/evaluations";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

import { formSchema } from "./AdminFilterForm/utils";

export const formToFiltersTransformer = formSchema.transform(
  ({ evaluatedAt, ...schema }) => ({
    ...schema,
    ...(evaluatedAt?.from && {
      evaluatedAtAfter: format(evaluatedAt.from, "yyyy-MM-dd"),
    }),
    ...(evaluatedAt?.to && {
      evaluatedAtBefore: format(evaluatedAt.to, "yyyy-MM-dd"),
    }),
  }),
);

export const tableStateToQueryParamsTransformer = z.object({
  dashboardFilters: TableStateBaseParamsSchema.extend({
    filters: formToFiltersTransformer.optional(),
  })
    .transform(
      ({ filters, ordering, ...schema }): AdminEvaluationQueryParams => ({
        ...filters,
        search: schema.search,
        limit: schema.per,
        offset:
          !schema.per || !schema.page ? 0 : schema.per * (schema.page - 1),
        ...(ordering && {
          ordering: ordering.map((field) =>
            decamelize(
              {
                cycle: "cycle__active_end_date",
                "-cycle": "-cycle__active_end_date",
                evaluatee: "evaluatee_full_name",
                "-evaluatee": "-evaluatee_full_name",
              }[field] || field,
            ),
          ),
        }),
      }),
    )
    .optional(),
});
