import { create } from "zustand";

import type { EvaluationRating } from "@/api/evaluationRatings";

interface ModalStore {
  evaluationDeleteConfirmModalOpen: boolean;
  evaluationRating: EvaluationRating | null;
  openDeleteDialog: (options: {
    evaluationRating: EvaluationRating | null;
  }) => void;
  setEvaluationDeleteConfirmModalOpen: (flag: boolean) => void;
  setEvaluationRating: (value: EvaluationRating) => void;
}

export const useEvaluationsDashboardModal = create<ModalStore>((set) => ({
  evaluationDeleteConfirmModalOpen: false,
  evaluationRating: null,
  openDeleteDialog: ({ evaluationRating }) => {
    set(() => ({
      evaluationRating,
      evaluationDeleteConfirmModalOpen: true,
    }));
  },
  setEvaluationDeleteConfirmModalOpen: (flag: boolean) => {
    set(() => ({ evaluationDeleteConfirmModalOpen: flag }));
  },
  setEvaluationRating: (value) => {
    set(() => ({
      evaluationRating: value,
    }));
  },
}));
