import {
  createFileRoute,
  ErrorComponent,
  Outlet,
  useLocation,
} from "@tanstack/react-router";
import { z } from "zod";

import { LoadingComponent } from "@/components/app/LoadingComponent";
import { MainLayout } from "@/components/app/MainLayout";
import { AdminDashboardPage } from "@/components/pages/AdminDashboardPage";
import { formSchema as adminFormSchema } from "@/components/pages/AdminDashboardPage/AdminFilterForm/utils";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";
import { adminRestriction, checkAuthentication } from "@/utils/auth";

function Admin() {
  const { pathname, isModalShown } = useLocation({
    select: (location) => ({
      pathname: location.pathname,
      isModalShown: location.search.isModalShown,
    }),
  });

  /**
   * This still should allow nested routes to be rendered, but without the approach of using
   * another index.tsx route definition. Otherwise, this entails rendering the AdminDashboardPage
   * twice, causing re-renders when the modal is opened
   */
  return (
    <MainLayout>
      {isModalShown || pathname === "/admin" ? (
        <AdminDashboardPage />
      ) : (
        <Outlet />
      )}
    </MainLayout>
  );
}

const routeParamsSchema = z
  .object({
    dashboardFilters: TableStateBaseParamsSchema.extend({
      filters: adminFormSchema.optional(),
    }).optional(),
    isModalShown: z.coerce.boolean().optional(),
  })
  .transform(({ dashboardFilters, ...schema }) => ({
    ...schema,
    dashboardFilters: {
      ...dashboardFilters,
      per: dashboardFilters?.per ?? 0,
      grouping: [...new Set(["cycle", ...(dashboardFilters?.grouping || [])])],
      ordering: [
        "-cycle",
        ...(dashboardFilters?.ordering?.filter(
          (field) => field !== "-cycle" && field !== "cycle",
        ) || []),
      ],
    },
  }));

export type RouteParams = z.infer<typeof routeParamsSchema>;

export const Route = createFileRoute("/admin")({
  component: Admin,
  errorComponent: ErrorComponent,
  pendingComponent: LoadingComponent,
  validateSearch: (search): z.input<typeof routeParamsSchema> =>
    routeParamsSchema.parse(search),
  beforeLoad: ({ context, location }) => {
    checkAuthentication(location.href, context.auth);
    adminRestriction(context.auth);
    return { routeId: "admin" };
  },
});
