import { useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";

import type { QueryOptions } from "../constants";
import { api, handleApiError, validateSchema } from "../utils";
import { EvaluationSchema, evaluationsKeys } from "./constants";

export const getEvaluationById = async (id: number) => {
  const response = await api.get(`evaluations/${id}`);

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationSchema, data);
};

export const useGetEvaluationById = (
  id: number,
  options: QueryOptions = { enabled: true },
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled: options.enabled,
    queryFn: () => getEvaluationById(id),
    queryKey: evaluationsKeys.detail(id),
  });

  return { evaluation: data || null, isFetched, isFetching };
};
