import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { type HTMLAttributes, useMemo, useState } from "react";

import { cn } from "@/utils/ui";

import { IconButton } from "../IconButton";
import { getPaginationVisiblePages } from "./utils";

export interface PaginationProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "onChange"> {
  boundaryCount?: number;
  defaultValue?: number;
  onChange?: (page: number, prev: number) => void;
  onNextPageClick?: () => void;
  onPrevPageClick?: () => void;
  pageCount: number;
  siblingCount?: number;
  value?: number;
}

export function Pagination({
  boundaryCount = 1,
  className,
  defaultValue = 1,
  onChange,
  onNextPageClick,
  onPrevPageClick,
  pageCount,
  siblingCount = 1,
  value,
  ...props
}: PaginationProps) {
  const [internalValue, setInternalValue] = useState(defaultValue);

  const finalValue = value ?? internalValue;
  const hasNext = finalValue < pageCount;
  const hasPrev = finalValue > 1;

  const visiblePageNumbers = useMemo(
    () =>
      getPaginationVisiblePages({
        boundaryCount,
        page: finalValue,
        pageCount,
        siblingCount,
      }),
    [boundaryCount, finalValue, pageCount, siblingCount],
  );

  const handlePageClick = (page: number) => {
    setInternalValue(page);
    onChange?.(page, internalValue);
  };

  const handleNextPageClick = () => {
    setInternalValue((page) => page + 1);
    onNextPageClick?.();
    onChange?.(finalValue + 1, finalValue);
  };

  const handlePrevPageClick = () => {
    setInternalValue((page) => page - 1);
    onPrevPageClick?.();
    onChange?.(finalValue - 1, finalValue);
  };

  return (
    <div
      className={cn(
        "flex items-center divide-x divide-other-divider rounded-md border border-other-divider",
        className,
      )}
      {...props}
    >
      <IconButton
        className={cn(
          "group relative h-[2.1875rem] rounded-l-md rounded-r-none p-2",
          "enabled:hover:bg-primary-background enabled:hover:text-primary-main disabled:bg-actions-disabled-background",
        )}
        disabled={!hasPrev}
        icon={<ChevronLeftIcon className="size-[1.0625rem]" />}
        onClick={handlePrevPageClick}
        size="custom"
      />
      {visiblePageNumbers.map((pageRange) => {
        if (Array.isArray(pageRange)) {
          return pageRange.map((n) => (
            <button
              className={cn(
                "relative h-[2.1875rem] w-10 select-none text-caption text-text-secondary hover:bg-primary-background hover:text-primary-main",
                "after:absolute after:left-0 after:top-0 after:z-[1] after:h-full after:w-full after:outline-1 after:outline-offset-0 after:outline-transparent after:content-[''] after:[outline-style:solid]",
                "data-[state=active]:bg-primary-background data-[state=active]:text-primary-main data-[state=active]:after:outline-primary-border",
              )}
              data-state={n === finalValue ? "active" : undefined}
              key={n}
              onClick={() => {
                handlePageClick(n);
              }}
              type="button"
            >
              {n}
            </button>
          ));
        }

        return (
          <span
            className="flex h-[2.1875rem] w-10 select-none items-center justify-center text-caption text-text-secondary"
            key={pageRange.key}
          >
            {pageRange.separator}
          </span>
        );
      })}
      <IconButton
        className={cn(
          "group relative h-[2.1875rem] rounded-l-none rounded-r-md p-2",
          "enabled:hover:bg-primary-background enabled:hover:text-primary-main disabled:bg-actions-disabled-background",
        )}
        disabled={!hasNext}
        icon={<ChevronRightIcon className="size-[1.0625rem]" />}
        onClick={handleNextPageClick}
        size="custom"
      />
    </div>
  );
}
