import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { Translation } from "react-i18next";

import {
  EVALUATION_RATINGS,
  type EvaluationRating,
} from "@/api/evaluationRatings";
import { Rating } from "@/components/ui/Rating";

import type { FilterFn } from "../columns";

export const filterFn: FilterFn<string, TableColumnData> = (
  _row,
  _,
  _filterValue,
) => true;

export interface TableColumnData extends EvaluationRating {
  isPreviousCycle: boolean | null;
}

export const columnHelper = createColumnHelper<TableColumnData>();

export const columns = [
  columnHelper.accessor((row) => row.evaluation.cycle.name, {
    id: "cycle",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.cycle")}</Translation>
    ),
    cell: (info) => (
      <span className="text-body2 text-text-primary">{info.getValue()}</span>
    ),
  }),

  columnHelper.accessor(
    (row) => (!row.rating ? 0 : EVALUATION_RATINGS[row.rating]),
    {
      id: "rating",
      enableGlobalFilter: false,
      enableMultiSort: true,
      filterFn,
      header: () => (
        <Translation>{(t) => t("admin.filterForm.rating")}</Translation>
      ),
      cell: (info) => (
        <Rating
          buttonClassName="p-0 size-6"
          className="flex size-auto items-center p-0"
          readOnly
          size="medium"
          value={info.getValue()}
        />
      ),
    },
  ),

  columnHelper.accessor((row) => row.ratingNotes, {
    id: "ratingNotes",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.review")}</Translation>
    ),
    cell: (info) => (
      <span className="whitespace-pre-line text-body2 text-text-primary">
        {info.getValue()}
      </span>
    ),
  }),

  columnHelper.accessor((row) => row.evaluation.evaluatedAt, {
    id: "evaluatedAt",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("admin.filterForm.dateSubmitted")}</Translation>
    ),
    cell: (info) => (
      <span className="text-body2 text-text-primary">
        {info.getValue()
          ? format(info.getValue() || new Date(), "MMM dd, yyyy")
          : "N/A"}
      </span>
    ),
  }),

  columnHelper.accessor((row) => row.isPreviousCycle, {
    id: "isPreviousCycle",
  }),
] as ColumnDef<TableColumnData>[];
