import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export const formSchema = z.object({
  evaluator: z.number().array().optional(),
  project: z.number().array().optional(),
  rating: z.number().array().optional(),
  evaluatedAt: z
    .object({
      from: z.coerce.date().optional(),
      to: z.coerce.date().optional(),
    })
    .optional(),
});

export type FormData = z.infer<typeof formSchema>;

export const defaultValues: FormData = {
  evaluator: [],
  project: [],
  rating: [],
  evaluatedAt: {},
};

export const resolver = zodResolver(formSchema);
