import { useParams, useSearch } from "@tanstack/react-router";
import { useMemo } from "react";

import { useGetEvaluationCycleMemberEvaluators } from "@/api/evaluationCycles/useGetEvaluationCycleMemberEvaluators";
import { NavigationTable } from "@/components/ui/NavigationTable";
import { cn } from "@/utils/ui";

import { EmptyDashboard } from "../../EmptyDashboard";
import { columns } from "./columns";
import { FilterForm } from "./FilterForm";
import { defaultValues, formSchema } from "./FilterForm/utils";
import { FilterSummary } from "./FilterSummary";
import { tableStateToQueryParamsTransformer } from "./utils";

export interface EvaluatorsTabProps {
  className?: string;
}

export function EvaluatorsTab({ className, ...props }: EvaluatorsTabProps) {
  const from = "/admin/cycles/$cycleId/members/$memberId/evaluators";
  const { cycleId, memberId } = useParams({ from });
  const search = useSearch({ from });
  const evaluateesQueryParams =
    tableStateToQueryParamsTransformer.parse(search);

  const { evaluators, isFetched } = useGetEvaluationCycleMemberEvaluators(
    Number(cycleId),
    Number(memberId),
    evaluateesQueryParams,
  );

  const data = useMemo(() => evaluators?.results ?? [], [evaluators]);

  return (
    <NavigationTable
      className={cn("flex-auto", className)}
      columns={columns}
      data={data}
      defaultFilterFormValues={defaultValues}
      formSchema={formSchema}
      isFetching={!isFetched}
      placeholder={Boolean(isFetched) && <EmptyDashboard />}
      renderFilterForm={FilterForm}
      renderFilterSummary={FilterSummary}
      {...props}
    />
  );
}
