import {
  type DetailedHTMLProps,
  type FormHTMLAttributes,
  useMemo,
} from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { EvaluationCycle } from "@/api/evaluationCycles";
import {
  MultiSelectField,
  RatingSelectField,
} from "@/components/app/FormFields";
import { Button } from "@/components/ui/Button";
import { Form } from "@/components/ui/Form";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { DateSubmittedField } from "./DateSubmittedField";
import type { FormData as SelfEvaluationFilterFormData } from "./utils";

export interface FilterFormProps
  extends Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    "children" | "onSubmit"
  > {
  resetDisabled?: boolean;
  form: UseFormReturn<SelfEvaluationFilterFormData>;
  onClear?: () => boolean | undefined;
  onSubmit: (formData: SelfEvaluationFilterFormData) => void;
  submitDisabled?: boolean;
  evaluationCycles?: EvaluationCycle[];
}

export function FilterForm({
  className,
  resetDisabled,
  form,
  onClear,
  onSubmit,
  submitDisabled,
  evaluationCycles = [],
  ...props
}: FilterFormProps) {
  const { t } = useTranslation();
  const { maxMd } = useScreenSize();
  const screenType = maxMd ? "mobile" : "default";

  const cycleOptions = useMemo(
    () =>
      evaluationCycles.map((cycle) => ({
        label: cycle.name,
        value: cycle.id,
      })),
    [evaluationCycles],
  );

  return (
    <Form {...form}>
      <form
        className={cn("space-y-[0.625rem]", className)}
        onSubmit={(e) => {
          void form.handleSubmit(onSubmit)(e);
        }}
        {...props}
      >
        <div className="text-h6 text-text-primary">
          {t("admin.filterForm.filters")}
        </div>
        <div className="h-max space-y-6 pb-6">
          <MultiSelectField
            control={form.control}
            label={t("components.evaluationFilterForm.evaluationCycle")}
            name="cycle"
            options={cycleOptions}
            placeholder={t(
              `components.evaluationFilterForm.placeholderSelectCycle.${screenType}`,
            )}
          />
          <RatingSelectField
            control={form.control}
            label={t("admin.filterForm.rating")}
            name="rating"
          />
          <DateSubmittedField
            control={form.control}
            label={t("admin.filterForm.dateSubmitted")}
            name="evaluatedAt"
          />
        </div>
        <div className="flex gap-[0.625rem]">
          <Button
            className="flex-auto"
            disabled={resetDisabled}
            onClick={onClear}
            type="reset"
            variant="outlined"
          >
            {t("admin.filterForm.buttonResetFilters")}
          </Button>
          <Button className="flex-auto" disabled={submitDisabled} type="submit">
            {t("admin.filterForm.buttonApplyFilters")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
