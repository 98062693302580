import { useTranslation } from "react-i18next";

import {
  ConfirmDialog,
  type ConfirmDialogProps,
} from "@/components/ui/ConfirmDialog";

export interface DeleteEvaluationConfirmDialogProps
  extends Omit<
    ConfirmDialogProps,
    "description" | "footerButtons" | "title" | "variant"
  > {
  isLoading?: boolean;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
}

export function DeleteEvaluationDraftConfirmDialog({
  isLoading,
  onCancelClick,
  onConfirmClick,
  ...props
}: DeleteEvaluationConfirmDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      description={t("components.discardDraftConfirmDialog.description")}
      footerButtons={[
        {
          action: "cancel",
          disabled: isLoading,
          label: t("components.discardDraftConfirmDialog.buttonCancel"),
          onClick: onCancelClick,
          variant: "outlined",
        },
        {
          action: "submit",
          disabled: isLoading,
          label: t("components.discardDraftConfirmDialog.buttonConfirm"),
          onClick: onConfirmClick,
        },
      ]}
      title={
        <span className="text-h6">
          {t("components.discardDraftConfirmDialog.title")}
        </span>
      }
      variant="danger"
      {...props}
    />
  );
}
