import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { camelizeKeys } from "humps";

import { api, handleApiError, validateSchema } from "../utils";
import {
  type CreateEvaluationCycleBody,
  type EvaluationCycle,
  EvaluationCycleSchema,
  evaluationCyclesKeys,
} from "./constants";

export const createEvaluationCycle = async (
  body?: CreateEvaluationCycleBody,
) => {
  const response = await api.post("evaluation-cycles/", {
    json: body && {
      periodStartDate: format(body.periodStartDate, "yyyy-MM-dd"),
      periodEndDate: format(body.periodEndDate, "yyyy-MM-dd"),
      deadline: format(body.deadline, "yyyy-MM-dd"),
    },
  });

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleSchema, data);
};

export const useCreateEvaluationCycle = ({
  onSuccess,
}: {
  onSuccess?: (
    data: EvaluationCycle | undefined,
    variables: CreateEvaluationCycleBody,
    context: unknown,
  ) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, ...mutation } = useMutation({
    mutationFn: (body: CreateEvaluationCycleBody) =>
      createEvaluationCycle(body),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: evaluationCyclesKeys.list(),
      });

      onSuccess?.(data, variables, context);
    },
    mutationKey: evaluationCyclesKeys.list(),
  });

  return {
    ...mutation,
    create: mutateAsync,
    createSync: mutate,
  };
};
