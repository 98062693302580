import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { checkAuthentication } from "@/utils/auth";

const loginSearchSchema = z.object({
  // Since using router.invalidate() seems to not synchronize properly with the update
  // of the context, allow the context to be deliberately ignored on this page so that
  // it will be overriden upon login. Otherwise, at this point, the context may
  // still "have" a stale value.
  ignoreAuth: z.boolean().optional(),
  redirect: z.string().optional(),
});

export const Route = createFileRoute("/login")({
  validateSearch: (search) => loginSearchSchema.parse(search),
  beforeLoad: ({ context, location, search }) => {
    checkAuthentication(
      location.href,
      search.ignoreAuth ? undefined : context.auth,
      true,
      search.redirect,
    );
  },
});
