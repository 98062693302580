import type { WithRequired } from "@tanstack/react-query";
import { z } from "zod";

import { generatePaginatedResponseSchema } from "@/api/utils";

import {
  BasicEvaluationRatingSchema,
  BasicUserSchema,
  EvaluationStatus,
  RatingChoice,
} from "../constants";
import {
  AdminEvaluationCycleSchema,
  EvaluationCycleSchema,
} from "../evaluationCycles/constants";

export const EvaluationDraftDataSchema = z.object({
  rankingNotes: z.string().optional(),
  ratings: z
    .object({
      evaluateeId: z.number().optional(),
      projectId: z.number().optional(),
      projectName: z.string().optional(),
      rating: z.nativeEnum(RatingChoice).or(z.literal("")).optional(),
      ratingNotes: z.string().optional(),
    })
    .array()
    .optional(),
  version: z.number(),
});

export const EvaluationSchema = z.object({
  id: z.number(),
  draftData: EvaluationDraftDataSchema.nullable().optional(),
  cycle: EvaluationCycleSchema,
  evaluatedAt: z.string().nullable(),
  rankingNotes: z.string(),
  ratings: BasicEvaluationRatingSchema.array(),
  status: z.nativeEnum(EvaluationStatus),
  updatedAt: z.string().nullable(),
});

export const EvaluationListResponseSchema = generatePaginatedResponseSchema(
  EvaluationSchema.omit({ updatedAt: true }),
);

export const EvaluationSubmitResponseSchema = EvaluationSchema.omit({
  cycle: true,
  status: true,
  updatedAt: true,
});

export const EvaluationQueryParamsSchema = z.object({
  status: z.nativeEnum(EvaluationStatus).optional(),
});

export const EvaluationSubmitPayloadSchema = z.object({
  isDraft: z.boolean(),
  ratings: z
    .object({
      id: z.number().optional(),
      evaluateeId: z.number().optional(),
      projectId: z.number().optional(),
      rating: z.nativeEnum(RatingChoice),
      ratingNotes: z.string(),
    })
    .array(),
  rankingNotes: z.string(),
});

export const ReceivedEvaluationSchema = z.object({
  id: z.number(),
  cycle: EvaluationCycleSchema,
  evaluatedAt: z.string().nullable(),
  evaluator: BasicUserSchema,
  rankingNotes: z.string(),
});

export const ReceivedEvaluationListResponseSchema =
  generatePaginatedResponseSchema(ReceivedEvaluationSchema);

export type Evaluation = z.infer<typeof EvaluationSchema>;

export type EvaluationListResponse = z.infer<
  typeof EvaluationListResponseSchema
>;

export type EvaluationSubmitResponse = z.infer<
  typeof EvaluationSubmitResponseSchema
>;

export type EvaluationQueryParams = z.infer<typeof EvaluationQueryParamsSchema>;

export type EvaluationSubmitPayload = z.infer<
  typeof EvaluationSubmitPayloadSchema
>;

export type EvaluationDraftData = z.infer<typeof EvaluationDraftDataSchema>;

export type ReceivedEvaluation = z.infer<typeof ReceivedEvaluationSchema>;

export type ReceivedEvaluationListResponse = z.infer<
  typeof ReceivedEvaluationListResponseSchema
>;

export const AdminEvaluationsSchema = z
  .object({
    id: z.number(),
    avgRating: z.number().nullable(),
    cycle: AdminEvaluationCycleSchema,
    draftData: EvaluationDraftDataSchema.nullable().optional(),
    evaluatedAt: z.coerce.date(),
    evaluateeAvatar: z.string(),
    evaluateeEmail: z.string(),
    evaluateeFullName: z.string(),
    evaluateeId: z.number(),
    evaluatorCount: z.number(),
    evaluators: z.object({ id: z.number(), fullName: z.string() }).array(),
    projects: z.object({ id: z.number(), name: z.string() }).array(),
    selfRating: z.number().nullable(),
  })
  .transform(
    ({
      evaluateeAvatar,
      evaluateeEmail,
      evaluateeFullName,
      evaluateeId,
      ...schema
    }) => ({
      ...schema,
      evaluatee: {
        id: evaluateeId,
        avatar: evaluateeAvatar,
        email: evaluateeEmail,
        fullName: evaluateeFullName,
      },
    }),
  );

export const AdminEvaluationsListResponseSchema =
  generatePaginatedResponseSchema(
    AdminEvaluationsSchema.transform(({ draftData: _, ...schema }) => schema),
  );

export type AdminEvaluation = z.infer<typeof AdminEvaluationsSchema>;

export type AdminEvaluationsListResponse = z.infer<
  typeof AdminEvaluationsListResponseSchema
>;

export const AdminEvaluationQueryParamsSchema = z.object({
  avgRating: z.number().array().optional(),
  cycle: z.number().array().optional(),
  evaluatedAtAfter: z.string().optional(),
  evaluatedAtBefore: z.string().optional(),
  evaluatee: z.number().array().optional(),
  evaluators: z.number().array().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  ordering: z.string().array().optional(),
  projects: z.number().array().optional(),
  search: z.string().optional(),
  selfRating: z.number().array().optional(),
});

export const AdminEvaluationQueryParamsSchemaTransformer =
  AdminEvaluationQueryParamsSchema.transform(
    ({ evaluatee, evaluators, ordering, projects, ...schema }) => ({
      ...(evaluatee && { evaluatee: evaluatee.join(",") }),
      ...(evaluators && { evaluator: evaluators.join(",") }),
      ...(projects &&
        projects.length > 0 && { project: `{${projects.toString()}}` }),
      ...(ordering && { ordering: ordering.join(",") }),
      ...schema,
    }),
  );

export type AdminEvaluationQueryParams = z.infer<
  typeof AdminEvaluationQueryParamsSchema
>;

export type AdminEvaluationQueryParamsTransformer = z.infer<
  typeof AdminEvaluationQueryParamsSchemaTransformer
>;

export const AdminEvaluationDetailSchema = z.object({
  id: z.number(),
  avgRating: z.number().nullable(),
  cycle: EvaluationCycleSchema,
  evaluatedAt: z.coerce.date(),
  evaluateeCount: z.number().nullable(),
  evaluator: BasicUserSchema,
  evaluatorCount: z.number().nullable(),
  projectList: z.array(z.string()),
  projectCount: z.number().nullable(),
  selfRating: z.number().nullable(),
});

export type AdminEvaluationDetail = z.infer<typeof AdminEvaluationDetailSchema>;

export const evaluationsKeys = {
  current: ["api", "evaluations", "current"],
  detail: (id: number) => ["api", "evaluations", id],
  adminDetail: (
    cycleId: number | undefined,
    evaluationId: number | undefined,
    evaluatorId: number | undefined,
  ) => ["api", "adminEvaluations", cycleId, evaluationId, evaluatorId],
  list: (params?: AdminEvaluationQueryParams) =>
    params ? ["api", "evaluations", params] : ["api", "evaluations"],
};

export const EvaluationDraftRatingUser = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  avatar: z.string(),
  email: z.string(),
  fullName: z.string(),
});

export const EvaluationDraftRatingSchema = z.object({
  evaluateeId: z.number().optional(),
  isSelfEvaluation: z.boolean().optional(),
  projectId: z.number().optional(),
  projectName: z.string().optional(),
  rating: z.nativeEnum(RatingChoice).or(z.literal("")).optional(),
  evaluatee: BasicUserSchema.optional(),
  isPending: z.boolean(),
});

export type EvaluationDraftRating = z.infer<
  typeof EvaluationDraftRatingSchema
> & {
  evaluationId: number;
};

export type EvaluationDraftRatingValidEvaluatee = WithRequired<
  EvaluationDraftRating,
  "evaluatee" | "evaluateeId"
>;

export const EvaluationDraftSchema = z
  .object({
    id: z.number(),
    evaluator: EvaluationDraftRatingUser,
    cycle: z.number(),
    cycleName: z.string(),
    draftData: EvaluationDraftDataSchema.extend({
      ratings: EvaluationDraftRatingSchema.array(),
    }).nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
  })
  .transform(({ draftData, ...data }) => ({
    ...data,
    draftData: draftData
      ? {
          ...draftData,
          ratings: draftData.ratings.map((rating) => ({
            evaluationId: data.id,
            ...rating,
          })),
          ratingsWithEvaluatee: draftData.ratings
            .filter(
              (rating) =>
                Boolean(rating.evaluateeId) && Boolean(rating.evaluatee),
            )
            .map((rating) => ({
              evaluationId: data.id,
              ...rating,
            })) as EvaluationDraftRatingValidEvaluatee[],
        }
      : undefined,
  }));

export type EvaluationDraft = z.infer<typeof EvaluationDraftSchema>;

export const EvaluationDraftQueryParamsSchema = z
  .object({
    isDraft: z.boolean(),
    cycle: z.number().array(),
    projects: z.number().array(),
    search: z.string(),
  })
  .partial();

export type EvaluationDraftQueryParams = z.infer<
  typeof EvaluationDraftQueryParamsSchema
>;

export const EvaluationDraftListResponseSchema =
  generatePaginatedResponseSchema(EvaluationDraftSchema);

export const evaluationDraftsKeys = {
  detail: (id: number) => ["api", "ownEvaluations", id],
  list: (params?: EvaluationDraftQueryParams) =>
    params ? ["api", "ownEvaluations", params] : ["api", "ownEvaluations"],
};
