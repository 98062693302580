import type {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import { z } from "zod";

export const SEARCH_DEBOUNCE_TIME = 1000;

export const TableStateBaseParamsSchema = z.object({
  grouping: z.string().array().optional(),
  per: z.number().optional(),
  page: z.number().optional(),
  ordering: z.string().array().optional(),
  search: z.string().optional(),
  selected: z.number().array().optional(),
});

export type TableStateBaseParams = z.infer<typeof TableStateBaseParamsSchema>;

export const filteringToColumnFilterState = (
  filtering: Record<string, unknown> | undefined,
): ColumnFiltersState =>
  filtering
    ? Object.entries(filtering).map(([id, value]) => ({ id, value }))
    : [];

export const columnFilterStateToFiltering = (
  columnFilters: ColumnFiltersState | undefined,
): Record<string, unknown> | undefined =>
  columnFilters?.reduce((cols, col) => ({ ...cols, [col.id]: col.value }), {});

export const orderingToSortingState = (
  ordering: string[] | undefined,
): SortingState =>
  ordering?.map((col) => {
    const desc = col.startsWith("-");
    const id = desc ? col.slice(1) : col;
    return { id, desc };
  }) ?? [];

export const sortingStateToOrdering = (
  sorting: SortingState | undefined,
): string[] | undefined =>
  sorting && sorting.length > 0
    ? sorting.map((col) => (col.desc ? `-${col.id}` : col.id))
    : undefined;

export const selectionStateToSelectionList = (
  rowSelections: RowSelectionState | undefined,
): number[] | undefined =>
  rowSelections
    ? Object.entries(rowSelections)
        .filter(([_, flag]) => flag)
        .map(([id]) => Number.parseInt(id))
        .sort((a, b) => a - b)
    : undefined;

export const selectionListToSelectionState = (
  selected: number[] | undefined,
): RowSelectionState =>
  selected ? Object.fromEntries(selected.map((id) => [`${id}`, true])) : {};
