import { CheckIcon } from "@heroicons/react/16/solid";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { cn } from "@/utils/ui";

export interface DraftSaveSuccessToastProps {
  className?: string;
  onViewDraftClick?: () => void;
  showDraftViewButton?: boolean;
}

export function DraftSaveSucessToast({
  className,
  onViewDraftClick,
  showDraftViewButton = true,
}: DraftSaveSuccessToastProps) {
  const { t } = useTranslation();

  return (
    <div className={cn("flex items-center gap-6 min-w-fit", className)}>
      <div className="flex items-center gap-3">
        <div className="rounded-full bg-success-main p-1">
          <CheckIcon className="size-3 text-primary-contrast-text" />
        </div>
        <p className="whitespace-nowrap text-body1">
          {t("evaluations.create.toastMessageDraftSaved")}
        </p>
      </div>
      {Boolean(showDraftViewButton) && (
        <Button
          asChild
          isLink
          onClick={onViewDraftClick}
          size="small"
          variant="outlined"
        >
          <Link to="/evaluations/drafts">
            {t("evaluations.create.toastButtonViewDraft")}
          </Link>
        </Button>
      )}
    </div>
  );
}
