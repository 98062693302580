import { useQuery } from "@tanstack/react-query";

import { api, handleApiError, validateSchema } from "../utils";
import { UserSchema, usersKeys } from "./constants";

export function useGetCurrentUser() {
  const { data, isFetched, isFetching } = useQuery({
    queryKey: usersKeys.current,
    queryFn: async () => {
      const response = await api.get("users/me/");

      await handleApiError(response, { ignoredErrorCodes: [403] });

      if (response.status === 403) {
        return null;
      }

      const json = await response.json();

      return validateSchema(UserSchema, json);
    },
    meta: {
      errorMessage: "Failed to verify authentication.",
    },
  });

  return {
    currentUser: data || null,
    isAuthenticated: Boolean(data),
    isFetched,
    isFetching,
  };
}
