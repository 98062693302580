import { PlusIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { type KeyboardEventHandler, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import { CreateEvaluationCycleSchema } from "@/api/evaluationCycles";
import { AlertDialog } from "@/components/ui/AlertDialog";
import { Button } from "@/components/ui/Button";
import { Dialog } from "@/components/ui/Dialog";

import { CreateCycleForm } from "./CreateCycleForm";

export function CreateCycleDialog() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const form = useForm<z.infer<typeof CreateEvaluationCycleSchema>>({
    resolver: zodResolver(CreateEvaluationCycleSchema),
  });

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleOpenClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleOpenAutoFocus = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key !== "Escape") return;
      setIsOpen(false);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);

    form.resetField("periodStartDate");
    form.resetField("periodEndDate");
    form.resetField("deadline");
  }, [form]);

  const handleCreateSuccess = useCallback(() => {
    handleClose();

    setIsSuccessDialogOpen(true);
  }, [handleClose]);

  return (
    <>
      <AlertDialog
        className="lg:w-[34.5rem]"
        description={t("components.createCycleAlertDialog.description")}
        footer={
          <Button onClick={handleCloseSuccessDialog}>
            {t("components.createCycleAlertDialog.backToDashboard")}
          </Button>
        }
        open={isSuccessDialogOpen}
        title={t("components.createCycleAlertDialog.title")}
        variant="success"
      />

      <Dialog open={isOpen}>
        <Dialog.Trigger asChild>
          <Button
            onClick={handleOpenClick}
            startIcon={<PlusIcon className="size-4" />}
          >
            {t("components.createCycleForm.createCycle")}
          </Button>
        </Dialog.Trigger>
        <Dialog.Content
          onKeyDown={handleKeyDown}
          onOpenAutoFocus={handleOpenAutoFocus}
          onPointerDownOutside={handleClose}
        >
          <CreateCycleForm
            form={form}
            onClose={handleClose}
            onCreateSuccess={handleCreateSuccess}
          />
        </Dialog.Content>
      </Dialog>
    </>
  );
}
