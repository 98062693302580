import { CalendarIcon } from "@heroicons/react/24/solid";
import { format, parse } from "date-fns";
import React, {
  type FocusEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  type DayClickEventHandler,
  DayPicker,
  type DayPickerSingleProps,
  useInput,
} from "react-day-picker";
import { useTranslation } from "react-i18next";

import type { TextInput } from "@/components/ui/TextInput";

import { CustomCaption } from "./CustomCaption";
import { PopoverInput } from "./PopoverInput";

export interface DatePickerProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof TextInput>,
    "defaultValue" | "onChange" | "value"
  > {
  dayPickerProps?: Omit<DayPickerSingleProps, "mode">;
  defaultValue?: Date;
  format?: string;
  isOpen?: boolean;
  onChange?: (date: Date | undefined) => void;
  onTextInputChange?: (text: string) => void;
  popoverClassName?: string;
  textInputValue?: string;
  value?: Date;
}

export const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  function DatePicker(
    {
      dayPickerProps,
      defaultValue,
      disabled: _,
      endIcon = <CalendarIcon className="cursor-pointer text-text-secondary" />,
      format: dateFormat = "PP",
      isOpen,
      onChange,
      onTextInputChange,
      placeholder,
      popoverClassName,
      textInputValue,
      value,
      ...props
    },
    ref,
  ) {
    const { t } = useTranslation();

    const [internalIsOpen, setInternalIsOpen] = useState(false);
    const finalIsOpen = isOpen ?? internalIsOpen;

    const [internalTextInputValue, setInternalTextInputValue] = useState(
      value ? format(value, dateFormat) : "",
    );
    const finalTextInputValue = textInputValue ?? internalTextInputValue;

    const {
      dayPickerProps: { month, onDayClick, selected, ...inputDayPickerProps },
      inputProps,
    } = useInput({ defaultSelected: defaultValue, format: dateFormat });
    const finalValue = value ?? selected;

    const {
      classNames,
      components,
      fromYear = 1900,
      today,
      toYear = 2099,
      ...customDayPickerProps
    } = dayPickerProps ?? {};
    const todayDate = useMemo(() => today ?? new Date(), [today]);

    const updateFormattedValue = useCallback(
      (newDate: Date | undefined) => {
        if (!newDate || isNaN(newDate.getTime())) {
          setInternalTextInputValue("");

          onTextInputChange?.("");
          onChange?.(undefined);

          return;
        }

        const newDateYear = newDate.getFullYear();

        if (newDateYear < fromYear || newDateYear > toYear) {
          const boundedDateYear = Math.max(
            fromYear,
            Math.min(toYear, newDateYear),
          );
          newDate.setFullYear(boundedDateYear);
          onDayClick?.(newDate, {}, {} as React.MouseEvent);
        }

        const updatedTextInputValue = format(newDate, dateFormat);

        setInternalTextInputValue(updatedTextInputValue);

        onTextInputChange?.(updatedTextInputValue);
        onChange?.(newDate);
      },
      [fromYear, toYear, dateFormat, onTextInputChange, onChange, onDayClick],
    );

    const handleClose = useCallback(() => {
      setInternalIsOpen(false);
    }, []);

    const handleOpen = useCallback(() => {
      setInternalIsOpen(true);
    }, []);

    const handleTextInputBlur: FocusEventHandler<HTMLInputElement> =
      useCallback(
        (e) => {
          inputProps.onBlur?.(e);
          inputProps.onChange?.(e);

          updateFormattedValue(parse(e.target.value, dateFormat, todayDate));
        },
        [dateFormat, inputProps, todayDate, updateFormattedValue],
      );

    const handleTextInputChange = useCallback(
      (text: string) => {
        onTextInputChange?.(text);
        setInternalTextInputValue(text);
      },
      [onTextInputChange],
    );

    const handleTextInputKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (e.code !== "Enter") return;
        updateFormattedValue(parse(finalTextInputValue, dateFormat, todayDate));
        setInternalIsOpen(false);
      },
      [dateFormat, finalTextInputValue, todayDate, updateFormattedValue],
    );

    const handleDayClick: DayClickEventHandler = useCallback(
      (day, modifier, e) => {
        onDayClick?.(day, modifier, e);
        updateFormattedValue(modifier.selected ? undefined : day);
        setInternalIsOpen(false);
      },
      [onDayClick, updateFormattedValue],
    );

    return (
      <PopoverInput
        containerClassName={popoverClassName}
        endIcon={endIcon}
        isOpen={finalIsOpen}
        onClickOutside={() => {
          setInternalIsOpen(false);
        }}
        onClose={handleClose}
        onEscapeKeyDown={handleTextInputKeyDown}
        onInputBlur={handleTextInputBlur}
        onInputChange={handleTextInputChange}
        onOpen={handleOpen}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        placeholder={placeholder ?? t("components.datePicker.placeholder")}
        ref={ref}
        value={finalTextInputValue}
        {...props}
      >
        <DayPicker
          classNames={{
            caption:
              "flex select-none justify-around relative items-center gap-2 py-3 px-4",
            cell: "select-none text-center",
            day: "rounded-full size-6 hover:bg-primary-background aria-selected:hover:bg-primary-dark disabled:hover:bg-transparent text-subtitle1 leading-none",
            day_hidden: "invisible",
            day_outside: "opacity-50",
            day_selected: "bg-primary-main text-white hover:bg-primary-dark",
            dropdown:
              "ring-0 gap-3.5 px-2.5 py-3 h-10 [&:not(:focus)]:enabled:hover:ring-0 data-[state=open]:ring-0",
            dropdown_month: "w-[8.75rem]",
            dropdown_year: "w-[6.25rem]",
            head: "h-10",
            head_cell: "select-none text-subtitle1 text-text-secondary",
            nav_button: "shrink-0 flex",
            row: "h-10",
            table: "w-full mb-2",
            ...classNames,
          }}
          components={{
            Caption: CustomCaption,
            ...components,
          }}
          fromYear={fromYear}
          onDayClick={handleDayClick}
          toYear={toYear}
          today={todayDate}
          {...inputDayPickerProps}
          month={finalTextInputValue ? month : undefined}
          selected={finalValue}
          {...customDayPickerProps}
        />
      </PopoverInput>
    );
  },
);
