import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import type { CheckedState } from "@radix-ui/react-checkbox";
import * as SelectPrimitive from "@radix-ui/react-select";
import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/utils/ui";

import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { Label } from "./Label";

const BaseSelect = SelectPrimitive.Root;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    className={cn(
      "group flex h-12 w-full items-center justify-between gap-1 rounded-lg ring-1 ring-other-border bg-white px-5 py-3 focus:border-transparent focus:outline-none focus:ring-[0.1875rem] focus:ring-primary-border [&:not(:focus)]:enabled:hover:border-transparent [&:not(:focus)]:enabled:hover:ring-1 [&:not(:focus)]:enabled:hover:ring-primary-main disabled:bg-actions-disabled-background disabled:ring-actions-disabled-text data-[placeholder]:text-text-hint data-[state=open]:border-transparent data-[state=open]:ring-[0.1875rem] data-[state=open]:ring-primary-border data-[state=open]:outline-none",
      className,
    )}
    ref={ref}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDownIcon className="size-4 shrink-0 text-text-hint transition-[transform] duration-300 group-data-[state=open]:-rotate-180" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    ref={ref}
    {...props}
  >
    <ChevronUpIcon className="size-4" />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    ref={ref}
    {...props}
  >
    <ChevronDownIcon className="size-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ children, className, position = "popper", ...props }, ref) => {
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        className={cn(
          "relative z-50 max-h-[17.125rem] min-w-[8rem] overflow-hidden data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          position === "popper" &&
            "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
          "rounded-lg bg-white shadow-elevation5 my-1",
          className,
        )}
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
        position={position}
        ref={ref}
        {...props}
      >
        <SelectPrimitive.Viewport
          className={cn(
            "py-2",
            position === "popper" &&
              "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)}
    ref={ref}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const defaultSelectItemClassName =
  "group relative flex w-full cursor-pointer select-none py-2 pl-4 outline-none focus:bg-primary-background data-[disabled]:pointer-events-none data-[disabled]:opacity-50";

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    className={cn(defaultSelectItemClassName, className)}
    ref={ref}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    className={cn("-mx-1 my-1 h-px bg-other-border", className)}
    ref={ref}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export interface SelectOption {
  disabled?: boolean;
  label: string;
  value: string | number;
}

interface SelectProps
  extends Omit<SelectPrimitive.SelectProps, "value">,
    Pick<SelectPrimitive.SelectValueProps, "placeholder"> {
  className?: string;
  emptyPlaceholderProps?: React.HTMLAttributes<HTMLDivElement>;
  options: SelectOption[];
  scrollSentry?: React.ReactNode;
  valueClassName?: string;
}

type SelectItemProps = Omit<
  SelectPrimitive.SelectItemProps & React.RefAttributes<HTMLDivElement>,
  "ref"
> &
  React.RefAttributes<HTMLDivElement> & {
    optionProps: SelectOption;
  };

type MultipleSelectItemProps = SelectItemProps & {
  checkboxProps: React.ComponentPropsWithoutRef<typeof Checkbox>;
};

export interface SingleSelectProps extends SelectProps {
  allowClear?: boolean;
  id?: string;
  onChange?: (value: SelectOption | null) => void;
  renderDropdown?: (originalDropdown: React.ReactNode) => React.ReactNode;
  renderItem?: ({ optionProps }: SelectItemProps) => React.ReactNode;
  scrollSentry?: React.ReactNode;
  type?: "single";
  value?: SelectOption | null;
}

export interface MultipleSelectProps extends SelectProps {
  closeOnSelect?: boolean;
  id?: string;
  onChange?: (value: SelectOption[] | null, selected: SelectOption) => void;
  renderDropdown?: (originalDropdown: React.ReactNode) => React.ReactNode;
  renderItem?: ({
    optionProps,
    checkboxProps,
  }: MultipleSelectItemProps) => React.ReactNode;
  selectClassName?: string;
  scrollSentry?: React.ReactNode;
  showSelectionTags?: boolean;
  type: "multiple";
  value?: SelectOption[] | null;
}

const SingleSelect = forwardRef<HTMLInputElement, SingleSelectProps>(
  function SingleSelect(
    {
      allowClear = true,
      className,
      disabled,
      emptyPlaceholderProps,
      id,
      name,
      onChange = () => undefined,
      options,
      placeholder = "Select",
      renderDropdown,
      renderItem,
      scrollSentry,
      value,
      valueClassName,
      ...props
    },
    ref,
  ) {
    const [internalValue, setInternalValue] =
      useState<SelectOption["value"]>("");
    const [open, setOpen] = useState(false);

    const actualValue = value?.value ?? internalValue;

    const openSelection = () => {
      setOpen(true);
    };

    const closeSelection = () => {
      setOpen(false);
    };

    const changeSelected = (selectedValue: SelectOption["value"]) => {
      if (actualValue === selectedValue && allowClear) {
        setInternalValue("");
        onChange(null);
        return;
      }

      setInternalValue(selectedValue);
      onChange(options.find((item) => item.value === selectedValue) || null);
    };

    const internalRenderItem = ({
      optionProps,
      ...itemProps
    }: SelectItemProps) => (
      <SelectItem {...itemProps}>
        <Label
          className={cn(
            "cursor-pointer text-body1 text-text-secondary group-focus:text-primary-main",
            "group-disabled:text-text-disabled",
            "group-data-[state=checked]:text-primary-contrast-text group-data-[state=checked]:group-focus:text-primary-contrast-text",
          )}
        >
          {optionProps.label}
        </Label>
      </SelectItem>
    );

    const RenderItem = renderItem ?? internalRenderItem;

    const originalDropdown = (
      <SelectContent
        onEscapeKeyDown={closeSelection}
        onPointerDownOutside={closeSelection}
        ref={ref}
      >
        {options.length > 0 ? (
          <>
            {options.map((option) => (
              <RenderItem
                className={cn(
                  defaultSelectItemClassName,
                  "data-[state=checked]:bg-primary-main data-[state=checked]:focus:bg-primary-dark data-[state=checked]:text-primary-contrast-text",
                )}
                defaultValue={props.defaultValue}
                disabled={option.disabled}
                key={option.value}
                onClick={() => {
                  changeSelected(option.value);
                  closeSelection();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    changeSelected(option.value);
                    closeSelection();
                  }
                }}
                optionProps={option}
                value={`${option.value}`}
              />
            ))}
            {scrollSentry}
          </>
        ) : (
          <EmptyPlaceholder {...emptyPlaceholderProps} />
        )}
      </SelectContent>
    );

    const dropdown = renderDropdown?.(originalDropdown) ?? originalDropdown;

    return (
      <>
        {Boolean(name) && (
          <input
            id={id}
            name={name}
            ref={ref}
            type="hidden"
            value={actualValue}
          />
        )}
        <BaseSelect
          disabled={disabled}
          open={open}
          value={`${actualValue}`}
          {...props}
        >
          <SelectTrigger
            className={className}
            onClick={openSelection}
            onKeyDown={(e) => {
              (e.key === "Enter" || e.key === " ") && openSelection();
            }}
          >
            <SelectValue asChild placeholder={placeholder}>
              <span
                className={cn(
                  "truncate text-body1 text-text-primary",
                  "group-disabled:text-text-disabled",
                  valueClassName,
                )}
              >
                {options.find((item) => item.value === actualValue)?.label}
              </span>
            </SelectValue>
          </SelectTrigger>

          {dropdown}
        </BaseSelect>
      </>
    );
  },
);

const MultipleSelect = forwardRef<HTMLInputElement, MultipleSelectProps>(
  function MultipleSelect(
    {
      className,
      closeOnSelect = true,
      disabled,
      emptyPlaceholderProps,
      id,
      name,
      onChange = () => undefined,
      options,
      placeholder = "Select",
      renderDropdown,
      renderItem,
      scrollSentry,
      selectClassName,
      showSelectionTags = true,
      value,
      valueClassName,
      ...props
    },
    ref,
  ) {
    const [internalValue, setInternalValue] = useState(
      value ?? Array<SelectOption>(),
    );
    const [open, setOpen] = useState(false);

    const selected = value ?? internalValue;
    const actualValue = selected.map((option) => `${option.value}`).join(",");

    const openSelection = () => {
      setInternalValue(selected);
      setOpen(true);
    };

    const closeSelection = () => {
      setOpen(false);
    };

    const changeSelected = (
      option: SelectOption,
      checked?: CheckedState | undefined,
    ) => {
      if (checked || !selected.find((item) => item.value === option.value)) {
        setInternalValue((prev) => {
          const newValue = [option, ...prev];
          onChange(newValue, option);

          return newValue;
        });
        return;
      }

      const newValue = selected.filter((item) => item.value !== option.value);
      setInternalValue(newValue);
      onChange(newValue, option);
    };

    const removeSelected = (option: SelectOption) => {
      const newValue = selected.filter((item) => item.value !== option.value);
      setInternalValue(newValue);
      onChange(newValue, option);
    };

    const handleKeyPressOnItem = (
      event: React.KeyboardEvent<HTMLDivElement>,
      option: SelectOption,
    ) => {
      if (event.key === "Enter") {
        changeSelected(option);

        if (!closeOnSelect) return;

        closeSelection();
      }
    };

    const internalRenderItem = ({
      optionProps,
      checkboxProps,
      ...itemProps
    }: MultipleSelectItemProps) => {
      return (
        <SelectItem {...itemProps}>
          <div className="flex items-center gap-[0.6875rem]">
            <Checkbox {...checkboxProps} />
            <Label
              className={cn(
                "cursor-pointer text-body1 text-text-secondary group-focus:text-primary-main",
                checkboxProps.checked &&
                  "text-primary-contrast-text group-focus:text-primary-contrast-text",
              )}
            >
              {optionProps.label}
            </Label>
          </div>
        </SelectItem>
      );
    };

    const RenderItem = renderItem ?? internalRenderItem;

    const originalDropdown = (
      <SelectContent
        onEscapeKeyDown={closeSelection}
        onPointerDownOutside={closeSelection}
        ref={ref}
      >
        {options.length > 0 ? (
          <>
            {options.map((option) => {
              const isSelected = Boolean(
                selected.find((item) => item.value === option.value),
              );
              return (
                <RenderItem
                  checkboxProps={{
                    checked: isSelected,
                    className:
                      "group-focus:border-primary-main group-focus:data-[state=checked]:text-primary-dark",
                    defaultChecked: isSelected,
                    disabled: option.disabled,
                    onCheckedChange: (checked) => {
                      changeSelected(option, checked);
                    },
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                    variant: "inverted",
                  }}
                  className={cn(
                    defaultSelectItemClassName,
                    "flex items-center gap-[0.6875rem]",
                    isSelected &&
                      "bg-primary-main focus:bg-primary-dark text-primary-contrast-text",
                  )}
                  disabled={option.disabled}
                  key={option.value}
                  onClick={() => {
                    changeSelected(option);

                    if (!closeOnSelect) return;

                    closeSelection();
                  }}
                  onKeyDown={(e) => {
                    handleKeyPressOnItem(e, option);
                  }}
                  optionProps={option}
                  value={`${option.value}`}
                />
              );
            })}
            {scrollSentry}
          </>
        ) : (
          <EmptyPlaceholder {...emptyPlaceholderProps} />
        )}
      </SelectContent>
    );

    const dropdown = renderDropdown?.(originalDropdown) ?? originalDropdown;

    return (
      <div className={cn("space-y-2", className)}>
        <BaseSelect disabled={disabled} open={open} {...props}>
          {Boolean(name) && (
            <input
              id={id}
              name={name}
              ref={ref}
              type="hidden"
              value={actualValue}
            />
          )}
          <SelectTrigger
            className={selectClassName}
            onClick={openSelection}
            onKeyDown={(e) => {
              (e.key === "Enter" || e.key === " ") && openSelection();
            }}
          >
            <span
              className={cn(
                "truncate text-text-hint",
                "group-disabled:text-text-disabled",
                valueClassName,
              )}
            >
              {placeholder}
            </span>
          </SelectTrigger>

          {dropdown}
        </BaseSelect>

        {Boolean(showSelectionTags) && selected.length > 0 && (
          <div className="flex flex-wrap gap-1">
            {selected.map((item) => (
              <Button
                className={cn(
                  "h-6 cursor-default border-other-border pl-2 pr-1 text-caption text-text-primary has-[:hover]:bg-actions-hover",
                  disabled && "px-2",
                )}
                color="custom"
                disabled={disabled}
                endIcon={
                  !disabled && (
                    <XCircleIcon
                      className="w-3.5 cursor-pointer text-other-border"
                      onClick={() => {
                        removeSelected(item);
                      }}
                    />
                  )
                }
                key={`${item.label}-${item.value}`}
                variant="outlined"
              >
                {item.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    );
  },
);

function EmptyPlaceholder({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div
      className={cn("flex justify-center gap-[0.625rem] px-4 py-2", className)}
      {...props}
    >
      <div className="flex size-6 items-center justify-center">
        <InformationCircleIcon className="size-[1.125rem] text-text-disabled" />
      </div>
      <div>
        <div className="text-body1 text-text-secondary">
          {t("components.select.noResults")}
        </div>
        <div className="text-caption text-text-hint">
          {t("components.select.checkInput")}
        </div>
      </div>
    </div>
  );
}

const Select = forwardRef<
  HTMLInputElement,
  SingleSelectProps | MultipleSelectProps
>(function Select({ ...props }, ref) {
  if (props.type === "multiple") {
    return <MultipleSelect ref={ref} {...props} />;
  }

  return <SingleSelect ref={ref} {...props} />;
});

export { Select };
