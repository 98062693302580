import { type DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";

import { formatDateRange } from "@/components/ui/DatePicker/utils";

import { Tag, type TagProps } from "./Tag";

export interface DateRangeTagProps extends Omit<TagProps, "onRemoveClick"> {
  dateRange: DateRange;
  onRemoveClick?: (rating: DateRange) => void;
}

export function DateRangeTag({
  dateRange,
  onRemoveClick,
  ...props
}: DateRangeTagProps) {
  const { t } = useTranslation();

  return dateRange.from || dateRange.to ? (
    <Tag
      label={t("components.filterTag.dateRange")}
      onRemoveClick={() => onRemoveClick?.(dateRange)}
      value={formatDateRange(dateRange, "PP")}
      {...props}
    />
  ) : null;
}
