/* eslint-disable @typescript-eslint/no-misused-promises */
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { type ReactNode, useCallback, useState } from "react";
import { type UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { type BasicUser } from "@/api/constants";
import { type BasicProject } from "@/api/projects";
import { RatingGuide } from "@/components/app/RatingGuide";
import { Button } from "@/components/ui/Button";
import { Form } from "@/components/ui/Form";
import { Rating } from "@/components/ui/Rating";
import { TextArea } from "@/components/ui/TextArea";
import { withLoadingState } from "@/utils/decorators";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { cn } from "@/utils/ui";

import { useEvaluationPageStore } from "../utils/hooks/useEvaluationPageStore";
import { Placeholder } from "./Placeholder";
import { type FormData as EvaluationRatingFormData } from "./utils";

export interface CreateEvaluationFormProps {
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  form: UseFormReturn<EvaluationRatingFormData>;
  hasNextMember?: boolean;
  hasPrevMember?: boolean;
  isCurrentUserClicked: boolean;
  isLoading?: boolean;
  onFormInputValueChange?: () => void;
  onNextMemberClick?: () => void;
  onPrevMemberClick?: () => void;
  project?: BasicProject | null;
  user: BasicUser | null;
}

export const CreateEvaluationForm = withLoadingState(
  function CreateEvaluationForm({
    children,
    className,
    containerClassName,
    form,
    hasNextMember,
    hasPrevMember,
    isCurrentUserClicked,
    isLoading,
    onFormInputValueChange,
    onNextMemberClick,
    onPrevMemberClick,
    project,
    user,
  }: CreateEvaluationFormProps) {
    const { t } = useTranslation();
    const { md } = useScreenSize();

    const [isRatingGuideOpen, setIsRatingGuideOpen] = useState(false);

    const saveRating = useEvaluationPageStore((state) => state.saveRating);

    const canClear =
      form.getValues("rating") > 0 || Boolean(form.getValues("ratingNotes"));

    const handleSaveRating = (formData: EvaluationRatingFormData) => {
      saveRating(formData, true);
    };

    const handleSubmitRating = (formData: EvaluationRatingFormData) => {
      saveRating(formData, false);
    };

    const handleChange = async () => {
      await form.handleSubmit(handleSubmitRating, () => {
        handleSaveRating(form.getValues());
      })();
      onFormInputValueChange?.();
    };

    const handleClearClick = () => {
      if (!user) return;

      const memberId = { evaluatee: user.id, project: project?.id };
      const blankRating = { ...memberId, rating: 0, ratingNotes: "" };

      handleSaveRating(blankRating);
      form.reset(blankRating);
    };

    const handleRatingOpenChange = useCallback((isOpen: boolean) => {
      setIsRatingGuideOpen(isOpen);
    }, []);

    return (
      <OverlayScrollbarsComponent
        className={cn(
          "flex flex-col items-stretch p-0.5 md:max-lg:max-h-fit",
          containerClassName,
        )}
        defer
        element="div"
        options={{ scrollbars: { autoHide: "move" } }}
      >
        <Form {...form}>
          <form
            className={cn(
              "flex h-fit min-h-full flex-auto flex-col items-stretch gap-[0.625rem] rounded-2xl px-3.5 pb-[1.375rem] pt-3.5 shadow-elevation1 max-md:gap-6 max-md:px-4 max-md:pb-6 max-md:pt-4",
              className,
              isRatingGuideOpen && "min-h-[101%]",
            )}
          >
            {children}

            <div className="flex flex-auto flex-col items-stretch gap-6">
              {(Boolean(project) || Boolean(isCurrentUserClicked)) && (
                <div className="flex">
                  <div className="w-[50%] space-y-2 pr-3">
                    <p className="whitespace-nowrap text-subtitle2 text-text-secondary">
                      {t("components.evaluationForm.memberName")}
                    </p>
                    <p
                      className={cn(
                        "text-h5 max-md:text-h6",
                        user ? "text-text-primary" : "text-text-secondary",
                      )}
                    >
                      {user?.fullName ?? "-"}
                    </p>
                  </div>
                  <div className="w-[50%] space-y-2 pl-3">
                    <p className="whitespace-nowrap text-subtitle2 text-text-secondary">
                      {t("components.evaluationForm.projectName")}
                    </p>
                    <p className="text-h5 text-text-primary max-md:text-h6">
                      {isCurrentUserClicked
                        ? t("evaluations.create.selfEvaluation")
                        : (project?.name ?? "-")}
                    </p>
                  </div>
                </div>
              )}

              {(project && user) || isCurrentUserClicked ? (
                <>
                  <RatingGuide onOpenChange={handleRatingOpenChange} />

                  <Form.Field
                    control={form.control}
                    name="rating"
                    render={({ field: { onChange, ...field } }) => (
                      <Form.Item className="space-y-3">
                        <Form.Label className="text-subtitle2 text-text-secondary">
                          {t("components.evaluationForm.overallRating")}
                        </Form.Label>
                        <Form.Control>
                          <Rating
                            onChange={async (e) => {
                              onChange(e);
                              await handleChange();
                            }}
                            {...field}
                            size={md ? "medium" : "large"}
                          />
                        </Form.Control>
                      </Form.Item>
                    )}
                  />

                  <Form.Field
                    control={form.control}
                    name="ratingNotes"
                    render={({ field: { onChange, ...field } }) => (
                      <Form.Item
                        className="group space-y-[0.625rem]"
                        data-has-errors={
                          Boolean(form.formState.errors.ratingNotes) &&
                          !isLoading
                        }
                      >
                        <div className="flex items-center justify-between gap-2">
                          <Form.Label className="text-subtitle2 text-text-secondary">
                            {t("components.evaluationForm.addNotes")}
                          </Form.Label>
                          <p className="text-caption text-text-secondary">
                            {t("components.evaluationForm.required")}
                          </p>
                        </div>
                        <Form.Control>
                          <TextArea
                            helper={t(
                              "components.evaluationForm.minCharacters",
                              {
                                charCount: form.getValues("ratingNotes").length,
                              },
                            )}
                            helperClassName="flex items-center justify-between w-full group-data-[has-errors=true]:text-error-main"
                            hideCounter
                            inputClassName="w-full group-data-[has-errors=true]:ring-error-main group-data-[has-errors=true]:focus:ring-error-light"
                            onChange={async (e) => {
                              onChange(e);
                              await handleChange();
                            }}
                            placeholder={t(
                              "components.evaluationForm.writeSomething",
                            )}
                            {...field}
                          />
                        </Form.Control>
                      </Form.Item>
                    )}
                  />
                </>
              ) : (
                <Placeholder />
              )}
            </div>

            {!isCurrentUserClicked && (
              <div className="flex items-center justify-between gap-2 border-other-divider max-md:border-t max-md:pt-5">
                <Button
                  className="rounded-full"
                  disabled={form.formState.isSubmitting || !canClear}
                  onClick={handleClearClick}
                  size={md ? "medium" : "small"}
                  variant="text"
                >
                  {t("components.evaluationForm.buttonClear")}
                </Button>

                <div className="space-x-3">
                  <Button
                    className="align-middle"
                    disabled={!hasPrevMember}
                    onClick={onPrevMemberClick}
                    size={md ? "medium" : "small"}
                    variant="outlined"
                  >
                    <ArrowLeftIcon className="size-[1.375rem] max-md:size-[1.125rem]" />
                    <span className="max-md:hidden">
                      {t("components.evaluationForm.buttonPrevMember")}
                    </span>
                  </Button>
                  <Button
                    className="align-middle"
                    disabled={!hasNextMember}
                    onClick={onNextMemberClick}
                    size={md ? "medium" : "small"}
                  >
                    <ArrowRightIcon className="size-[1.375rem] max-md:size-[1.125rem]" />
                    <span className="max-md:hidden">
                      {t("components.evaluationForm.buttonNextMember")}
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </form>
        </Form>
      </OverlayScrollbarsComponent>
    );
  },
);
