import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { type HTMLAttributes } from "react";

import { withLoadingState } from "@/utils/decorators";
import { cn } from "@/utils/ui";

export interface InfoTextProps extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
}

export const InfoText = withLoadingState(function InfoText({
  children,
  className,
  isLoading,
  title,
  subtitle,
  ...props
}: InfoTextProps) {
  return (
    <div
      className={cn(
        "flex items-center gap-3 rounded-lg border border-actions-main p-3",
        className,
      )}
      {...props}
    >
      {children}
      <InformationCircleIcon
        className={cn("size-6 shrink-0", isLoading ? "invisible" : "")}
      />
      <div className={isLoading ? "invisible" : ""}>
        {Boolean(title) && (
          <p className="text-subtitle2 text-text-secondary">{title}</p>
        )}
        {Boolean(subtitle) && (
          <p className="text-body2 text-text-secondary">{subtitle}</p>
        )}
      </div>
    </div>
  );
});
