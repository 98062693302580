import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useListProjects } from "@/api/projects";
import { useListUsers } from "@/api/users";
import { TablePagination } from "@/components/ui/DataTable";
import { TableHeaderControls } from "@/components/ui/NavigationTable/TableHeaderControls";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { useEvaluationPageStore } from "../../../utils/hooks/useEvaluationPageStore";
import {
  columns,
  type TableColumns as EvaluatedMembersTableColumns,
} from "./columns";
import { MembersTable, type MembersTableProps } from "./MembersTable";

const formSchema = z.object({});

export interface EvaluatedMembersTableProps {
  className?: string;
  isFetching?: boolean;
}

export const EvaluatedMembersTable = memo(function EvaluatedMembersTable({
  className,
  isFetching,
}: EvaluatedMembersTableProps) {
  const { maxMd } = useScreenSize();
  const { t } = useTranslation();

  const { users, isFetched: isFetchedUsers } = useListUsers();

  const payload = useEvaluationPageStore((state) => state.getSubmitPayload());

  const payloadProjectIds = useMemo(
    () =>
      payload?.ratings
        .map(({ projectId }) => projectId)
        .filter(
          (id, index, self) => id !== undefined && self.indexOf(id) === index,
        ) as number[] | undefined,
    [payload?.ratings],
  );

  const { results: projects, isFetched: isFetchedProjects } = useListProjects(
    { id: payloadProjectIds, limit: 99999 },
    { enabled: Boolean(payload) },
  );

  const isFetched = isFetchedProjects && isFetchedUsers;

  const projectsMap = useMemo(
    () => Object.fromEntries(projects.map((project) => [project.id, project])),
    [projects],
  );
  const usersMap = useMemo(
    () => Object.fromEntries(users?.map((user) => [user.id, user]) ?? []),
    [users],
  );

  const data = useMemo<EvaluatedMembersTableColumns[]>(
    () =>
      isFetched
        ? (payload?.ratings.map((ratingData) => ({
            evaluatee: usersMap[`${ratingData.evaluateeId}`],
            projectId: ratingData.projectId,
            projectName:
              ratingData.projectId !== undefined &&
              ratingData.projectId in projectsMap
                ? projectsMap[`${ratingData.projectId}`].name
                : "",
            rating: ratingData.rating,
            ratingNotes: ratingData.ratingNotes,
            isSelfEvaluation: ratingData.projectId === undefined,
          })) ?? [])
        : [],
    [isFetched, payload, projectsMap, usersMap],
  );

  const renderTableFooter = useCallback(
    (footerProps: Parameters<MembersTableProps["renderTableFooter"]>[0]) =>
      maxMd ? <TablePagination {...footerProps} /> : null,
    [maxMd],
  );

  const renderTableHeader = useCallback(
    (headerProps: Parameters<MembersTableProps["renderTableHeader"]>[0]) => (
      <TableHeaderControls
        {...headerProps}
        className={cn(headerProps.className, "!min-w-0")}
        formSchema={formSchema}
        searchDebounceTime={0}
        searchPlaceholder={t(
          maxMd
            ? "evaluations.dashboard.searchPlaceholder.mobile"
            : "evaluations.dashboard.searchPlaceholder.default",
        )}
      />
    ),
    [maxMd, t],
  );

  return (
    <MembersTable
      className={cn("overflow-auto", className)}
      columns={columns}
      data={data}
      isFetching={!isFetched || isFetching}
      renderTableFooter={renderTableFooter}
      renderTableHeader={renderTableHeader}
    />
  );
});
