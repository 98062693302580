import type {
  AnyRoute,
  RegisteredRouter,
  RootSearchSchema,
  RouteById,
  RouteIds,
} from "@tanstack/react-router";
import { useNavigate, useSearch } from "@tanstack/react-router";
import {
  type ColumnDef,
  type ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type GroupingState,
  type InitialTableState,
  type OnChangeFn,
  type PaginationState,
  type RowSelectionState,
  type SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import type { KeysOfUnion } from "type-fest";
import { type z } from "zod";

import {
  DataTable,
  type DataTableProps,
  TablePagination,
} from "@/components/ui/DataTable";
import { TABLE_PAGE_SIZE_OPTIONS } from "@/components/ui/DataTable/utils";
import { cn } from "@/utils/ui";

import { EmptyDashboard } from "./EmptyDashboard";
import {
  type TableHeaderContolsProps,
  TableHeaderControls,
} from "./TableHeaderControls";
import {
  columnFilterStateToFiltering,
  filteringToColumnFilterState,
  orderingToSortingState,
  selectionListToSelectionState,
  selectionStateToSelectionList,
  sortingStateToOrdering,
  type TableStateBaseParams,
} from "./utils";

export interface NavigationTableProps<
  TData,
  TFormSchema extends z.AnyZodObject = z.AnyZodObject,
  TRouteTree extends AnyRoute = RegisteredRouter["routeTree"],
  TFrom extends RouteIds<TRouteTree> = RouteIds<TRouteTree>,
  TSearch = Exclude<
    RouteById<TRouteTree, TFrom>["types"]["fullSearchSchema"],
    RootSearchSchema
  >,
> extends DataTableProps<TData, unknown> {
  columns: ColumnDef<TData>[];
  customFrom?: TFrom;
  data: TData[];
  defaultFilterFormValues?: z.infer<TFormSchema>;
  enableShowAll?: boolean;
  filteredResultsCount?: number;
  filterSource?: KeysOfUnion<TSearch>;
  formSchema: TFormSchema;
  getSubRows?: (row: TData, index: number) => TData[] | undefined;
  initialState?: InitialTableState;
  isResetDisabled?: boolean;
  onUpdateStateParams?: (
    partial: TableStateBaseParams & { filters?: z.infer<TFormSchema> },
  ) => void;
  renderFilterForm?: TableHeaderContolsProps<
    TData,
    TFormSchema
  >["renderFilterForm"];
  renderFilterSummary?: TableHeaderContolsProps<
    TData,
    TFormSchema
  >["renderFilterSummary"];
  searchPlaceholder?: string;
}

export function NavigationTable<
  TData,
  TFormSchema extends z.AnyZodObject = z.AnyZodObject,
  TRouteTree extends AnyRoute = RegisteredRouter["routeTree"],
  TFrom extends RouteIds<TRouteTree> = RouteIds<TRouteTree>,
  TSearch = Exclude<
    RouteById<TRouteTree, TFrom>["types"]["fullSearchSchema"],
    RootSearchSchema
  >,
>({
  className,
  columns,
  customFrom,
  filterSource,
  data,
  defaultFilterFormValues,
  enableShowAll = true,
  onUpdateStateParams,
  formSchema,
  getSubRows,
  initialState,
  isFetching,
  isResetDisabled,
  filteredResultsCount,
  renderFilterForm,
  renderFilterSummary,
  searchPlaceholder,
  ...props
}: NavigationTableProps<TData, TFormSchema, TRouteTree, TFrom, TSearch>) {
  const navigate = useNavigate();

  const stateParams = useSearch({
    from: customFrom,
    strict: false,
    select: filterSource
      ? (search) =>
          search[
            filterSource as keyof typeof search
          ] as TableStateBaseParams & { filters?: z.infer<typeof formSchema> }
      : undefined,
  });

  const { per, page, filters, grouping, ordering, selected, search } =
    stateParams;

  const pageSize = per ?? TABLE_PAGE_SIZE_OPTIONS[0].value;
  const pageIndex = !page ? 0 : page - 1;

  const pagination = useMemo<PaginationState>(
    () => ({
      pageIndex: 0,
      pageSize: pageSize <= 0 ? 999999 : pageSize,
    }),
    [pageSize],
  );

  const columnFilters = useMemo(
    () => filteringToColumnFilterState(filters ?? {}),
    [filters],
  );
  const sorting = useMemo(
    () => orderingToSortingState(ordering ?? []),
    [ordering],
  );
  const rowSelection = useMemo(
    () => selectionListToSelectionState(selected ?? []),
    [selected],
  );

  const updateState = useCallback(
    (partial: TableStateBaseParams & { filters?: z.infer<TFormSchema> }) => {
      if (onUpdateStateParams) {
        onUpdateStateParams(partial);
        return;
      }

      void navigate({
        to: undefined,
        params: {},
        replace: true,
        search: (prev) => ({ ...prev, ...partial }),
      });
    },
    [navigate, onUpdateStateParams],
  );

  const onColumnFiltersChange: OnChangeFn<ColumnFiltersState> = useCallback(
    (updater) => {
      const updatedFiltering = columnFilterStateToFiltering(
        typeof updater === "function" ? updater(columnFilters) : updater,
      );

      updateState({ filters: updatedFiltering });
    },
    [columnFilters, updateState],
  );

  const onGlobalFilterChange: OnChangeFn<string> = useCallback(
    (updater) => {
      const updatedFilter =
        typeof updater === "function" ? updater(search ?? "") : updater;

      updateState({ page: 1, search: updatedFilter });
    },
    [search, updateState],
  );

  const onGroupingChange: OnChangeFn<GroupingState> = useCallback(
    (updater) => {
      const updatedGrouping =
        typeof updater === "function" ? updater(grouping ?? []) : updater;

      updateState({ grouping: updatedGrouping });
    },
    [grouping, updateState],
  );

  const onRowSelectionChange: OnChangeFn<RowSelectionState> = useCallback(
    (updater) => {
      const updatedSelection = selectionStateToSelectionList(
        typeof updater === "function" ? updater(rowSelection) : updater,
      );

      updateState({ selected: updatedSelection });
    },
    [rowSelection, updateState],
  );

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const updatedSorting = sortingStateToOrdering(
        typeof updater === "function" ? updater(sorting) : updater,
      );

      updateState({ ordering: updatedSorting });
    },
    [sorting, updateState],
  );

  const table = useReactTable({
    columns,
    data,
    initialState,
    getSubRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel:
      data.length > 0 && grouping && grouping.length > 0
        ? getGroupedRowModel()
        : undefined,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange,
    onGlobalFilterChange,
    onGroupingChange,
    onRowSelectionChange,
    onSortingChange,
    state: {
      columnFilters,
      globalFilter: search,
      grouping,
      pagination,
      rowSelection,
      sorting,
    },
  });

  const handlePageChange = useCallback(
    (newPage: number) => {
      updateState({ page: newPage });

      return false;
    },
    [updateState],
  );

  const handlePageSizeChange = useCallback(
    (value: number) => {
      updateState({ page: 1, per: value });

      return false;
    },
    [updateState],
  );

  const renderTableFooter = useCallback(
    ({ table: instance, ...renderProps }: { table: typeof table }) => (
      <TablePagination
        {...renderProps}
        enableShowAll={enableShowAll}
        filteredResultsCount={filteredResultsCount ?? data.length}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageIndex={pageIndex}
        pageSize={pageSize}
        table={instance}
      />
    ),
    [
      filteredResultsCount,
      data,
      enableShowAll,
      handlePageChange,
      handlePageSizeChange,
      pageIndex,
      pageSize,
    ],
  );

  const renderTableHeader = useCallback(
    ({ table: instance, ...renderProps }: { table: typeof table }) => (
      <TableHeaderControls
        {...renderProps}
        customFrom={customFrom}
        defaultFilters={defaultFilterFormValues}
        filterSource={filterSource}
        formSchema={formSchema}
        isResetDisabled={isResetDisabled}
        renderFilterForm={renderFilterForm}
        renderFilterSummary={renderFilterSummary}
        searchPlaceholder={searchPlaceholder}
        table={instance}
      />
    ),
    [
      customFrom,
      defaultFilterFormValues,
      filterSource,
      formSchema,
      isResetDisabled,
      renderFilterForm,
      renderFilterSummary,
      searchPlaceholder,
    ],
  );

  return (
    <DataTable
      className={cn("flex-auto", className)}
      isFetching={isFetching}
      placeholder={!isFetching && <EmptyDashboard />}
      renderTableFooter={renderTableFooter}
      renderTableHeader={renderTableHeader}
      table={table}
      {...props}
    />
  );
}
