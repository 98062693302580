import type { InitOptions } from "i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import type { HttpBackendOptions } from "i18next-http-backend";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { isLocal } from "./utils/constants";

const options: InitOptions<HttpBackendOptions> = {
  debug: isLocal,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  load: "languageOnly",
  ns: "common",
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options)
  .catch((e) => {
    throw e;
  });

export { i18n };
