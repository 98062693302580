import { TrashIcon } from "@heroicons/react/24/outline";
import type { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import type { BasicProject } from "@/api/projects";
import { IconButton } from "@/components/ui/IconButton";
import { cn } from "@/utils/ui";

export interface ProjectListItemProps {
  className?: string;
  isSelfEvaluation?: boolean;
  onClick?: (project: BasicProject) => void;
  onDeleteClick?: (project: BasicProject) => void;
  project: BasicProject | null;
}

export function ProjectListItem({
  className,
  isSelfEvaluation,
  onClick,
  onDeleteClick,
  project,
  ...props
}: ProjectListItemProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (project === null) return;
    onClick?.(project);
  };

  const handleDeleteClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (project === null) return;
    e.stopPropagation();
    onDeleteClick?.(project);
  };

  return (
    <div
      className={cn(
        "group flex items-center justify-between gap-[0.625rem] rounded-lg px-5 py-2 hover:bg-secondary-background data-[state=selected]:bg-primary-main",
        className,
      )}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      {...props}
    >
      {Boolean(project !== null || isSelfEvaluation) && (
        <span className="text-subtitle2 text-text-primary group-data-[state=selected]:text-primary-contrast-text md:text-subtitle1 md:text-text-secondary">
          {isSelfEvaluation
            ? t("components.listItem.selfEvaluation")
            : project?.name}
        </span>
      )}

      {Boolean(onDeleteClick) && !isSelfEvaluation && (
        <IconButton
          icon={
            <TrashIcon className="size-[1.125rem] text-error-main group-data-[state=selected]:text-error-light" />
          }
          onClick={handleDeleteClick}
        />
      )}
    </div>
  );
}
