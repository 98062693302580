import { PencilSquareIcon, PlusIcon } from "@heroicons/react/16/solid";
import { Link, useSearch } from "@tanstack/react-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EvaluationCycleStatus } from "@/api/evaluationCycles";
import { useGetCurrentEvaluationCycle } from "@/api/evaluationCycles/useGetCurrentEvaluationCycle";
import { useGetCurrentEvaluation } from "@/api/evaluations";
import FileSearching from "@/assets/svg/file_searching.svg?react";
import { Button } from "@/components/ui/Button";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { cn } from "@/utils/ui";

export function EmptyDashboard() {
  const search = useSearch({ from: "/evaluations/_dashboard" });

  const cycle = useMemo(
    () => search.filters?.cycle ?? [],
    [search.filters?.cycle],
  );

  const { md } = useScreenSize();
  const { t } = useTranslation();

  const { currentCycle } = useGetCurrentEvaluationCycle();
  const { evaluation, isFetched: isCurrentEvaluationFetched } =
    useGetCurrentEvaluation();

  // There could be a scenario where the current cycle is not the one filtered for (may already be inactive)
  // or there are multiple cycles being filtered for ("create" can be ambiguous), ensure that the
  // "Create Evaluation" is shown when the cycle filtered for is only the current cycle
  const isCurrentCycleShown = useMemo(
    () =>
      currentCycle &&
      (cycle.length === 0 ||
        (cycle.length === 1 && cycle[0] === currentCycle.id)),
    [currentCycle, cycle],
  );

  const isCreateButtonShown = useMemo(
    () =>
      isCurrentEvaluationFetched &&
      isCurrentCycleShown &&
      (!evaluation || evaluation.ratings.length === 0) &&
      currentCycle?.status === EvaluationCycleStatus.Active,
    [isCurrentEvaluationFetched, isCurrentCycleShown, evaluation, currentCycle],
  );

  const canEdit =
    evaluation && (evaluation.draftData || evaluation.ratings.length > 0);

  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="flex flex-col items-center gap-6">
        <div className="max-w-full space-y-10 px-6 text-center">
          <FileSearching
            className="m-auto h-[12.9375rem]"
            title={t("evaluations.dashboard.emptyTitle")}
          />
          <p className="m-auto max-w-full whitespace-pre-line text-body1 text-text-hint max-md:text-caption md:w-[35.125rem]">
            {t(
              isCreateButtonShown
                ? "evaluations.dashboard.emptyInfoCreatePrompt"
                : "evaluations.dashboard.emptyInfo",
            )}
          </p>
        </div>
        <div>
          {isCreateButtonShown ? (
            <Button
              asChild
              className={cn(
                "gap-2 shadow-elevation2",
                md ? "text-button-medium" : "text-button-small",
              )}
              isLink
              size={md ? "medium" : "small"}
            >
              <Link to="/evaluations/create">
                {canEdit ? (
                  <>
                    <PencilSquareIcon className="size-5" />
                    <span>{t("evaluations.dashboard.buttonEditDraft")}</span>
                  </>
                ) : (
                  <>
                    <PlusIcon className="size-5" />
                    <span>
                      {t("evaluations.dashboard.buttonCreateEvaluation")}
                    </span>
                  </>
                )}
              </Link>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
