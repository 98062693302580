import { createFileRoute } from "@tanstack/react-router";

interface LoaderProps {
  params: {
    cycleId: string;
  };
}

const loader = ({ params }: LoaderProps) => {
  return params.cycleId;
};

export const Route = createFileRoute("/admin/cycles/$cycleId")({
  beforeLoad: () => {
    return { routeId: null };
  },
  loader,
});
