import { type ComponentProps, memo, type ReactNode } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { Form } from "@/components/ui/Form";
import {
  SearchSelect,
  type SearchSelectProps,
} from "@/components/ui/SearchSelect";
import type { MultipleSelectProps, SelectOption } from "@/components/ui/Select";
import { cn } from "@/utils/ui";

const CustomSelectItem = memo(function CustomSelectItem({
  className,
  ...props
}: SearchSelectProps["Item"]) {
  return (
    <SearchSelect.Item
      {...props}
      className={cn(
        "data-[state=checked]:bg-primary-main data-[state=checked]:hover:bg-primary-dark data-[state=checked]:focus:bg-primary-dark",
        className,
      )}
      renderCheckbox={({ className: cbClassName, ...cbProps }) => (
        <SearchSelect.Item.Checkbox
          {...cbProps}
          className={cn(cbClassName, "p-0")}
          variant="inverted"
        />
      )}
      renderLabel={({ className: labelClassName, ...labelProps }) => (
        <SearchSelect.Item.Label
          {...labelProps}
          className={cn(
            labelClassName,
            "cursor-pointer text-body1",
            "group-data-[state=checked]:text-primary-contrast-text",
          )}
        />
      )}
    />
  );
});

export interface MultiSelectSearchFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ComponentProps<typeof Form.Field<TFieldValues, TName>>,
    "render"
  > {
  className?: string;
  isFetching?: boolean;
  label: string;
  onChange?: MultipleSelectProps["onChange"];
  onSearchChange?: (search: string) => void;
  options: SelectOption[];
  placeholder?: string;
  scrollSentry?: ReactNode;
  searchDebounceTime?: number;
  value?: SelectOption[] | null;
}

export function MultiSelectSearchField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  className,
  isFetching,
  label,
  onChange,
  onSearchChange,
  options,
  placeholder,
  scrollSentry,
  searchDebounceTime = 1000,
  value: controlledValue,
  ...props
}: MultiSelectSearchFieldProps<TFieldValues, TName>) {
  const handleSearchChange = useDebouncedCallback((search: string) => {
    onSearchChange?.(search);
  }, searchDebounceTime);

  return (
    <Form.Field
      render={({ field: { value, onChange: handleChange, ...field } }) => {
        const values = (value as (string | number)[] | null)?.slice() ?? [];

        return (
          <Form.Item className={cn("space-y-[0.625rem]", className)}>
            <Form.Label className="text-subtitle2 text-text-secondary">
              {label}
            </Form.Label>
            <Form.Control>
              <SearchSelect
                disableFiltering
                isFetching={isFetching}
                onRemoveSelected={(option) => {
                  handleChange(values.filter((v) => v !== option.value));
                }}
                onSearchChange={handleSearchChange}
                onSelectItem={(clicked, isSelected) => {
                  if (!isSelected) {
                    values.splice(values.indexOf(clicked.value), 1);
                  }

                  const selectedValues = isSelected
                    ? values.concat(clicked.value)
                    : values;
                  const selectedOptions = options.filter((option) =>
                    selectedValues.includes(option.value),
                  );
                  const orderedSelectedValues = selectedOptions.map(
                    (option) => option.value,
                  );

                  handleChange(orderedSelectedValues);
                  onChange?.(selectedOptions, clicked);
                }}
                options={options}
                placeholder={placeholder}
                renderDropdownFooter={() => null}
                renderItem={CustomSelectItem}
                scrollSentry={scrollSentry}
                showSelectionTags
                value={
                  controlledValue ??
                  options.filter((option) =>
                    (value as (number | string)[] | null)?.includes(
                      option.value,
                    ),
                  )
                }
                {...field}
              />
            </Form.Control>
          </Form.Item>
        );
      }}
      {...props}
    />
  );
}
