import type { ComponentProps, ReactNode } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";

import { Form } from "@/components/ui/Form";
import {
  type MultipleSelectProps,
  Select,
  type SelectOption,
} from "@/components/ui/Select";
import { cn } from "@/utils/ui";

export interface MultiSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ComponentProps<typeof Form.Field<TFieldValues, TName>>,
    "render"
  > {
  className?: string;
  label: string;
  onChange?: MultipleSelectProps["onChange"];
  options: SelectOption[];
  placeholder?: string;
  scrollSentry?: ReactNode;
  value?: SelectOption[] | null;
}

export function MultiSelectField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  className,
  label,
  onChange,
  options,
  placeholder,
  scrollSentry,
  value: controlledValue,
  ...props
}: MultiSelectFieldProps<TFieldValues, TName>) {
  return (
    <Form.Field
      render={({ field: { value, onChange: handleChange, ...field } }) => (
        <Form.Item className={cn("space-y-[0.625rem]", className)}>
          <Form.Label className="text-subtitle2 text-text-secondary">
            {label}
          </Form.Label>
          <Form.Control>
            <Select
              closeOnSelect={false}
              onChange={(selected, clicked) => {
                handleChange(selected?.map((option) => option.value));
                onChange?.(selected, clicked);
              }}
              options={options}
              placeholder={placeholder}
              scrollSentry={scrollSentry}
              type="multiple"
              value={
                controlledValue ??
                options.filter((option) =>
                  (value as number[] | null)?.includes(option.value as number),
                )
              }
              {...field}
            />
          </Form.Control>
        </Form.Item>
      )}
      {...props}
    />
  );
}
