import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/utils/ui";

const buttonVariants = cva(
  "inline-flex select-none items-center justify-center gap-2 whitespace-nowrap !leading-none disabled:text-actions-disabled-text",
  {
    variants: {
      size: {
        large: "h-[2.625rem] px-[1.375rem] py-2 text-button-large",
        medium: "h-9 px-4 py-1.5 text-button-medium",
        small: "h-[1.875rem] px-2.5 py-1 text-button-small",
        custom: "",
      },
      color: {
        primary: "border-primary-border",
        secondary: "border-secondary-border",
        danger: "",
        success: "",
        warning: "",
        custom: "",
      },
      variant: {
        contained:
          "rounded-[3.75rem] bg-actions-main text-text-primary disabled:bg-actions-disabled-background",
        outlined:
          "rounded-[3.75rem] border bg-transparent disabled:border-actions-main disabled:hover:bg-transparent",
        text: "rounded-lg text-text-primary disabled:hover:bg-transparent",
      },
      isLink: {
        true: "",
        false: "",
      },
      disabled: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: "contained",
        color: "primary",
        className:
          "bg-primary-main text-primary-contrast-text hover:bg-primary-dark",
      },
      {
        variant: "contained",
        color: "secondary",
        className:
          "bg-secondary-main text-secondary-contrast-text hover:bg-secondary-dark",
      },
      {
        variant: "contained",
        color: "danger",
        className: "bg-error-main text-error-contrast-text hover:bg-error-dark",
      },
      {
        variant: "contained",
        color: "success",
        className:
          "bg-success-main text-success-contrast-text hover:bg-success-dark",
      },
      {
        variant: "contained",
        color: "warning",
        className:
          "bg-warning-main text-warning-contrast-text hover:bg-warning-dark",
      },
      {
        variant: "contained",
        isLink: true,
        disabled: true,
        className:
          "!bg-actions-disabled-background bg-primary-main !text-actions-disabled-text text-primary-contrast-text hover:bg-primary-dark",
      },
      {
        variant: "outlined",
        color: "primary",
        className: "text-primary-main hover:bg-primary-background",
      },
      {
        variant: "outlined",
        color: "secondary",
        className: "text-secondary-main hover:bg-secondary-background",
      },
      {
        variant: "outlined",
        color: "danger",
        className: "text-error-main hover:bg-error-light-bg",
      },
      {
        variant: "outlined",
        color: "success",
        className: "text-success-main hover:bg-success-light-bg",
      },
      {
        variant: "outlined",
        color: "warning",
        className: "text-warning-main hover:bg-warning-light-bg",
      },
      {
        variant: "outlined",
        isLink: true,
        disabled: true,
        className:
          "!border-actions-main !text-actions-disabled-text text-warning-main hover:!bg-transparent hover:bg-warning-light-bg",
      },
      {
        variant: "text",
        color: "primary",
        className: "text-primary-main hover:bg-primary-background",
      },
      {
        variant: "text",
        color: "secondary",
        className: "text-secondary-main hover:bg-secondary-background",
      },
      {
        variant: "text",
        color: "danger",
        className: "text-error-main hover:bg-error-light-bg",
      },
      {
        variant: "text",
        color: "success",
        className: "text-success-main hover:bg-success-light-bg",
      },
      {
        variant: "text",
        color: "warning",
        className: "text-warning-main hover:bg-warning-light-bg",
      },
      {
        variant: "text",
        size: "small",
        className: "p-[0.3125rem]",
      },
      {
        variant: "text",
        size: "medium",
        className: "px-2",
      },
      {
        variant: "text",
        size: "large",
        className: "px-[0.6875rem]",
      },
      {
        variant: "text",
        isLink: true,
        disabled: true,
        className:
          "px-[0.6875rem] !text-actions-disabled-text hover:!bg-transparent",
      },
    ],
    defaultVariants: {
      size: "medium",
      variant: "contained",
      color: "primary",
      isLink: false,
      disabled: false,
    },
  },
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    Omit<VariantProps<typeof buttonVariants>, "disabled"> {
  asChild?: boolean;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      color,
      endIcon,
      isLink,
      size,
      startIcon,
      type = "button",
      variant,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            color,
            isLink,
            disabled: props.disabled,
            className,
          }),
        )}
        ref={ref}
        type={type}
        {...props}
      >
        {startIcon}
        <Slottable>{children}</Slottable>
        {endIcon}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button };
