// import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { type Row } from "@tanstack/react-table";

import { EvaluationCycleStatus } from "@/api/evaluationCycles";
import { type AdminEvaluation } from "@/api/evaluations";
import { EvaluationCycleStatusIndicator } from "@/components/app/EvaluationCycleStatusIndicator";
import { Progress } from "@/components/ui/Progress";

export interface CycleGroupRowProps {
  row: Row<AdminEvaluation>;
}

export function CycleGroupRow({ row }: CycleGroupRowProps) {
  const cycle = row.original.cycle;

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex h-9 items-center gap-[0.625rem] py-1 max-md:flex-auto">
        <div className="text-subtitle2 text-text-secondary">{cycle.name}</div>
        {cycle.status === EvaluationCycleStatus.Active && (
          <>
            <Progress
              className="px-4 max-md:flex-auto md:w-[21.25rem]"
              labelPosition="right"
              size="small"
              value={cycle.progress}
            />
            <EvaluationCycleStatusIndicator status={cycle.status} />
          </>
        )}
      </div>
      {/*<Link
        params={{
          cycleId: `${row.original.cycle.id}`,
        }}
        to="/admin/cycles/$cycleId"
      >
        <IconButton
          icon={<ArrowRightIcon className="size-5 text-text-secondary" />}
        />
      </Link>*/}
    </div>
  );
}
