import { useTranslation } from "react-i18next";

import type { EvaluationCycle as cycle } from "@/api/evaluationCycles";

import type { TagProps } from "./Tag";
import { Tag } from "./Tag";

export interface CycleTagProps extends Omit<TagProps, "onRemoveClick"> {
  cycle: cycle;
  onRemoveClick?: (cycle: cycle) => void;
}
export function CycleTag({ onRemoveClick, cycle, ...props }: CycleTagProps) {
  const { t } = useTranslation();

  return (
    <Tag
      label={t("admin.filterForm.filterTagCycle")}
      onRemoveClick={() => onRemoveClick?.(cycle)}
      value={cycle.name}
      {...props}
    />
  );
}
