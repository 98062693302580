import { useMatches } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import type { RootRouterContext } from "@/utils/constants";

type BreadcrumbContext = Pick<Partial<RootRouterContext>, "routeId">;

export const useRouteBreadcrumbs = () => {
  const matches = useMatches();
  const { t } = useTranslation();

  return matches
    .filter((match) =>
      Boolean((match.routeContext as BreadcrumbContext).routeId),
    )
    .map((match) => ({
      url: match.pathname,
      title: t(
        `routeNames.${(match.routeContext as BreadcrumbContext).routeId}`,
      ),
    }));
};
