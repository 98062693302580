import { z } from "zod";

export const formSchema = z.object({
  rating: z.number().array().optional(),
  evaluatedAt: z
    .object({
      from: z.coerce.date().optional(),
      to: z.coerce.date().optional(),
    })
    .optional(),
  cycle: z.number().array().optional(),
});

export type FormData = z.infer<typeof formSchema>;

export const defaultValues: FormData = {
  evaluatedAt: {},
  rating: [],
  cycle: [],
};
