import { useTranslation } from "react-i18next";

import { type EvaluationCycleMember } from "@/api/evaluationCycles";
import ClipboardCheckBadge from "@/assets/svg/clipboard_check_badge.svg?react";
import ClipboardListBadge from "@/assets/svg/clipboard_list_badge.svg?react";
import StarBadge from "@/assets/svg/star_badge.svg?react";
import UserGroupBadge from "@/assets/svg/user_group_badge.svg?react";
import { Avatar } from "@/components/ui/Avatar";
import { Label } from "@/components/ui/Label";

interface MemberDetailsProps {
  Icon: React.ReactElement;
  label: string;
  value: number | React.ReactElement | null | undefined;
}

function MemberDetails({ Icon, label, value }: MemberDetailsProps) {
  return (
    <div className="flex flex-1 items-start gap-4">
      {Icon}
      <div className="flex flex-col">
        <Label className="whitespace-nowrap text-subtitle2 text-text-secondary">
          {label}
        </Label>
        <p className="h-[1.8125rem] text-h6 text-text-primary">
          {value ?? "-"}
        </p>
      </div>
    </div>
  );
}

export interface MemberDetailsHeaderProps {
  cycleMember: EvaluationCycleMember | null;
}

export function MemberDetailsHeader({ cycleMember }: MemberDetailsHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8 rounded-2xl p-3 px-6 shadow-elevation2 md:flex-row md:items-center">
      <Avatar
        className="static ring-[3px] ring-blue-grey-100"
        displayName={cycleMember?.fullName ?? ""}
        size={60}
        src={cycleMember?.avatar ?? ""}
      />
      <div className="flex flex-1 flex-col items-stretch gap-6">
        {/* <Label className="h-[1.8rem] text-h6 text-text-primary">
          {cycleMember?.fullName}
        </Label> */}
        <div className="grid flex-1 grid-cols-2 gap-3 md:flex md:flex-auto md:flex-wrap md:items-stretch md:gap-6">
          <MemberDetails
            Icon={<StarBadge className="size-[1.875rem] shrink-0" />}
            label={t("admin.memberDetails.header.selfRating")}
            value={cycleMember?.selfRating}
          />
          <MemberDetails
            Icon={<StarBadge className="size-[1.875rem] shrink-0" />}
            label={t("admin.memberDetails.header.overallRating")}
            value={cycleMember?.avgRating}
          />
          <MemberDetails
            Icon={<ClipboardCheckBadge className="size-[1.875rem] shrink-0" />}
            label={t("admin.memberDetails.header.projects")}
            value={cycleMember?.projectCount}
          />
          <MemberDetails
            Icon={<UserGroupBadge className="size-[1.875rem] shrink-0" />}
            label={t("admin.memberDetails.header.evaluators")}
            value={cycleMember?.evaluatorCount}
          />
          <MemberDetails
            Icon={<ClipboardListBadge className="size-[1.875rem] shrink-0" />}
            label={t("admin.memberDetails.header.evaluatees")}
            value={cycleMember?.evaluateeCount}
          />
        </div>
      </div>
    </div>
  );
}
