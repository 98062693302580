import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn } from "@/utils/ui";

const BaseAvatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    className={cn(
      "relative bg-grey-400 text-white flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
      className,
    )}
    ref={ref}
    {...props}
  />
));
BaseAvatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    className={cn("aspect-square h-full w-full", className)}
    ref={ref}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full text-[0.5em] font-normal",
      className,
    )}
    ref={ref}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const getInitials = (displayName: string) => {
  if (!displayName) {
    return displayName;
  }

  const splitName = displayName.trim().split(" ");
  return (
    splitName[0][0] +
    (splitName.length > 1 ? splitName[splitName.length - 1][0] : "")
  ).toUpperCase();
};

interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> {
  className?: string;
  defaultBgColor?: string;
  defaultTextColor?: string;
  displayName?: string;
  size?: string | number | null;
  src?: string;
}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(
  (
    {
      className,
      defaultBgColor,
      defaultTextColor,
      displayName,
      size = 40,
      src,
      style = {},
      ...props
    },
    ref,
  ) => (
    <BaseAvatar
      className={className}
      ref={ref}
      style={{
        backgroundColor: defaultBgColor,
        color: defaultTextColor,
        ...(size !== null && { height: size, width: size, fontSize: size }),
        ...style,
      }}
      {...props}
    >
      <AvatarImage src={src} />
      <AvatarFallback>{getInitials(displayName || "")}</AvatarFallback>
    </BaseAvatar>
  ),
);
Avatar.displayName = AvatarPrimitive.Root.displayName;

export { Avatar, type AvatarProps };
