import {
  type ChangeEvent,
  type ComponentProps,
  type FocusEventHandler,
  forwardRef,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { cn } from "@/utils/ui";

import { Popover } from "../Popover";
import type { SelectOption } from "../Select";
import { TextInput } from "../TextInput";

export interface PopoverInputProps
  extends Omit<ComponentProps<typeof Popover>, "anchor" | "content" | "open"> {
  anchorClassName?: string;
  containerClassName?: string;
  emptyResultsPlaceholder?: ReactNode;
  endIcon?: ComponentProps<typeof TextInput>["endIcon"];
  inputClassName?: string;
  isOpen?: boolean;
  onCancelClick?: (selected: SelectOption[]) => void;
  onClickOutside?: () => void;
  onClose?: () => void;
  onConfirmClick?: (selected: SelectOption[]) => void;
  onEscapeKeyDown?: (e: KeyboardEvent) => void;
  onInputBlur?: FocusEventHandler<HTMLInputElement>;
  onInputChange?: (search: string) => void;
  onOpen?: () => void;
  placeholder?: string;
  value?: string;
}

export const PopoverInput = forwardRef(function PopoverInput(
  {
    anchorClassName,
    children,
    className,
    containerClassName,
    disabled,
    endIcon,
    inputClassName,
    isOpen,
    onClickOutside,
    onClose,
    onEscapeKeyDown,
    onInputBlur,
    onInputChange,
    onOpen,
    placeholder,
    value,
    ...props
  }: PopoverInputProps,
  ref,
) {
  const [internalValue, setInternalValue] = useState<string>("");
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const internalRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const finalIsOpen = isOpen ?? internalIsOpen;
  const finalValue = value ?? internalValue;

  const handleClose = useCallback(() => {
    setInternalIsOpen(false);
    onClose?.();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    onOpen?.();
    setInternalIsOpen(true);
    internalRef.current?.focus();
  }, [onOpen]);

  const handleEscapeKeyDown = useCallback(
    (e: KeyboardEvent) => {
      handleClose();
      onEscapeKeyDown?.(e);
    },
    [handleClose, onEscapeKeyDown],
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const query = e.target.value;

      onInputChange?.(query);

      setInternalValue(query);
    },
    [onInputChange],
  );

  const handleClickOutside = useCallback(
    (e: PointerEvent) => {
      const element = e.target as Element;
      if (
        !internalRef.current?.contains(element) &&
        !dropdownRef.current?.contains(element)
      ) {
        handleClose();
        onClickOutside?.();
      }
    },
    [handleClose, onClickOutside],
  );

  const onAnchorMount = useCallback(
    (anchor: HTMLElement | null) => {
      if (anchor === null) {
        document.removeEventListener("pointerdown", handleClickOutside);
        return;
      }

      document.addEventListener("pointerdown", handleClickOutside);
    },
    [handleClickOutside],
  );

  useImperativeHandle(ref, () => internalRef.current!, []);

  return (
    <Popover
      anchor={
        <div
          className={cn("group/anchor", containerClassName)}
          data-state={finalIsOpen ? "open" : undefined}
          ref={onAnchorMount}
        >
          <TextInput
            className={cn(
              "flex flex-1 h-12 w-full items-center justify-between gap-1 min-w-0 rounded-lg",
              anchorClassName,
            )}
            data-state={finalIsOpen ? "open" : undefined}
            disabled={disabled}
            endIcon={endIcon}
            inputClassName={cn(
              "flex-1 items-center !pr-5 min-w-0 overflow-hidden group-data-[state=open]/anchor:ring-primary-border group-data-[state=open]/anchor:ring-[0.1875rem]",
              inputClassName,
            )}
            onBlur={onInputBlur}
            onChange={handleInputChange}
            onClick={handleOpen}
            onFocus={handleOpen}
            placeholder={placeholder}
            ref={internalRef}
            value={finalValue}
          />
        </div>
      }
      className={cn("w-max px-3 py-0", className)}
      content={children}
      onEscapeKeyDown={handleEscapeKeyDown}
      open={finalIsOpen}
      ref={dropdownRef}
      {...props}
    />
  );
});
