import { format, isValid } from "date-fns";
import { type DateRange } from "react-day-picker";

const assertValidDate = (date: Date) => (isValid(date) ? date : undefined);

export const formatDateRange = (
  dateRange: DateRange | undefined,
  dateFormat: string,
) => {
  if (!dateRange?.from) return "";

  if (!dateRange.to) return format(dateRange.from, dateFormat);

  return `${format(dateRange.from, dateFormat)} - ${format(dateRange.to, dateFormat)}`;
};

export const dateListToDateRange = (dateList: Date[]): DateRange => {
  if (dateList.length > 1)
    return {
      from: assertValidDate(dateList[0]),
      to: assertValidDate(dateList[1]),
    };

  if (dateList.length > 0) return { from: assertValidDate(dateList[0]) };

  return { from: undefined };
};
