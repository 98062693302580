/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetCurrentEvaluation } from "@/api/evaluations";
import { useGetCurrentUser } from "@/api/users";
import { UserListItem } from "@/components/app/ListItem";

import { CreateEvaluationForm } from "../../CreateEvaluationForm";
import {
  type FormData as EvaluationRatingFormData,
  resolver,
} from "../../CreateEvaluationForm/utils";
import { useDraftAutosave } from "../../utils/hooks/useDraftAutosave";
import { useEvaluationPageFormDefaultValues } from "../../utils/hooks/useEvaluationPageFormDefaultValues";
import { useEvaluationPageStore } from "../../utils/hooks/useEvaluationPageStore";

export function EvaluateSelfStep() {
  const { t } = useTranslation();

  const { currentUser, isFetched: isFetchedCurrentUser } = useGetCurrentUser();
  const { isFetched: isFetchedEvaluation } = useGetCurrentEvaluation();
  const { defaultValues, isFetched: isFetchedValues } =
    useEvaluationPageFormDefaultValues();

  const isSelfRatingPending = useEvaluationPageStore((state) =>
    state.getIsSelfRatingPending(),
  );

  const { onAutosaveDraftDebounced } = useDraftAutosave();

  const form = useForm<EvaluationRatingFormData>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  return (
    <div className="flex flex-auto items-stretch gap-6 overflow-y-hidden max-lg:flex-col max-md:gap-3 max-md:px-6 max-md:pb-6 max-md:pt-3 md:gap-10 lg:gap-6">
      <div className="md:ml-0.5 md:mr-[1.125rem] md:mt-0.5 lg:grow-[40%] lg:basis-[40%]">
        <div className="space-y-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1">
          <p className="text-subtitle2 text-text-secondary">
            {t("evaluations.create.selfEvaluation")}
          </p>
          <UserListItem
            className="cursor-default px-0 hover:bg-transparent"
            status={
              // eslint-disable-next-line no-nested-ternary
              !isFetchedEvaluation
                ? "none"
                : isSelfRatingPending
                  ? "pending"
                  : "evaluated"
            }
            user={currentUser}
          />
        </div>
      </div>

      <CreateEvaluationForm
        className="lg:mr-[1.375rem]"
        containerClassName="lg:grow-[60%] lg:basis-[60%] max-lg:flex-auto"
        form={form}
        isCurrentUserClicked
        isLoading={
          !isFetchedValues || !isFetchedEvaluation || !isFetchedCurrentUser
        }
        onFormInputValueChange={onAutosaveDraftDebounced}
        user={currentUser}
      />
    </div>
  );
}
