import { ChevronUpIcon } from "@heroicons/react/16/solid";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import {
  type Column,
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Header,
  type PaginationState,
  type Row,
  type RowSelectionState,
  type SortingState,
  type Table as TableType,
  useReactTable,
  type VisibilityState,
} from "@tanstack/react-table";
import React, {
  Fragment,
  type HTMLAttributes,
  useCallback,
  useState,
} from "react";

import { LoadingComponent } from "@/components/app/LoadingComponent";
import { Table } from "@/components/ui/Table";
import { useScreenSize } from "@/utils/hooks";
import { cn } from "@/utils/ui";

import { TablePagination } from "./TablePagination";
import { TABLE_PAGE_SIZE_OPTIONS } from "./utils";

interface RenderRowProps<TData>
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  renderExpandedSubcomponent?: (props: {
    row: Row<TData>;
  }) => React.ReactNode | undefined | null;
  row: Row<TData>;
}

function RenderRow<TData>({
  renderExpandedSubcomponent,
  row,
  ...props
}: RenderRowProps<TData>) {
  return (
    <>
      <Table.Row
        className={cn(
          "hover:bg-actions-hover data-[state=selected]:bg-primary-background",
          "[&:has([data-row-highlight=primary])]:bg-primary-background [&:has([data-row-highlight=secondary])]:bg-secondary-background",
        )}
        data-row-type="single"
        data-state={row.getIsSelected() && "selected"}
        key={row.id}
        {...props}
      >
        {row.getVisibleCells().map((cell) => (
          <Table.Cell
            className={cn(
              "border-b border-other-divider",
              cell.column.columnDef.meta &&
                "cellClassName" in cell.column.columnDef.meta &&
                typeof cell.column.columnDef.meta.cellClassName === "string"
                ? cell.column.columnDef.meta.cellClassName
                : "",
            )}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Table.Cell>
        ))}
      </Table.Row>
      {renderExpandedSubcomponent ? (
        <Table.Row data-row-type="expandable" key={`${row.id}-expanded`}>
          <Table.Cell
            className="p-0"
            colSpan={row.getVisibleCells().length}
            key={`cell-${row.id}-expanded`}
          >
            <div
              className={cn(
                "grid transition-[grid-template-rows]",
                row.getIsExpanded() ? "grid-rows-[1fr]" : "grid-rows-[0fr]",
              )}
            >
              <div className="overflow-y-hidden" key={`${row.getIsExpanded()}`}>
                {renderExpandedSubcomponent({ row })}
              </div>
            </div>
          </Table.Cell>
        </Table.Row>
      ) : null}
    </>
  );
}

interface RenderRowMobileProps<TData>
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  renderExpandedSubcomponent?: (props: {
    row: Row<TData>;
  }) => React.ReactNode | undefined | null;
  row: Row<TData>;
}

function RenderRowMobile<TData>({
  renderExpandedSubcomponent,
  row,
  ...props
}: RenderRowMobileProps<TData>) {
  return (
    <>
      <Table.Row data-row-type="single" {...props}>
        <Table.Cell
          className={cn(
            "p-3",
            "[&:has([data-row-highlight=primary])]:bg-primary-background [&:has([data-row-highlight=secondary])]:bg-secondary-background",
          )}
        >
          {row.getVisibleCells().map((cell) => (
            <Fragment key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ))}
        </Table.Cell>
      </Table.Row>
      {renderExpandedSubcomponent ? (
        <Table.Row data-row-type="expandable" key={`${row.id}-expanded`}>
          <Table.Cell
            className="p-0"
            colSpan={row.getVisibleCells().length}
            key={`cell-${row.id}-expanded`}
          >
            <div
              className={cn(
                "grid transition-[grid-template-rows]",
                row.getIsExpanded() ? "grid-rows-[1fr]" : "grid-rows-[0fr]",
              )}
            >
              <div className="overflow-y-hidden" key={`${row.getIsExpanded()}`}>
                {renderExpandedSubcomponent({ row })}
              </div>
            </div>
          </Table.Cell>
        </Table.Row>
      ) : null}
    </>
  );
}

export interface DataTableProps<TData, TValue>
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  bodyClassName?: string;
  contentClassName?: string;
  columnHeaderClassName?: string;
  columns?: ColumnDef<TData, TValue>[];
  data?: TData[];
  defaultColumnFilters?: ColumnFiltersState;
  defaultGlobalFilter?: string;
  defaultPagination?: PaginationState;
  defaultRowSelection?: RowSelectionState;
  defaultSorting?: SortingState;
  defaultVisibility?: VisibilityState;
  isFetching?: boolean;
  onSortingChange?: (column: Column<TData>) => boolean | undefined;
  placeholder?: React.ReactNode;
  renderExpandedSubcomponent?: (props: {
    row: Row<TData>;
  }) => React.ReactNode | undefined | null;
  renderGroupRow?: (props: {
    row: Row<TData>;
  }) => React.ReactNode | undefined | null;
  renderTableHeader?: ({
    className,
    table,
  }: {
    className?: string;
    table: TableType<TData>;
  }) => React.ReactNode;
  renderTableFooter?: ({
    table,
  }: {
    table: TableType<TData>;
  }) => React.ReactNode;
  table?: TableType<TData>;
}

function DataTableBody<TData>({
  className,
  isFetching,
  placeholder,
  renderExpandedSubcomponent,
  renderGroupRow,
  table,
}: {
  className?: string;
  isFetching?: boolean;
  placeholder: React.ReactNode;
  renderExpandedSubcomponent?: (props: {
    row: Row<TData>;
  }) => React.ReactNode | undefined | null;
  renderGroupRow?: (props: { row: Row<TData> }) => React.ReactNode;
  table: TableType<TData>;
}) {
  const rows = table.getRowModel().rows;

  return (
    <Table.Body
      className={cn(
        rows.length === 0 && "relative",
        isFetching && "opacity-60",
        className,
      )}
    >
      {rows.length > 0 ? (
        rows.map((row) => {
          const groupRow = renderGroupRow?.({ row });

          return row.getIsGrouped() ? (
            <React.Fragment key={row.id}>
              {groupRow !== undefined && (
                <Table.Row
                  className="bg-secondary-background"
                  data-index={row.index}
                >
                  <Table.Cell
                    className="px-4 py-[6px]"
                    colSpan={row.getVisibleCells().length}
                  >
                    {groupRow}
                  </Table.Cell>
                </Table.Row>
              )}
              {row.subRows.map((subRow) => (
                <RenderRow
                  key={subRow.id}
                  renderExpandedSubcomponent={renderExpandedSubcomponent}
                  row={subRow}
                />
              ))}
            </React.Fragment>
          ) : (
            <RenderRow
              key={row.id}
              renderExpandedSubcomponent={renderExpandedSubcomponent}
              row={row}
            />
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={table.getVisibleLeafColumns().length}>
            {placeholder}
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
}

export function DataTable<TData, TValue>({
  bodyClassName,
  className,
  contentClassName,
  columnHeaderClassName,
  columns = [],
  data = [],
  defaultColumnFilters = [],
  defaultGlobalFilter = "",
  defaultPagination = {
    pageIndex: 0,
    pageSize: TABLE_PAGE_SIZE_OPTIONS[0].value,
  },
  defaultRowSelection = {},
  defaultSorting = [],
  defaultVisibility = {},
  isFetching,
  onSortingChange,
  placeholder,
  table,
  renderExpandedSubcomponent,
  renderGroupRow,
  renderTableHeader,
  renderTableFooter,
  ...props
}: DataTableProps<TData, TValue>) {
  const { md } = useScreenSize();

  const [globalFilter, setGlobalFilter] = useState(defaultGlobalFilter);
  const [columnFilters, setColumnFilters] = useState(defaultColumnFilters);
  const [columnVisibility, setColumnVisibility] = useState(defaultVisibility);
  const [sorting, setSorting] = useState(defaultSorting);
  const [pagination, setPagination] = useState(defaultPagination);
  const [rowSelection, setRowSelection] = useState(defaultRowSelection);

  const internalTable = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination,
      rowSelection,
      sorting,
    },
  });

  const finalTable = table ?? internalTable;
  const rows = finalTable.getRowModel().rows;

  const aggSize = finalTable
    .getHeaderGroups()
    .map((headerGroup) =>
      headerGroup.headers.map((header) => header.column.columnDef.size),
    )
    .flat(1)
    .map((size) => size ?? 0)
    .reduce((prev, curr) => prev + curr, 0);

  const TableHeaderProp = renderTableHeader;
  const TableFooterProp = renderTableFooter ?? TablePagination;

  const handleSortingChange = useCallback(
    (header: Header<TData, unknown>) => {
      if (onSortingChange?.(header.column) === false) return;
      const sortedState = header.column.getIsSorted();
      header.column.toggleSorting(sortedState === "asc");
    },
    [onSortingChange],
  );

  return (
    <div
      className={cn(
        "relative divide-y divide-other-divider rounded-2xl shadow-elevation1 flex flex-col overflow-auto",
        className,
      )}
      {...props}
    >
      {Boolean(isFetching) && (
        <div
          className="absolute inset-0 flex items-center justify-center"
          hidden
        >
          <LoadingComponent className="size-full" />
        </div>
      )}
      {TableHeaderProp !== undefined && (
        <TableHeaderProp className="!border-t-0" table={finalTable} />
      )}
      {!isFetching && rows.length === 0 ? (
        placeholder
      ) : (
        <>
          <Table
            className={cn(
              "border-none",
              rows.length === 0 && "h-full",
              contentClassName,
            )}
            containerClassName="flex-auto"
          >
            {md ? (
              <>
                <Table.Header className={columnHeaderClassName}>
                  {finalTable.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const canSort = header.column.getCanSort();
                        const sortedState = header.column.getIsSorted();
                        const icon =
                          sortedState === false ? (
                            <ChevronUpDownIcon className="size-[1.125rem]" />
                          ) : (
                            <ChevronUpIcon
                              className={cn(
                                "size-[0.875rem] my-[0.0625rem]",
                                {
                                  asc: "self-start",
                                  desc: "self-end rotate-180",
                                  false: "",
                                }[sortedState.toString()],
                              )}
                            />
                          );

                        return (
                          <Table.Head
                            className={cn(
                              "relative",
                              canSort && "cursor-pointer select-none",
                            )}
                            key={header.id}
                            {...(canSort && {
                              onClick: () => {
                                handleSortingChange(header);
                              },
                            })}
                            {...(header.column.columnDef.size && {
                              style: {
                                width: `${(header.column.columnDef.size / aggSize) * 100}%`,
                              },
                            })}
                          >
                            <div className="inline-flex w-full items-center justify-between gap-2 whitespace-nowrap">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {Boolean(canSort) && (
                                <div className="flex size-[1.125rem] items-center justify-center">
                                  {icon}
                                </div>
                              )}
                            </div>
                          </Table.Head>
                        );
                      })}
                    </Table.Row>
                  ))}
                </Table.Header>
                <DataTableBody
                  className={bodyClassName}
                  isFetching={isFetching}
                  placeholder={placeholder}
                  renderExpandedSubcomponent={
                    renderExpandedSubcomponent as (props: {
                      row: Row<unknown>;
                    }) => React.ReactNode | undefined
                  }
                  renderGroupRow={
                    renderGroupRow as (props: {
                      row: Row<unknown>;
                    }) => React.ReactNode | undefined
                  }
                  table={finalTable as TableType<unknown>}
                />
              </>
            ) : (
              <Table.Body className={cn("flex-auto", bodyClassName)}>
                {rows.map((row) => {
                  const groupRow = renderGroupRow?.({ row });

                  return row.getIsGrouped() ? (
                    <React.Fragment key={row.id}>
                      {groupRow !== undefined && (
                        <Table.Row
                          className="bg-secondary-background"
                          data-index={row.index}
                        >
                          <Table.Cell
                            className="px-4 py-[6px]"
                            colSpan={row.getVisibleCells().length}
                          >
                            {groupRow}
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {row.subRows.map((subRow) => (
                        <RenderRowMobile
                          className="relative"
                          key={subRow.id}
                          renderExpandedSubcomponent={
                            renderExpandedSubcomponent
                          }
                          row={subRow}
                        />
                      ))}
                    </React.Fragment>
                  ) : (
                    <RenderRowMobile
                      className="relative"
                      key={row.id}
                      renderExpandedSubcomponent={renderExpandedSubcomponent}
                      row={row}
                    />
                  );
                })}
              </Table.Body>
            )}
          </Table>
          <TableFooterProp table={finalTable} />
        </>
      )}
    </div>
  );
}
