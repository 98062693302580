import { createFileRoute } from "@tanstack/react-router";

interface LoaderProps {
  params: {
    evaluationId: string;
  };
}

export const Route = createFileRoute("/evaluations/$evaluationId")({
  beforeLoad: () => {
    return { routeId: "evaluationView" };
  },
  loader: ({ params }: LoaderProps) => params.evaluationId,
});
