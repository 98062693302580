import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { EvaluationCycleStatus } from "@/api/evaluationCycles";
import { cn } from "@/utils/ui";

export interface EvaluationStatusIndicatorProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  status: EvaluationCycleStatus;
}

export function EvaluationCycleStatusIndicator({
  className,
  status,
  ...props
}: EvaluationStatusIndicatorProps) {
  const { t } = useTranslation();

  return (
    <div className={cn("flex items-center gap-2", className)} {...props}>
      <div
        className={cn(
          "h-2 w-2 rounded-full",
          status === EvaluationCycleStatus.Active
            ? "bg-success-main"
            : "bg-actions-disabled-background",
        )}
      />
      <span className="text-body2 text-text-primary">
        {t(`constants.cycleStatus.${status}`)}
      </span>
    </div>
  );
}
