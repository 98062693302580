import { useTranslation } from "react-i18next";

import {
  ConfirmDialog,
  type ConfirmDialogProps,
} from "@/components/ui/ConfirmDialog";
import { useScreenSize } from "@/utils/hooks/useScreenSize";

export interface RemoveProjectConfirmDialogProps
  extends Omit<
    ConfirmDialogProps,
    "description" | "footerButtons" | "title" | "variant"
  > {
  isLoading?: boolean;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
}

export function RemoveProjectConfirmDialog({
  isLoading,
  onCancelClick,
  onConfirmClick,
  ...props
}: RemoveProjectConfirmDialogProps) {
  const { t } = useTranslation();
  const { sm } = useScreenSize();

  const screenType = sm ? "default" : "mobile";

  return (
    <ConfirmDialog
      description={t("components.removeProjectConfirmDialog.description")}
      footerButtons={[
        {
          action: "cancel",
          disabled: isLoading,
          label: t(
            `components.removeProjectConfirmDialog.buttonCancel.${screenType}`,
          ),
          onClick: onCancelClick,
          variant: "outlined",
        },
        {
          action: "submit",
          disabled: isLoading,
          label: t(
            `components.removeProjectConfirmDialog.buttonConfirm.${screenType}`,
          ),
          onClick: onConfirmClick,
        },
      ]}
      title={t("components.removeProjectConfirmDialog.title")}
      variant="danger"
      {...props}
    />
  );
}
