import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import FileSearching from "@/assets/svg/file_searching.svg?react";
import { withLoadingState } from "@/utils/decorators";
import { cn } from "@/utils/ui";

export interface EmptyDashboardProps extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
}

export const EmptyDashboard = withLoadingState(function EmptyDashboard({
  children,
  className,
  isLoading,
  ...props
}: EmptyDashboardProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "relative h-full flex items-center justify-center",
        className,
      )}
      {...props}
    >
      {children}

      <div
        className={cn(
          "space-y-[4.5rem] text-center max-w-full px-6",
          Boolean(isLoading) && "opacity-0",
        )}
      >
        <FileSearching
          className="m-auto h-[12.9375rem]"
          title={t("components.navigationTable.emptyDashboardTitle")}
        />
        <p className="m-auto w-[35.125rem] max-w-full text-body1 text-text-hint max-md:text-caption">
          {t("components.navigationTable.emptyDashboardInfo")}
        </p>
      </div>
    </div>
  );
});
