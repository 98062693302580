import { type ComponentProps } from "react";
import { type FieldPath, type FieldValues } from "react-hook-form";

import { Form } from "@/components/ui/Form";
import {
  Select,
  type SelectOption,
  type SingleSelectProps,
} from "@/components/ui/Select";
import { cn } from "@/utils/ui";

export interface SelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ComponentProps<typeof Form.Field<TFieldValues, TName>>,
    "render"
  > {
  className?: string;
  fallbackValue?: number | string;
  label: string;
  onChange?: SingleSelectProps["onChange"];
  options: SelectOption[];
  placeholder?: string;
  value?: SelectOption | null;
}

export function SelectField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  className,
  fallbackValue,
  label,
  onChange,
  options,
  placeholder,
  value: controlledValue,
  ...props
}: SelectFieldProps<TFieldValues, TName>) {
  return (
    <Form.Field
      render={({ field: { value, onChange: handleChange, ...field } }) => (
        <Form.Item className={cn("space-y-[0.625rem]", className)}>
          <Form.Label className="text-subtitle2 text-text-secondary">
            {label}
          </Form.Label>
          <Form.Control>
            <Select
              allowClear
              onChange={(option) => {
                handleChange(option?.value ?? fallbackValue);
                onChange?.(option);
              }}
              options={options}
              placeholder={placeholder}
              type="single"
              value={
                controlledValue ??
                options.find(
                  (option) => option.value === (value ?? fallbackValue),
                )
              }
              {...field}
            />
          </Form.Control>
        </Form.Item>
      )}
      {...props}
    />
  );
}
