import { format } from "date-fns";
import { decamelize } from "humps";

import type { EvaluationRatingQueryParams } from "@/api/evaluationRatings";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

import { formSchema } from "./FilterForm/utils";

export const formToFiltersTransformer = formSchema.transform(
  ({ evaluatedAt, ...schema }) => ({
    ...schema,
    ...(evaluatedAt?.from && {
      evaluatedAtAfter: format(evaluatedAt.from, "yyyy-MM-dd"),
    }),
    ...(evaluatedAt?.to && {
      evaluatedAtBefore: format(evaluatedAt.to, "yyyy-MM-dd"),
    }),
  }),
);

export const tableStateToQueryParamsTransformer =
  TableStateBaseParamsSchema.extend({
    filters: formToFiltersTransformer.optional(),
  }).transform(
    ({ filters, ordering, ...schema }): EvaluationRatingQueryParams => ({
      ...filters,
      ...schema,
      ordering: ordering?.map(decamelize) ?? [],
    }),
  );
