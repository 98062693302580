/* eslint-disable react/no-array-index-key */
import { StarIcon } from "@heroicons/react/24/solid";
import { cva, type VariantProps } from "class-variance-authority";
import React, { forwardRef, Fragment, useState } from "react";

import { IconButton } from "@/components/ui/IconButton";
import { cn } from "@/utils/ui";

const ratingVariants = cva("whitespace-nowrap p-[0.125rem]", {
  variants: {
    size: {
      large: "size-[30px]",
      medium: "size-6",
      small: "size-[18px]",
      auto: "size-[18px] md:size-6",
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

interface RatingProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "value" | "size" | "onChange"
    >,
    VariantProps<typeof ratingVariants> {
  activeRatingClassName?: string;
  buttonClassName?: string;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  inactiveRatingClassName?: string;
  onChange?: (value: number) => void;
  readOnly?: boolean;
  type?: "discrete" | "continuous";
  value?: number;
}

const Rating = forwardRef<HTMLInputElement, RatingProps>(
  (
    {
      activeRatingClassName,
      buttonClassName,
      className,
      disabled,
      icon = <StarIcon />,
      inactiveRatingClassName,
      onChange = () => undefined,
      readOnly = false,
      size = "medium",
      type = "discrete",
      value,
      ...props
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState(value ?? 0);

    const actualValue = value ?? internalValue;

    const handleChange = (rating: number) => {
      const nextRating = actualValue === rating ? 0 : rating;

      if (value === undefined) {
        setInternalValue(nextRating);
      }

      onChange(nextRating);
    };

    return (
      <div
        className={cn(
          "flex items-center",
          type === "continuous" && "gap-[0.125rem]",
          className,
        )}
      >
        <input ref={ref} type="hidden" value={actualValue} {...props} />
        {
          {
            continuous: [
              <Fragment key="option">
                <span className="text-body2 leading-none text-text-primary">
                  {actualValue.toFixed(2)}
                </span>
                <IconButton
                  className={cn(
                    ratingVariants({ size }),
                    actualValue > 0
                      ? "text-other-active-rating"
                      : "text-other-border",
                    "pointer-events-none outline-none",
                    actualValue > 0
                      ? activeRatingClassName
                      : inactiveRatingClassName,
                    buttonClassName,
                  )}
                  icon={<Fragment key={size}>{icon}</Fragment>}
                />
              </Fragment>,
            ],
            discrete: [...Array<number>(5)].map((_, index) => (
              <IconButton
                className={cn(
                  ratingVariants({ size }),
                  actualValue > index
                    ? "text-other-active-rating"
                    : "text-other-border",
                  readOnly && "pointer-events-none outline-none",
                  actualValue > index
                    ? activeRatingClassName
                    : inactiveRatingClassName,
                  buttonClassName,
                )}
                disabled={disabled}
                icon={<Fragment key={size}>{icon}</Fragment>}
                key={index}
                onClick={() => {
                  if (!readOnly) handleChange(index + 1);
                }}
              />
            )),
          }[type]
        }
      </div>
    );
  },
);
Rating.displayName = "Rating";

export { Rating };
