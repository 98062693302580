import { z } from "zod";

import { generatePaginatedResponseSchema } from "@/api/utils";

import { EvaluationStatus, RatingChoice } from "../constants";

export enum EvaluationCycleStatus {
  Active = "active",
  Inactive = "inactive",
}

export const EvaluationCycleSchema = z.object({
  id: z.number(),
  createdAt: z.string(),
  deadline: z.string(),
  name: z.string(),
  status: z.nativeEnum(EvaluationCycleStatus),
});

export const EvaluationCycleListResponseSchema =
  generatePaginatedResponseSchema(EvaluationCycleSchema);

export const EvaluationCycleQueryParamsSchema = z.object({
  status: z.nativeEnum(EvaluationCycleStatus).optional(),
});

export const CreateEvaluationCycleSchema = z.object({
  periodStartDate: z.coerce.date(),
  periodEndDate: z.coerce.date(),
  deadline: z.coerce.date(),
});

export const AdminEvaluationCycleSchema = z.object({
  id: z.number(),
  name: z.string(),
  deadline: z.coerce.date(),
  progress: z.number(),
  status: z.nativeEnum(EvaluationCycleStatus),
});

export const EvaluationCycleMemberSchema = z.object({
  id: z.number(),
  avatar: z.string(),
  avgRating: z.number().nullable(),
  email: z.string(),
  evaluateeCount: z.number(),
  evaluatorCount: z.number(),
  fullName: z.string(),
  projectCount: z.number(),
  projects: z.object({ id: z.number(), name: z.string() }).array(),
  selfRating: z.number().nullable(),
});

export const EvaluationCycleMemberRatingSchema = z.object({
  id: z.number(),
  avatar: z.string(),
  email: z.string(),
  evaluatedAt: z.string(),
  evaluationStatus: z.nativeEnum(EvaluationStatus),
  fullName: z.string(),
  projectId: z.number(),
  projectName: z.string(),
  rating: z.nativeEnum(RatingChoice),
  ratingNotes: z.string(),
});

export const EvaluationCycleMemberRatingListResponseSchema =
  generatePaginatedResponseSchema(EvaluationCycleMemberRatingSchema);

export const EvaluationCycleMemberEvaluateeQueryParamsSchema = z.object({
  evaluatedAtAfter: z.string().optional(),
  evaluatedAtBefore: z.string().optional(),
  evaluatee: z.number().array().optional(),
  project: z.number().array().optional(),
  rating: z.number().array().optional(),
  search: z.string().optional(),
});

export const EvaluationCycleMemberEvaluateeQueryParamsSchemaTransformer =
  EvaluationCycleMemberEvaluateeQueryParamsSchema.transform(
    ({ project, rating, ...schema }) => ({
      ...schema,
      ...(project && { project: project.join(",") }),
      ...(rating && { rating: rating.join(",") }),
    }),
  );

export const EvaluationCycleMemberEvaluatorQueryParamsSchema =
  EvaluationCycleMemberEvaluateeQueryParamsSchema.omit({
    evaluatee: true,
  }).extend({
    evaluator: z.number().array().optional(),
  });

export const EvaluationCycleMemberEvaluatorQueryParamsSchemaTransformer =
  EvaluationCycleMemberEvaluatorQueryParamsSchema.transform(
    ({ project, rating, ...schema }) => ({
      ...schema,
      ...(project && { project: project.join(",") }),
      ...(rating && { rating: rating.join(",") }),
    }),
  );

export const EvaluationCycleMemberReceivedEvaluationQueryParamsSchema =
  z.object({
    ordering: z.string().array().optional(),
  });

export const EvaluationCycleMemberReceivedEvaluationsQueryParamsSchemaTransformer =
  EvaluationCycleMemberReceivedEvaluationQueryParamsSchema.transform(
    ({ ordering }) => ({
      ...(ordering && { ordering: ordering.join(",") }),
    }),
  );

export type EvaluationCycle = z.infer<typeof EvaluationCycleSchema>;

export type EvaluationCycleListResponse = z.infer<
  typeof EvaluationCycleListResponseSchema
>;

export type EvaluationCycleQueryParams = z.infer<
  typeof EvaluationCycleQueryParamsSchema
>;

export type EvaluationCycleMember = z.infer<typeof EvaluationCycleMemberSchema>;

export type EvaluationCycleMemberRating = z.infer<
  typeof EvaluationCycleMemberRatingSchema
>;

export type EvaluationCycleMemberRatingListResponse = z.infer<
  typeof EvaluationCycleMemberRatingListResponseSchema
>;

export type EvaluationCycleMemberEvaluateeQueryParams = z.infer<
  typeof EvaluationCycleMemberEvaluateeQueryParamsSchema
>;

export type EvaluationCycleMemberEvaluatorQueryParams = z.infer<
  typeof EvaluationCycleMemberEvaluatorQueryParamsSchema
>;

export type EvaluationCycleMemberReceivedEvaluationQueryParams = z.infer<
  typeof EvaluationCycleMemberReceivedEvaluationQueryParamsSchema
>;

export type CreateEvaluationCycleBody = z.infer<
  typeof CreateEvaluationCycleSchema
>;

export type AdminEvaluationCycle = z.infer<typeof AdminEvaluationCycleSchema>;

export const evaluationCyclesKeys = {
  current: ["api", "evaluationCycles", "current"],
  detail: (id: number) => ["api", "evaluationCycles", id],
  list: (params?: EvaluationCycleQueryParams) =>
    params ? ["api", "evaluationCycles", params] : ["api", "evaluationCycles"],
  memberDetail: (cycleId: number | "current", memberId: number) => [
    "api",
    "evaluationCycles",
    cycleId,
    "members",
    memberId,
  ],
  memberEvaluateeRatingDetail: (
    cycleId: number | "current",
    memberId: number,
    evaluateeId: number,
  ) => [
    "api",
    "evaluationCycles",
    cycleId,
    "members",
    memberId,
    "evaluatees",
    evaluateeId,
  ],
  memberEvaluateeRatingList: (
    cycleId: number | "current",
    memberId: number,
    params?: EvaluationCycleMemberEvaluateeQueryParams,
  ) =>
    params
      ? [
          "api",
          "evaluationCycles",
          cycleId,
          "members",
          memberId,
          "evaluatees",
          params,
        ]
      : ["api", "evaluationCycles", cycleId, "members", memberId, "evaluatees"],
  memberEvaluatorRatingDetail: (
    cycleId: number | "current",
    memberId: number,
    evaluatorId: number,
  ) => [
    "api",
    "evaluationCycles",
    cycleId,
    "members",
    memberId,
    "evaluators",
    evaluatorId,
  ],
  memberEvaluatorRatingList: (
    cycleId: number | "current",
    memberId: number,
    params?: EvaluationCycleMemberEvaluatorQueryParams,
  ) =>
    params
      ? [
          "api",
          "evaluationCycles",
          cycleId,
          "members",
          memberId,
          "evaluators",
          params,
        ]
      : ["api", "evaluationCycles", cycleId, "members", memberId, "evaluators"],
  memberReceivedEvaluationList: (
    cycleId: number | "current",
    memberId: number,
    params?: EvaluationCycleMemberReceivedEvaluationQueryParams,
  ) =>
    params
      ? [
          "api",
          "evaluationCycles",
          cycleId,
          "members",
          memberId,
          "received-evaluations",
          params,
        ]
      : [
          "api",
          "evaluationCycles",
          cycleId,
          "members",
          memberId,
          "received-evaluations",
        ],
};
