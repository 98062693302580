import { FolderOpenIcon, StarIcon } from "@heroicons/react/24/solid";
import { Link } from "@tanstack/react-router";
import type { HTMLAttributes } from "react";

import { EvaluationStatus } from "@/api/constants";
import type { EvaluationRating } from "@/api/evaluationRatings";
import { useGetCurrentUser } from "@/api/users";
import { Avatar } from "@/components/ui/Avatar";
import { cn } from "@/utils/ui";

export interface UserRatingInfoCellProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  evaluationRating: EvaluationRating;
}

export function UserRatingInfoCell({
  className,
  evaluationRating,
  ...props
}: UserRatingInfoCellProps) {
  const { currentUser } = useGetCurrentUser();

  const evaluatee = evaluationRating.evaluatee;
  const isDraft = evaluationRating.evaluation.status === EvaluationStatus.Draft;
  const isSelfEval = evaluationRating.evaluatee.id === currentUser?.id;

  const statusIcon = isSelfEval ? (
    <StarIcon
      className="size-6 text-primary-main"
      data-row-highlight="primary"
    />
  ) : (
    isDraft && (
      <FolderOpenIcon
        className="size-6 text-text-hint"
        data-row-highlight="secondary"
      />
    )
  );

  return (
    <div className={cn("flex items-center gap-2", className)} {...props}>
      <div className="h-[2.625rem] w-9 py-[0.5625rem] pr-3 max-md:hidden">
        {Boolean(isSelfEval || isDraft) && statusIcon}
      </div>

      <div className="flex gap-2 max-md:flex-col md:h-fit md:items-center">
        <div className="max-md:flex max-md:items-center max-md:gap-2">
          <Avatar
            displayName={evaluatee.fullName}
            size={42}
            src={evaluatee.avatar}
          />
          <div className="md:hidden">
            {Boolean(isSelfEval || isDraft) && statusIcon}
          </div>
        </div>
        <div>
          <Link
            params={{ evaluationId: `${evaluationRating.evaluation.id}` }}
            search={{
              evaluateeId: evaluationRating.evaluatee.id,
              projectId: evaluationRating.projectId,
            }}
            to="/evaluations/$evaluationId"
          >
            <p className="text-subtitle2 text-text-primary hover:underline">
              {evaluatee.fullName}
            </p>
          </Link>
          <p className="text-body2 text-text-secondary">{evaluatee.email}</p>
        </div>
      </div>
    </div>
  );
}
