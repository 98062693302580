import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { CreateEvaluationPage } from "@/components/pages/CreateEvaluationPage";

const routeParamsSchema = z
  .object({
    evaluateeId: z.string().or(z.number()).optional(),
    projectId: z.string().or(z.number()).optional(),
    step: z.string().or(z.number()).optional(),
  })
  .transform(({ evaluateeId, projectId, step }) => {
    const numEvaluateeId = Number.parseInt(`${evaluateeId ?? ""}`);
    const numProjectId = Number.parseInt(`${projectId ?? ""}`);
    const numStep = Number.parseInt(`${step ?? "1"}`);

    return {
      ...(!Number.isNaN(numEvaluateeId) && { evaluateeId: numEvaluateeId }),
      ...(!Number.isNaN(numProjectId) && { projectId: numProjectId }),
      ...(!Number.isNaN(numStep) && {
        step: Math.max(1, Math.min(4, numStep)),
      }),
    };
  });

export const Route = createFileRoute("/evaluations/create/")({
  component: CreateEvaluationPage,
  beforeLoad: () => ({ routeId: null }),
  validateSearch: (search) => routeParamsSchema.parse(search),
});
