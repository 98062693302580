import { TrashIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/solid";
import type { MouseEventHandler } from "react";

import type { BasicUser } from "@/api/users";
import { UserThumbnail } from "@/components/app/UserThumbnail";
import { IconButton } from "@/components/ui/IconButton";
import { cn } from "@/utils/ui";

import { StatusIndicator } from "./StatusIndicator";

export interface UserListItemProps {
  className?: string;
  onClick?: (user: BasicUser) => void;
  onDeleteClick?: (user: BasicUser) => void;
  selected?: boolean;
  showEmail?: boolean;
  status?: "evaluated" | "pending" | "none";
  user: BasicUser | null;
}

export function UserListItem({
  className,
  onClick,
  onDeleteClick,
  selected,
  showEmail,
  status = "none",
  user,
  ...props
}: UserListItemProps) {
  const handleClick = () => {
    if (user === null) return;
    onClick?.(user);
  };

  const handleDeleteClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (user === null) return;
    onDeleteClick?.(user);
  };

  return (
    <div
      className={cn(
        "group flex items-center justify-between gap-[0.625rem] rounded-lg px-5 py-2 hover:bg-secondary-background data-[state=selected]:bg-primary-main",
        className,
      )}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      {...props}
    >
      {user !== null && (
        <UserThumbnail
          className="min-w-0 flex-auto overflow-hidden"
          contrast={selected}
          showEmail={showEmail}
          user={user}
        />
      )}

      <div className="flex items-center xl:gap-2">
        {
          {
            pending: (
              <StatusIndicator
                className="group-data-[state=selected]:text-primary-contrast-text"
                icon={
                  <EllipsisHorizontalCircleIcon className="size-4 text-text-secondary group-data-[state=selected]:text-primary-contrast-text" />
                }
                text="Pending"
              />
            ),
            evaluated: (
              <StatusIndicator
                className="group-data-[state=selected]:text-primary-contrast-text"
                icon={
                  <CheckCircleIcon className="size-4 text-success-main group-data-[state=selected]:text-success-light" />
                }
                text="Evaluated"
              />
            ),
            none: null,
          }[status]
        }

        {Boolean(onDeleteClick) && (
          <IconButton
            icon={
              <TrashIcon className="size-[1.125rem] text-error-main group-data-[state=selected]:text-error-light" />
            }
            onClick={handleDeleteClick}
          />
        )}
      </div>
    </div>
  );
}
