import { XMarkIcon } from "@heroicons/react/24/outline";

import { IconButton } from "@/components/ui/IconButton";
import { cn } from "@/utils/ui";

export interface TagProps {
  className?: string;
  label?: string;
  onRemoveClick?: () => void;
  value?: string;
}

export function Tag({
  className,
  label,
  onRemoveClick,
  value,
  ...props
}: TagProps) {
  return (
    <div
      className={cn(
        "flex w-fit items-center gap-3.5 rounded-full bg-grey-200 px-3.5 py-[0.3125rem] pr-[0.3125rem]",
        className,
      )}
      {...props}
    >
      <span className="text-caption leading-none text-text-secondary">
        {`${label}: `}
        <span className="text-button-small leading-none text-text-secondary">
          {value}
        </span>
      </span>

      <IconButton
        className="size-4 bg-grey-400 hover:bg-grey-600"
        color="custom"
        icon={<XMarkIcon className="stroke-[4px] text-grey-200" />}
        onClick={onRemoveClick}
      />
    </div>
  );
}
