import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export const formSchema = z.object({
  evaluatee: z.number().array().optional(),
  cycle: z.number().array().optional(),
  evaluators: z.number().array().optional(),
  selfRating: z.number().array().optional(),
  avgRating: z.number().array().optional(),
  projects: z.number().array().optional(),
  evaluatedAt: z
    .object({
      from: z.coerce.date().optional(),
      to: z.coerce.date().optional(),
    })
    .optional(),
});

export type FormData = z.infer<typeof formSchema>;

export const defaultValues: FormData = {
  evaluatee: [],
  cycle: [],
  evaluators: [],
  selfRating: [],
  avgRating: [],
  projects: [],
  evaluatedAt: {},
};

export const resolver = zodResolver(formSchema);
