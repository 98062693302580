import { useQuery } from "@tanstack/react-query";
import { decamelizeKeys } from "humps";
import qs from "query-string";

import { type QueryOptions } from "../constants";
import { api, handleApiError } from "../utils";
import {
  type AdminEvaluationQueryParams,
  AdminEvaluationQueryParamsSchemaTransformer,
  evaluationsKeys,
} from "./constants";

export const downloadEvaluations = async (
  params: AdminEvaluationQueryParams,
) => {
  const searchParams = qs.stringify(
    decamelizeKeys(AdminEvaluationQueryParamsSchemaTransformer.parse(params)),
    { arrayFormat: "comma" },
  );
  const response = await api.get("evaluations/download", {
    searchParams,
  });

  await handleApiError(response);

  return response.blob();
};

export const useDownloadEvaluations = (
  params: AdminEvaluationQueryParams = {},
  options: QueryOptions = { enabled: true },
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled: options.enabled,
    queryFn: () => downloadEvaluations(params),
    queryKey: evaluationsKeys.list(params),
  });

  return { dataCsv: data || null, isFetched, isFetching };
};
