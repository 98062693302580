import { createFileRoute, redirect } from "@tanstack/react-router";

import { UserRole } from "@/api/users";
import { checkAuthentication } from "@/utils/auth";

export const Route = createFileRoute("/")({
  beforeLoad: ({ context, location }) => {
    checkAuthentication(location.href, context.auth);

    if (!context.auth) {
      return;
    }

    const {
      auth: { userRole },
    } = context;

    if (userRole === UserRole.Administrator) {
      throw redirect({
        to: "/admin",
      });
    }

    throw redirect({
      to: "/evaluations",
    });
  },
});
