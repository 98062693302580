import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Translation } from "react-i18next";

import type { ReceivedEvaluation } from "@/api/evaluations";

import { UserRatingInfoCell } from "../../UserRatingInfoCell";
import type { FilterFn } from "../columns";

export const filterFn: FilterFn<string, ReceivedEvaluation> = (
  _row,
  _,
  _filterValue,
) => true;

export const columnHelper = createColumnHelper<ReceivedEvaluation>();

export const columns = [
  columnHelper.accessor((row) => row.evaluator.fullName, {
    id: "evaluator",
    enableGlobalFilter: true,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>
        {(t) => t("admin.memberDetails.header.evaluator")}
      </Translation>
    ),
    cell: (info) => (
      <UserRatingInfoCell
        className="max-md:mb-1"
        user={info.row.original.evaluator}
      />
    ),
  }),

  columnHelper.display({
    id: "rankingNotes",
    filterFn,
    header: () => (
      <Translation>
        {(t) => t("admin.memberDetails.header.rankingNotes")}
      </Translation>
    ),
    cell: (info) => (
      <p className="whitespace-pre-line break-words text-body2 text-text-secondary max-md:text-caption max-md:text-text-primary">
        {info.row.original.rankingNotes}
      </p>
    ),
  }),
] as ColumnDef<ReceivedEvaluation>[];
