import { useTranslation } from "react-i18next";

import { type BasicProject } from "@/api/projects";

import { Tag, type TagProps } from "./Tag";

export interface ProjectTagProps extends Omit<TagProps, "onRemoveClick"> {
  project: BasicProject;
  onRemoveClick?: (project: BasicProject) => void;
}

export function ProjectTag({
  onRemoveClick,
  project,
  ...props
}: ProjectTagProps) {
  const { t } = useTranslation();

  return (
    <Tag
      label={t("components.filterTag.project")}
      onRemoveClick={() => onRemoveClick?.(project)}
      value={project.name}
      {...props}
    />
  );
}
