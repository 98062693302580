import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/utils/ui";

export interface DraftRatingStatusIndicatorProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  isPending: boolean;
}

export function DraftRatingStatusIndicator({
  className,
  isPending,
  ...props
}: DraftRatingStatusIndicatorProps) {
  const { t } = useTranslation();

  return (
    <div className={cn("flex items-center gap-2", className)} {...props}>
      <div
        className={cn(
          "h-2 w-2 rounded-full",
          isPending ? "bg-actions-disabled-background" : "bg-success-main",
        )}
      />
      <span className="text-body2 text-text-primary">
        {t(
          `constants.ratingDraftStatus.${isPending ? "pending" : "evaluated"}`,
        )}
      </span>
    </div>
  );
}
