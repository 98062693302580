import { useTranslation } from "react-i18next";

import { EVALUATION_RATINGS, type RatingChoice } from "@/api/constants";

import { Tag, type TagProps } from "./Tag";

export interface RatingTagProps extends Omit<TagProps, "onRemoveClick"> {
  onRemoveClick?: (rating: RatingChoice) => void;
  rating: RatingChoice;
}

export function RatingTag({ onRemoveClick, rating, ...props }: RatingTagProps) {
  const { t } = useTranslation();
  const numRating = EVALUATION_RATINGS[rating];

  return (
    <Tag
      label={t("components.filterTag.rating")}
      onRemoveClick={() => onRemoveClick?.(rating)}
      value={`${numRating} - ${t(`constants.ratings.${numRating}.label`)}`}
      {...props}
    />
  );
}
