import { useCallback, useEffect, useMemo } from "react";

import { EVALUATION_RATINGS } from "@/api/evaluationRatings";
import { useGetCurrentEvaluation } from "@/api/evaluations";
import type { BasicProject } from "@/api/projects";
import type { User } from "@/api/users";

import {
  type FormData as EvaluationRatingFormData,
  FormSchema as formSchema,
} from "../../CreateEvaluationForm/utils";
import type { BasicRating } from "../types";
import { useEvaluationPageStore } from "./useEvaluationPageStore";
import { useMemberSelectionState } from "./useMemberSelectionState";

const isRatingPending = (rating: BasicRating) => {
  const parsedRating: EvaluationRatingFormData = {
    evaluatee: rating.evaluateeId!,
    project: rating.projectId,
    rating: EVALUATION_RATINGS[rating.rating],
    ratingNotes: rating.ratingNotes ?? "",
  };

  return !formSchema.safeParse(parsedRating).success;
};

export const useEvaluationPageFormDefaultValues = ({
  users,
}: { users?: User[] } = {}) => {
  const { evaluation, isFetched, isFetching } = useGetCurrentEvaluation();

  const isInitialized = useEvaluationPageStore((state) => state._isInitialized);
  const initializeStore = useEvaluationPageStore((state) => state._initialize);
  const getRating = useEvaluationPageStore((state) => state.getRating);
  const getSelectedProjects = useEvaluationPageStore(
    (state) => state.getSelectedProjects,
  );
  const getSelectedProjectMembers = useEvaluationPageStore(
    (state) => state.getSelectedProjectMembers,
  );
  const initializeRatings = useEvaluationPageStore(
    (state) => state.clearRatings,
  );

  const { clickedUserId, clickedProjectId, isCurrentUserClicked } =
    useMemberSelectionState();

  const initializeEvaluationState = useCallback(() => {
    if (isFetching || isInitialized) {
      return;
    }

    const ratings =
      evaluation?.draftData?.ratings?.map((rating) => ({
        ...rating,
        evaluatee: { id: rating.evaluateeId },
        evaluationId: evaluation.id,
        projectName: rating.projectName,
        rating: rating.rating ?? "",
        isSelfEvaluation: rating.projectId === undefined,
      })) ??
      evaluation?.ratings ??
      [];

    const projectMemberRatings: Record<
      string,
      Record<string, BasicRating>
    > = {};
    const storeProjects: BasicProject[] = [];

    ratings.forEach((rating) => {
      if (rating.isSelfEvaluation) {
        projectMemberRatings.self = {
          eval: {
            ...rating,
            projectId: undefined,
            evaluateeId: rating.evaluatee.id,
          },
        };
        return;
      }

      if (!(`${rating.projectId}` in projectMemberRatings)) {
        projectMemberRatings[`${rating.projectId}`] = {
          project: {
            evaluationId: 0,
            isSelfEvaluation: false,
            projectId: rating.projectId,
            projectName: rating.projectName,
            rating: "",
          },
        };
        if (rating.projectId && rating.projectName) {
          storeProjects.push({
            id: rating.projectId,
            name: rating.projectName,
          });
        }
      }

      if (rating.evaluatee.id !== undefined) {
        projectMemberRatings[`${rating.projectId}`][`${rating.evaluatee.id}`] =
          {
            ...rating,
            evaluateeId: rating.evaluatee.id,
          };
      }
    });

    const storeProjectMemberRatings = Object.fromEntries(
      Object.entries(projectMemberRatings).map(([projectId, memberRatings]) => [
        projectId,
        Object.fromEntries(
          Object.entries(memberRatings).map(([evaluateeId, rating]) => [
            evaluateeId,
            {
              id: rating.id,
              evaluateeId: rating.evaluateeId,
              projectId: rating.projectId,
              projectName: rating.projectName,
              rating: rating.rating,
              ratingNotes: rating.ratingNotes,
              isPending: isRatingPending(rating),
            },
          ]),
        ),
      ]),
    );

    initializeRatings(storeProjectMemberRatings, storeProjects);
    initializeStore();
  }, [
    evaluation,
    isFetching,
    isInitialized,
    initializeStore,
    initializeRatings,
  ]);

  const defaultValues = useMemo<EvaluationRatingFormData>(() => {
    if (!isInitialized) {
      return {
        evaluatee: 0,
        project: undefined,
        rating: 0,
        ratingNotes: "",
      };
    }

    const hasSelectedMember =
      clickedProjectId !== null || clickedUserId !== null;
    const selectedProjects = getSelectedProjects();
    const resolvedClickedProjectId = hasSelectedMember
      ? clickedProjectId
      : (selectedProjects[0]?.id ?? null);
    const members = getSelectedProjectMembers(
      users ?? [],
      resolvedClickedProjectId,
    );
    const resolvedClickedUserId = hasSelectedMember
      ? clickedUserId
      : (members[0]?.id ?? null);

    const currentRating = getRating({
      evaluatee: resolvedClickedUserId,
      project: !isCurrentUserClicked ? resolvedClickedProjectId : null,
    });

    return {
      evaluatee: resolvedClickedUserId ?? 0,
      project: resolvedClickedProjectId || undefined,
      rating: currentRating?.rating
        ? EVALUATION_RATINGS[currentRating.rating]
        : 0,
      ratingNotes: currentRating?.ratingNotes ?? "",
    };
  }, [
    isInitialized,
    clickedProjectId,
    clickedUserId,
    getSelectedProjects,
    getSelectedProjectMembers,
    users,
    getRating,
    isCurrentUserClicked,
  ]);

  useEffect(() => {
    initializeEvaluationState();
  }, [initializeEvaluationState]);

  return { defaultValues, isFetched, isFetching, initializeEvaluationState };
};
