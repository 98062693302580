import { type ComponentProps, useEffect, useState } from "react";
import { type DateRange } from "react-day-picker";
import {
  type FieldPath,
  type FieldValues,
  useFormContext,
} from "react-hook-form";

import { DateRangePicker } from "@/components/ui/DatePicker";
import { formatDateRange } from "@/components/ui/DatePicker/utils";
import { Form } from "@/components/ui/Form";
import { cn } from "@/utils/ui";

import { type FormData } from "./utils";

export interface DateSubmittedFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ComponentProps<typeof Form.Field<TFieldValues, TName>>,
    "render"
  > {
  className?: string;
  label: string;
}

export function DateSubmittedField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  className,
  label,
  ...props
}: DateSubmittedFieldProps<TFieldValues, TName>) {
  const form = useFormContext<FormData>();
  const formValue = form.getValues("evaluatedAt") as DateRange | undefined;

  const [value, setValue] = useState(formValue);
  const [textInputValue, setTextInputValue] = useState(
    formatDateRange(value, "PP"),
  );

  useEffect(() => {
    setValue(formValue);
    setTextInputValue(formatDateRange(formValue, "PP"));
  }, [form, formValue]);

  return (
    <Form.Field
      render={({ field: { onChange, ...field } }) => (
        <Form.Item className={cn("flex flex-col gap-[0.625rem]", className)}>
          <Form.Label className="text-subtitle2 text-text-secondary">
            {label}
          </Form.Label>
          <DateRangePicker
            className="flex"
            id="evaluatedAt"
            inputClassName="w-full ring-actions-selected"
            onChange={(dateRange) => {
              onChange(dateRange);
              setValue(dateRange);
            }}
            onTextInputChange={setTextInputValue}
            placeholder="Select date range"
            textInputValue={textInputValue}
            {...field}
            value={value}
          />
        </Form.Item>
      )}
      {...props}
    />
  );
}
