import type { RowModel } from "@tanstack/react-table";

export const TABLE_PAGE_SIZE_OPTIONS = [
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "250", value: 250 },
];

export const countLeafRows = <TData>(rowModel: RowModel<TData>) =>
  rowModel.rows
    .map((row) => (row.getIsGrouped() ? row.getLeafRows().length : 1))
    .reduce((prev, n) => prev + n, 0);
