import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";

import { api, handleApiError, validateSchema } from "../utils";
import { EvaluationCycleMemberSchema, evaluationCyclesKeys } from "./constants";

export const getEvaluationCycleMember = async (
  cycleId: number,
  memberId: number,
) => {
  const response = await api.get(
    `evaluation-cycles/${cycleId}/members/${memberId}/`,
  );

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationCycleMemberSchema, data);
};

export const useGetEvaluationCycleMember = (
  cycleId: number,
  memberId: number,
) => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: () => getEvaluationCycleMember(cycleId, memberId),
    queryKey: evaluationCyclesKeys.memberDetail(cycleId, memberId),
    placeholderData: keepPreviousData,
  });

  return { cycleMember: data || null, isFetched, isFetching };
};
