import { useTranslation } from "react-i18next";

import {
  AlertDialog,
  type AlertDialogProps,
} from "@/components/ui/AlertDialog";
import { Button } from "@/components/ui/Button";

export interface SubmitEvaluationAlertDialogProps
  extends Omit<AlertDialogProps, "footer" | "title" | "variant"> {
  onConfirmClick?: () => void;
}

export function SubmitEvaluationAlertDialog({
  onConfirmClick,
  ...props
}: SubmitEvaluationAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      footer={
        <Button onClick={onConfirmClick}>
          {t("components.submitEvaluationAlertDialog.buttonConfirm")}
        </Button>
      }
      title={t("components.submitEvaluationAlertDialog.title")}
      variant="success"
      {...props}
    />
  );
}
