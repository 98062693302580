import "./index.css";
import "./i18n";
import "overlayscrollbars/overlayscrollbars.css";

import { createRoot } from "react-dom/client";

import { App } from "./components/app/App";
import { config } from "./utils/constants";

async function enableMocking() {
  if (!config.enableMocking) {
    return;
  }

  const { worker } = await import("./mocks/browser");

  return worker.start();
}

void enableMocking().then(() => {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  window.addEventListener("vite:preloadError", (_) => {
    window.location.reload();
  });

  root.render(<App />);
});
