import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";

import { type QueryOptions } from "../constants";
import { api, handleApiError, validateSchema } from "../utils";
import {
  EvaluationRatingListResponseSchema,
  type EvaluationRatingQueryParams,
  EvaluationRatingQueryParamsSchemaTransformer,
  evaluationRatingsKeys,
} from "./constants";

const listEvaluationRatings = async (params: EvaluationRatingQueryParams) => {
  const searchParams = qs.stringify(
    decamelizeKeys(EvaluationRatingQueryParamsSchemaTransformer.parse(params)),
    { arrayFormat: "comma" },
  );

  const response = await api.get("evaluation-ratings/", { searchParams });

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationRatingListResponseSchema, data);
};

export const useListEvaluationRatings = (
  params: EvaluationRatingQueryParams = {},
  options: QueryOptions = { enabled: true },
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled: options.enabled,
    queryFn: () => listEvaluationRatings(params),
    queryKey: evaluationRatingsKeys.list(params),
    placeholderData: keepPreviousData,
  });

  return { evaluationRatings: data || null, isFetched, isFetching };
};
