import {
  type ColumnDef,
  createColumnHelper,
  type Row,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { Translation } from "react-i18next";

import { EVALUATION_RATINGS } from "@/api/constants";
import { type EvaluationRating } from "@/api/evaluationRatings";
import { EvaluationCycleStatusIndicator } from "@/components/app/EvaluationCycleStatusIndicator";
import { Rating } from "@/components/ui/Rating";
import { cn } from "@/utils/ui";

import { TableRowAction } from "./TableRowAction";
import { UserRatingInfoCell } from "./UserRatingInfoCell";

type FilterFn<Data> = (
  row: Row<EvaluationTableColumns>,
  _: unknown,
  filterValue?: Data,
) => boolean;

/*
 * Dummy sorting and filter functions
 * Only used for changing table state indicator UI
 */
const filterFn: FilterFn<string> = (_row, _, _filterValue) => true;

const sortingFn = (_rowA: unknown, _rowB: unknown) => 0;

export type EvaluationTableColumns = Omit<EvaluationRating, "">;

const columnHelper = createColumnHelper<EvaluationTableColumns>();

export const columns = [
  columnHelper.accessor((row) => row.evaluatee.fullName, {
    id: "evaluatee",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    sortingFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.memberName")}</Translation>
    ),
    cell: (info) => (
      <UserRatingInfoCell
        className="max-md:mb-2"
        evaluationRating={info.row.original}
      />
    ),
    size: 345,
  }),

  columnHelper.accessor((row) => row.evaluation.cycle.deadline, {
    id: "deadline",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    sortingFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.deadline")}</Translation>
    ),
    cell: (info) => (
      <div className="flex items-center gap-x-1 max-md:mb-[0.125rem] max-md:flex-wrap">
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.deadline")}:`}
          </Translation>
        </span>
        <span className="text-body2 text-text-primary max-md:text-caption">
          {format(info.getValue(), "MMM dd, yyyy")}
        </span>
      </div>
    ),
    size: 181.8,
  }),

  columnHelper.accessor((row) => row.evaluation.evaluatedAt, {
    id: "evaluatedAt",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    sortingFn,
    header: () => (
      <Translation>
        {(t) => t("evaluations.dashboard.dateSubmitted")}
      </Translation>
    ),
    cell: (info) => (
      <div className="flex items-center gap-x-1 max-md:mb-[0.125rem] max-md:flex-wrap">
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.dateSubmitted")}:`}
          </Translation>
        </span>
        <span className="text-body2 text-text-primary max-md:text-caption">
          {info.getValue()
            ? format(info.getValue() || new Date(), "MMM dd, yyyy")
            : "N/A"}
        </span>
      </div>
    ),
    size: 181.8,
  }),

  columnHelper.accessor(
    (row) => (!row.rating ? 0 : EVALUATION_RATINGS[row.rating]),
    {
      id: "rating",
      enableGlobalFilter: false,
      enableMultiSort: true,
      filterFn,
      sortingFn,
      header: () => (
        <Translation>{(t) => t("evaluations.dashboard.rating")}</Translation>
      ),
      cell: (info) => (
        <Rating
          buttonClassName="p-0"
          className="flex items-center p-0 max-md:mb-2"
          readOnly
          size="auto"
          value={info.getValue()}
        />
      ),
      size: 181.8,
    },
  ),

  columnHelper.accessor((row) => row.projectName, {
    id: "project",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    sortingFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.project")}</Translation>
    ),
    cell: (info) => (
      <div
        className={cn(
          "flex items-center gap-x-1 max-md:mb-[0.125rem] max-md:flex-wrap",
          info.row.original.isSelfEvaluation && "max-md:hidden",
        )}
      >
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.project")}:`}
          </Translation>
        </span>
        <span className="text-caption text-text-primary md:text-body2">
          {info.getValue()}
        </span>
      </div>
    ),
    size: 181.8,
  }),

  columnHelper.display({
    id: "cycleStatus",
    enableGlobalFilter: false,
    filterFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.status")}</Translation>
    ),
    cell: (info) => (
      <div className="flex items-center gap-x-1 max-md:flex-wrap">
        <span className="text-caption text-text-secondary md:hidden">
          <Translation>
            {(t) => `${t("evaluations.dashboard.status")}:`}
          </Translation>
        </span>
        <EvaluationCycleStatusIndicator
          className="max-md:*:text-caption"
          status={info.row.original.evaluation.cycle.status}
        />
      </div>
    ),
    size: 181.8,
  }),

  columnHelper.display({
    id: "actions",
    header: () => (
      <Translation>
        {(t) => (
          <span className="w-full text-right">
            {t("evaluations.dashboard.actions")}
          </span>
        )}
      </Translation>
    ),
    cell: (info) => <TableRowAction evaluationRating={info.row.original} />,
    size: 106,
  }),

  columnHelper.accessor((row) => row.evaluation.cycle.id, {
    // hidden
    id: "cycle",
    enableGlobalFilter: false,
    enableMultiSort: true,
    filterFn,
    header: () => (
      <Translation>{(t) => t("evaluations.dashboard.cycle")}</Translation>
    ),
    cell: (info) => (
      <span className="text-body2 text-text-primary">{info.getValue()}</span>
    ),
  }),
] as ColumnDef<EvaluationTableColumns>[];
