import { StarIcon } from "@heroicons/react/24/solid";
import { Link } from "@tanstack/react-router";

import type { EvaluationDraftRatingValidEvaluatee } from "@/api/evaluations";
import { useGetCurrentUser } from "@/api/users";
import { Avatar } from "@/components/ui/Avatar";
import { cn } from "@/utils/ui";

export interface DraftUserRatingInfoCellProps {
  className?: string;
  evaluationRating: EvaluationDraftRatingValidEvaluatee;
}

export function DraftUserRatingInfoCell({
  className,
  evaluationRating,
}: DraftUserRatingInfoCellProps) {
  const { currentUser } = useGetCurrentUser();

  const evaluatee = evaluationRating.evaluatee;
  const isSelfEval =
    Boolean(currentUser) &&
    Boolean(evaluatee) &&
    currentUser?.id === evaluatee.id;

  return (
    <div className={cn("flex items-center gap-2", className)}>
      <div className="h-[2.625rem] w-9 py-[0.5625rem] pr-3 max-md:hidden">
        {Boolean(isSelfEval) && (
          <StarIcon
            className="size-6 text-primary-main"
            data-row-highlight="primary"
          />
        )}
      </div>

      <div className="flex items-center gap-2 max-md:flex-col max-md:items-start md:h-fit">
        <div className="max-md:flex max-md:items-center max-md:gap-2">
          <Avatar
            displayName={evaluatee.fullName}
            size={42}
            src={evaluatee.avatar}
          />
          <div className="md:hidden">
            {Boolean(isSelfEval) && (
              <StarIcon
                className="size-6 text-primary-main"
                data-row-highlight="primary"
              />
            )}
          </div>
        </div>
        <div>
          <Link
            params={{ evaluationId: `${evaluationRating.evaluationId}` }}
            search={{
              evaluateeId: evaluationRating.evaluatee.id,
              projectId: evaluationRating.projectId,
            }}
            to="/evaluations/$evaluationId"
          >
            <p className="text-subtitle2 text-text-primary hover:underline">
              {evaluatee.fullName}
            </p>
          </Link>
          <p className="text-body2 text-text-secondary">{evaluatee.email}</p>
        </div>
      </div>
    </div>
  );
}
