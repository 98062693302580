import * as React from "react";

import { cn } from "@/utils/ui";

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  helper?: React.ReactNode;
  helperClassName?: string;
  hideCounter?: boolean;
  inputClassName?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      helper,
      helperClassName,
      hideCounter,
      inputClassName,
      maxLength,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [count, setCount] = React.useState(0);

    return (
      <div className={className}>
        <textarea
          className={cn(
            "min-h-[9.875rem] rounded-lg p-5 text-text-primary outline-none ring-1 ring-other-border hover:ring-primary-main focus:ring-[0.1875rem] focus:ring-primary-border enabled:active:ring-[0.1875rem] enabled:active:ring-primary-border disabled:bg-actions-disabled-background disabled:text-text-disabled disabled:ring-1 disabled:ring-actions-disabled-text",
            inputClassName,
          )}
          onChange={(e) => {
            setCount(e.target.value.length);
            return onChange?.(e);
          }}
          ref={ref}
          {...props}
        />

        {(Boolean(helper) || !hideCounter) && (
          <div
            className={cn(
              "flex justify-between gap-1 text-caption text-text-secondary",
              maxLength !== undefined && count > maxLength && "text-error-main",
            )}
          >
            <div className={helperClassName}>{helper}</div>
            {!hideCounter && (
              <div>
                {count}
                {maxLength !== undefined && `/${maxLength}`}
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
TextArea.displayName = "TextArea";

export { TextArea };
