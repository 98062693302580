/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  CheckIcon,
} from "@heroicons/react/16/solid";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TimeAgo from "react-timeago";
import { toast } from "sonner";

import { EvaluationStep } from "@/api/constants";
import {
  EvaluationSubmitPayloadSchema,
  useGetCurrentEvaluation,
  useSubmitEvaluation,
} from "@/api/evaluations";
import { ApiError } from "@/api/utils";
import { logoutEvent } from "@/components/app/Navigation/events";
import { SubmitEvaluationAlertDialog } from "@/components/app/SubmitEvaluationAlertDialog";
import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { Stepper } from "@/components/ui/Stepper";
import { cn } from "@/utils/ui";

import { DraftSaveSucessToast } from "./DraftSaveSuccessToast";
import { SubmitDeadlineErrorAlertDialog } from "./SubmitDeadlineErrorAlertDialog";
import { SubmitEvaluationConfirmDialog } from "./SubmitEvaluationConfirmDialog";
import { useDraftAutosave } from "./utils/hooks/useDraftAutosave";
import { useEvaluationPageStore } from "./utils/hooks/useEvaluationPageStore";
import { useNavigationBlocker } from "./utils/hooks/useNavigationBlocker";

export interface LayoutProps {
  children?: ReactNode;
  className?: string;
}

export function Layout({ children, className }: LayoutProps) {
  const { t } = useTranslation();

  const steps = useMemo(
    () =>
      Object.keys(EvaluationStep)
        .filter((v) => !isNaN(Number(v)))
        .map((value) => ({
          name: `constants.evaluationSteps.${value}.name`,
          label: t(`constants.evaluationSteps.${value}.label`),
        })),
    [t],
  );

  const { evaluation, isFetched } = useGetCurrentEvaluation();

  const { step = 1, ...search }: { step: number } = useSearch({
    strict: false,
  });
  const hasPrevStep = step > (EvaluationStep.EvaluateSelf as number);
  const hasNextStep = step < (EvaluationStep.RankMembers as number);

  const isStoreInitialized = useEvaluationPageStore(
    (state) => state._isInitialized,
  );

  const isSelfRatingPending = useEvaluationPageStore((state) =>
    state.getIsSelfRatingPending(),
  );
  const selectedProjects = useEvaluationPageStore(
    (state) => state.selectedProjects,
  );
  const projectMemberRatings = useEvaluationPageStore(
    (state) => state.selectedProjectMemberRatings,
  );
  const rankingNotes = useEvaluationPageStore((state) => state.rankingNotes);
  const resetStore = useEvaluationPageStore((state) => state.reset);

  const selectedMemberRatings = useMemo(
    () =>
      isStoreInitialized
        ? selectedProjects
            .map((project) => Object.values(projectMemberRatings[project.id]))
            .flat()
            .filter((rating) => rating?.evaluateeId !== undefined)
        : [],
    [isStoreInitialized, projectMemberRatings, selectedProjects],
  );

  const {
    isAutosaved,
    isAutosavePending,
    onAutosaveDraft,
    onAutosaveDraftDebounced,
  } = useDraftAutosave();

  let notificationText: string;
  if (isAutosavePending) {
    notificationText = t("notifications.info.evaluationAutosaveInProgress");
  } else {
    notificationText = t("notifications.info.evaluationAutosaveSuccess");
    if (evaluation?.updatedAt !== null) {
      notificationText = `${notificationText} ${t("notifications.info.evaluationLastAutosaved")}`;
    }
  }

  const { disableNextBlocker } = useNavigationBlocker();
  const navigate = useNavigate();

  const submitPayload = useEvaluationPageStore((state) =>
    state.getSubmitPayload(),
  );

  const { submit, isPending: isSubmitPending } = useSubmitEvaluation();

  const [unlockedStep, setUnlockedStep] = useState(step);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitConfirmModalOpen, setIsSubmitConfirmModalOpen] =
    useState(false);
  const [isSubmitAlertModalOpen, setIsSubmitAlertModalOpen] = useState(false);
  const [apiErrorCodes, setApiErrorCodes] = useState<string[]>();

  const isCycleDeadlineError = apiErrorCodes?.includes("inactive_cycle");

  const canGoToNextStep = useCallback(
    (evalStep: EvaluationStep) =>
      ({
        [EvaluationStep.EvaluateSelf]: !isSelfRatingPending,
        [EvaluationStep.SelectMembers]:
          selectedProjects.length > 0 &&
          selectedProjects.every(
            ({ id }) =>
              id in projectMemberRatings &&
              Object.entries(projectMemberRatings[id]).length > 1,
          ),
        [EvaluationStep.EvaluateMembers]: selectedMemberRatings.every(
          (rating) => !(rating?.isPending ?? true),
        ),
        [EvaluationStep.RankMembers]: false,
      })[evalStep],
    [
      isSelfRatingPending,
      projectMemberRatings,
      selectedMemberRatings,
      selectedProjects,
    ],
  );

  const canSubmit = Boolean(rankingNotes);

  const handleStepClick = (stepOption: { name: string }) => {
    const stepValue =
      steps.findIndex(({ name }) => name === stepOption.name) + 1;

    disableNextBlocker(async () => {
      await navigate({ search: { ...search, step: stepValue } });
    });
  };

  const handleSubmitClick = () => {
    setIsSubmitConfirmModalOpen(true);
  };

  const handleBackClick = () => {
    disableNextBlocker(async () => {
      await navigate({ search: { ...search, step: step - 1 } });
    });

    if (!onAutosaveDraftDebounced.isPending()) return;

    void onAutosaveDraftDebounced();
  };

  const handleNextClick = () => {
    disableNextBlocker(async () => {
      await navigate({ search: { ...search, step: step + 1 } });
    });

    setUnlockedStep((value) => Math.max(value, step + 1));

    if (!onAutosaveDraftDebounced.isPending()) return;

    void onAutosaveDraftDebounced();
  };

  const handleSubmitCancelClick = () => {
    setIsSubmitConfirmModalOpen(false);
  };

  const handleSubmitConfirmClick = async () => {
    onAutosaveDraftDebounced.cancel();

    setIsSubmitting(true);

    try {
      const payload = EvaluationSubmitPayloadSchema.parse(submitPayload);
      await submit(payload);

      setIsSubmitAlertModalOpen(true);
    } catch (e) {
      if (e instanceof ApiError) {
        setApiErrorCodes(e.errors?.map(({ code }) => code));
      }
    }

    setIsSubmitConfirmModalOpen(false);
    setIsSubmitting(false);
  };

  const handleSubmitSuccessClick = () => {
    setIsSubmitAlertModalOpen(false);
    disableNextBlocker(async () => {
      await navigate({ to: "/evaluations" });
    });
  };

  const handleDeadlineErrorConfirm = () => {
    setApiErrorCodes(undefined);
    disableNextBlocker(async () => {
      await navigate({ to: "/evaluations" });
    });
  };

  useEffect(() => {
    const onLogout = async () => {
      await onAutosaveDraft();
      resetStore();

      toast.success(<DraftSaveSucessToast showDraftViewButton={false} />);
    };

    logoutEvent.subscribe(onLogout);

    return () => {
      logoutEvent.unsubscribe(onLogout);
    };
  }, [onAutosaveDraft, resetStore]);

  useEffect(() => {
    if (step === (EvaluationStep.EvaluateSelf as number)) return;

    let latestStep = step;
    while (latestStep > (EvaluationStep.EvaluateSelf as number)) {
      if (canGoToNextStep(latestStep - 1)) break;
      --latestStep;
    }

    if (latestStep === step) return;

    disableNextBlocker(async () => {
      await navigate({ search: { step: latestStep, ...search } });
    });
  }, [canGoToNextStep, disableNextBlocker, navigate, search, step]);

  return (
    <>
      <SubmitEvaluationConfirmDialog
        isLoading={isSubmitPending}
        onCancelClick={handleSubmitCancelClick}
        onConfirmClick={handleSubmitConfirmClick}
        open={isSubmitConfirmModalOpen}
      />
      <SubmitEvaluationAlertDialog
        onConfirmClick={handleSubmitSuccessClick}
        open={isSubmitAlertModalOpen}
      />
      <SubmitDeadlineErrorAlertDialog
        onConfirmClick={handleDeadlineErrorConfirm}
        open={isCycleDeadlineError}
      />

      <div
        className={cn(
          "flex h-full max-h-full flex-auto flex-col items-stretch px-10 pb-10 pt-12 max-md:pb-[4.25rem] max-md:min-h-full max-md:px-0 max-md:pt-0 md:max-xl:gap-6 xl:gap-12",
          className,
        )}
      >
        <div className="flex flex-col items-stretch justify-between md:flex-row md:flex-wrap md:items-start md:gap-x-2 md:gap-y-6">
          <div className="flex flex-1 flex-col max-md:p-6 md:gap-2">
            <h1
              className={cn(
                "text-h5 text-text-primary whitespace-nowrap max-md:text-h6",
                !isFetched && "invisible",
              )}
            >
              {evaluation
                ? t("evaluations.create.editEvaluation")
                : t("evaluations.create.createEvaluation")}
            </h1>
            <div
              className={cn(
                "flex items-center gap-2",
                !isAutosaved && "max-md:hidden md:invisible",
              )}
            >
              {isAutosavePending ? (
                <Spinner className="size-4" />
              ) : (
                <CheckCircleIcon className="size-4 text-text-secondary" />
              )}
              <div className="text-caption leading-none text-text-secondary">
                {notificationText}{" "}
                <TimeAgo date={evaluation?.updatedAt ?? new Date()} />.
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center max-md:px-6 max-md:pb-6 md:flex-1 md:max-xl:hidden">
            <Stepper
              className="max-w-[28.3125rem] self-center"
              classNames={{
                stepContainer: "max-md:min-w-[4.26rem] max-md:max-w-[4.26rem]",
                stepLabel: { default: "max-md:text-[0.625rem]" },
                stepSeparatorContainer: "max-md:min-w-[1.125rem]",
              }}
              currentStep={steps[step - 1].name}
              isCurrentStepCompleted={
                step === (EvaluationStep.RankMembers as number) && canSubmit
              }
              onStepClick={handleStepClick}
              steps={steps}
              unlockedStep={steps[unlockedStep - 1].name}
            />
          </div>

          <div className="flex flex-1 items-center justify-end gap-3 max-md:hidden">
            <Button
              asChild
              className="rounded-full px-3"
              disabled={isAutosavePending}
              isLink
              variant="text"
            >
              <Link to="/evaluations">
                {t("evaluations.create.buttonCancel")}
              </Link>
            </Button>
            <Button
              className={cn(!hasPrevStep && "hidden")}
              disabled={!hasPrevStep}
              onClick={handleBackClick}
              variant="outlined"
            >
              <ArrowLeftIcon className="size-5" />
              <span>{t("evaluations.create.buttonPrevStep")}</span>
            </Button>
            <Button
              className={cn(!hasNextStep && "hidden")}
              disabled={!hasNextStep || !canGoToNextStep(step)}
              onClick={handleNextClick}
              variant="outlined"
            >
              <span>{t("evaluations.create.buttonNextStep")}</span>
              <ArrowRightIcon className="size-5" />
            </Button>
            <Button
              className={cn(hasNextStep && "hidden")}
              disabled={!canSubmit || isSubmitting}
              onClick={handleSubmitClick}
            >
              <CheckIcon className="size-5" />
              <span>{t("evaluations.create.buttonSubmit")}</span>
            </Button>
          </div>
        </div>

        <div className="fixed inset-x-0 bottom-0 z-[1] flex items-center justify-end gap-3 border-t border-other-divider bg-white px-6 py-4 md:hidden">
          <Button
            asChild
            className="flex-1 rounded-full"
            isLink
            size="small"
            variant="text"
          >
            <Link to="/evaluations">
              {t("evaluations.create.buttonCancel")}
            </Link>
          </Button>
          <Button
            className={cn("flex-1", !hasPrevStep && "hidden")}
            disabled={!hasPrevStep}
            onClick={handleBackClick}
            size="small"
            variant="outlined"
          >
            <ArrowLeftIcon className="size-[1.125rem]" />
            <span>{t("evaluations.create.buttonPrevStep")}</span>
          </Button>
          <Button
            className={cn("flex-1", !hasNextStep && "hidden")}
            disabled={!hasNextStep || !canGoToNextStep(step)}
            onClick={handleNextClick}
            size="small"
            variant="outlined"
          >
            <span>{t("evaluations.create.buttonNextStep")}</span>
            <ArrowRightIcon className="size-[1.125rem]" />
          </Button>
          <Button
            className={cn("flex-1", hasNextStep && "hidden")}
            disabled={!canSubmit || isSubmitting}
            onClick={handleSubmitClick}
            size="small"
          >
            <CheckIcon className="size-[1.125rem]" />
            <span>{t("evaluations.create.buttonSubmit")}</span>
          </Button>
        </div>

        <div className="h-[0.0625rem] bg-other-divider max-md:hidden xl:hidden" />

        <Stepper
          className="max-w-[28.3125rem] self-center max-md:hidden xl:hidden"
          currentStep={steps[step - 1].name}
          isCurrentStepCompleted={
            step === (EvaluationStep.RankMembers as number) && canSubmit
          }
          onStepClick={handleStepClick}
          steps={steps}
          unlockedStep={steps[unlockedStep - 1].name}
        />

        {children}
      </div>
    </>
  );
}
