import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "query-string";
import { useMemo } from "react";

import { config } from "@/utils/constants";

import { type QueryOptions } from "../constants";
import { api, handleApiError, validateSchema } from "../utils";
import {
  type ProjectListResponse,
  ProjectListResponseSchema,
  type ProjectQueryParams,
  ProjectQueryParamsSchemaTransformer,
  projectsKeys,
} from "./constants";

const listProjects = async ({ pageParam }: { pageParam: string }) => {
  const response = await api.get(pageParam, { prefixUrl: "" });

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return (
    validateSchema(ProjectListResponseSchema, data) ?? {
      count: 0,
      next: null,
      previous: null,
      results: [],
    }
  );
};

export const useListProjects = (
  params: ProjectQueryParams = {},
  options: QueryOptions = { enabled: true },
) => {
  const searchParams = qs.stringify(
    decamelizeKeys(ProjectQueryParamsSchemaTransformer.parse(params)),
  );
  const {
    data,
    isFetched,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ProjectListResponse>({
    enabled: options.enabled,
    initialPageParam: `${config.EVALS_API_BASE}/projects/${searchParams ? `?${searchParams}` : ""}`,
    getNextPageParam: (lastPage) => lastPage.next,
    queryFn: (param: unknown) => listProjects(param as { pageParam: string }),
    queryKey: projectsKeys.list(params),
    placeholderData: keepPreviousData,
  });

  const aggregatedResults = useMemo(
    () => (data?.pages.map((page) => page.results) ?? []).flat(),
    [data],
  );

  const cursor = useMemo(
    () => ({
      isFetching,
      hasNextPage,
      loadMore: fetchNextPage,
    }),
    [fetchNextPage, hasNextPage, isFetching],
  );

  const currPage = useMemo(() => data?.pages.at(-1) || null, [data]);

  return {
    projects: currPage,
    results: aggregatedResults,
    cursor,
    isFetched,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
