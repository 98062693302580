/* eslint-disable @typescript-eslint/no-misused-promises */
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import {
  type CreateEvaluationCycleSchema,
  useCreateEvaluationCycle,
} from "@/api/evaluationCycles";
import { Button } from "@/components/ui/Button";
import { DatePicker } from "@/components/ui/DatePicker";
import { Dialog } from "@/components/ui/Dialog";
import { Form } from "@/components/ui/Form";

export interface CreateCycleFormProps {
  onClose?: () => void;
  onCreateSuccess?: () => void;
  form: UseFormReturn<z.infer<typeof CreateEvaluationCycleSchema>>;
}

export function CreateCycleForm({
  onClose,
  onCreateSuccess,
  form,
}: CreateCycleFormProps) {
  const { t } = useTranslation();

  const mutation = useCreateEvaluationCycle({
    onSuccess: () => {
      onCreateSuccess?.();
    },
  });

  const handleCreateCycleSubmission = (
    values: z.infer<typeof CreateEvaluationCycleSchema>,
  ) => {
    mutation.createSync(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleCreateCycleSubmission)}>
        <Dialog.Header>
          <Dialog.Title>
            {t("components.createCycleForm.createCycle")}
          </Dialog.Title>
        </Dialog.Header>

        <Dialog.Body className="space-y-[1.125rem]">
          <div className="flex flex-col gap-[1.125rem] lg:flex-row lg:gap-4">
            <Form.Field
              control={form.control}
              name="periodStartDate"
              render={({ field }) => (
                <Form.Item className="flex flex-col gap-[0.625rem]">
                  <Form.Label>
                    {t("components.createCycleForm.coverageStart")}
                  </Form.Label>
                  <Form.Control>
                    <DatePicker
                      id="periodStartDate"
                      inputClassName="ring-actions-selected"
                      placeholder="Select date"
                      {...field}
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />

            <Form.Field
              control={form.control}
              name="periodEndDate"
              render={({ field }) => (
                <Form.Item className="flex flex-col gap-[0.625rem]">
                  <Form.Label>
                    {t("components.createCycleForm.coverageEnd")}
                  </Form.Label>
                  <Form.Control>
                    <DatePicker
                      id="periodEndDate"
                      inputClassName="ring-actions-selected"
                      placeholder="Select date"
                      {...field}
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>

          <Form.Field
            control={form.control}
            name="deadline"
            render={({ field }) => (
              <Form.Item className="flex flex-col gap-[0.625rem]">
                <Form.Label>
                  {t("components.createCycleForm.deadline")}
                </Form.Label>
                <Form.Control>
                  <DatePicker
                    id="deadline"
                    inputClassName="w-full ring-actions-selected"
                    placeholder="Select date"
                    {...field}
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
        </Dialog.Body>

        <Dialog.Footer>
          <Dialog.Close asChild className="flex-1">
            <Button onClick={onClose} variant="outlined">
              {t("components.createCycleForm.cancel")}
            </Button>
          </Dialog.Close>
          <Button
            className="flex-1"
            disabled={mutation.isPending}
            type="submit"
          >
            {t("components.createCycleForm.save")}
          </Button>
        </Dialog.Footer>
      </form>
    </Form>
  );
}
