export const logoutEvent = {
  dispatch: () => {
    const event = new CustomEvent("user-logout", {});
    document.dispatchEvent(event);
  },
  subscribe: (listener: EventListenerOrEventListenerObject) => {
    document.addEventListener("user-logout", listener);
  },
  unsubscribe: (listener: EventListenerOrEventListenerObject) => {
    document.removeEventListener("user-logout", listener);
  },
};
