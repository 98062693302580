import { PlusIcon } from "@heroicons/react/16/solid";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { useGetCurrentEvaluation } from "@/api/evaluations";
import {
  type BasicProject,
  type Project,
  useListProjects,
} from "@/api/projects";
import { useGetCurrentUser, useListUsers, type User } from "@/api/users";
import { InfoText } from "@/components/app/InfoText";
import { ProjectListItem, UserListItem } from "@/components/app/ListItem";
import { LoadingComponent } from "@/components/app/LoadingComponent";
import { RemoveProjectConfirmDialog } from "@/components/app/RemoveProjectConfirmDialog";
import { SearchBar } from "@/components/app/SearchBar";
import { Button } from "@/components/ui/Button";
import { cn } from "@/utils/ui";

import { useDraftAutosave } from "../../utils/hooks/useDraftAutosave";
import { useEvaluationPageFormDefaultValues } from "../../utils/hooks/useEvaluationPageFormDefaultValues";
import { useEvaluationPageStore } from "../../utils/hooks/useEvaluationPageStore";
import { AddMembersDialog } from "./AddMembersDialog";
import { ProjectSearchSelect } from "./ProjectSearchSelect";

export function SelectMembersStep() {
  const { t } = useTranslation();

  const { currentUser } = useGetCurrentUser();

  const [userSearchText, setUserSearchText] = useState<string>();
  const [projectSearchText, setProjectSearchText] = useState<string>();
  const [removeProjectConfirmModalOpen, setRemoveProjectConfirmModalOpen] =
    useState(false);
  const [projectToRemove, setProjectToRemove] = useState<BasicProject>();
  const [projectToAddMembers, setProjectToAddMembers] = useState<Project>();

  const { onAutosaveDraftDebounced } = useDraftAutosave();

  const { evaluation } = useGetCurrentEvaluation();

  const { isFetched: isFetchedValues } = useEvaluationPageFormDefaultValues();

  const evaluationProjectsBasic = useMemo(() => {
    if (!evaluation) return [];

    const uniqueIds: Record<string, boolean> = {};
    const uniqueProjects: BasicProject[] = [];

    evaluation.ratings
      .filter(({ projectId }) => projectId !== undefined)
      .map(
        (rating) =>
          ({
            id: rating.projectId,
            name: rating.projectName,
          }) as BasicProject,
      )
      .forEach((project) => {
        if (`${project.id}` in uniqueIds) return;

        uniqueIds[`${project.id}`] = true;
        uniqueProjects.push(project);
      });

    return uniqueProjects;
  }, [evaluation]);

  const evaluationProjectsBasicIds = useMemo(
    () => evaluationProjectsBasic.map(({ id }) => id),
    [evaluationProjectsBasic],
  );

  const { projects: evaluationProjects } = useListProjects(
    {
      id: evaluationProjectsBasicIds,
      limit: 99999,
      ordering: ["name"],
    },
    { enabled: evaluationProjectsBasicIds.length > 0 },
  );

  const projectsQuery =
    currentUser === null
      ? {
          params: { limit: 99999, search: projectSearchText },
          options: { enabled: false },
        }
      : {
          params: {
            limit: 99999,
            search: projectSearchText,
            user: [currentUser.id],
          },
          options: { enabled: true },
        };

  const { results: loadedProjectsList, isFetched: isFetchedUserProjects } =
    useListProjects(projectsQuery.params, projectsQuery.options);
  const {
    results: loadedAllProjectsList,
    isFetched: isFetchedAllProjects,
    cursor: allProjectsCursor,
  } = useListProjects({ ordering: ["name"], search: projectSearchText });

  const { users, isFetched: isFetchedUsers } = useListUsers({
    search: userSearchText,
    ordering: ["first_name", "last_name"],
  });

  const usersList = useMemo(() => users ?? [], [users]);

  const { users: allUsers } = useListUsers({
    ordering: ["first_name", "last_name"],
  });

  const allUsersList = useMemo(
    () => allUsers?.filter(({ id }) => id !== currentUser?.id) ?? [],
    [allUsers, currentUser],
  );

  const projectsList = useMemo(() => {
    const loadedProjectIds = loadedProjectsList.map(({ id }) => id);
    const evaluationExtraProjects =
      evaluationProjects?.results.filter(
        ({ id }) => !loadedProjectIds.includes(id),
      ) ?? [];

    return [...loadedProjectsList, ...evaluationExtraProjects];
  }, [evaluationProjects, loadedProjectsList]);

  const otherProjects = useMemo(() => {
    const projectIdsList = projectsList.map(({ id }) => id);
    const loadedOtherProjects = loadedAllProjectsList.filter(
      ({ id }) => !projectIdsList.includes(id),
    );

    return loadedOtherProjects;
  }, [loadedAllProjectsList, projectsList]);

  const {
    projectMemberRatings,
    selectedProjects,
    addSelectedProject,
    setSelectedUsers,
    removeSelectedProject,
    removeSelectedUser,
  } = useEvaluationPageStore((state) => ({
    selectedProjects: state.selectedProjects,
    projectMemberRatings: state.selectedProjectMemberRatings,
    addSelectedProject: state.addSelectedProject,
    setSelectedUsers: state.setSelectedUsers,
    removeSelectedProject: state.removeSelectedProject,
    removeSelectedUser: state.removeSelectedUser,
  }));

  const [orderedSelectedProjects, setOrderedSelectedProjects] =
    useState(selectedProjects);

  const selectedProjectsIds = useMemo(
    () => orderedSelectedProjects.map(({ id }) => id),
    [orderedSelectedProjects],
  );

  const { projects, isFetched: isFetchedProjects } = useListProjects(
    {
      id: selectedProjectsIds,
      limit: 99999,
    },
    { enabled: selectedProjectsIds.length > 0 },
  );

  const isFetchingMembers =
    !isFetchedUsers ||
    !isFetchedValues ||
    (!isFetchedProjects && selectedProjectsIds.length > 0);

  const selectedProjectMembers = useMemo(
    () =>
      projects?.results
        .filter(({ id }) => id in projectMemberRatings)
        .sort(
          (a, b) =>
            selectedProjectsIds.indexOf(a.id) -
            selectedProjectsIds.indexOf(b.id),
        )
        .map((project) => ({
          project,
          members: (
            Object.entries(projectMemberRatings[project.id])
              .map(([userId]) =>
                usersList.find((user) => user.id === Number(userId)),
              )
              .filter((user) => user !== undefined) as User[]
          ).sort((a, b) => a.fullName.localeCompare(b.fullName)),
        })) ?? [],
    [projects, projectMemberRatings, selectedProjectsIds, usersList],
  );

  const [sessionSelectedProjectMembers, setSessionSelectedProjectMembers] =
    useState<Record<string, User[]>>({});

  const orderedSelectedProjectMembers = useMemo(() => {
    return selectedProjectMembers.map(({ project, members }) => {
      const sessionProjectMembers =
        project.id in sessionSelectedProjectMembers
          ? sessionSelectedProjectMembers[project.id]
          : [];
      const sessionProjectMemberIds = sessionProjectMembers.map(({ id }) => id);
      const storeProjectMembers = members.filter(
        ({ id }) => !sessionProjectMemberIds.includes(id),
      );

      return {
        project,
        members: [...sessionProjectMembers, ...storeProjectMembers],
      };
    });
  }, [sessionSelectedProjectMembers, selectedProjectMembers]);

  const alreadyAddedMembers = useMemo(
    () =>
      Object.fromEntries(
        selectedProjectMembers.map(({ project, members }) => [
          project.id,
          members,
        ]),
      ),
    [selectedProjectMembers],
  );

  const handleSelectProjects = useCallback(
    (selected: Project[]) => {
      selected.forEach((project) => {
        addSelectedProject(project);
        setOrderedSelectedProjects((curr) => [project, ...curr]);

        if (project.isTraining) return;

        const selectedUserOptions = project.userIds
          .map((userId) => allUsersList.find(({ id }) => id === userId))
          .filter((user) => user !== undefined)
          .map((user) => ({ label: user!.fullName, value: user!.id }));

        setSelectedUsers(selectedUserOptions, allUsersList, project.id);
      });

      setProjectSearchText(undefined);

      void onAutosaveDraftDebounced();
    },
    [
      addSelectedProject,
      allUsersList,
      onAutosaveDraftDebounced,
      setSelectedUsers,
    ],
  );

  const handleMemberDeleteClick = useCallback(
    (user: User, project: BasicProject) => {
      removeSelectedUser(user, project.id);
      setSessionSelectedProjectMembers((curr) => ({
        ...curr,
        [project.id]: (project.id in curr ? curr[project.id] : []).filter(
          ({ id }) => id !== user.id,
        ),
      }));

      void onAutosaveDraftDebounced();
    },
    [onAutosaveDraftDebounced, removeSelectedUser],
  );

  const handleUserSearchChange = useDebouncedCallback(setUserSearchText, 500);

  const handleProjectDeleteClick = useCallback((project: BasicProject) => {
    setRemoveProjectConfirmModalOpen(true);
    setProjectToRemove(project);
  }, []);

  const handleProjectSearchChange = useDebouncedCallback(
    setProjectSearchText,
    500,
  );

  const handleRemoveProjectCancelClick = useCallback(() => {
    setRemoveProjectConfirmModalOpen(false);
  }, []);

  const handleRemoveProjectConfirmClick = useCallback(() => {
    setRemoveProjectConfirmModalOpen(false);

    if (!projectToRemove) return;

    removeSelectedProject(projectToRemove);
    setOrderedSelectedProjects((curr) =>
      curr.filter(({ id }) => id !== projectToRemove.id),
    );

    void onAutosaveDraftDebounced();
  }, [onAutosaveDraftDebounced, projectToRemove, removeSelectedProject]);

  const handleAddMembersCancelClick = useCallback(() => {
    setProjectToAddMembers(undefined);
  }, []);

  const handleAddMembersConfirmClick = useCallback(
    (selected: User[]) => {
      if (projectToAddMembers === undefined) return;

      setSelectedUsers(
        selected.map((user) => ({ label: user.fullName, value: user.id })),
        allUsersList,
        projectToAddMembers.id,
      );
      setSessionSelectedProjectMembers((curr) => ({
        ...curr,
        [projectToAddMembers.id]: [
          ...selected,
          ...(projectToAddMembers.id in curr
            ? curr[projectToAddMembers.id]
            : []),
        ],
      }));

      setProjectToAddMembers(undefined);

      void onAutosaveDraftDebounced();
    },
    [
      projectToAddMembers,
      setSelectedUsers,
      allUsersList,
      onAutosaveDraftDebounced,
    ],
  );

  const handleAddMembersDialogOpenChange = useCallback((flag: boolean) => {
    if (flag) return;
    setProjectToAddMembers(undefined);
  }, []);

  return (
    <>
      <AddMembersDialog
        excludedMembers={alreadyAddedMembers}
        onCancelClick={handleAddMembersCancelClick}
        onConfirmClick={handleAddMembersConfirmClick}
        onOpenChange={handleAddMembersDialogOpenChange}
        open={projectToAddMembers !== undefined}
        project={projectToAddMembers}
      />
      <RemoveProjectConfirmDialog
        onCancelClick={handleRemoveProjectCancelClick}
        onConfirmClick={handleRemoveProjectConfirmClick}
        open={removeProjectConfirmModalOpen}
      />

      <div className="flex flex-auto items-stretch gap-6 max-lg:flex-col max-md:gap-3 max-md:px-6 max-md:pb-6 max-md:pt-3 md:gap-10 lg:gap-6 lg:overflow-y-hidden">
        <OverlayScrollbarsComponent
          className="flex flex-col items-stretch lg:min-h-full lg:flex-[50%]"
          defer
          element="div"
          options={{ scrollbars: { autoHide: "move" } }}
        >
          <div className="m-0.5 flex flex-col items-stretch gap-3 overflow-y-auto rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 lg:min-h-[99%]">
            <div className="flex flex-col gap-[0.625rem]">
              <p className="text-subtitle2 text-text-secondary">
                {t("evaluations.create.projects")}
              </p>
              <ProjectSearchSelect
                cursor={allProjectsCursor}
                isFetching={
                  !isFetchedUserProjects ||
                  !isFetchedAllProjects ||
                  (!isFetchedProjects && selectedProjectsIds.length > 0)
                }
                onConfirmClick={handleSelectProjects}
                onProjectSearchChange={handleProjectSearchChange}
                otherProjects={otherProjects}
                placeholder={t("evaluations.create.selectProjectsPlaceholder")}
                projects={projectsList}
              />
            </div>

            <div className="relative flex flex-auto flex-col items-stretch gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1">
              {Boolean(!isFetchedValues) && (
                <LoadingComponent className="absolute inset-0 h-full" />
              )}
              <p className="text-subtitle2 text-text-secondary">
                {t("evaluations.create.addedProjects")}
              </p>
              {orderedSelectedProjects.length > 0 ? (
                <>
                  <InfoText
                    className="border-0 p-0"
                    subtitle={t("evaluations.create.projectSelectedInfo")}
                  />
                  {orderedSelectedProjects.map((project) => (
                    <ProjectListItem
                      className="cursor-default px-0 hover:bg-transparent"
                      key={project.id}
                      onDeleteClick={handleProjectDeleteClick}
                      project={project}
                    />
                  ))}
                </>
              ) : (
                <>
                  <InfoText
                    className={cn(
                      "max-md:hidden border-0 p-0",
                      !isFetchedValues && "hidden",
                    )}
                    title={t("evaluations.create.noProjectInfo")}
                  />
                  <InfoText
                    className={cn(
                      "md:hidden border-0 p-0",
                      !isFetchedValues && "hidden",
                    )}
                    subtitle={t("evaluations.create.noProjectInfo")}
                    title={t("evaluations.create.nothingToShow")}
                  />
                </>
              )}
            </div>
          </div>
        </OverlayScrollbarsComponent>

        <OverlayScrollbarsComponent
          className="flex flex-col items-stretch lg:min-h-full lg:flex-[50%]"
          defer
          element="div"
          options={{ scrollbars: { autoHide: "move" } }}
        >
          <div className="m-0.5 flex flex-col items-stretch gap-3 overflow-y-auto rounded-2xl px-4 pb-6 pt-4 shadow-elevation1 lg:min-h-[99%]">
            <div className="flex flex-col gap-[0.625rem]">
              <p className="text-subtitle2 text-text-secondary">
                {t("evaluations.create.members")}
              </p>
              <SearchBar
                disabled={orderedSelectedProjects.length === 0}
                inputClassName="w-full !rounded-lg !ring-1"
                onSearchChange={handleUserSearchChange}
                placeholder={t("evaluations.create.searchMembersPlaceholder")}
              />
            </div>

            <div
              className={cn(
                "relative flex flex-auto flex-col items-stretch gap-3 rounded-2xl px-4 pb-6 pt-4 shadow-elevation1",
                isFetchingMembers && "opacity-60",
              )}
            >
              {Boolean(isFetchingMembers) && (
                <LoadingComponent className="absolute inset-0 h-full" />
              )}
              <p className="text-subtitle2 text-text-secondary">
                {t("evaluations.create.addedMembers")}
              </p>
              {orderedSelectedProjects.length > 0 ? (
                orderedSelectedProjectMembers.map(({ project, members }) => (
                  <Fragment key={project.id}>
                    <div className="flex flex-col items-start justify-between gap-1 max-md:pb-3 md:flex-row md:items-center md:gap-3">
                      <p className="text-overline uppercase text-text-secondary">
                        {project.name}
                      </p>
                      <Button
                        className="gap-[0.375rem]"
                        onClick={() => {
                          setProjectToAddMembers(project);
                        }}
                        size="small"
                        variant="outlined"
                      >
                        <PlusIcon className="size-[1.125rem]" />
                        {t("evaluations.create.buttonAddMember")}
                      </Button>
                    </div>
                    {members.map((user) => (
                      <UserListItem
                        className="cursor-default px-0 hover:bg-transparent"
                        key={`${project.id}-${user.id}`}
                        onDeleteClick={() => {
                          handleMemberDeleteClick(user, project);
                        }}
                        showEmail={false}
                        status="none"
                        user={user}
                      />
                    ))}
                  </Fragment>
                ))
              ) : (
                <>
                  <InfoText
                    className={cn(
                      "max-md:hidden border-0 p-0",
                      isFetchingMembers && "hidden",
                    )}
                    title={t("evaluations.create.noMemberInfo")}
                  />
                  <InfoText
                    className={cn(
                      "md:hidden border-0 p-0",
                      isFetchingMembers && "hidden",
                    )}
                    subtitle={t("evaluations.create.noMemberInfo")}
                    title={t("evaluations.create.nothingToShow")}
                  />
                </>
              )}
            </div>
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </>
  );
}
