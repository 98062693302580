import { decamelize } from "humps";

import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

import { formSchema } from "./DraftsFilterForm/utils";

export const tableStateToQueryParamsTransformer =
  TableStateBaseParamsSchema.extend({
    filters: formSchema.optional(),
  })
    .transform(({ filters, ordering, ...schema }) => ({
      ...filters,
      search: schema.search,
      limit: schema.per,
      offset: !schema.per || !schema.page ? 0 : schema.per * (schema.page - 1),
      ...(ordering && {
        ordering: ordering.map((field) =>
          decamelize(
            {
              cycle: "cycle__active_end_date",
              "-cycle": "-cycle__active_end_date",
              project: "all_project_names",
              "-project": "-all_project_names",
              membersEvaluated: "total_ratings",
              "-membersEvaluated": "-total_ratings",
            }[field] || field,
          ),
        ),
      }),
    }))
    .optional();
