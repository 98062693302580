import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { type ChangeEvent, forwardRef, memo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { type InputProps, TextInput } from "@/components/ui/TextInput";
import { cn } from "@/utils/ui";

export type SearchBarProps = Omit<InputProps, "type" | "onChange"> &
  React.RefAttributes<HTMLInputElement> & {
    onChange?: (text: string) => void;
    onSearchChange?: (text: string) => void;
    searchDebounceTime?: number;
  };

export const SearchBar = memo(
  forwardRef<HTMLInputElement, SearchBarProps>(function SearchBar(
    {
      className,
      defaultValue,
      inputClassName,
      onChange,
      onSearchChange,
      searchDebounceTime = 1000,
      value,
      ...props
    },
    ref,
  ) {
    const [inputValue, setInputValue] = useState(defaultValue ?? "");
    const finalValue = value ?? inputValue;

    const handleSearchChange = useDebouncedCallback((text: string) => {
      onSearchChange?.(text);
    }, searchDebounceTime);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const inputText = event.target.value;
      setInputValue(inputText);
      onChange?.(inputText);
      handleSearchChange(inputText);
    };

    return (
      <TextInput
        className={cn("!min-w-[18.75rem]", className)}
        inputClassName={cn("ring-0 max-w-none rounded-full", inputClassName)}
        onChange={handleChange}
        ref={ref}
        startIcon={
          <MagnifyingGlassIcon className="m-1 size-4 fill-text-secondary" />
        }
        type="search"
        value={finalValue}
        {...props}
      />
    );
  }),
);
