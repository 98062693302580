import { useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";

import { api, handleApiError, validateSchema } from "../utils";
import { EvaluationSchema, evaluationsKeys } from "./constants";

const getCurrentEvaluation = async () => {
  const response = await api.get("evaluations/current/");

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationSchema, data);
};

export const useGetCurrentEvaluation = () => {
  const { data, isFetched, isFetching } = useQuery({
    queryFn: getCurrentEvaluation,
    queryKey: evaluationsKeys.current,
  });

  return { evaluation: data || null, isFetched, isFetching };
};
