import { createFileRoute } from "@tanstack/react-router";

import { EvaluationsDashboardDraftsTab } from "@/components/pages/EvaluationsDashboardPage/DraftsTab";
import { formSchema as filterFormSchema } from "@/components/pages/EvaluationsDashboardPage/DraftsTab/DraftsFilterForm/utils";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

const routeParamsSchema = TableStateBaseParamsSchema.extend({
  filters: filterFormSchema.partial().optional(),
});

export const Route = createFileRoute("/evaluations/_dashboard/drafts")({
  component: EvaluationsDashboardDraftsTab,
  beforeLoad: () => ({ routeId: null }),
  validateSearch: (search) => routeParamsSchema.parse(search),
});
