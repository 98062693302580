import {
  Fragment,
  type HTMLAttributes,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { type SelectOption } from "@/components/ui/Select";
import { cn } from "@/utils/ui";

import { SelectItem, type SelectItemProps } from "./SelectItem";
import { isOptionsGrouped } from "./utils";

function GroupItem({ label }: { label: string }) {
  return (
    <div className="group flex h-10 select-none items-center gap-[0.625rem] px-6 py-1 text-overline uppercase text-text-secondary focus:outline-none focus:ring-0">
      {label}
    </div>
  );
}

export interface DropdownContentProps extends HTMLAttributes<HTMLDivElement> {
  emptyPlaceholder?: ReactNode;
  options: SelectOption[] | { header: string; options: SelectOption[] }[];
  onSelectItem?: (option: SelectOption, flag: boolean) => void;
  onCancelClick?: (selected: SelectOption[]) => void;
  onConfirmClick?: (selected: SelectOption[]) => void;
  renderFooter?: (props: {
    options: SelectOption[];
    selected: SelectOption[];
    original: ReactNode;
  }) => ReactNode;
  renderGroupItem?: (props: { label: string }) => ReactNode;
  renderItem?: (props: SelectItemProps) => ReactNode;
  selected?: SelectOption[];
  scrollSentry?: ReactNode;
}

export function DropdownContent({
  className,
  emptyPlaceholder,
  options,
  selected,
  onCancelClick,
  onConfirmClick,
  onSelectItem,
  renderFooter,
  renderGroupItem,
  renderItem,
  scrollSentry,
  ...props
}: DropdownContentProps) {
  const { t } = useTranslation();
  const [internalSelected, setInternalSelected] = useState<SelectOption[]>([]);

  const groupedOptions = useMemo(
    () =>
      (isOptionsGrouped(options) ? options : [{ header: "", options }]).filter(
        (optionsGroup) => optionsGroup.options.length > 0,
      ),
    [options],
  );

  const flatOptions = useMemo(
    () => groupedOptions.flatMap((optionsGroup) => optionsGroup.options),
    [groupedOptions],
  );

  const optionValues = useMemo(
    () => flatOptions.map((option) => option.value),
    [flatOptions],
  );

  const finalSelected = selected ?? internalSelected;
  const FinalSelectItem = renderItem ?? SelectItem;

  const validSelected = useMemo(
    () => finalSelected.filter(({ value }) => optionValues.includes(value)),
    [finalSelected, optionValues],
  );

  const handleCancelClick = () => {
    onCancelClick?.(validSelected);
  };
  const handleConfirmClick = () => {
    onConfirmClick?.(finalSelected);
  };
  const handleSelectItem = useCallback(
    (option: SelectOption) => {
      const isSelected = Boolean(
        validSelected.find(({ value }) => value === option.value),
      );
      onSelectItem?.(option, !isSelected);
      setInternalSelected((internal) => {
        const internalIsSelected = Boolean(
          internal.find(({ value }) => value === option.value),
        );
        if (internalIsSelected) {
          return internal.filter(({ value }) => value !== option.value);
        }
        return [...internal, option];
      });
    },
    [validSelected, onSelectItem],
  );

  const footer = (
    <div className="flex justify-end gap-x-3 px-3">
      <Button
        className="max-w-[4.8125rem] rounded-full"
        onClick={handleCancelClick}
        variant="text"
      >
        {t("components.searchSelect.buttonCancel")}
      </Button>
      <Button
        className="max-w-[9.4375rem]"
        disabled={
          finalSelected.length === 0 ||
          groupedOptions.every(
            (optionsGroup) => optionsGroup.options.length === 0,
          )
        }
        onClick={handleConfirmClick}
      >
        {t("components.searchSelect.buttonAdd")}{" "}
        {finalSelected.length > 0 && `(${finalSelected.length})`}
      </Button>
    </div>
  );

  const RenderGroupItem = renderGroupItem ?? GroupItem;

  return (
    <div
      className={cn(
        "flex flex-col items-stretch gap-[0.625rem] max-h-[15.0625rem]",
        className,
      )}
      {...props}
    >
      <div className="flex flex-col items-stretch overflow-y-auto">
        {flatOptions.length > 0
          ? groupedOptions.map((optionGroup) => (
              <Fragment key={optionGroup.header}>
                {Boolean(optionGroup.header) && (
                  <RenderGroupItem
                    key={optionGroup.header}
                    label={optionGroup.header}
                  />
                )}

                {optionGroup.options.map((option) => {
                  const isSelected = Boolean(
                    validSelected.find((item) => item.value === option.value),
                  );
                  return (
                    <FinalSelectItem
                      isSelected={isSelected}
                      key={option.value}
                      onSelectItem={handleSelectItem}
                      option={option}
                    />
                  );
                })}
              </Fragment>
            ))
          : emptyPlaceholder}
        {scrollSentry}
      </div>

      {renderFooter
        ? renderFooter({
            options: flatOptions,
            selected: validSelected,
            original: footer,
          })
        : footer}
    </div>
  );
}
