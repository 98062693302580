import type { Table } from "@tanstack/react-table";
import type { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/utils/ui";

import { Pagination } from "../Pagination";
import { PageSizeDropdown } from "./PageSizeDropdown";
import { countLeafRows } from "./utils";

export interface TablePaginationProps<TData>
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  enableShowAll?: boolean;
  filteredResultsCount?: number;
  onPageChange?: (page: number) => boolean | undefined;
  onPageSizeChange?: (value: number) => boolean | undefined;
  pageIndex?: number;
  pageSize?: number;
  siblingCount?: number;
  table: Table<TData>;
}

export function TablePagination<TData>({
  className,
  enableShowAll,
  pageIndex,
  filteredResultsCount,
  onPageChange,
  onPageSizeChange,
  pageSize,
  siblingCount,
  table,
  ...props
}: TablePaginationProps<TData>) {
  const { t } = useTranslation();

  const finalFilteredResultsCount =
    filteredResultsCount ?? countLeafRows(table.getFilteredRowModel());
  const finalResultsCount = countLeafRows(table.getRowModel());
  const pagination = table.getState().pagination;
  const finalPageIndex = pageIndex ?? pagination.pageIndex;
  const finalPageSize = pageSize ?? pagination.pageSize;
  const pageCount =
    finalPageSize <= 0
      ? 1
      : Math.ceil(finalFilteredResultsCount / finalPageSize);
  const normalizedFinalPageSize = finalPageSize <= 0 ? 1 : finalPageSize;
  const [rowStartIndex, rowEndIndex] =
    finalResultsCount > 0
      ? [
          finalPageIndex * normalizedFinalPageSize + 1,
          finalPageIndex * normalizedFinalPageSize + finalResultsCount,
        ]
      : [0, 0];
  const resultsInfo = t("components.dataTable.paginationResultsInfo", {
    filteredResultsCount: finalFilteredResultsCount,
    rowEndIndex,
    rowStartIndex,
  });

  const handlePageChange = (page: number) => {
    if (onPageChange?.(page) === false) return;
    table.setPageIndex(page - 1);
  };

  return (
    <div
      className={cn(
        "flex flex-wrap min-h-[3.875rem] items-center justify-between rounded-b-2xl border-t pl-6 pr-4 py-3 gap-4 overflow-hidden",
        "max-md:flex-col max-md:items-start max-md:px-[1.125rem] max-md:py-2 max-md:gap-1.5",
        className,
      )}
      {...props}
    >
      <div className="flex flex-wrap items-center gap-x-5 gap-y-[0.1875rem] max-md:flex-col max-md:items-start max-md:px-1.5">
        <div className="flex items-center gap-2">
          <p className="whitespace-nowrap text-caption text-text-secondary">
            {t("components.dataTable.paginationInfo")}
          </p>
          <PageSizeDropdown
            enableShowAll={enableShowAll}
            filteredResultsCount={finalFilteredResultsCount}
            onChange={onPageSizeChange}
            table={table}
            value={finalPageSize}
          />
        </div>
        <p className="whitespace-nowrap text-caption text-text-secondary">
          {resultsInfo}
        </p>
      </div>
      <Pagination
        className="max-md:my-1.5"
        onChange={handlePageChange}
        onNextPageClick={table.nextPage}
        onPrevPageClick={table.previousPage}
        pageCount={pageCount}
        siblingCount={siblingCount}
        value={finalPageIndex + 1}
      />
    </div>
  );
}
