import { useQueries, useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";
import { useMemo } from "react";

import { api, handleApiError, validateSchema } from "../utils";
import { type User, UserSchema, usersKeys } from "./constants";

const getUserById = async (id: number) => {
  const response = await api.get(`users/${id}`);

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(UserSchema, data);
};

export const useGetUserById = (
  id: number,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled,
    queryFn: () => getUserById(id),
    queryKey: usersKeys.detail(id),
  });

  return { user: data || null, isFetched, isFetching };
};

export const useGetUsersByIds = (ids: number[]) => {
  const { data, isFetched, isFetching } = useQueries({
    queries: ids.map((id) => ({
      queryFn: () => getUserById(id),
      queryKey: usersKeys.detail(id),
    })),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isFetched: results.every((result) => result.isFetched),
      isFetching: results.some((result) => result.isFetching),
    }),
  });

  const users = useMemo(
    () => data.filter((result) => result !== undefined) as unknown as User[],
    [data],
  );

  return { users, isFetched, isFetching };
};
