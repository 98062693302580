import { redirect } from "@tanstack/react-router";

import { UserRole } from "@/api/users";

import type { AuthContext } from "./constants";

export function checkAuthentication(
  currentRoute: string,
  auth?: AuthContext,
  isLoginRoute?: boolean,
  redirectTo?: string,
): void {
  if (!auth) {
    return;
  }

  const { isAuthenticated } = auth;
  if (!isAuthenticated && !isLoginRoute) {
    throw redirect({
      to: "/login",
      search: {
        redirect: currentRoute,
      },
    });
  }

  if (isAuthenticated && isLoginRoute) {
    throw redirect({
      to: redirectTo ?? "/",
    });
  }
}

export function adminRestriction(auth?: AuthContext): void {
  if (!auth) {
    return;
  }

  const { isAuthenticated, userRole } = auth;
  if (isAuthenticated && userRole !== UserRole.Administrator) {
    throw redirect({
      to: "/evaluations",
    });
  }
}
