import { ArrowLeftIcon } from "@heroicons/react/16/solid";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import {
  type BasicEvaluationRating,
  EVALUATION_RATINGS,
} from "@/api/evaluationRatings";
import { Button } from "@/components/ui/Button";
import { Rating } from "@/components/ui/Rating";
import { withLoadingState } from "@/utils/decorators";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { cn } from "@/utils/ui";

export interface EvaluationRatingDetailProps
  extends HTMLAttributes<HTMLDivElement> {
  containerClassName?: string;
  isLoading: boolean;
  hasNextRating: boolean;
  hasPrevRating: boolean;
  onNextRatingClick: () => void;
  onPrevRatingClick: () => void;
  projectName: string;
  rating:
    | (Omit<BasicEvaluationRating, "id"> &
        Partial<Pick<BasicEvaluationRating, "id">>)
    | null;
}

export const EvaluationRatingDetail = withLoadingState(
  function EvaluationRatingDetail({
    children,
    className,
    containerClassName,
    hasNextRating,
    hasPrevRating,
    onNextRatingClick,
    onPrevRatingClick,
    projectName,
    rating,
    isLoading: _,
    ...props
  }: EvaluationRatingDetailProps) {
    const { t } = useTranslation();
    const { md } = useScreenSize();

    return (
      <OverlayScrollbarsComponent
        className={cn(
          "flex flex-col items-stretch max-md:flex-auto max-md:p-4 max-md:pb-6 max-md:rounded-2xl max-md:shadow-elevation1",
          containerClassName,
        )}
        defer
        element="div"
        options={{ scrollbars: { autoHide: "move" } }}
      >
        {children}

        <div
          className={cn(
            "flex flex-col items-stretch gap-6 md:px-4 md:pb-6 md:pt-4",
            className,
          )}
          {...props}
        >
          <div className="flex flex-col gap-6">
            <div className="flex gap-6">
              <div className="flex flex-[50%] flex-col gap-2">
                <p className="text-subtitle2 text-text-secondary">
                  {t("evaluations.detail.memberName")}
                </p>
                {rating ? (
                  <p className="text-h6 text-text-primary md:text-h5">
                    {rating.evaluatee.fullName}
                  </p>
                ) : (
                  <p className="text-h5 text-text-secondary">-</p>
                )}
              </div>

              <div className="flex flex-[50%] flex-col gap-6">
                <div className="flex flex-auto flex-col gap-2">
                  <p className="text-subtitle2 text-text-secondary">
                    {t("evaluations.detail.projectName")}
                  </p>
                  <p className="text-h6 text-text-primary md:text-h5">
                    {projectName}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <p className="text-subtitle2 text-text-secondary">
                {t("evaluations.detail.overallRating")}
              </p>
              <Rating
                readOnly
                size={md ? "medium" : "large"}
                value={rating?.rating ? EVALUATION_RATINGS[rating.rating] : 0}
              />
            </div>
          </div>

          <div className="flex flex-auto flex-col gap-[0.625rem]">
            <p className="text-subtitle2 text-text-secondary">
              {t("evaluations.detail.notes")}
            </p>
            <p className="whitespace-pre-line text-body1 text-text-primary">
              {rating?.ratingNotes ? rating.ratingNotes : "-"}
            </p>
          </div>

          <div className="flex items-center justify-end gap-3 border-t border-t-other-divider pt-6 md:hidden">
            <Button
              disabled={!hasPrevRating}
              onClick={onPrevRatingClick}
              size={md ? "medium" : "small"}
              variant="outlined"
            >
              <ArrowLeftIcon className="size-[1.125rem]" />
            </Button>
            <Button
              disabled={!hasNextRating}
              onClick={onNextRatingClick}
              size={md ? "medium" : "small"}
            >
              <ArrowLeftIcon className="size-[1.125rem] rotate-180" />
            </Button>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    );
  },
);
