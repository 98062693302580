import { useSearch } from "@tanstack/react-router";

import { EvaluationStep } from "@/api/constants";

import {
  EvaluateMembersStep,
  EvaluateSelfStep,
  RankMembersStep,
  SelectMembersStep,
} from "./EvaluationSteps";
import { Layout } from "./Layout";
import { DraftAutosaveProvider } from "./utils/DraftAutosaveProvider";

export function CreateEvaluationPage() {
  const { step = 1 } = useSearch({ from: "/evaluations/create/" });

  return (
    <DraftAutosaveProvider>
      <Layout>
        {
          {
            [EvaluationStep.EvaluateSelf]: <EvaluateSelfStep />,
            [EvaluationStep.SelectMembers]: <SelectMembersStep />,
            [EvaluationStep.EvaluateMembers]: <EvaluateMembersStep />,
            [EvaluationStep.RankMembers]: <RankMembersStep />,
          }[step as EvaluationStep]
        }
      </Layout>
    </DraftAutosaveProvider>
  );
}
