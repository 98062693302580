import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  useCallback,
  useState,
} from "react";

import { Checkbox } from "@/components/ui/Checkbox";
import { Label } from "@/components/ui/Label";
import { type SelectOption } from "@/components/ui/Select";
import { cn } from "@/utils/ui";

interface ItemCheckboxProps extends ComponentPropsWithoutRef<typeof Checkbox> {
  isSelected: boolean;
  option: SelectOption;
}

export function ItemCheckbox({
  className,
  isSelected,
  option,
  ...props
}: ItemCheckboxProps) {
  return (
    <div className={className}>
      <Checkbox
        checked={isSelected}
        className="group-hover:border-primary-main"
        disabled={option.disabled}
        tabIndex={-1}
        {...props}
      />
    </div>
  );
}

interface ItemLabelProps extends ComponentPropsWithoutRef<typeof Label> {
  option: SelectOption;
}

export function ItemLabel({ className, option, ...props }: ItemLabelProps) {
  return (
    <Label className={className} {...props}>
      {option.label}
    </Label>
  );
}

export interface SelectItemProps extends HTMLAttributes<HTMLDivElement> {
  defaultIsSelected?: boolean;
  isSelected?: boolean;
  onSelectItem?: (option: SelectOption) => void;
  option: SelectOption;
  renderCheckbox?: (props: ItemCheckboxProps) => ReactNode;
  renderLabel?: (props: ItemLabelProps) => ReactNode;
}

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  function SelectItem(
    {
      className,
      defaultIsSelected = false,
      isSelected,
      onSelectItem,
      option,
      renderCheckbox,
      renderLabel,
    },
    ref,
  ) {
    const [internalIsSelected, setInternalIsSelected] =
      useState(defaultIsSelected);
    const finalIsSelected = isSelected ?? internalIsSelected;

    const handleSelectItem = useCallback(() => {
      onSelectItem?.(option);
      setInternalIsSelected((value) => !value);
    }, [onSelectItem, option]);

    const RenderCheckbox = renderCheckbox ?? ItemCheckbox;
    const RenderLabel = renderLabel ?? ItemLabel;

    return (
      <div
        className={cn(
          "group flex select-none items-center gap-[0.625rem] px-4 py-2 hover:bg-primary-background focus:outline-none focus:ring-0",
          className,
        )}
        data-label={option.label}
        data-state={finalIsSelected ? "checked" : undefined}
        data-value={option.value}
        onClick={handleSelectItem}
        onKeyDown={() => null}
        ref={ref}
        role="button"
        tabIndex={0}
      >
        <RenderCheckbox
          className="p-3"
          isSelected={finalIsSelected}
          option={option}
        />
        <RenderLabel
          className="cursor-pointer text-subtitle2 leading-none text-text-primary group-hover:text-primary-main"
          option={option}
        />
      </div>
    );
  },
);
