import { useParams, useSearch } from "@tanstack/react-router";
import { type InitialTableState, type Row } from "@tanstack/react-table";
import { type HTMLAttributes, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  useGetCurrentEvaluationCycle,
  useListEvaluationCycles,
} from "@/api/evaluationCycles";
import { useListEvaluationRatings } from "@/api/evaluationRatings";
import { NavigationTable } from "@/components/ui/NavigationTable";
import { cn } from "@/utils/ui";

import { EmptyDashboard } from "../../EmptyDashboard";
import { columns, type TableColumnData } from "./columns";
import type { FilterFormProps } from "./FilterForm";
import { FilterForm } from "./FilterForm";
import { defaultValues, formSchema } from "./FilterForm/utils";
import type { FilterSummaryProps } from "./FilterSummary";
import { FilterSummary } from "./FilterSummary";
import { tableStateToQueryParamsTransformer } from "./utils";

const initialState: InitialTableState = {
  columnVisibility: {
    isPreviousCycle: false,
  },
};

export type SelfEvaluationsTabProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>;

export function SelfEvaluationsTab({
  className,
  ...props
}: SelfEvaluationsTabProps) {
  const { t } = useTranslation();
  const from = "/admin/cycles/$cycleId/members/$memberId/self_evaluations";
  const { memberId } = useParams({ from });
  const search = useSearch({ from });
  const queryParams = tableStateToQueryParamsTransformer.parse(search);

  const { currentCycle, isFetched: isFetchedCycle } =
    useGetCurrentEvaluationCycle();

  const { evaluationCycles } = useListEvaluationCycles();

  const { evaluationRatings, isFetched: isFetchedRatings } =
    useListEvaluationRatings({
      ...queryParams,
      evaluator: [Number(memberId)],
      isSelfEvaluation: true,
    });

  const isFetching = !isFetchedCycle || !isFetchedRatings;

  const data = useMemo<TableColumnData[]>(() => {
    if (!evaluationRatings) return [];

    return evaluationRatings.results.map((result) => ({
      ...result,
      isPreviousCycle:
        currentCycle && result.evaluation.cycle.id !== currentCycle.id,
    }));
  }, [currentCycle, evaluationRatings]);

  const renderGroupRow = useCallback(
    ({ row }: { row: Row<TableColumnData> }) =>
      row.original.isPreviousCycle ? (
        <p className="text-subtitle2 text-text-secondary">
          {t("admin.memberDetails.previousCyclesDivider")}
        </p>
      ) : undefined,
    [t],
  );

  const renderFilterForm = useCallback(
    (filterProps: FilterFormProps) => (
      <FilterForm
        {...filterProps}
        evaluationCycles={evaluationCycles?.results ?? []}
      />
    ),
    [evaluationCycles?.results],
  );

  const renderFilterSummary = useCallback(
    (filterProps: FilterSummaryProps<TableColumnData>) => (
      <FilterSummary
        {...filterProps}
        evaluationCycles={evaluationCycles?.results ?? []}
      />
    ),
    [evaluationCycles?.results],
  );

  return (
    <NavigationTable
      className={cn("flex-auto", className)}
      columns={columns}
      data={data}
      defaultFilterFormValues={defaultValues}
      formSchema={formSchema}
      initialState={initialState}
      isFetching={isFetching}
      placeholder={!isFetching && <EmptyDashboard />}
      renderFilterForm={renderFilterForm}
      renderFilterSummary={renderFilterSummary}
      renderGroupRow={renderGroupRow}
      {...props}
    />
  );
}
