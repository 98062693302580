import { useTranslation } from "react-i18next";

import { Tag, type TagProps } from "./Tag";

export interface RatingRangeTagProps extends Omit<TagProps, "onRemoveClick"> {
  onRemoveClick?: (rating: number) => void;
  rating: number;
}

export function RatingRangeTag({
  onRemoveClick,
  rating,
  ...props
}: RatingRangeTagProps) {
  const { t } = useTranslation();

  return (
    <Tag
      label={t("components.filterTag.ratingRange")}
      onRemoveClick={() => onRemoveClick?.(rating)}
      value={
        [0, 5].includes(rating) ? rating.toFixed(2) : `${rating} - ${rating}.99`
      }
      {...props}
    />
  );
}
