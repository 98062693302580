/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetCurrentEvaluation } from "@/api/evaluations";
import { resolver } from "@/components/pages/CreateEvaluationPage/CreateEvaluationForm/utils";

import { useDraftAutosave } from "../../utils/hooks/useDraftAutosave";
import { useEvaluationPageFormDefaultValues } from "../../utils/hooks/useEvaluationPageFormDefaultValues";
import { useEvaluationPageStore } from "../../utils/hooks/useEvaluationPageStore";
import { EvaluatedMembersTable } from "./EvaluatedMembersTable";
import { RankingForm } from "./RankingForm";
import { type FormData as RankingFormData } from "./RankingForm/utils";

export function RankMembersStep() {
  const { t } = useTranslation();

  const { evaluation } = useGetCurrentEvaluation();

  const initialized = useRef(false);

  const { onAutosaveDraftDebounced } = useDraftAutosave();

  const rankingNotes = useEvaluationPageStore((state) => state.rankingNotes);
  const setRankingNotes = useEvaluationPageStore(
    (state) => state.setRankingNotes,
  );

  const { isFetched: isFetchedValues } = useEvaluationPageFormDefaultValues();

  const defaultValues = useMemo<RankingFormData>(
    () => ({
      rankingNotes:
        rankingNotes ||
        (evaluation?.draftData?.rankingNotes ?? evaluation?.rankingNotes ?? ""),
    }),
    [
      evaluation?.draftData?.rankingNotes,
      evaluation?.rankingNotes,
      rankingNotes,
    ],
  );

  const form = useForm<RankingFormData>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (initialized.current && isFetchedValues) return;
    if (isFetchedValues) initialized.current = true;

    setRankingNotes(defaultValues.rankingNotes);
    form.reset(defaultValues);
  }, [defaultValues, form, isFetchedValues, setRankingNotes]);

  return (
    <div className="flex flex-auto items-stretch gap-6 overflow-hidden max-lg:flex-col-reverse max-md:gap-0 max-md:p-0 md:gap-6">
      <div className="flex flex-col gap-[0.625rem] overflow-y-hidden max-md:p-6 max-md:pt-0 md:mx-[0.25rem] md:mb-[0.125rem] md:flex-[57%] md:overflow-y-auto">
        <p className="text-subtitle2 text-text-secondary max-md:hidden">
          {t("evaluations.create.membersEvaluated")}
        </p>
        <EvaluatedMembersTable
          className="m-0.5"
          isFetching={!isFetchedValues}
        />
      </div>
      <RankingForm
        className="flex-1 max-md:p-6 max-md:pt-3 md:mx-[0.25rem] md:h-fit md:flex-[43%]"
        form={form}
        onFormInputValueChange={onAutosaveDraftDebounced}
      />
    </div>
  );
}
