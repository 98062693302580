import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import type { Row } from "@tanstack/react-table";
import { useCallback, useState } from "react";

import type { EvaluationDraft } from "@/api/evaluations";
import { useDiscardDraftById } from "@/api/evaluations/useDiscardDraftById";
import { DeleteEvaluationDraftConfirmDialog } from "@/components/app/DeleteEvaluationDraftConfirmDialog";
import { IconButton } from "@/components/ui/IconButton";
import { cn } from "@/utils/ui";

export function TableRowAction({ row }: { row: Row<EvaluationDraft> }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const discardDraftMutation = useDiscardDraftById({
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClickDelete = () => {
    setIsModalOpen(true);
  };

  const handleClickCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleClickConfirm = useCallback(() => {
    discardDraftMutation.deleteSync(row.original.id);
  }, [discardDraftMutation, row.original.id]);

  return (
    <div className="flex w-[8.25rem] items-center justify-end gap-6 max-md:absolute max-md:inset-y-0 max-md:right-2 max-md:w-[3.125rem] max-md:flex-col-reverse max-md:justify-center max-md:border-l max-md:border-other-divider max-md:pl-2 md:gap-2">
      <DeleteEvaluationDraftConfirmDialog
        isLoading={discardDraftMutation.isPending}
        onCancelClick={handleClickCancel}
        onConfirmClick={handleClickConfirm}
        open={isModalOpen}
      />

      <IconButton
        icon={<TrashIcon className="size-5 text-error-main" />}
        onClick={handleClickDelete}
      />
      <IconButton
        className={cn(
          "transition-transform",
          row.getIsExpanded() ? "" : "rotate-180",
        )}
        icon={<ChevronUpIcon className="size-7 text-text-secondary" />}
        onClick={() => {
          row.toggleExpanded();
        }}
      />
    </div>
  );
}
