import { useMutation, useQueryClient } from "@tanstack/react-query";
import { camelizeKeys, decamelizeKeys } from "humps";

import { api, handleApiError, validateSchema } from "../utils";
import {
  type EvaluationDraftData,
  evaluationDraftsKeys,
  evaluationsKeys,
  type EvaluationSubmitPayload,
  EvaluationSubmitResponseSchema,
} from "./constants";

const submitEvaluation = async (
  payload:
    | EvaluationSubmitPayload
    | { draftData: EvaluationDraftData; isDraft?: boolean },
) => {
  const response = await api.patch("evaluation-cycles/current/evaluate/", {
    json: decamelizeKeys(payload),
  });

  await handleApiError(response, { showErrorToast: false });

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(EvaluationSubmitResponseSchema, data);
};

export const useSubmitEvaluation = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, isSuccess, mutate, mutateAsync } = useMutation({
    mutationFn: submitEvaluation,
    onSuccess: async (data) => {
      if (data === undefined) return;

      await queryClient.invalidateQueries({
        queryKey: evaluationsKeys.detail(data.id),
      });
      await queryClient.invalidateQueries({ queryKey: evaluationsKeys.list() });
      await queryClient.invalidateQueries({
        queryKey: evaluationDraftsKeys.list(),
      });
    },
  });

  return {
    isError,
    isPending,
    isSuccess,
    submit: mutateAsync,
    submitSync: mutate,
  };
};
