import { useQueries, useQuery } from "@tanstack/react-query";
import { camelizeKeys } from "humps";
import { useMemo } from "react";

import { api, handleApiError, validateSchema } from "../utils";
import { type Project, ProjectSchema, projectsKeys } from "./constants";

const getProjectById = async (id: number | null) => {
  if (id === null) return null;

  const response = await api.get(`projects/${id}`);

  await handleApiError(response);

  const json = await response.json();
  const data = camelizeKeys(json);

  return validateSchema(ProjectSchema, data);
};

export const useGetProjectById = (
  id: number | null,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  const { data, isFetched, isFetching } = useQuery({
    enabled,
    queryFn: () => getProjectById(id),
    queryKey: projectsKeys.detail(id),
  });

  return { project: data || null, isFetched, isFetching };
};

export const useGetProjectsByIds = (ids: number[]) => {
  const { data, isFetched, isFetching } = useQueries({
    queries: ids.map((id) => ({
      queryFn: () => getProjectById(id),
      queryKey: projectsKeys.detail(id),
    })),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isFetched: results.every((result) => result.isFetched),
      isFetching: results.some((result) => result.isFetching),
    }),
  });

  const projects = useMemo(
    () =>
      data.filter(
        (result) => result !== null && result !== undefined,
      ) as unknown as Project[],
    [data],
  );

  return { projects, isFetched, isFetching };
};
