import { createFileRoute } from "@tanstack/react-router";
import type { z } from "zod";

import { formSchema } from "@/components/pages/MemberDetailPage/Tabs/EvaluatorsTab/FilterForm/utils";
import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

const routeParamsSchema = TableStateBaseParamsSchema.extend({
  filters: formSchema.optional(),
}).transform(({ per = 0, ordering = ["evaluator"], ...schema }) => ({
  ...schema,
  per,
  ordering,
}));

export type RouteParams = z.infer<typeof routeParamsSchema>;

export const Route = createFileRoute(
  "/admin/cycles/$cycleId/members/$memberId/evaluators",
)({
  validateSearch: (search) => routeParamsSchema.parse(search),
  beforeLoad: () => {
    return { routeId: null };
  },
});
