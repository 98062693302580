import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import FileSearching from "@/assets/svg/file_searching.svg?react";
import { cn } from "@/utils/ui";

export type EmptyDashboardProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>;

export function EmptyDashboard({ className, ...props }: EmptyDashboardProps) {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        "relative h-full flex items-center justify-center",
        className,
      )}
      {...props}
    >
      <div className="space-y-[4.5rem] text-center">
        <FileSearching
          className="m-auto h-[12.9375rem]"
          title={t("evaluations.dashboard.emptyTitle")}
        />
        <p className="m-auto w-[35.125rem] text-body1 text-text-hint">
          {t("evaluations.dashboard.emptyInfo")}
        </p>
      </div>
    </div>
  );
}
