import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/utils/ui";

const buttonVariants = cva(
  "flex items-center justify-center rounded-full p-1 disabled:text-actions-disabled-text",
  {
    variants: {
      size: {
        large: "size-[2.6875rem]",
        medium: "size-8",
        small: "size-7",
        custom: "",
      },
      color: {
        primary: "text-primary-main enabled:hover:bg-primary-background",
        secondary: "text-secondary-main enabled:hover:bg-secondary-background",
        text: "text-text-secondary enabled:hover:bg-actions-hover",
        custom: "",
      },
    },
    defaultVariants: {
      size: "small",
      color: "text",
    },
  },
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: React.ReactNode;
}

const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      size,
      color,
      asChild = false,
      icon,
      type = "button",
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(
          buttonVariants({ size, color, className }),
          "*:size-full",
        )}
        ref={ref}
        type={type}
        {...props}
      >
        {icon}
      </Comp>
    );
  },
);
IconButton.displayName = "IconButton";

export { IconButton };
