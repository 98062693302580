import { UserGroupIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import { Dropdown } from "@/components/ui/Dropdown";

interface AdminNavigationProps {
  routePath: string;
}

function AdminNavigation({ routePath }: AdminNavigationProps) {
  const { t } = useTranslation();

  return (
    <div className="pb-3 pt-1.5">
      <div className="px-4 text-overline uppercase text-text-disabled">
        {t("components.navigation.switchTo")}
      </div>

      {routePath.startsWith("/admin") && (
        <Link to="/evaluations">
          <Dropdown.Item className="flex items-center gap-1.5 text-subtitle2 text-primary-main">
            <UserIcon className="size-4 stroke-2" />
            {t("components.navigation.memberView")}
          </Dropdown.Item>
        </Link>
      )}

      {routePath.startsWith("/evaluations") && (
        <Link to="/admin">
          <Dropdown.Item className="flex items-center gap-1.5 text-subtitle2 text-primary-main">
            <UserGroupIcon className="size-4 stroke-2" />
            {t("components.navigation.adminView")}
          </Dropdown.Item>
        </Link>
      )}
    </div>
  );
}

export { AdminNavigation };
