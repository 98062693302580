import React from "react";

import { LoadingComponent } from "@/components/app/LoadingComponent";
import { cn } from "@/utils/ui";

interface BaseProps {
  children?: React.ReactNode;
  className?: string;
}

interface StateProps extends BaseProps {
  loadingClassName?: string;
  loadingComponent?: React.ReactNode;
  isLoading?: boolean;
}

export function withLoadingState<P extends BaseProps>(
  Component: React.ComponentType<P>,
  options?: Pick<StateProps, "loadingClassName" | "loadingComponent">,
) {
  function WrappedComponent({
    children,
    className,
    isLoading,
    loadingClassName,
    loadingComponent,
    ...props
  }: P & StateProps) {
    if (!isLoading)
      return (
        <Component className={className} {...(props as P)}>
          {children}
        </Component>
      );

    const finalLoadingComponent = loadingComponent ??
      options?.loadingComponent ?? (
        <LoadingComponent className="absolute inset-0 h-full" />
      );

    return (
      <Component
        className={cn(
          className,
          "relative",
          loadingClassName ?? options?.loadingClassName ?? "opacity-60",
        )}
        isLoading={isLoading}
        {...(props as P)}
      >
        {children}
        {finalLoadingComponent}
      </Component>
    );
  }
  WrappedComponent.displayName = Component.displayName;
  return WrappedComponent;
}
