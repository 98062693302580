/* eslint-disable @typescript-eslint/no-misused-promises */
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { type UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { EvaluationCycleStatus } from "@/api/evaluationCycles";
import type { EvaluationCycle } from "@/api/evaluationCycles/constants";
import { useGetProjectsByIds, useListProjects } from "@/api/projects";
import {
  MultiSelectField,
  MultiSelectSearchField,
  SelectField,
} from "@/components/app/FormFields";
import { Button } from "@/components/ui/Button";
import { Form } from "@/components/ui/Form";
import { IconButton } from "@/components/ui/IconButton";
import { Spinner } from "@/components/ui/Spinner";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { cn } from "@/utils/ui";

import { type FormData as EvaluationsFilterFormData } from "./utils";

const orderingFieldsOptions = [
  { label: "Member Name", value: "evaluatee" },
  { label: "Deadline", value: "deadline" },
  { label: "Date Submitted", value: "evaluatedAt" },
  { label: "Rating", value: "rating" },
  { label: "Project", value: "project" },
];

export interface EvaluationsFilterFormProps {
  className?: string;
  resetDisabled?: boolean;
  form: UseFormReturn<EvaluationsFilterFormData>;
  onReset?: () => void;
  onCloseClick?: () => void;
  onSubmit: (formData: EvaluationsFilterFormData) => void;
  submitDisabled?: boolean;
  evaluationCycles: EvaluationCycle[];
}

export function EvaluationsFilterForm({
  className,
  resetDisabled,
  form,
  onReset,
  onCloseClick,
  onSubmit,
  submitDisabled,
  evaluationCycles,
}: EvaluationsFilterFormProps) {
  const { t } = useTranslation();
  const { maxMd } = useScreenSize();
  const screenType = maxMd ? "mobile" : "default";

  const [projectSearch, setProjectSearch] = useState<string>();

  const {
    results: projects,
    isFetched: isFetchedProjects,
    cursor: projectsCursor,
  } = useListProjects({
    ...(projectSearch && { search: projectSearch }),
  });

  const [projectsScrollSentryRef] = useInfiniteScroll({
    loading: projectsCursor.isFetching,
    hasNextPage: projectsCursor.hasNextPage,
    onLoadMore: projectsCursor.loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  const evaluationStatusOptions = useMemo(
    () =>
      Object.values(EvaluationCycleStatus).map((value) => ({
        label: t(`components.evaluationFilterForm.statusOptions.${value}`),
        value,
      })),
    [t],
  );

  const selectedProjectIds = form.getValues("project") ?? [];
  const { projects: selectedProjects, isFetched: isFetchedSelectedProjects } =
    useGetProjectsByIds(selectedProjectIds);

  const isFetchingProjects = !isFetchedSelectedProjects || !isFetchedProjects;

  const projectsList = useMemo(() => {
    const fetchedProjectIds = projects.map(({ id }) => id);
    const selectedProjectsExtra = selectedProjects.filter(
      ({ id }) => !fetchedProjectIds.includes(id),
    );
    return [...projects, ...selectedProjectsExtra];
  }, [projects, selectedProjects]);

  const cycleOptions = useMemo(
    () =>
      evaluationCycles.map((cycle) => ({
        label: cycle.name,
        value: cycle.id,
      })),
    [evaluationCycles],
  );

  const projectOptions = useMemo(
    () =>
      projectsList.map((project) => ({
        label: project.name,
        value: project.id,
      })),
    [projectsList],
  );

  return (
    <Form {...form}>
      <form
        className={cn("flex flex-col items-stretch gap-[0.625rem]", className)}
        onSubmit={(e) => {
          void form.handleSubmit(onSubmit)(e);
        }}
      >
        <div className="flex flex-col items-stretch gap-6">
          <div className="flex items-center justify-between">
            <p className="text-h6 text-text-primary">
              {t("components.evaluationFilterForm.filters")}
            </p>
            {onCloseClick !== undefined && (
              <IconButton
                icon={<XMarkIcon className="size-6 text-text-secondary" />}
                onClick={onCloseClick}
              />
            )}
          </div>
          <div className="flex flex-col items-stretch gap-[1.125rem] pb-[1.4375rem]">
            <MultiSelectField
              control={form.control}
              label={t("components.evaluationFilterForm.evaluationCycle")}
              name="cycle"
              options={cycleOptions}
              placeholder={t(
                `components.evaluationFilterForm.placeholderSelectCycle.${screenType}`,
              )}
            />
            <MultiSelectSearchField
              control={form.control}
              isFetching={isFetchingProjects}
              label={t("components.evaluationFilterForm.project")}
              name="project"
              onSearchChange={setProjectSearch}
              options={projectOptions}
              placeholder={t(
                `components.evaluationFilterForm.placeholderSelectProject.${screenType}`,
              )}
              scrollSentry={
                <div
                  className="flex min-h-[1px] w-full justify-center"
                  ref={projectsScrollSentryRef}
                >
                  {Boolean(projectsCursor.isFetching) && (
                    <div className="p-2">
                      <Spinner className="size-4" />
                    </div>
                  )}
                </div>
              }
            />
            <SelectField
              control={form.control}
              fallbackValue=""
              label={t("components.evaluationFilterForm.status")}
              name="cycleStatus"
              options={evaluationStatusOptions}
              placeholder={t(
                `components.evaluationFilterForm.placeholderSelectStatus.${screenType}`,
              )}
            />
            {Boolean(maxMd) && (
              <MultiSelectField
                control={form.control}
                label={t("components.evaluationFilterForm.ordering")}
                name="ordering"
                options={orderingFieldsOptions}
                placeholder={t(
                  "components.evaluationFilterForm.placeholderSelectOrdering.mobile",
                )}
              />
            )}
          </div>
        </div>

        <div className="flex gap-[0.625rem]">
          <Button
            className="flex-auto"
            disabled={resetDisabled}
            onClick={onReset}
            type="reset"
            variant="outlined"
          >
            {t("components.evaluationFilterForm.buttonClearFilters")}
          </Button>
          <Button className="flex-auto" disabled={submitDisabled} type="submit">
            {t("components.evaluationFilterForm.buttonApplyFilters")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
