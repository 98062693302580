import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const routeParamsSchema = z
  .object({
    evaluateeId: z.string().or(z.number()).optional(),
    projectId: z.string().or(z.number()).optional(),
  })
  .transform(({ evaluateeId, projectId }) => {
    const numEvaluateeId = Number.parseInt(`${evaluateeId ?? ""}`);
    const numProjectId = Number.parseInt(`${projectId ?? ""}`);

    return {
      ...(!Number.isNaN(numEvaluateeId) && { evaluateeId: numEvaluateeId }),
      ...(!Number.isNaN(numProjectId) && { projectId: numProjectId }),
    };
  });

export const Route = createFileRoute("/evaluations/view")({
  beforeLoad: () => {
    return { routeId: "evaluationView" };
  },
  validateSearch: (search) => routeParamsSchema.parse(search),
});
