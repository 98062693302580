import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { UserRole } from "@/api/users";

const gitlabCallbackSearchSchema = z.object({
  code: z.string(),
  redirect: z.string().optional(),
});

export const Route = createFileRoute("/auth/gitlab/redirect")({
  validateSearch: (search): z.input<typeof gitlabCallbackSearchSchema> =>
    gitlabCallbackSearchSchema.parse(search),
  beforeLoad: ({ context, search }) => {
    const { code, redirect: redirectTo } = search;

    if (!code) {
      throw redirect({ to: "/login" });
    }

    if (!context.auth) {
      return;
    }

    const {
      auth: { isAuthenticated, userRole },
    } = context;

    if (isAuthenticated) {
      if (redirectTo) {
        throw redirect({ to: redirectTo });
      } else {
        const navigateTo =
          userRole === UserRole.Administrator ? "/admin" : "/evaluations";
        throw redirect({ to: navigateTo });
      }
    }
  },
});
