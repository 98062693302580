import { createFileRoute } from "@tanstack/react-router";
import type { z } from "zod";

import { TableStateBaseParamsSchema } from "@/components/ui/NavigationTable/utils";

const routeParamsSchema = TableStateBaseParamsSchema.transform(
  ({ per = 0, grouping = [], ordering = [], ...schema }) => ({
    ...schema,
    per,
    grouping: [...new Set(grouping)],
    ordering: [...new Set(ordering)],
  }),
);

export type RouteParams = z.infer<typeof routeParamsSchema>;

export const Route = createFileRoute(
  "/admin/cycles/$cycleId/members/$memberId/ranking_notes",
)({
  validateSearch: (search) => routeParamsSchema.parse(search),
  beforeLoad: () => {
    return { routeId: null };
  },
});
