import { useCallback, useMemo, useState } from "react";

import { useListUsers, type User } from "@/api/users";
import { SearchSelect, type SelectOption } from "@/components/ui/SearchSelect";
import type { SelectItemProps } from "@/components/ui/SearchSelect/SelectItem";

import { MemberSelectItem } from "./MemberSelectItem";

export interface MemberSearchSelectProps {
  disableFiltering?: boolean;
  excludedMembers: User[];
  onSelectItem: (
    option: SelectOption,
    isSelected: boolean,
    selectedUsers: User[],
  ) => void;
  placeholder?: string;
}

export function MemberSearchSelect({
  disableFiltering = true,
  excludedMembers,
  onSelectItem,
  placeholder,
}: MemberSearchSelectProps) {
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserItems, setSelectedUserItems] = useState<User[]>([]);

  const { users } = useListUsers({ search: searchText });

  const usersList = useMemo(() => users ?? [], [users]);

  const [usersCache, setUsersCache] = useState<User[]>([]);
  if (
    usersList.some(
      (user) => !usersCache.find((userCache) => userCache.id === user.id),
    )
  ) {
    setUsersCache((prev) => {
      const updated = [...prev];
      for (const user of usersList) {
        if (!prev.find((userCache) => userCache.id === user.id)) {
          updated.push(user);
        }
      }
      return updated;
    });
  }

  const selectedItems = useMemo(
    () =>
      selectedUserItems.map((user) => ({
        label: user.fullName,
        value: user.id,
      })),
    [selectedUserItems],
  );

  const options = useMemo(
    () => usersList.map((user) => ({ label: user.fullName, value: user.id })),
    [usersList],
  );
  const excludedMemberIds = useMemo(
    () => excludedMembers.map(({ id }) => id),
    [excludedMembers],
  );
  const remainingOptions = useMemo(
    () => options.filter(({ value }) => !excludedMemberIds.includes(value)),
    [options, excludedMemberIds],
  );

  const handleConfirmClick = useCallback(() => {
    setSelectedUserItems([]);
  }, []);

  const handleSearchChange = useCallback((search: string) => {
    setSearchText(search);
  }, []);

  const handleSelectItem = useCallback(
    (option: SelectOption, isSelected: boolean) => {
      const selectedUser = usersCache.find(({ id }) => id === option.value);

      if (!selectedUser) return;

      const updatedSelectedUsers = !isSelected
        ? selectedUserItems.filter(({ id }) => id !== option.value)
        : [...selectedUserItems, selectedUser];

      onSelectItem(option, isSelected, updatedSelectedUsers);

      setSelectedUserItems((current) =>
        !isSelected
          ? current.filter(({ id }) => id !== option.value)
          : [...current, selectedUser],
      );
    },
    [onSelectItem, selectedUserItems, usersCache],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCancelClick = useCallback(() => {
    setIsOpen(false);
    setSelectedUserItems([]);
  }, []);

  const renderItem = useCallback(
    (itemProps: SelectItemProps) => {
      const user = usersList.find(({ id }) => id === itemProps.option.value);
      return user ? <MemberSelectItem {...itemProps} user={user} /> : null;
    },
    [usersList],
  );

  return (
    <SearchSelect
      disableFiltering={disableFiltering}
      isOpen={isOpen}
      onCancelClick={handleCancelClick}
      onClose={handleClose}
      onConfirmClick={handleConfirmClick}
      onOpen={handleOpen}
      onSearchChange={handleSearchChange}
      onSelectItem={handleSelectItem}
      options={remainingOptions}
      placeholder={placeholder}
      renderDropdownFooter={() => null}
      renderItem={renderItem}
      value={selectedItems}
    />
  );
}
