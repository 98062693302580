import { useSearch } from "@tanstack/react-router";

import { EvaluationStep } from "@/api/constants";
import { useGetCurrentUser } from "@/api/users";

export const useMemberSelectionState = () => {
  const { evaluateeId, projectId, step } = useSearch({
    from: "/evaluations/create/",
  });

  const { currentUser } = useGetCurrentUser();

  const isCurrentUserClicked = step === EvaluationStep.EvaluateSelf;

  const clickedProjectId = isCurrentUserClicked ? null : projectId || null;

  const clickedUserId = isCurrentUserClicked
    ? currentUser?.id || null
    : evaluateeId || null;

  return { clickedProjectId, clickedUserId, isCurrentUserClicked };
};
